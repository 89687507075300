<section class="mb-8">
  <h2>Assets</h2>
  <div class="table-wrapper">
    <table>
      <thead>
        <tr>
          <th scope="col" class="sr-only" id="asset">Asset</th>
          <th scope="col" id="borrowerVolume">Purchaser Volume</th>
          <th scope="col" id="lenderVolume">Seller Volume</th>
          <th scope="col" id="borrowerRewards">Purchaser Rewards</th>
          <th scope="col" id="lenderRewards">Seller Rewards</th>
          <th scope="col" class="sr-only" id="actions">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let a of assets">
          <td headers="asset" class="items-center">
            <div class="flex">
              <sc-icon [src]="cryptoIcons[a.symbol]" width="32px" height="32px"
                class="self-center mb-2">
              </sc-icon>
              <div class="pl-3">
                <div class="text-base font-medium">{{ a.symbol }}</div>
                <div class="font-normal text-gray-500">{{ a.name }}</div>
              </div>
            </div>
          </td>
          <td headers="borrowerVolume" class="items-center">
            <div class="flex flex-col">
              <div class="text-base font-medium">{{ a.totalBorrowedValueInUSD || 0 |
                currency:'USD':'symbol-narrow':'1.0-0' }}</div>
              <div class="font-normal text-gray-500">Min. Fee: {{ a.minInterestRate }}%
              </div>
            </div>
          </td>
          <td headers="lenderVolume" class="items-center">
            <div class="flex flex-col">
              <div class="text-base font-medium">{{ a.totalLendedValueInUSD || 0 |
                currency:'USD':'symbol-narrow':'1.0-0' }}</div>
              <div class="font-normal text-gray-500">Max APY: {{ a.maxAPY }}% </div>
            </div>
          </td>
          <td headers="borrowerRewards">
            <span class="pill primary">{{ a.borrowersAPY || 0 }}%</span>
          </td>
          <td headers="lenderRewards">
            <span class="pill success">{{ a.lendersAPY || 0 }}%</span>
          </td>
          <td headers="actions" class="text-right">
            <a role="button" class="primary-inverse sm mx-1" [routerLink]="['/purchase']"
              [queryParams]="{a: a.symbol}" [href]="environment.baseUrl + '/purchase'">
              Purchase
            </a>
            <a role="button" *ngIf="a.symbol !== 'SMARTCREDIT'" class="success-inverse sm mx-1"
              [routerLink]="['/sell']" [queryParams]="{a: a.symbol}"
              [href]="environment.baseUrl + '/sell'">
              Sell
            </a>
            <!-- <a role="button" *ngIf="a.symbol === 'SMARTCREDIT'" class="success-inverse sm mx-1"
              [routerLink]="['/stake']" [href]="environment.baseUrl + '/stake'">
              Lend
            </a> -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
