import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GENERAL_ICONS } from 'src/assets/images/svg-icons';
import { filter, Subject, takeUntil } from 'rxjs';
import { LoanData } from 'src/app/shared/interfaces/borrowing/loan-data';
import { AppState } from 'src/app/store/app.reducer';
import { scFadeInOut } from 'src/config/animations.config';
@Component({
  selector: 'app-create-loan',
  templateUrl: './create-loan.dialog.html',
  styles: [``],
  animations: [scFadeInOut],
})
export class CreateLoanDialog implements OnInit, OnDestroy {
  @HostBinding('@scFadeInOut') animationState = true;
  public icons = { ...GENERAL_ICONS };
  public creditLine: string;
  public loanData: LoanData;
  private destroy$ = new Subject<void>();

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.store.select(state => state.borrowing.loanData)
      .pipe(filter(loanData => !!loanData), takeUntil(this.destroy$))
      .subscribe((loanData: LoanData) => {
        this.loanData = loanData;
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
