export const PLATFORM = {
  abi: [{
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'fixedIncomeFund',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'salt',
        type: 'bytes32'
      }
    ],
    name: 'FixedIncomeFundCreationComplete',
    type: 'event'
  }, {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'creditLine',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'borrower',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'bytes32',
        name: 'salt',
        type: 'bytes32'
      },
      {
        indexed: false,
        internalType: 'bytes32',
        name: '_type',
        type: 'bytes32'
      }
    ],
    name: 'LoanContractCreated',
    type: 'event'
  }, {
    inputs: [
      {
        internalType: 'bytes32',
        name: '_type',
        type: 'bytes32'
      },
      {
        internalType: 'bytes32',
        name: '_salt',
        type: 'bytes32'
      }
    ],
    name: 'createCreditLine',
    outputs: [
      {
        internalType: 'address',
        name: '_creditLine',
        type: 'address'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [
      {
        internalType: 'bytes32',
        name: '_type',
        type: 'bytes32'
      },
      {
        internalType: 'bytes32',
        name: '_salt',
        type: 'bytes32'
      },
      {
        components: [
          {
            internalType: 'address',
            name: 'assetAddress',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'loanAmount',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'loanTerm',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'interestRate',
            type: 'uint256'
          },
          {
            internalType: 'address',
            name: 'collateralAddress',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'collateralId',
            type: 'uint256'
          }
        ],
        internalType: 'struct INFTLoan.LoanRequestCreate',
        name: '_loanRequest',
        type: 'tuple'
      }
    ],
    name: 'createNFTLoan',
    outputs: [
      {
        internalType: 'address',
        name: '_loanContract',
        type: 'address'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [
      {
        internalType: 'bytes32',
        name: '_type',
        type: 'bytes32'
      },
      {
        internalType: 'bytes32',
        name: '_salt',
        type: 'bytes32'
      },
      {
        internalType: 'uint256[4]',
        name: '_ratios',
        type: 'uint256[4]'
      }
    ],
    name: 'createFixedIncomeFund',
    outputs: [
      {
        internalType: 'address',
        name: '_fixedIncomeFund',
        type: 'address'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [
      {
        internalType: 'address[]',
        name: '_fixedIncomeFunds',
        type: 'address[]'
      }
    ],
    name: 'investFixedIncomeFundToCompound',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }, {
    inputs: [
      {
        components: [
          {
            internalType: "bytes32",
            name: "leverageId",
            type: "bytes32"
          },
          {
            internalType: "uint256",
            name: "initialInvestment",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "leverageShares",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "leveragePeriod",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "interestRate",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "borrowFeeRate",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "leverageFeeRate",
            type: "uint256"
          },
          {
            components: [
              {
                internalType: "uint256",
                name: "borrowedFunds",
                type: "uint256"
              },
              {
                internalType: "address",
                name: "lenderAddress",
                type: "address"
              }
            ],
            internalType: "struct LeverageStorage.Lender[]",
            name: "lenders",
            type: "tuple[]"
          },
          {
            internalType: "address",
            name: "insurer",
            type: "address"
          }
        ],
        internalType: "struct LeverageStorage.LeverageRequest",
        name: "_leverageRequest",
        type: "tuple"
      },
      {
        internalType: "address",
        name: "_leverageLine",
        type: "address"
      },
      {
        internalType: "bytes",
        name: "_signature",
        type: "bytes"
      },
      {
        internalType: "uint256",
        name: "_deadline",
        type: "uint256"
      }
    ],
    name: "requestLeverage",
    outputs: [],
    stateMutability: "payable",
    type: "function",
    payable: true
  }, {
    "inputs": [
      {
        "components": [
          {
            "internalType": "bytes32",
            "name": "loanId",
            "type": "bytes32"
          },
          {
            "internalType": "uint64",
            "name": "loanInterestRate",
            "type": "uint64"
          },
          {
            "internalType": "uint64",
            "name": "loanTerm",
            "type": "uint64"
          },
          {
            "internalType": "uint256",
            "name": "loanAmount",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "fee",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "initialCollateralAmount",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "lender",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "collateralAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "underlyingAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "insurer",
            "type": "address"
          }
        ],
        "internalType": "struct LoanStorage.LoanRequest",
        "name": "_loanRequest",
        "type": "tuple"
      },
      {
        "internalType": "address",
        "name": "_creditLine",
        "type": "address"
      },
      {
        "internalType": "bytes",
        "name": "_signature",
        "type": "bytes"
      },
      {
        "internalType": "uint256",
        "name": "_deadline",
        "type": "uint256"
      }
    ],
    "name": "requestLoan",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  }]
};