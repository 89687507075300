<section>
  <div class="flex flex-col md:flex-row w-full md:items-center justify-between gap-4 mb-3">
    <h2 class="mr-2 mb-0">Articles & Announcements</h2>
    <a href="https://blog.islamiccoin.net/" target="_blank"
      class="flex items-center text-sc-black hover:text-sc-primary font-semibold text-sm">
      BarakaFi Blog
      <sc-icon [size]="'0.3'" [src]="icons.external" [width]="'1rem'" [height]="'0rem'"
        [classes]="'-mt-2 h-0'" class="h-0"></sc-icon>
    </a>
  </div>
  <div *ngIf="articles && articles.length" class="flex max-w-full gap-4 flex-wrap md:flex-nowrap">
    <ng-container *ngFor="let article of articles; let i = index">
      <shared-article *ngIf="i < 4" class="w-full md:w-1/4" [title]="article.title"
        [image]="article.image" [url]="article.url"></shared-article>
    </ng-container>
  </div>
</section>
