import { Component, EventEmitter, Output } from '@angular/core';
import { MENU_ICONS } from 'src/assets/images/svg-icons';

@Component({
  selector: 'app-simple-advanced-info',
  templateUrl: './simple-advanced-info.component.html',
  styleUrls: ['./simple-advanced-info.component.scss']
})
export class SimpleAdvancedInfoComponent {
  icons = { ...MENU_ICONS }
  @Output('close') close: EventEmitter<void> = new EventEmitter();

  onClose() {
    this.close.emit();
  }
}
