import { Component, Input } from '@angular/core';

@Component({
  selector: 'spinner',
  template: `<div class="lds-ring {{size}} {{color}}"><div></div><div></div><div></div><div></div></div>`,
})

export class SpinnerComponent {
  @Input() size: 'xxs' | 'xs' | 'sm' | 'md' | 'lg';
  @Input() color?: 'gray';
}
