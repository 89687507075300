<div class="lg:-mr-6" [ngClass]="{'lg:mr-0': promoCards.length > 3, 'cards-container': promoCards.length > 3 }">
  <drag-scroll *ngIf="promoCards" class="h-44 w-full" [drag-scroll-y-disabled]="true" [snap-duration]="250">
    <div drag-scroll-item *ngFor="let card of promoCards" class="w-11/12 md:w-4/5"
      [ngClass]="{'lg:w-[32%]': promoCards.length > 3, 'lg:w-1/3': promoCards.length <= 3 }">
      <div class="promo-card flex select-none">
        <div class="h-36 flex flex-col justify-between py-4 pl-6">
          <div class="font-semibold line-clamp-1 whitespace-normal">{{ card.title }}</div>
          <div class="line-clamp-3 whitespace-normal">{{ card.description }}</div>
          <div *ngIf="card.actions" class="flex -ml-6 -mb-4 pl-6 py-2">
            <ng-container *ngFor="let action of card.actions">
              <a role="button" *ngIf="action.routerLink" [routerLink]="action.routerLink" [href]="environment.baseUrl + action.routerLink"
                class="nostyle w-fit flex items-center mr-3 text-base" title="{{ action.name }}">
                {{ action.name }}
                <sc-icon [src]="icons.rightArrow" [size]="'0.875'" class="ml-1"
                  [width]="'1.6rem'"></sc-icon>
              </a>
              <a *ngIf="action.link" [href]="action.link" target="_blank">
                <button class="nostyle w-fit text-base flex items-center mr-3">
                  {{ action.name }}
                  <sc-icon [src]="icons.externalOp" [size]="'0.3'" class="ml-1 -mt-3.5"
                    [width]="'0.625rem'" [height]="'0rem'"></sc-icon>
                </button>
              </a>
            </ng-container>
          </div>
        </div>
        <!-- <img *ngIf="card.image" src="{{ card.image }}" alt="Invite Friends" class="w-32 h-32"> -->
      </div>
    </div>
  </drag-scroll>
</div>
