<div class="rounded-3xl border-slate-200 bg-white p-4">
  <div class="flex justify-between items-center mb-4">
    <h3 class="mb-0 mr-2 truncate">What is Crypto Credit Score?</h3>
    <button class="default sm" (click)="onClose()">
      Close
    </button>
  </div>
  <p class="mb-2 text-slate-500">
    Higher Crypto Credit Score means lower interest rate and collateral ratio. Improve your Crypto Credit Score by
    completing tasks on <a class="text-base" href="https://chainaware.ai/credit-score" target="_blank">ChainAware.ai</a>.
  </p>
</div>
