import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditLineType'
})
export class CreditLineTypePipe implements PipeTransform {

  transform(creditLineType: unknown): string {
    let assets: string;
    let collaterals: string;
    if (creditLineType === 'Type A Loan') {
      assets = 'ISLM'
      collaterals = 'ERC20'
    } else if (creditLineType === 'Type B Loan') {
      assets = 'ERC20'
      collaterals = 'ISLM'
    } else if (creditLineType === 'Type C Loan') {
      assets = 'ERC20'
      collaterals = 'ERC20'
    } else if (creditLineType === 'Type D Loan') {
      assets = 'ISLM'
      collaterals = 'ISLM'
    }
    return `${assets} - ${collaterals}`;
  }
}
