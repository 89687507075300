import { Dialog, DialogRef } from "@angular/cdk/dialog";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { LegendPosition, ScaleType } from "@swimlane/ngx-charts";
import { filter, Subject, take, takeUntil } from "rxjs";
import { LendingService } from "src/app/core/services/lending.service";
import * as constants from "src/app/shared/constants";
import { FifBucket } from "src/app/shared/interfaces/fifs/fif-bucket";
import { FixedIncomeFund } from "src/app/shared/interfaces/fifs/fixedIncomeFund";
import { User } from "src/app/shared/interfaces/user/user";
import { AppState } from "src/app/store/app.reducer";
import { CRYPTO_ICONS, FIF, GENERAL_ICONS } from "src/assets/images/svg-icons";
import { DepositFifDialog } from "../../components/deposit-fif-dialog/deposit-fif.dialog";
import { DistributionInfoDialog } from "../../components/distribution-info/distribution-info.dialog";
import { ReallocateDialog } from "../../components/reallocate/reallocate.dialog";

@Component({
  selector: "app-fif",
  templateUrl: "./fif.component.html",
  styleUrls: ["./fif.component.scss"],
})
export class FifComponent implements OnInit, OnDestroy {
  public icons: any;
  public fif: FixedIncomeFund;
  public fundId: string;
  public apy: number = 0;
  public riskFreeRateLogBase: number;
  public user: User;
  public isTerminating: boolean;
  public isWithdrawing: boolean;
  public isDepositing: boolean;
  public isReallocating: boolean;
  public pulse: boolean;
  private reallocateDialog: DialogRef<any>;
  private depositDialogRef: DialogRef<any>
  private fundDialog: DialogRef<any>;
  private destroy$ = new Subject<void>();
  view: [number, number] = [170, 150];
  legend: boolean = true;
  legendPosition: LegendPosition = LegendPosition.Below;
  colorSchemes = [
    {
      name: 'cool',
      selectable: true,
      group: ScaleType.Ordinal,
      domain: [
        '#05D394',
      ]
    }, {
      name: 'cool',
      selectable: true,
      group: ScaleType.Ordinal,
      domain: [
        '#7ea5e9',
      ]
    }, {
      name: 'cool',
      selectable: true,
      group: ScaleType.Ordinal,
      domain: [
        '#4d7dd6',
      ]
    }, {
      name: 'cool',
      selectable: true,
      group: ScaleType.Ordinal,
      domain: [
        '#1e56c3',
      ]
    }
  ]
  public chartData: any = [];

  constructor(
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private lendingService: LendingService,
    public dialog: Dialog
  ) {
    this.fundId = this.activatedRoute.snapshot.paramMap.get("id");
    this.icons = { ...GENERAL_ICONS, ...FIF, ...CRYPTO_ICONS }
  }

  ngOnInit(): void {
    this.lendingService.getRiskFreeRateLogBase();
    this.store.select(state => state.core.user)
      .pipe(
        filter(user => !!user),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.user = user;
        if (this.user && this.user.ethAddress) {
          this.lendingService.getFixedIncomeFunds(this.user.ethAddress, false);
        }
      });

    this.store.select(state => state.lending.fixedIncomeFunds)
      .pipe(filter(fifs => !!fifs), takeUntil(this.destroy$))
      .subscribe((fifs) => {
        this.fif = fifs.find((fif) => fif.id === this.fundId);
        this.fif = { ...this.fif, buckets: this.fif.buckets.slice().sort((a, b) => a.index - b.index) }
        this.chartData = this.fif.buckets.map((bucket: FifBucket) => ([{
          name: `${bucket.minTerm} - ${bucket.maxTerm} days`,
          value: bucket.investmentRatio
        }]))
        this.store.select(state => state.lending.riskFreeRateLogBase)
          .pipe(
            filter(riskFreeRateLogBase => !!riskFreeRateLogBase),
            takeUntil(this.destroy$)
          )
          .subscribe((riskFreeRateLogBase: number) => {
            this.riskFreeRateLogBase = riskFreeRateLogBase;
            if (this.riskFreeRateLogBase && typeof this.riskFreeRateLogBase === 'number') {
              this.lendingService
                .getApy(this.fif.underlying, this.fif.buckets, this.riskFreeRateLogBase)
                .pipe(takeUntil(this.destroy$))
                .subscribe((apy: number) => {
                  this.apy = apy;
                })
            } else {
              this.lendingService.getRiskFreeRateLogBase();
            }
          });
      })

    this.store.select(state => state.lending)
      .pipe(takeUntil(this.destroy$))
      .subscribe((lendingState) => {
        this.isWithdrawing = lendingState.withdrawingFif;
        this.isTerminating = lendingState.terminatingFif;
        this.isDepositing = lendingState.depositingFif;
        this.isReallocating = lendingState.reallocatingFif;
      })
  }

  openDepositDialog() {
    this.depositDialogRef = this.dialog.open(DepositFifDialog, {
      id: 'deposit-fif-dialog',
      minWidth: '300px',
      autoFocus: false,
      data: {
        ethAddress: this.fif.ownerAddress,
        contractAddress: this.fif.contractAddress,
        currency: this.fif.underlying,
      }
    });

    this.depositDialogRef.componentInstance['deposited']
      .pipe(takeUntil(this.destroy$))
      .subscribe((amount) => {
        this.lendingService.depositFunds(this.fif, amount)
        this.depositDialogRef.close();
      })

    this.depositDialogRef.componentInstance['reject']
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.depositDialogRef.close();
      })
  }

  openReallocateDialog() {
    this.reallocateDialog = this.dialog.open(ReallocateDialog, {
      id: 'reallocate',
      minWidth: '400px',
      maxWidth: '400px',
      autoFocus: false,
      data: {
        buckets: this.fif.buckets,
        currency: this.fif.underlying,
        riskFreeRateLogBase: this.riskFreeRateLogBase
      }
    });

    this.reallocateDialog.componentInstance['reallocate']
      .pipe(takeUntil(this.destroy$))
      .subscribe((buckets: FifBucket[]) => {
        this.lendingService.reallocate(this.fif, buckets)
        this.reallocateDialog.close();
      })

    this.reallocateDialog.componentInstance['reject']
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.reallocateDialog.close();
      })
  }

  openInfoDialog() {
    this.fundDialog = this.dialog.open(DistributionInfoDialog, {
      id: 'fund-info',
      ...constants.DEFAULT_DIALOG_CONFIG,
    });

    this.fundDialog.componentInstance['reject']
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe((reject) => {
        this.fundDialog.close();
      })
  }

  updateInvestmentStatus(status) {
    this.lendingService.updateInvestmentStatus(this.fif, status);
  }

  withdrawFunds() {
    this.lendingService.withdrawFunds(this.fif, this.fif.availableFunds);
  }

  setPulse() {
    this.pulse = true;
    setTimeout(() => {
      this.pulse = false;
    }, 250);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
