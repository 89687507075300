import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CurrenciesService } from 'src/app/core/services/currencies.service';
import { LoanStates } from 'src/app/shared/interfaces/borrowing/loan';
import { Currency } from 'src/app/shared/interfaces/currencies/currency';
import { regEx } from 'src/config/app.config';

@Component({
  selector: 'app-increase-collateral',
  templateUrl: './increase-collateral.dialog.html',
  styles: [``]
})
export class IncreaseCollateralDialog implements OnInit {
  public increaseAmount: FormControl = new FormControl(null, Validators.required);
  public transferAmount: FormControl = new FormControl(null, Validators.required);

  public requiredAmount: number;
  public collateral: Currency;
  public loanStates: LoanStates;
  public unlockedCollateral: any;
  public depositingCollateral: boolean;

  public showTransfer: boolean = false;

  @Output('increase') increase: EventEmitter<string> = new EventEmitter();
  @Output('transfer') transfer: EventEmitter<string> = new EventEmitter();
  @Output('reject') reject: EventEmitter<void> = new EventEmitter();

  constructor(
    @Inject(DIALOG_DATA) public data: {
      requiredAmount: number,
      unlockedCollateral: number,
      collateral: Currency,
      loanStates: LoanStates,
      depositingCollateral: boolean
    },
    private currenciesService: CurrenciesService
  ) {
    this.requiredAmount = data.requiredAmount;
    this.collateral = data.collateral;
    this.loanStates = data.loanStates;
    this.unlockedCollateral = data.unlockedCollateral;
    this.depositingCollateral = data.depositingCollateral;
  }

  ngOnInit(): void {
    const amount = this.requiredAmount < this.currenciesService.weiToCurrency(this.unlockedCollateral, this.collateral) ? this.requiredAmount : this.currenciesService.weiToCurrency(this.unlockedCollateral, this.collateral);
    this.increaseAmount.setValidators([Validators.required, Validators.min(0.000001), Validators.pattern(regEx.numbers)])
    this.increaseAmount.setValue(amount);
    this.transferAmount.setValidators([Validators.required, Validators.min(0.000001), Validators.pattern(regEx.numbers)])
    this.transferAmount.setValue(this.requiredAmount);

    if (this.unlockedCollateral <= 0 || (this.loanStates.nearLiquidation && this.requiredAmount > this.unlockedCollateral)) {
      this.showTransfer = true;
    }
  }

  onIncrease() {
    this.increase.emit(this.currenciesService.currencyToWei(this.increaseAmount.value, this.collateral));
  }

  viewTransfer() {
    this.showTransfer = true;
  }

  viewIncrease() {
    this.showTransfer = false;
  }

  onTransfer() {
    this.transfer.emit(this.currenciesService.currencyToWei(this.transferAmount.value, this.collateral));
  }

  onClose() {
    this.reject.emit();
  }
}
