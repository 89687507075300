<div class="rounded-3xl border-slate-200 bg-white p-4">
  <div class="flex justify-between items-center mb-4">
    <h3 class="mb-0 mr-2 truncate">Reallocate Funds</h3>
    <button class="default sm" (click)="onClose()">
      Close
    </button>
  </div>
  <form [formGroup]="reallocateFundsForm" class="flex flex-wrap w-full gap-6 mt-6 pb-2">
    <div class="card accent sm w-full first">
      <div class="mb-2">
        <div class="font-semibold text-lg">{{ buckets[0].investmentRatio }}%</div>
        <div class="font-medium text-slate-500">7 - 30 days</div>
      </div>
      <div class="slider">
        <input type="range" step="1" min="0" max="{{ buckets[0].max }}"
          formControlName="first" value="{{ buckets[0].investmentRatio }}" class="range"
          [ngStyle]="{'width': 'calc(' + buckets[0].max + '% * 1)', 'min-width':'16px'}">
      </div>
    </div>
    <div class="card accent sm w-full second">
      <div class="mb-2">
        <div class="font-semibold text-lg">{{ buckets[1].investmentRatio }}%</div>
        <div class="font-medium text-slate-500">31 - 60 days</div>
      </div>
      <div class="slider">
        <input type="range" step="1" min="0" max="{{ buckets[1].max }}" formControlName="second"
          value="{{ buckets[1].investmentRatio }}" class="range"
          [ngStyle]="{'width': 'calc(' + buckets[1].max + '% * 1)', 'min-width':'16px'}">
      </div>
    </div>
    <div class="card accent sm w-full third">
      <div class="mb-2">
        <div class="font-semibold text-lg">{{ buckets[2].investmentRatio }}%</div>
        <div class="font-medium text-slate-500">61 - 90 days</div>
      </div>
      <div class="slider">
        <input type="range" step="1" min="0" max="{{ buckets[2].max }}" formControlName="third"
          value="{{ buckets[2].investmentRatio }}" class="range"
          [ngStyle]="{'width': 'calc(' + buckets[2].max + '% * 1)', 'min-width':'16px'}">
      </div>
    </div>
    <div class="card accent sm w-full fourth">
      <div class="mb-2">
        <div class="font-semibold text-lg">{{ buckets[3].investmentRatio }}%</div>
        <div class="font-medium text-slate-500">91 - 180 days</div>
      </div>
      <div class="slider">
        <input type="range" step="1" min="0" max="{{ buckets[3].max }}" formControlName="fourth"
          value="{{ buckets[3].investmentRatio }}" class="range"
          [ngStyle]="{'width': 'calc(' + buckets[3].max + '% * 1)', 'min-width':'16px'}">
      </div>
    </div>
    <div
      class="bg-[linear-gradient(#d2ecfc, #c1e4fa)] h-20 w-full rounded-2xl overflow-hidden font-medium shadow-md flex justify-between">
      <div class="py-4 px-6">
        <div class="text-blue-900">Unallocated: {{100 - (buckets[0].investmentRatio +
          buckets[1].investmentRatio + buckets[2].investmentRatio + buckets[3].investmentRatio) }}%
        </div>
        <div class="text-lg whitespace-nowrap">Projected APY: {{ apy }}%</div>
      </div>
      <img src="assets/images/apy.webp" alt="APY" class="w-36 h-20">
    </div>
    <button class="ml-auto primary w-fit" (click)="onReallocate()"
      [disabled]="reallocateFundsForm.invalid">Reallocate Funds</button>
  </form>
</div>
