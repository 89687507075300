import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClaimedReward } from 'src/app/shared/interfaces/rewards/claimed-reward';
import { MENU_ICONS } from 'src/assets/images/svg-icons';

@Component({
  selector: 'claimed-rewards',
  templateUrl: './claimed-rewards.component.html',
  styleUrls: ['./claimed-rewards.component.scss']
})
export class ClaimedRewardsComponent {
  @Input() claimedRewards: ClaimedReward[];
  @Input() isRedeeming: boolean;
  @Input() rewardToken: string;
  
  public arrow = MENU_ICONS.arrowRight;
  public showDetailsIndex: number;

  @Output() redeemReward = new EventEmitter<ClaimedReward>();
  constructor() { }

  onRedeem(claimedReward: ClaimedReward) {
    this.redeemReward.emit(claimedReward);
  }
}
