import { Component, DestroyRef, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import { Article } from 'src/app/shared/interfaces/articles/article';
import { Currencies } from 'src/app/shared/interfaces/currencies/currencies';
import { CRYPTO_ICONS } from 'src/assets/images/svg-icons';
import { CurrenciesService } from 'src/app/core/services/currencies.service';
import { ArticlesService } from 'src/app/core/services/articles.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducer';
import { StatsService } from 'src/app/core/services/stats.service';
import { DashboardStats } from 'src/app/shared/interfaces/statistics/dashboard-stats';
import { banner } from 'src/config/promos.config';
import { Subject, takeUntil } from 'rxjs';
import { Meta, Title, MetaDefinition } from '@angular/platform-browser';
import seoConfig from '../../../../assets/static/seo.config.json';
import { environment } from 'src/environments/environment';
import { PixelService } from 'src/app/core/services/pixel.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: ['']
})

export class DashboardComponent implements OnInit, OnDestroy {
  articles: Article[];
  currencies: Currencies;
  stats: DashboardStats;
  bannerConfig = banner;
  cryptoIcons = CRYPTO_ICONS;
  segmentation: any;
  destroy$ = new Subject<void>();
  isBrowser: boolean;
  pageDetails: any;
  environment = environment;
  currenciesService = inject(CurrenciesService);
  articlesService = inject(ArticlesService);
  store = inject(Store<AppState>);
  statsService = inject(StatsService);
  title = inject(Title);
  meta = inject(Meta);
  pixelService = inject(PixelService);
  destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.pixelService.getPixelData().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    this.addSeoConfig(seoConfig.routes.find(r => r.path === '/'));
    this.store.select('dashboard').subscribe((dashboardStore => {
      this.currencies = dashboardStore.currencies;
      this.articles = dashboardStore.articles;
      this.stats = dashboardStore.stats;
      if (!this.articles) {
        this.articlesService.getArticles();
      }
      if (!this.currencies) {
        this.currenciesService.fetchCurrencies();
      }
      if (!this.stats) {
        this.statsService.getDashboardStats();
      }
    }))
  }

  addSeoConfig(seoConfig: any): void {
    this.pageDetails = seoConfig;
    this.title.setTitle(seoConfig.seoTitle)
    if (seoConfig.metaTags) {
      seoConfig.metaTags.forEach((tag: MetaDefinition) => {
        this.meta.addTag(tag);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
