import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BorrowComponent } from './pages/borrow/borrow.component';
import { LoanComponent } from './pages/loan/loan.component';
import { BorrowingDashboardComponent } from './pages/borrowing-dashboard/borrowing-dashboard.component';
import { CreditLineComponent } from './pages/credit-line/credit-line.component';
import { BorrowGuard } from '../core/guards/borrow.guard';

const routes: Routes = [{
  path: '',
  component: BorrowComponent
}, {
  path: 'dashboard',
  component: BorrowingDashboardComponent,
  canActivate: [BorrowGuard]
}, {
  path: 'loan',
  component: LoanComponent,
  canActivate: [BorrowGuard]
}, {
  path: 'credit-line/:creditLineAddress',
  component: CreditLineComponent,
  canActivate: [BorrowGuard]
}, {
  path: 'credit-line/:creditLineAddress/loan/:loanId',
  component: LoanComponent,
  canActivate: [BorrowGuard]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BorrowingRoutingModule { }
