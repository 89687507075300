import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditLineTypeText'
})
export class CreditLineTypeTextPipe implements PipeTransform {

  transform(creditLineType: unknown): string {
    let description: string;

    if (creditLineType === 'Type A Loan') {
      description = 'Only ISLM token is eligible to be used as asset, while all ERC20 tokens can be used as collateral in this financing line'
    } else if (creditLineType === 'Type B Loan') {
      description = 'All ERC20 tokens are eligible to be used as assets, while only ISLM token can be used as collateral in this financing line'
    } else if (creditLineType === 'Type C Loan') {
      description = 'All ERC20 tokens are eligible to be used as assets and collateral in this financing line'
    } else if (creditLineType === 'Type D Loan') {
      description = ''
    }
    return description;
  }

}
