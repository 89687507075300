<div
  class="flex flex-col footer bg-slate-50 border text-slate-800 px-2 lg:px-8 overflow-hidden pb-20 pt-10 md:py-10"
>
  <div class="w-10/12 mx-auto">
    <div [routerLink]="['/']" class="pr-6 h-[30px] mb-8">
      <sc-icon [src]="logo" [height]="'40px'" [width]="'200px'"></sc-icon>
    </div>
    <div class="flex flex-col md:flex-row w-full justify-between">
      <div class="flex flex-col pb-4 md:pb-0">
        <h3 class="text-slate-800 text-sm">SOLUTIONS</h3>
        <a
          [href]="environment.baseUrl + '/purchase'"
          [routerLink]="['/purchase']"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light"
          >Crypto Purchase</a
        >
        <a
          [href]="environment.baseUrl + '/sell'"
          [routerLink]="['/sell']"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light"
          >Crypto Sell</a
        >
        <!-- <a [href]="environment.baseUrl + '/stake'" [routerLink]="['/stake']"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light">Staking</a>
        <a href="https://learn.barakafi.com/integrations" target="_blank"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light">Crypto
          Credit
          API</a> -->
      </div>
      <div class="flex flex-col pb-4 md:pb-0">
        <h3 class="text-slate-800 text-sm">COMPANY</h3>
        <a
          href="https://barakafi.com/about-us/"
          target="_blank"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light"
          >About Us</a
        >
        <a
          [routerLink]="['support']"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light"
          >Contact Us</a
        >
        <a
          href="https://barakafi.com/blog/"
          target="_blank"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light"
          >Blog</a
        >
        <!-- <a
          href="https://learn.barakafi.com/"
          target="_blank"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light"
          >Learn</a
        > -->
        <!-- <a href="https://barakafi.com/partners/" target="_blank"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light">Partners</a>
        <a href="https://barakafi.com/careers/" target="_blank"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light">Careers</a> -->
      </div>
      <div class="flex flex-col pb-4 md:pb-0">
        <h3 class="text-slate-800 text-sm">ISLM TOKEN</h3>
        <a
          href="https://www.coingecko.com/en/coins/islamic-coin"
          target="_blank"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light"
          >CoinGecko</a
        >
        <a
          href="https://defillama.com/chain/HAQQ"
          target="_blank"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light"
          >DefiLlama</a
        >
        <a
          href="https://coinmarketcap.com/currencies/islamic-coin/"
          target="_blank"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light"
          >CoinMarketCap</a
        >
        <a
          href="https://etherscan.io/token/0xf10c41ca085fc8d9326a65408d14dae28a3e69a5"
          target="_blank"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light"
          >Etherscan</a
        >
        <!-- <a
          href=""
          target="_blank"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light"
          >Token Model</a
        > -->
      </div>
      <div class="flex flex-col pb-4 md:pb-0">
        <h3 class="text-slate-800 text-sm">SOCIAL MEDIA CHANNELS</h3>
        <a
          href="https://x.com/Islamic_Coin"
          target="_blank"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light"
          >X</a
        >
        <a
          href="https://t.me/islamiccoin_int"
          target="_blank"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light"
          >Telegram</a
        >
        <a
          href="https://blog.islamiccoin.net/"
          target="_blank"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light"
          >Medium</a
        >
        <a
          href="https://www.linkedin.com/company/islamiccoin/"
          target="_blank"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light"
          >LinkedIn</a
        >
        <a
          href="https://www.youtube.com/@haqq.network"
          target="_blank"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light"
          >Youtube</a
        >
        <a
          href="https://www.reddit.com/r/Islamic_Coin_Official/"
          target="_blank"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light"
          >Reddit</a
        >
      </div>
      <div class="flex flex-col pb-4 md:pb-0">
        <h3 class="text-slate-800 text-sm">LEGAL</h3>
        <a
          href="https://islamiccoin.net/privacy-policy"
          target="_blank"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light"
          >Privacy Policy</a
        >
        <a
          href="https://islamiccoin.net/whitepaper"
          target="_blank"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light"
        >
          Whitepaper
        </a>
        <a
          href="https://islamiccoin.net/shariah"
          target="_blank"
          class="pt-3 h-[40px] pb-3 md:pb-2 md:pt-1 md:h-auto text-sm text-slate-600 hover:text-sc-primary font-light"
        >
          Terms of Use
        </a>
      </div>
    </div>
    <div class="flex flex-col md:flex-row w-full mt-4">
      <span class="text-sm text-slate-800 font-light"
        >&#169; {{ year }} BarakaFi</span
      >
    </div>
  </div>
</div>
