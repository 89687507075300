<div
  *ngIf="!user"
  class="mx-auto text-center rounded-3xl bg-slate-50 py-24 mb-8"
>
  <sc-icon
    [size]="'2rem'"
    [src]="icons.locked"
    class="mx-auto"
    class="inline-block"
  >
  </sc-icon>
  <div class="text-md text-slate-400 font-medium px-4">
    You must log in to see rewards and invite friends!
  </div>
  <div class="mt-6">
    <shared-account-center></shared-account-center>
  </div>
</div>
<div *ngIf="user" class="w-11/12 mx-auto">
  <shared-title title="Rewards">
    <button
      *ngIf="!empty"
      class="primary-inverse xs"
      (click)="toggleReferral()"
    >
      {{ showReferral ? "Hide" : "Show" }} Referral Link
    </button>
  </shared-title>
  <div class="mt-7">
    <empty-state
      *ngIf="empty || showReferral"
      [referralCode]="user.referralCode"
      [baseUrl]="environment.baseUrl"
    >
    </empty-state>
    <ng-container *ngIf="!empty">
      <section class="mt-6">
        <div class="flex items-center justify-between mb-2">
          <div>
            <h3 class="mb-0 text-slate-700">Unclaimed Rewards</h3>
            <div class="font-medium text-slate-500">
              {{
                totalUnclaimedRewardsUsd | currency : "USD" : "symbol" : "1.2-2"
              }}
            </div>
          </div>
          <button
            class="primary ml-4"
            (click)="openClaimRewardsDialog()"
            [disabled]="totalUnclaimedRewardsUsd === 0"
          >
            Claim All
          </button>
        </div>
        <unclaimed-rewards
          [unclaimedRewards]="unclaimedRewards"
        ></unclaimed-rewards>
      </section>
      <!-- <section class="mt-8">
        <div class="flex items-center justify-between mb-2">
          <div>
            <h3 class="mb-0 text-slate-700">Unclaimed Staking Rewards</h3>
            <div class="font-medium text-slate-500">
              {{ totalStakingRewards | weiToCurrency:currency | number:'1.2-2'}} SMARTCREDIT
            </div>
          </div>
          <button class="primary ml-4" (click)="openClaimStakingRewardsDialog()"
            [disabled]="!totalStakingRewards || totalStakingRewards === '0'">Claim All</button>
        </div>
        <staking-rewards [stakingRewards]="stakingRewards" [currency]="currency"></staking-rewards>
      </section> -->
      <section class="mt-8">
        <h3 class="mb-2 text-slate-700">Claimed Rewards</h3>
        <claimed-rewards
          [claimedRewards]="claimedRewards"
          [isRedeeming]="isRedeeming"
          [rewardToken]="stats?.rewardConfigData?.rewardToken"
          (redeemReward)="redeemReward($event)"
        ></claimed-rewards>
      </section>
    </ng-container>
  </div>
</div>
