<div *ngIf="fif" class="flex items-center justify-between pb-4">
  <div class="flex items-center">
    <sc-icon [src]="icons[fif.underlying.symbol]" class="mr-2 icon"></sc-icon>
    <div>
      <h1 class="mb-0">
        {{ fif.underlying.symbol }} Fixed Income Fund
        <span
          class="badge status self-center ml-2"
          [ngClass]="{
            success: fif.status === 'ACTIVE',
            default: fif.status === 'TERMINATED',
            danger:fif.status === 'INACTIVE'
          }"
        ></span>
      </h1>
      <p
        *ngIf="fif.contractAddress"
        class="text-subtitle flex items-center"
        [ngClass]="{ 'animate-bounce': pulse }"
      >
        {{ fif.contractAddress | shortAddress }}
        <button
          [cdkCopyToClipboard]="fif.contractAddress"
          class="nostyle h-[0.8rem]"
          title="Copy to Clipboard"
          (click)="setPulse()"
        >
          <sc-icon
            [src]="icons.copy"
            [size]="'0.325'"
            [width]="'0.8rem'"
            [height]="'0.8rem'"
            [classes]="'-mt-3'"
          ></sc-icon>
        </button>
      </p>
    </div>
  </div>
  <button
    class="justify-self-end primary-inverse"
    [routerLink]="['/sell/dashboard']"
  >
    Financing Dashboard
  </button>
</div>
<section *ngIf="fif" class="flex flex-col md:flex-row w-full gap-6 mt-4">
  <div class="card flex flex-col w-full">
    <div class="flex flex-row">
      <sc-icon [src]="icons.fund" class="mr-2 icon self-center"></sc-icon>
      <div class="flex flex-col">
        <p class="text-slate-500">Total Funds</p>
        <p class="text-lg font-bold">
          {{
            +fif.activeInvested + +fif.availableFunds
              | weiToCurrency : fif.underlying
              | number : "1.2-4"
          }}
          {{ fif.underlying.symbol }}
        </p>
      </div>
    </div>
    <button
      class="primary sm mt-4 !h-10"
      (click)="openDepositDialog()"
      [disabled]="isDepositing || fif.status === 'INACTIVE'"
    >
      <sc-icon
        *ngIf="!isDepositing"
        [src]="icons.add"
        [size]="'0.4'"
        [width]="'1.5rem'"
        class="mr-2"
      ></sc-icon>
      <spinner
        size="xs"
        color="gray"
        *ngIf="isDepositing"
        class="mr-2"
      ></spinner>
      Deposit Funds
    </button>
  </div>
  <div class="card flex flex-col w-full">
    <div class="flex flex-row">
      <sc-icon [src]="icons.invest" class="mr-2 icon self-center"></sc-icon>
      <div class="flex flex-col">
        <p class="text-slate-500">Invested</p>
        <p class="text-lg font-bold">
          {{
            fif.activeInvested
              | weiToCurrency : fif.underlying
              | number : "1.2-4"
          }}
          {{ fif.underlying.symbol }}
        </p>
      </div>
    </div>
    <button
      class="danger-inverse sm mt-4 !h-10"
      (click)="
        updateInvestmentStatus(fif.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')
      "
      [disabled]="isTerminating"
    >
      <sc-icon
        *ngIf="!isTerminating"
        [src]="icons.terminate"
        class="mr-2"
      ></sc-icon>
      <spinner
        *ngIf="isTerminating"
        size="xs"
        color="gray"
        class="mr-2"
      ></spinner>
      {{ fif.status === "ACTIVE" ? "Deactivate" : "Activate" }}
    </button>
  </div>
  <div class="card flex flex-col w-full">
    <div class="flex flex-row">
      <sc-icon [src]="icons.available" class="mr-2 icon self-center"></sc-icon>
      <div class="flex flex-col">
        <p class="text-slate-500">Available</p>
        <p class="text-lg font-bold">
          {{
            fif.availableFunds
              | weiToCurrency : fif.underlying
              | number : "1.2-4"
          }}
          {{ fif.underlying.symbol }}
        </p>
      </div>
    </div>
    <button
      class="default sm mt-4 !h-10"
      (click)="withdrawFunds()"
      [disabled]="isWithdrawing || +fif.availableFunds === 0"
    >
      <sc-icon
        *ngIf="!isWithdrawing"
        [src]="icons.withdraw"
        class="mr-2"
      ></sc-icon>
      <spinner
        *ngIf="isWithdrawing"
        size="xs"
        color="gray"
        class="mr-2"
      ></spinner>
      Withdraw
    </button>
  </div>
  <div class="card flex flex-col w-full">
    <div class="flex flex-row">
      <sc-icon [src]="icons.apy" class="mr-2 icon self-center"></sc-icon>
      <div class="flex flex-col">
        <p class="text-slate-500">Projected Fee Earned</p>
        <p class="text-lg font-bold">{{ apy }}%</p>
      </div>
    </div>
    <button
      class="primary-inverse sm mt-4 !h-10"
      [disabled]="isReallocating || fif.status === 'INACTIVE'"
      (click)="openReallocateDialog()"
    >
      <sc-icon
        *ngIf="!isReallocating"
        [src]="icons.change"
        class="mr-2"
      ></sc-icon>
      <spinner
        *ngIf="isReallocating"
        size="xs"
        color="gray"
        class="mr-2"
      ></spinner>
      Adjust
    </button>
  </div>
</section>
<section *ngIf="fif" class="flex gap-6 flex-col md:flex-row">
  <div class="card md:w-3/4 mt-6 distribution-card">
    <div class="flex justify-between items-center mb-2">
      <h2 class="mb-0">Fund Distribution</h2>
      <button
        (click)="openInfoDialog()"
        class="nostyle text-sm font-medium text-sc-primary"
      >
        What is Fund Distribution?
      </button>
    </div>
    <div class="flex flex-wrap md:flex-row w-full">
      <div class="flex flex-col md:flex-row w-1/2 xl:w-1/4 pb-10 xl:pb-0">
        <ngx-charts-gauge
          [view]="view"
          [scheme]="colorSchemes[0]"
          [results]="chartData[0]"
          [legend]="legend"
          [legendPosition]="legendPosition"
          [bigSegments]="0"
          [startAngle]="-140"
          [angleSpan]="280"
          [showAxis]="false"
          [legendTitle]="''"
        >
        </ngx-charts-gauge>
      </div>
      <div class="flex flex-col md:flex-row w-1/2 xl:w-1/4 xl:pb-0">
        <ngx-charts-gauge
          [view]="view"
          [scheme]="colorSchemes[1]"
          [results]="chartData[1]"
          [legend]="legend"
          [legendPosition]="legendPosition"
          [bigSegments]="0"
          [startAngle]="-140"
          [angleSpan]="280"
          [showAxis]="false"
          [legendTitle]="''"
        >
        </ngx-charts-gauge>
      </div>
      <div class="flex flex-col md:flex-row w-1/2 xl:w-1/4 pb-10 xl:pb-0">
        <ngx-charts-gauge
          [view]="view"
          [scheme]="colorSchemes[2]"
          [results]="chartData[2]"
          [legend]="legend"
          [legendPosition]="legendPosition"
          [bigSegments]="0"
          [startAngle]="-140"
          [angleSpan]="280"
          [showAxis]="false"
          [legendTitle]="''"
        >
        </ngx-charts-gauge>
      </div>
      <div class="flex flex-col md:flex-row w-1/2 xl:w-1/4 xl:pb-0">
        <ngx-charts-gauge
          [view]="view"
          [scheme]="colorSchemes[3]"
          [results]="chartData[3]"
          [legend]="legend"
          [legendPosition]="legendPosition"
          [bigSegments]="0"
          [startAngle]="-140"
          [angleSpan]="280"
          [showAxis]="false"
          [legendTitle]="''"
        >
        </ngx-charts-gauge>
      </div>
    </div>
  </div>
  <div class="card md:w-1/4 mt-6">
    <h3>Fund Details</h3>
    <p class="text-slate-500 text-sm">Contract Address</p>
    <p class="text-sc-black font-semibold mb-4">
      {{ fif.contractAddress | shortAddress }}
    </p>
    <p class="text-slate-500 text-sm">Last Interacted</p>
    <p class="text-sc-black font-semibold mb-4">
      {{ fif.updatedAt || fif.createdAt | date }}
    </p>
    <div class="bg-slate-100 text-slate-500 p-3 rounded-2xl mt-2">
      <!-- <p class="text-xs mb-2">A minimum of 500 USDT is required for Compound.</p> -->
      <p class="text-xs mb-2">
        Funds in buckets (7-30, 31-60, 61-90 and 91-180 days) are invested in
        loan requests.
      </p>
    </div>
  </div>
</section>
