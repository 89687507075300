import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { LendingService } from 'src/app/core/services/lending.service';
import { BUCKETS } from 'src/app/shared/constants';
import { Currency } from 'src/app/shared/interfaces/currencies/currency';
import { FifBucket } from 'src/app/shared/interfaces/fifs/fif-bucket';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reallocate',
  templateUrl: './reallocate.dialog.html',
  styles: ['']
})
export class ReallocateDialog implements OnInit, OnDestroy {
  public buckets: FifBucket[];
  public currency: Currency;
  public reallocateFundsForm: FormGroup;
  public defaultBuckets: FifBucket[] = BUCKETS;
  public apy: number = 0;
  private riskFreeRateLogBase: number;
  private destroy$ = new Subject<void>();

  @Output('reallocate') reallocate: EventEmitter<FifBucket[]> = new EventEmitter();
  @Output('reject') reject: EventEmitter<boolean> = new EventEmitter();

  constructor(
    @Inject(DIALOG_DATA) public data: { buckets: FifBucket[]; currency: Currency, riskFreeRateLogBase: number },
    private fb: FormBuilder,
    private lendingService: LendingService,
  ) {
    this.buckets = data.buckets;
    this.currency = data.currency;
    this.riskFreeRateLogBase = data.riskFreeRateLogBase;
  }

  ngOnInit(): void {
    this.reallocateFundsForm = this.fb.group({
      first: [this.buckets[0].investmentRatio],
      second: [this.buckets[1].investmentRatio],
      third: [this.buckets[2].investmentRatio],
      fourth: [this.buckets[3].investmentRatio]
    });

    this.calculateApy();
    this.buckets = this.buckets.map((bucket: FifBucket, index) => ({
      ...this.defaultBuckets[index],
      ...bucket,
      max: bucket.investmentRatio
    }));
    this.onFormValueChangeEvents();
  }

  onFormValueChangeEvents() {
    this.reallocateFundsForm.valueChanges
    .pipe(debounceTime(100), takeUntil(this.destroy$))
    .subscribe((value) => {
      this.calculateApy();
    })

    this.reallocateFundsForm.controls['first'].valueChanges
    .pipe(debounceTime(100), takeUntil(this.destroy$))
    .subscribe((value) => {
      this.updateBucketValues(this.buckets[0], value);
    })

  this.reallocateFundsForm.controls['second'].valueChanges
    .pipe(debounceTime(100), takeUntil(this.destroy$))
    .subscribe((value) => {
      this.updateBucketValues(this.buckets[1], value);
    })

  this.reallocateFundsForm.controls['third'].valueChanges
    .pipe(debounceTime(100), takeUntil(this.destroy$))
    .subscribe((value) => {
      this.updateBucketValues(this.buckets[2], value);
    })

  this.reallocateFundsForm.controls['fourth'].valueChanges
    .pipe(debounceTime(100), takeUntil(this.destroy$))
    .subscribe((value) => {
      this.updateBucketValues(this.buckets[3], value);
    })
  }

  updateBucketValues(bucket: any, newValue: any) {
    if (bucket.investmentRatio < newValue) {
      this.buckets = this.buckets.map((b) => {
        if (b.name !== bucket.name) {
          return {
            ...b,
            max: b.max - (Math.abs(bucket.investmentRatio - newValue)),
            investmentRatio: b.investmentRatio > b.max ? b.max : b.investmentRatio
          }
        } else {
          return {
            ...b,
            investmentRatio: newValue
          }
        }
      })
    } else if (bucket.investmentRatio > newValue) {
      this.buckets = this.buckets.map((b) => {
        if (b.name !== bucket.name) {
          return {
            ...b,
            max: b.max + (Math.abs(bucket.investmentRatio - newValue)),
          }
        } else {
          return {
            ...b,
            investmentRatio: newValue
          }
        }
      })
    }
    this.reallocateFundsForm.controls['first'].setValue(this.buckets[0].investmentRatio, { emitEvent: false });
    this.reallocateFundsForm.controls['second'].setValue(this.buckets[1].investmentRatio, { emitEvent: false });
    this.reallocateFundsForm.controls['third'].setValue(this.buckets[2].investmentRatio, { emitEvent: false });
    this.reallocateFundsForm.controls['fourth'].setValue(this.buckets[3].investmentRatio, { emitEvent: false });
    this.calculateBucketsTotalAndUpdateValidity();
  }

  calculateBucketsTotalAndUpdateValidity() {
    if ((this.buckets[0].investmentRatio + this.buckets[1].investmentRatio + this.buckets[2].investmentRatio + this.buckets[3].investmentRatio) !== 100) {
      this.reallocateFundsForm.setErrors({ 'invalid': true });
    } else {
      this.reallocateFundsForm.setErrors(null);
    }
  }

  onReallocate() {
    this.reallocate.emit(this.buckets);
  }

  onClose() {
    this.reject.emit(true);
  }

  calculateApy() {
    this.lendingService.getApy(
      this.currency,
      this.buckets,
      this.riskFreeRateLogBase
    ).pipe(takeUntil(this.destroy$)).subscribe((apy) => {
      this.apy = apy;
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
