import { Action, createReducer, on } from "@ngrx/store"
import { CreditLine } from "src/app/shared/interfaces/borrowing/credit-line"
import { Loan } from "src/app/shared/interfaces/borrowing/loan"
import { LoanData } from "src/app/shared/interfaces/borrowing/loan-data"
import { RiskRateFee } from "src/app/shared/interfaces/borrowing/risk-rate-fee"
import { Currency } from "src/app/shared/interfaces/currencies/currency"
import * as BorrowingActions from './borrowing.actions'

export interface AppState {
  borrowing: State
}

export interface State {
  riskRateFees: RiskRateFee[],
  loanRequestValidators: {
    partnerFee: number
  },
  paramsInformation: {
    riskFreeRateLogBase: number;
    basePartnerFee: number;
    maxLoanAmount: number;
    minLoanAmount: number;
    minLoanTerm: number;
    maxLoanTerm: number;
    stableCoinsDepegThreshold: number;
    campaignReward: number;
    partnerFee?: number;
  },
  creditLines: {
    ownerAddress: string;
    contractAddress: string;
    type: string;
    createdAt: Date;
    updatedAt: Date;
    loans?: string[];
    assets?: Currency[];
    collaterals?: Currency[];
  }[],
  loans: Loan[],
  borrowedLoanSummary: {}[],
  loanData: LoanData,
  loanAsset: Currency,
  loanCollateral: Currency,
  creatingLoanRequest: boolean,
  depositingCollateral: boolean,
  repayingLoan: boolean,
  approvingRepayment: boolean,
  increasingCollateral: boolean,
  lastWeekRewards: any,
  loanOver1kAwarded: boolean
}

export const initialState: State = {
  riskRateFees: [],
  loanRequestValidators: {
    partnerFee: 0
  },
  paramsInformation: {
    riskFreeRateLogBase: null,
    basePartnerFee: null,
    maxLoanAmount: null,
    minLoanAmount: null,
    minLoanTerm: null,
    maxLoanTerm: null,
    stableCoinsDepegThreshold: null,
    campaignReward: null,
    partnerFee: null
  },
  loans: [],
  creditLines: [],
  borrowedLoanSummary: [],
  loanData: undefined,
  loanAsset: undefined,
  loanCollateral: undefined,
  creatingLoanRequest: false,
  depositingCollateral: false,
  repayingLoan: false,
  approvingRepayment: false,
  increasingCollateral: false,
  lastWeekRewards: undefined,
  loanOver1kAwarded: false
}

const _borrowingReducer = createReducer(
  initialState,
  on(BorrowingActions.initializeBorrowingForm,
    (state, actions) => ({
      ...state,
      riskRateFees: actions.riskRateFees,
      paramsInformation: actions.paramsInformation
    })
  ),
  on(BorrowingActions.getCreditLines,
    (state, actions) => ({
      ...state,
      creditLines: actions.creditLines
    })
  ),
  on(BorrowingActions.initializeBorrowingDashboard,
    (state, actions) => ({
      ...state,
      borrowedLoanSummary: actions.borrowedLoanSummary,
      creditLines: actions.creditLines
    })
  ),
  on(BorrowingActions.getCreditLineDetails,
    (state, actions) => ({
      ...state,
      creditLines: state.creditLines.map((creditLine: CreditLine) => {
        if (creditLine.contractAddress === actions.creditLine.contractAddress) {
          return creditLine = actions.creditLine
        } else {
          return creditLine
        }
      })
    })
  ),
  on(BorrowingActions.getLoan,
    (state, actions) => {
      const creditLines = state.creditLines.map((creditLine: CreditLine) => {
        if (creditLine.contractAddress === actions.loan.contractAddress) {
          return {
            ...creditLine,
            loans: creditLine.loans ? [...creditLine.loans, actions.loan.id] : [actions.loan.id]
          }
        }
        return creditLine
      })
      const filteredLoans = [
        ...state.loans.filter(loan => loan.id !== actions.loan.id),
        actions.loan
      ];
      const sortedLoans = filteredLoans.sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return -1;
        } else if (a.createdAt < b.createdAt) {
          return 1;
        } else {
          return 0;
        }
      })

      return {
        ...state,
        creditLines,
        loans: sortedLoans
      }
    }
  ),
  on(BorrowingActions.updateLoanData,
    (state, actions) => ({
      ...state,
      loanData: {
        ...state.loanData,
        ...actions.loanData
      }
    })
  ),
  on(BorrowingActions.creatingLoanRequest,
    (state, actions) => ({
      ...state,
      creatingLoanRequest: actions.creatingLoanRequest
    })
  ),
  on(BorrowingActions.updateLoanAsset,
    (state, actions) => ({
      ...state,
      loanAsset: actions.loanAsset
    })
  ),
  on(BorrowingActions.updateLoanCollateral,
    (state, actions) => ({
      ...state,
      loanCollateral: actions.loanCollateral
    })
  ),
  on(BorrowingActions.addCreditLineCurrencies, (state, action) => {
    let existingCreditLines = state.creditLines.filter((cL: CreditLine) => cL.contractAddress !== action.creditLine.contractAddress);
    return {
      ...state,
      creditLines: [
        ...existingCreditLines,
        {
          ...action.creditLine,
          assets: action.assets,
          collaterals: action.collaterals
        }
      ]
    }
  }),
  on(BorrowingActions.updateLoan, (state, action) => {
    let loans = state.loans.map((loan: Loan) => {
      if (loan.id === action.loan.id) {
        return action.loan
      } else {
        return loan
      }
    });
    return {
      ...state,
      loans
    }
  }),
  on(BorrowingActions.depositingCollateral, (state, action) => {
    return {
      ...state,
      depositingCollateral: action.depositingCollateral
    }
  }),
  on(BorrowingActions.repayingLoan, (state, action) => {
    return {
      ...state,
      repayingLoan: action.repayingLoan
    }
  }),
  on(BorrowingActions.approvingRepayment, (state, action) => {
    return {
      ...state,
      approvingRepayment: action.approvingRepayment
    }
  }),
  on(BorrowingActions.increasingCollateral, (state, action) => {
    return {
      ...state,
      increasingCollateral: action.increasingCollateral
    }
  }),
  on(BorrowingActions.updateCreditLineCollaterals, (state, action) => {
    let creditLines = state.creditLines.map((creditLine: CreditLine) => {
      if (creditLine.contractAddress === action.creditLine.contractAddress) {
        return {
          ...creditLine,
          collaterals: action.collaterals
        }
      } else {
        return creditLine
      }
    })
    return {
      ...state,
      creditLines
    }
  }),
  on(BorrowingActions.setLoanStates, (state, action) => {
    let loans = state.loans.map((loan: Loan) => {
      if (loan.contractAddress === action.loan.contractAddress) {
        return {
          ...loan,
          states: action.loan.states
        }
      } else {
        return loan;
      }
    })
    return {
      ...state,
      loans
    }
  }),
  on(BorrowingActions.updateCreditLine, (state, action) => {
    let creditLines = state.creditLines.map((creditLine: CreditLine) => {
      if (creditLine.contractAddress === action.creditLine.contractAddress) {
        return action.creditLine
      } else {
        return creditLine
      }
    })
    return {
      ...state,
      creditLines
    }
  }),
  on(BorrowingActions.updateParamsInformation, (state, action) => ({
    ...state,
    paramsInformation: {
      ...state.paramsInformation,
      ...action.paramsInformation

    }
  })),
  on(BorrowingActions.getLastWeekRewards, (state, action) => ({
    ...state,
    lastWeekRewards: action.lastWeekRewards
  })),
  on(BorrowingActions.updateLoanOver1kAwarded, (state, action) => ({
    ...state,
    loanOver1kAwarded: action.loanOver1kAwarded
  })),
  on(BorrowingActions.resetState, () => initialState)
)

export function borrowingReducer(state: State, action: Action) {
  return _borrowingReducer(state, action);
}
