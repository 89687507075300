import { gql } from 'apollo-angular';

export const createLeverageRequest = gql`
mutation(
  $initialInvestmentAmount: Float!,
  $leverageFactor: Int!,
  $leverageId: String!,
  $leverageLineAddress: String!,
  $leveragePeriod: Int,
  $underlyingCurrency: String
) {
  createLeverageRequest(input: {
    data: {
      initialInvestmentAmount: $initialInvestmentAmount,
      leverageFactor: $leverageFactor,
      leverageId: $leverageId,
      leverageLineAddress: $leverageLineAddress,
      leveragePeriod: $leveragePeriod,
      underlyingCurrency: $underlyingCurrency
    }
  }) {
    leverageRequest {
      contractAddress
      deadline
      borrowedAmount
      leverageId
      initialInvestmentAmount
      leveragePeriod
      leverageFactor
      status
      borrowedAmount
      leverageShares
      interestRate
      leverageFeeRate
      insurancePremiumRate
      platformFeeRate
      signature
      createdAt
      updatedAt
      withdrawAmount
      withdrawId
      underlying {
        symbol
        name
        ethAddress
        riskFreeRate
        exchangeRate
        medianExchangeRate
        creditCoinAddress
        creditCoinSymbol
        isUnderlying
        isCollateral
        decimalPlaces
      }
      borrows {
        lenderAddress
        principal
        collateralAmount
        collateral {
          symbol
          name
          ethAddress
          riskFreeRate
          exchangeRate
          medianExchangeRate
          creditCoinAddress
          creditCoinSymbol
          isUnderlying
          isCollateral
          decimalPlaces
        }
      }
      leverageStartDate
      leverageEndDate
    }
  }
}`;

export const leverageRequest = gql`
  query($leverageRequestId: ID!) {
    leverageRequest(id: $leverageRequestId) {
      contractAddress
      deadline
      borrowedAmount
      initialInvestmentAmount
      insurancePremiumRate
      interestRate
      leverageShares
      leverageFactor
      leverageFeeRate
      leverageId
      leveragePeriod
      platformFeeRate
      status
      signature
      createdAt
      updatedAt
      withdrawAmount
      withdrawId
      creditLine {
        ownerAddress,
        contractAddress,
        type,
        createdAt,
        updatedAt  
      }  
      underlying {
        creditCoinAddress
        creditCoinSymbol
        decimalPlaces
        ethAddress
        exchangeRate
        isCollateral
        isUnderlying
        medianExchangeRate
        name
        riskFreeRate
        symbol
      }
      borrows {
        collateral {
          creditCoinAddress
          creditCoinSymbol
          decimalPlaces
          ethAddress
          exchangeRate
          isCollateral
          isUnderlying
          medianExchangeRate
          name
          riskFreeRate
          symbol
        }
        collateralAmount
        lenderAddress
        principal
      }
      leverageStartDate
      leverageEndDate
    }   
  }
`;

export const leverageRequests = gql`
query($where: JSON) {
  leverageRequests(where: $where) {
    contractAddress
    deadline
    leverageId
    initialInvestmentAmount
    leveragePeriod
    leverageFactor
    status
    borrowedAmount
    leverageShares
    interestRate
    leverageFeeRate
    insurancePremiumRate
    platformFeeRate
    signature
    createdAt
    updatedAt
    withdrawAmount
    withdrawId
    creditLine {
      ownerAddress,
      contractAddress,
      type,
      createdAt,
      updatedAt  
    }
    underlying {
      symbol
      name
      ethAddress
      riskFreeRate
      exchangeRate
      medianExchangeRate
      creditCoinAddress
      creditCoinSymbol
      isUnderlying
      isCollateral
      decimalPlaces
    }
    leverageStartDate
    leverageEndDate
  }
}
`;

export const terminateLeverageRequest = gql`mutation($leverageId: ID!) {
  terminateLeverageRequest(input: { where: $leverageId}) {
    leverageRequest {
      contractAddress
      deadline
      leverageId
      initialInvestmentAmount
      leveragePeriod
      leverageFactor
      status
      borrowedAmount
      leverageShares
      interestRate
      leverageFeeRate
      insurancePremiumRate
      platformFeeRate
      signature
      createdAt
      updatedAt
      withdrawAmount
      withdrawId
      creditLine {
        ownerAddress,
        contractAddress,
        type,
        createdAt,
        updatedAt  
      }
      underlying {
        symbol
        name
        ethAddress
        riskFreeRate
        exchangeRate
        medianExchangeRate
        creditCoinAddress
        creditCoinSymbol
        isUnderlying
        isCollateral
        decimalPlaces
      }
      leverageStartDate
      leverageEndDate
    }
  }
}
`;

// where: { ownerAddress }
export const leverageLines = gql`query($where: JSON) {
  creditLines(where: $where) {
    ownerAddress,
    contractAddress,
    type,
    createdAt,
    updatedAt
  }
}`;

export const fetchLeverageReturns = gql`query($amount: Float!, $leverageFactor: Int!) {
  fetchLeverageReturns(amount: $amount, leverageFactor: $leverageFactor) {
    asset
    debt
    earnings
    interest
  }
}`;

export const getLeverageRewards = gql`
  query($walletAddress: String!) {
    unclaimedRewards(walletAddress: $walletAddress) {
      rewardType,
      rewardValue,
      rewardCode,
      status,
      createdAt,
      userEthAddress,
      Currency {
          symbol,
          name,
          ethAddress,
          exchangeRate,
          isUnderlying,
          isCollateral,
          decimalPlaces
      }
    }
    claimedRewardsHistory(walletAddress: $walletAddress) {
          id,
          rewards {
              rewardType,
              rewardValue,
              rewardCode,
              status,
              createdAt,
              Currency {
                  symbol,
                  name,
                  ethAddress,
                  exchangeRate,
                  isUnderlying,
                  isCollateral,
                  decimalPlaces
              }
          }
          signature,
          createdAt,
          status,
          receiver
        }
  }
`;

export const claimLeverageRewards = gql`
  mutation($contractAddress: String!) {
    claimLeverageRewards(contractAddress: $contractAddress) {
      id,
      createdAt,
      receiver
    }
  }
`;

export const leverageLiquidationProbabilityData = gql`
  query($loanValueUsd: String!, $collateralValueUsd: String!, $period: Int!) {
    leverageLiquidationProbabilityData(
      loanValueUSD: $loanValueUsd,
      collateralValueUSD: $collateralValueUsd,
      period: $period
    )
  }
`;