import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  public token: string
  private destroy$ = new Subject<boolean>();

  constructor(private userService: UserService,
    private activatedRoute: ActivatedRoute,) { }

  ngOnInit(): void {

    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this.token = params['token'];
        if (this.token) {
          this.userService.verifyEmail(this.token)
        }
      })
  }
}
