<shared-header></shared-header>
<main>
  <div class="content-wrapper">
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</main>
<shared-menu></shared-menu>
<shared-footer></shared-footer>
