import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { EMPTY, Observable, from, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class PixelService {
  isBrowser: boolean;
  
  constructor(
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }
  
  getPixelData(): Observable<any> {
    if (this.isBrowser) {
      return from(window.getPixelData ? window.getPixelData(environment.pixelId) : of(undefined))
        .pipe(map((res: any) => res ? res.Segment.data : undefined))
    } else {
      return EMPTY;
    }
  }
}
