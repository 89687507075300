import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-email-add',
  templateUrl: './email-add.dialog.html',
  styleUrls: ['./email-add.dialog.scss']
})
export class EmailAddDialog implements OnInit {
  public email: FormControl = new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)]);

  @Output('added') added: EventEmitter<string> = new EventEmitter()
  @Output('reject') reject: EventEmitter<void> = new EventEmitter();

  constructor(
    @Inject(DIALOG_DATA) public data: { email: string }
  ) {
    if (data.email) {
      this.email.setValue(data.email)
    }
  }

  ngOnInit(): void {
  }


  onAdd(event) {
    this.added.emit(this.email.value);
    event.preventDefault()
  }

  onClose() {
    this.reject.emit();
  }

}
