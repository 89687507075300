<div class="rounded-3xl border-slate-200 bg-white p-4">
  <div class="flex justify-between items-center mb-4">
    <h3 class="mb-0 mr-2 truncate">Deposit Collateral</h3>
    <button class="default sm" (click)="onClose()">Close</button>
  </div>
  <div
    class="info-box"
    [ngClass]="{'danger': depositAmount.errors?.['min'], 'info': depositAmount.valid }"
  >
    You must deposit {{requiredAmount - +unlockedCollateral |
    weiToCurrency:collateral }} {{collateral.symbol}} for this loan.
  </div>
  <form>
    <div class="input-group w-full mb-0">
      <label for="currency" class="text-left label">Amount</label>
      <input
        type="text"
        class="w-full h-16"
        [formControl]="depositAmount"
        placeholder="0"
      />
      <div class="suffix">{{ collateral.symbol }}</div>
    </div>
    <div class="text-xs text-slate-500 font-medium -mt-2 ml-2 mb-2">
      ≈{{ depositAmount.value * collateral.exchangeRate |
      currency:'USD':'symbol-narrow':'1.2-2' }}
    </div>
    <div class="info-box mt-4">
      You can transfer additional collateral to be used for other loans in the
      same Finance Line.
    </div>
    <div class="text-center mt-2">
      <button
        class="primary w-fit"
        [disabled]="depositAmount.invalid"
        (click)="onDeposit()"
      >
        Deposit Collateral
      </button>
    </div>
  </form>
  <div *ngIf="depositAmount.hasError('minBalance')" class="form-error">
    Not enough balance in wallet
  </div>
</div>
