import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { filter, Subject, takeUntil } from "rxjs";
import { LeveragingService } from "src/app/core/services/leveraging.service";
import { UserService } from "src/app/core/services/user.service";
import { KycInfo } from "src/app/shared/interfaces/user/kyc-info";
import { User } from "src/app/shared/interfaces/user/user";
import { AppState } from "src/app/store/app.reducer";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit, OnDestroy {
  environment = environment;
  public tabTitle: string;
  private destroy$ = new Subject<void>();
  public user: User;
  private devOpts;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private store: Store<AppState>,
    private leveragingService: LeveragingService
  ) { }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe((paramMap) => {
        const tabName = paramMap.get("activeTab");
        this.updateTitle(tabName);
      });

    this.store.select(state => state.core.user)
      .pipe(filter(user => !!user), takeUntil(this.destroy$))
      .subscribe((user: User) => {
        this.user = user;
      })

    this.devOpts = localStorage.getItem('devOpts') ? JSON.parse(localStorage.getItem('devOpts')) : undefined;
  }

  kycSubmitted(data: KycInfo) {
    this.userService.updateKyc(data);
  }

  credentialsSubmitted({ firstName, lastName }: { firstName: string; lastName: string }) {
    this.userService.updateCredentials({ firstName, lastName });
  }

  updateTitle(tabName: string | null) {
    switch (tabName) {
      case "details":
        this.tabTitle = "Details";
        break;
      case "customization":
        this.tabTitle = "Customization";
        break;
      case "kyc":
        this.tabTitle = "KYC";
        break;
      case "developer-options":
        this.tabTitle = 'Developer Options';
        break;
      default:
        this.tabTitle = "Profile";
    }
  }

  setCritical(value) {
    this.devOpts ? this.devOpts = { ...this.devOpts, critical: value } : this.devOpts = { critical: value };
    localStorage.setItem('devOpts', JSON.stringify(this.devOpts))
  }

  setOnTime(value) {
    this.devOpts ? this.devOpts = { ...this.devOpts, ontime: value } : this.devOpts = { ontime: value };
    localStorage.setItem('devOpts', JSON.stringify(this.devOpts))
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
