<section class="card flex flex-col md:flex-row w-full mb-8">
  <div class="w-full md:w-2/6 border-b-2 md:border-r-2 md:border-b-0">
    <div class="mb-4">
      <div class="text-[#5e7eab]">My Stats</div>
      <h2 class="mb-0">My Active FIFs</h2>
      <div class="text-2xl font-bold text-sc-primary">
        {{ fifStats.activeFifs }}
      </div>
    </div>
    <div class="mb-4">
      <div class="text-[#5e7eab]">Total Funds Deposited</div>
      <div>
        {{
          fifStats.totalDeposited | currency : "USD" : "symbol-narrow" : "1.0-0"
        }}
      </div>
    </div>
    <div class="mb-4">
      <div class="text-[#5e7eab]">Total Funds Invested</div>
      <div>
        {{
          fifStats.totalInvested | currency : "USD" : "symbol-narrow" : "1.0-0"
        }}
      </div>
    </div>
  </div>
  <div
    class="flex flex-col justify-between w-full md:w-4/6 md:pl-8 mt-4 pb-4 md:mt-0"
  >
    <div class="mb-4 flex items-top justify-between">
      <div>
        <div class="text-[#5e7eab]">Provided Financing</div>
        <h2 class="mb-0">Currently lent on Baraka<span class="text-sc-primary text-xl">Fi</span></h2>
        <div class="text-2xl font-bold text-sc-primary">
          {{
            fifStats.platformInvested
              | currency : "USD" : "symbol-narrow" : "1.0-0"
          }}
        </div>
      </div>
      <!-- <a href="https://dune.com/smartcredit/smartcredit" target="_blank">
        <button class="default sm flex items-center">
          <div class="text-[#5e7eab] font-medium text-sm">More Stats</div>
          <sc-icon
            [src]="icons.external"
            [size]="'0.3'"
            [width]="'1.2rem'"
          ></sc-icon>
        </button>
      </a> -->
    </div>
    <div class="flex gap-6 w-full md:w-1/2">
      <div
        class="flex w-full md:h-20 flex-col justify-between bg-[#EEF5FF] text-[#213b66] rounded-2xl px-4 py-2 shadow font-medium"
      >
        <div class="text-xl font-semibold">
          {{
            stats?.borrowerLenderWeeklyRewards?.lenderRewards || 0
              | number : "1.2-2"
          }}
          {{ stats?.rewardConfigData?.rewardToken }}
        </div>
        <div class="text-[#5e7eab]">Seller Rewards (Last Week)</div>
      </div>
    </div>
  </div>
</section>
