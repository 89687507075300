export const STAKING = {
  abi: [
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: '_borrowerContract',
          type: 'address'
        }
      ],
      name: 'Collaterized',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: '_amount',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'address',
          name: '_withdrawer',
          type: 'address'
        }
      ],
      name: 'Withdrawn',
      type: 'event'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_receiver',
          type: 'address'
        },
        {
          internalType: 'address',
          name: '_loanContract',
          type: 'address'
        },
        {
          internalType: 'uint256',
          name: '_loanAmount',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: '_loanTerm',
          type: 'uint256'
        }
      ],
      name: 'acceptLoan',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool'
        }
      ],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_creditLine',
          type: 'address'
        }
      ],
      name: 'balanceOf',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'borrowerContract',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'checkCooldownEnabled',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'creditLine',
          type: 'address'
        }
      ],
      name: 'checkCreditLineAddress',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'fixedIncomeFundBalance',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'uint8',
          name: '_position',
          type: 'uint8'
        }
      ],
      name: 'getBucketInfo',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'uint8',
          name: '_position',
          type: 'uint8'
        }
      ],
      name: 'getBucketInvested',
      outputs: [
        {
          internalType: 'uint256',
          name: 'invested',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_loanTerm',
          type: 'uint256'
        }
      ],
      name: 'getBucketPosition',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8'
        }
      ],
      stateMutability: 'pure',
      type: 'function'
    },
    {
      inputs: [],
      name: 'getFixedIncomeFundDetails',
      outputs: [
        {
          internalType: 'address',
          name: 'first',
          type: 'address'
        },
        {
          internalType: 'address',
          name: 'second',
          type: 'address'
        },
        {
          internalType: 'uint256',
          name: 'third',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: 'fourth',
          type: 'uint256'
        },
        {
          internalType: 'uint256[4]',
          name: 'fifth',
          type: 'uint256[4]'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_fundOwner',
          type: 'address'
        },
        {
          internalType: 'uint256[4]',
          name: '_ratios',
          type: 'uint256[4]'
        }
      ],
      name: 'initializeFixedIncomeFund',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_loanAmount',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: '_loanTerm',
          type: 'uint256'
        }
      ],
      name: 'repayToFIF',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'stakingCooldown',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'startCooldown',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'totalInvested',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_receiver',
          type: 'address'
        },
        {
          internalType: 'uint256',
          name: '_amount',
          type: 'uint256'
        }
      ],
      name: 'transfer',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_borrowerContract',
          type: 'address'
        }
      ],
      name: 'transferAsCollateral',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_loanAmount',
          type: 'uint256'
        },
        {
          internalType: 'uint8',
          name: '_bucketPosition',
          type: 'uint8'
        }
      ],
      name: 'validateInvestmentRequest',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_amount',
          type: 'uint256'
        }
      ],
      name: 'withdraw',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool'
        }
      ],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'getCurrencyAddress',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    }
  ]
};
