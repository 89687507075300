import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-promo-banner',
  template: `
  <div class="bg-blue-800 rounded-xl">
    <img src="{{ src }}" alt ="{{ alt }}"
    class="w-full shadow-md border h-auto min-h-[10rem] mb-6 md:mb-8 md:h-56 rounded-xl object-cover mix-blend-luminosity">
  </div>
  `
})

export class PromoBannerComponent {
  @Input() src: string;
  @Input() alt: string;
}
