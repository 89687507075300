import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sentencecase'
})
export class SentencecasePipe implements PipeTransform {

  transform(value: string): unknown {
    return value.toLowerCase().charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
}
