<div class="flex flex-wrap md:flex-nowrap space-y-6 md:space-y-0 md:space-x-6">
  <!-- CREDIT SCORE -->
  <!-- <div *ngIf="!advancedMode" class="card w-full md:w-1/2 min-h-[177px]">
    <div class="flex justify-between">
      <div>
        Crypto Credit Score
        <div
          class="font-bold text-3xl"
          *ngIf="user && user.creditData"
          [ngClass]="{
            'text-[#a8385d]': user.creditData.riskRating === 1,
            'text-[#9f4d78]': user.creditData.riskRating === 2,
            'text-[#975f8f]': user.creditData.riskRating === 3,
            'text-[#8f70a5]': user.creditData.riskRating === 4,
            'text-[#897db5]': user.creditData.riskRating === 5,
            'text-[#8488c3]': user.creditData.riskRating === 6,
            'text-[#8093d1]': user.creditData.riskRating === 7,
            'text-[#7d9ada]': user.creditData.riskRating === 8,
            'text-[#79a3e5]': user.creditData.riskRating === 9
          }"
        >
          {{ user.creditData.riskRating }}
        </div>
        <div class="text-[#a8385d] font-bold text-3xl" *ngIf="!user">
          {{ 1 }}
        </div>
        <button
          class="button nostyle font-semibold text-sm text-sc-primary outline-none"
        >
          Improve
        </button>
      </div>
      <ngx-charts-gauge
        class="trust-gauge"
        [view]="view"
        [scheme]="colorScheme[user ? user.creditData.riskRating : 1]"
        [max]="9"
        [results]="chartData"
        [legend]="legend"
        [legend]="false"
        [bigSegments]="0"
        [startAngle]="-140"
        [angleSpan]="280"
        [showAxis]="false"
      >
      </ngx-charts-gauge>
    </div>
    <div class="flex justify-end mt-6">
      <div class="text-right">
        <div class="text-sm font-medium">
          Provided by
          <a
            class="text-sm font-medium"
            href="https://chainaware.ai/credit-score"
            target="_blank"
            >ChainAware.ai</a
          >
        </div>
        <button
          class="nostyle text-sm text-slate-500 font-normal hover:text-sc-primary"
          (click)="openTrustScoreDialog()"
        >
          What is Crypto Credit Score?
        </button>
      </div>
    </div>
  </div> -->
  <!-- END CREDIT SCORE -->
  <!-- LIQUIDATION PROBABILITY -->
  <div class="card w-full md:w-1/2 flex flex-col justify-between min-h-[177px]">
    <div>
      <div>Liquidation Probability</div>
      <div class="text-3xl font-bold">
        {{
          isCalculating
            ? "Calculating"
            : (loanData.liquidationProbability / 100 | percent : "1.2-2")
        }}
      </div>
      <div
        *ngIf="loanData.liquidationProbability <= 20"
        class="font-semibold rounded-lg px-1.5 py-0.5 w-fit border border-green-600 bg-green-100 text-green-600"
      >
        Very Low
      </div>
      <div
        *ngIf="
          loanData.liquidationProbability <= 50 &&
          loanData.liquidationProbability > 20
        "
        class="font-semibold rounded-lg px-1.5 py-0.5 w-fit border border-lime-600 bg-lime-100 text-lime-600"
      >
        Low
      </div>
      <div
        *ngIf="
          loanData.liquidationProbability <= 70 &&
          loanData.liquidationProbability > 50
        "
        class="font-semibold rounded-lg px-1.5 py-0.5 w-fit border border-amber-600 bg-amber-100 text-amber-600"
      >
        Medium
      </div>
      <div
        *ngIf="
          loanData.liquidationProbability <= 85 &&
          loanData.liquidationProbability > 70
        "
        class="font-semibold rounded-lg px-1.5 py-0.5 w-fit border border-orange-600 bg-orange-100 text-orange-600"
      >
        High
      </div>
      <div
        *ngIf="loanData.liquidationProbability > 85"
        class="font-semibold rounded-lg px-1.5 py-0.5 w-fit border border-red-600 bg-red-100 text-red-600"
      >
        Very High
      </div>
    </div>
    <div
      *ngIf="loanData.liquidationProbability >= 100"
      class="text-red-600 text-sm font-medium"
    >
      Collateral will be immediately liquidated!
    </div>
  </div>
  <!-- END LIQUIDATION PROBABILITY-->
  <div
    *ngIf="tokenRewards"
    class="flex flex-col justify-between card w-full primary md:w-1/2 text-white hover:text-sc-primary"
  >
    <div>
      <div>Rewards</div>
      <div class="flex items-center">
        <div class="text-3xl mr-1 font-bold">
          {{ tokenRewards.totalRewards | number : "1.2-2" }}
        </div>
        <div class="text-xl font-light">
          {{ stats?.rewardConfigData?.rewardToken }}
        </div>
      </div>
      <div>
        {{
          tokenRewards.totalRewards * rewardToken?.exchangeRate
            | currency : "USD" : "symbol-narrow" : "1.2-2"
        }}
      </div>
    </div>
    <div class="flex justify-end">
      <div class="text-right">
        <div *ngIf="tokenRewards.baseReward > 0">
          <span class="text-sm text-blue-100 mr-2">Base Rewards</span>
          <span class="text-sm font-medium">
            {{ tokenRewards.baseReward | number : "1.2-2" }}
          </span>
        </div>
        <div *ngIf="tokenRewards.campaignReward > 0">
          <span class="text-sm text-blue-100 mr-2">Reward Campaign</span>
          <span class="text-sm font-medium">
            {{ tokenRewards.campaignReward | number : "1.2-2" }}
          </span>
        </div>
        <div *ngIf="tokenRewards.firstLoanReward > 0">
          <span class="text-sm text-blue-100 mr-2">First Loan Over $1000</span>
          <span class="text-sm font-medium">
            {{ tokenRewards.firstLoanReward | number : "1.2-2" }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card w-full mt-6">
  <div class="flex justify-between items-top mb-3">
    <h3 class="my-0">Summary</h3>
    <!-- <div class="flex gap-2 mb-2">
      <button
        *ngIf="!showUsd"
        class="nostyle rounded-lg py-1 px-1.5 text-slate-400 text-sm font-semibold bg-slate-50 hover:bg-sc-primary/5 hover:text-slate-500 transition"
        (click)="showUsd = true"
      >
        USD
      </button>
      <button
        *ngIf="showUsd"
        class="nostyle rounded-lg py-1 px-1.5 text-slate-400 text-sm font-semibold bg-slate-50 hover:bg-sc-primary/5 hover:text-slate-500 transition"
        (click)="showUsd = false"
      >
        CRYPTO
      </button>
    </div> -->
    <!-- <div class="text-sm text-slate-500 font-medium">{{ loanData.type }}</div> -->
  </div>
  <div class="flex flex-wrap md:flex-nowrap gap-6">
    <div class="w-full md:w-1/2">
      <div class="border p-4 flex flex-col rounded-2xl">
        <div class="text-slate-500 font-medium">Financing</div>
        <div class="flex h-13 flex-col gap-1">
          <div class="flex">
            <sc-icon
              [src]="icons[loanData.underlyingSymbol]"
              [size]="'0.7'"
              [classes]="'-ml-1.5'"
            ></sc-icon>
            <div class="font-semibold text-xl truncate">
              {{ loanData.loanAmount || 0.0 | number : "1.2-4" }}
              {{ loanData.underlyingSymbol }}
            </div>
          </div>
          <div
            class="nostyle w-fit flex rounded-lg py-1 px-1.5 text-slate-500 text-sm font-semibold bg-sc-primary/5"
          >
            ≈
            {{
              loanData.loanAmount
                ? (loanData.loanAmount * loanAsset.exchangeRate
                  | currency : "USD" : "symbol-narrow" : "1.2-2")
                : 0.0
            }}
          </div>
        </div>
        <div class="border-b mt-3 -mx-4"></div>
        <div class="flex justify-between mt-4 truncate gap-2">
          <div class="text-slate-500 truncate">Net Fees</div>
          <div class="font-semibold">
            {{ loanData.loanRate || 0.0 | number : "1.2-6" }}%
          </div>
        </div>
        <div class="flex justify-between mt-4 truncate gap-2">
          <div class="text-slate-500 truncate">To Repurchase</div>
          <div class="font-semibold">
            {{ +repaymentAmount || 0.0 | number : "1.2-6" }}
            {{ loanData.underlyingSymbol }}
          </div>
        </div>
        <div
          class="nostyle w-fit flex self-end rounded-lg py-1 px-1.5 text-slate-500 text-sm font-semibold bg-sc-primary/5"
        >
          ≈
          {{
            +repaymentAmount
              ? (+repaymentAmount * loanAsset.exchangeRate
                | currency : "USD" : "symbol-narrow" : "1.2-2")
              : 0.0
          }}
        </div>
        <div class="flex justify-between mt-4 truncate gap-2">
          <div class="text-slate-500 truncate">Repurchase Before</div>
          <div class="font-semibold">
            {{ loanEndDate | date : "mediumDate" }}
          </div>
        </div>
      </div>
    </div>
    <div class="w-full md:w-1/2">
      <div class="border p-4 flex flex-col rounded-2xl">
        <div class="text-slate-500 font-medium">Collateral</div>
        <div class="flex h-13 flex-col gap-1">
          <div class="flex">
            <sc-icon
              [src]="icons[loanData.collateralSymbol]"
              [size]="'0.7'"
              [classes]="'-ml-1.5'"
            ></sc-icon>
            <div class="font-semibold text-xl truncate">
              {{ loanData.collateralAmount || 0.0 | number : "1.2-4" }}
              {{ loanData.collateralSymbol }}
            </div>
          </div>
          <div
            class="nostyle w-fit flex rounded-lg py-1 px-1.5 text-slate-500 text-sm font-semibold bg-sc-primary/5"
          >
            ≈
            {{
              loanData.collateralAmount
                ? (loanData.collateralAmount * loanCollateral.exchangeRate
                  | currency : "USD" : "symbol-narrow" : "1.2-2")
                : 0.0
            }}
          </div>
        </div>
        <div class="border-b mt-3 -mx-4"></div>
        <div class="flex justify-between mt-4 truncate gap-2">
          <div class="text-slate-500 truncate">Collateral Ratio</div>
          <div
            class="font-semibold"
            [ngClass]="
              !loanData.collateralRatio ? 'text-slate-500 text-sm' : ''
            "
          >
            {{
              loanData.collateralRatio
                ? (loanData.collateralRatio | number : "1.2-2") + "%"
                : "Calculating"
            }}
          </div>
        </div>
        <div class="flex justify-between mt-4 truncate gap-2">
          <div class="text-slate-500 truncate">Liquidation Limit</div>
          <div
            class="font-semibold"
            [ngClass]="
              !loanData.liquidationLimit ? 'text-slate-500 text-sm' : ''
            "
          >
            {{
              loanData.liquidationLimit
                ? loanData.liquidationLimit
                : "Calculating"
            }}<span *ngIf="loanData.liquidationLimit">%</span>
          </div>
        </div>
        <div
          *ngIf="loanData.liquidationLimit"
          class="nostyle w-fit flex self-end rounded-lg py-1 px-1.5 text-slate-500 text-sm font-semibold bg-sc-primary/5"
        >
          ≈ {{ loanData.collateralSymbol }} @
          {{
            (loanData.liquidationLimit * loanData.loanAmountUsd) /
              100 /
              loanData.collateralAmount
              | currency : "USD" : "symbol-narrow" : "1.4-4"
          }}
        </div>
        <div class="flex justify-between mt-4 truncate gap-2">
          <div class="text-slate-500 truncate">Finance Line</div>
          <div class="font-semibold flex items-center">
            {{
              loanData && loanData.creditLineAddress
                ? (loanData.creditLineAddress | shortAddress : 6 : 4)
                : "New"
            }}
            <button
              *ngIf="loanData.creditLineAddress"
              (click)="goToCreditLine()"
              class="nostyle block-explorer"
              [tooltip]="'View Finance Line'"
            >
              <sc-icon
                [src]="icons.externalNew"
                [size]="'0.75'"
                class="external-icon"
                [classes]="'-mr-1'"
              ></sc-icon>
              <span class="sr-only">View Finance Line</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="mt-6 p-6 text-sc-primary font-medium bg-sc-primary/5 rounded-2xl">
    Loan term is {{ loanData.loanTerm }} days. You must repay the loan by {{ loanEndDate | date:'mediumDate' }}
  </div> -->
  <!-- NOT CONNECTED -->
  <div *ngIf="!user" class="bg-slate-50 p-4 rounded-2xl mt-6">
    <div class="flex gap-4 items-center">
      <sc-icon
        [src]="icons.walletX"
        class="wallet-icon"
        [size]="'1.25'"
      ></sc-icon>
      <div>
        <p class="font-semibold text-slate-500 text-base mb-1">
          Wallet not connected!
        </p>
        <p class="text-slate-500 text-base">
          Connect your wallet to create a loan.
        </p>
      </div>
      <div class="hidden md:block ml-auto">
        <shared-account-center></shared-account-center>
      </div>
    </div>
    <div class="block md:hidden ml-10 mt-2">
      <shared-account-center></shared-account-center>
    </div>
  </div>
  <!-- ADVANCED MODE NO PMS -->
  <div
    *ngIf="advancedMode && user && !user.telegram && !user.telegram_me"
    class="bg-slate-50 p-4 rounded-2xl flex gap-4 items-top mt-6"
  >
    <sc-icon
      [src]="icons.warningOutline"
      [size]="'1'"
      [classes]="'mt-1 ml-0'"
    ></sc-icon>
    <div>
      <p class="font-semibold text-sc-warning text-base mb-1">
        Connect your account to Telegram to use Advanced Mode!
      </p>
      <p class="text-slate-500 text-base">
        BarakaFi Position Monitoring System monitors your positions and notifies
        you through Telegram notifications.
      </p>
      <button class="primary-inverse mt-2 !text-base" [routerLink]="'/profile'">
        Connect to Telegram
      </button>
    </div>
  </div>
  <!-- END ADVANCED MODE NO PMS -->
  <div class="flex justify-center mt-16">
    <button
      [disabled]="
        isUpdating ||
        creatingLoanRequest ||
        borrowForm.invalid ||
        !user ||
        (advancedMode && !user.telegram && !user.telegram_me)
      "
      (click)="submit()"
      class="mt-6 primary mx-auto !h-12 create-loan-btn"
    >
      <div *ngIf="isUpdating" class="flex items-center px-10">
        <spinner size="xs" class="mr-2"></spinner>
        Updating...
      </div>

      <div *ngIf="!isUpdating" class="flex items-center px-10">
        <sc-icon
          [src]="icons.plus"
          classes="-ml-3"
          [size]="'0.4'"
          [width]="'2rem'"
        >
        </sc-icon>
        Create Financing Request
      </div>
    </button>
  </div>
</div>
