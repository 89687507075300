import { Action, createReducer, on } from "@ngrx/store";
import { FixedIncomeFund } from "src/app/shared/interfaces/fifs/fixedIncomeFund";
import * as LendingActions from "./lending.actions";

export interface AppState {
  lending: State;
}

export interface State {
  depositingFif: boolean;
  terminatingFif: boolean;
  withdrawingFif: boolean;
  creatingFif: boolean;
  fixedIncomeFunds: FixedIncomeFund[] | undefined;
  reallocatingFif: boolean;
  riskFreeRateLogBase: number;
}

const initialState = {
  fixedIncomeFunds: undefined,
  depositingFif: undefined,
  terminatingFif: undefined,
  withdrawingFif: undefined,
  creatingFif: undefined,
  reallocatingFif: undefined,
  riskFreeRateLogBase: undefined,
};

const _lendingReducer = createReducer(
  initialState,
  on(LendingActions.getFixedIncomeFunds, (state, action) => ({
    ...state,
    fixedIncomeFunds: action.fixedIncomeFunds,
  })),
  on(LendingActions.getFixedIncomeFund, (state, action) => {
    let fifs;
    if (state.fixedIncomeFunds && state.fixedIncomeFunds.length > 0) {
      fifs = state.fixedIncomeFunds.filter((fif: FixedIncomeFund) => fif.id !== action.fixedIncomeFund.id);
    }
    return {
      ...state,
      fixedIncomeFunds: [
        ...(fifs ? fifs : []),
        action.fixedIncomeFund,
      ]
    };
  }),
  on(LendingActions.creatingFif, (state, action) => ({
    ...state,
    creatingFif: action.creatingFif,
  })),
  on(LendingActions.depositingFif, (state, action) => ({
    ...state,
    depositingFif: action.depositingFif,
  })),
  on(LendingActions.withdrawingFif, (state, action) => ({
    ...state,
    withdrawingFif: action.withdrawingFif,
  })),
  on(LendingActions.terminatingFif, (state, action) => ({
    ...state,
    terminatingFif: action.terminatingFif,
  })),
  on(LendingActions.reallocatingFif, (state, action) => ({
    ...state,
    reallocatingFif: action.reallocatingFif,
  })),
  on(LendingActions.updateFif, (state, action) => {
    return {
      ...state,
      fixedIncomeFunds: state.fixedIncomeFunds.map((fund: FixedIncomeFund) => {
        if (fund.id === action.fixedIncomeFund.id) {
          return action.fixedIncomeFund;
        }
        return fund;
      })
    }
  }),
  on(LendingActions.updateRiskFreeRateLogBase, (state, action) => ({
    ...state,
    riskFreeRateLogBase: action.riskFreeRateLogBase,
  })),
  on(LendingActions.resetState, () => initialState)
);

export function lendingReducer(state: State, action: Action) {
  return _lendingReducer(state, action);
}
