import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, combineLatest, EMPTY, firstValueFrom, forkJoin, lastValueFrom, map, Observable, retry, Subject, take, takeUntil } from 'rxjs';
import { BorrowFormInit } from 'src/app/shared/interfaces/borrowing/borrow-form-init';
import { BorrowingDashboardInit } from 'src/app/shared/interfaces/borrowing/borrowing-dashboard-init';
import { CreditLine } from 'src/app/shared/interfaces/borrowing/credit-line';
import { Loan } from 'src/app/shared/interfaces/borrowing/loan';
import * as fromApp from 'src/app/store/app.reducer';
import * as BorrowingActions from 'src/app/borrowing/store/borrowing.actions';
import * as DashboardActions from 'src/app/dashboard/store/dashboard.actions';
import {
  borrowingFormInitQuery,
  creditLinesQuery,
  requiredCollateralRatioQuery,
  liquidityPoolQuery,
  borrowingDashboardInitQuery,
  terminateLoanRequest,
  loanQuery,
  createLoanRequest,
  loanAdditionalDetailsQuery,
  liquidationProbability
} from '../graphql/borrowingTypes';
import { LoanData } from 'src/app/shared/interfaces/borrowing/loan-data';
import { Web3Service } from '../web3/web3.service';
import { Currency } from 'src/app/shared/interfaces/currencies/currency';
import BigNumber from 'bignumber.js';
import * as Math from 'mathjs';
import { Apollo } from 'apollo-angular';
import { RiskRateFee } from 'src/app/shared/interfaces/borrowing/risk-rate-fee';
import { BorrowingFormData } from 'src/app/shared/interfaces/borrowing/borrowing-form-data';
import { loansQuery } from '../graphql/borrowingTypes';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CurrenciesService } from './currencies.service';
import { ParamsInformation } from 'src/app/shared/interfaces/borrowing/params-information';
import { config } from 'src/app/shared/constants';
import { currencies } from 'src/app/shared/constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BorrowingService implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<fromApp.AppState>,
    private web3Service: Web3Service,
    private apollo: Apollo,
    private toastr: ToastrService,
    private router: Router,
    private currenciesService: CurrenciesService
  ) { }

  getBorrowingFormInitData() {
    return this.apollo.watchQuery({
      query: borrowingFormInitQuery
    }).valueChanges
      .pipe(catchError((err: any): any => {
        if (!environment.production) console.log(err);
        return EMPTY;
      }))
      .pipe(map((res: any) => res.data))
      .subscribe((res: BorrowFormInit) => {
        this.store.dispatch(BorrowingActions.initializeBorrowingForm({ riskRateFees: res.riskRateFees, paramsInformation: res.paramsInformation }))
      })
  }

  getCreditLines(where) {
    this.apollo.watchQuery({
      query: creditLinesQuery,
      variables: {
        where
      }
    }).valueChanges
      .pipe(catchError((err: any): any => {
        this.toastr.error('Please retry!', 'Could not get Credit Lines');
        if (!environment.production) console.log(err);
        return EMPTY;
      }),
        map((res: any) => res.data.creditLines),
        map((res: CreditLine[]) => res.filter((res) => res.type !== 'Type F Loan')),
        takeUntil(this.destroy$)
      )
      .subscribe((res: CreditLine[]) => {
        this.store.dispatch(BorrowingActions.getCreditLines({ creditLines: res }))
      })
  }

  getBorrowingDashboardInitData(walletAddress: string = '0x12345') {
    this.apollo.watchQuery({
      query: borrowingDashboardInitQuery,
      variables: {
        walletAddress
      }
    }).valueChanges
      .pipe(
        catchError((err: any): any => {
          this.toastr.error('Please retry!', err);
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
        map((res: any) => res.data),
        takeUntil(this.destroy$)
      )
      .subscribe((res: BorrowingDashboardInit) => {
        this.store.dispatch(BorrowingActions.initializeBorrowingDashboard({ borrowedLoanSummary: res.borrowedLoanSummary, creditLines: res.creditLines }))
      })
  }

  approveRepayment(loan: Loan) {
    this.store.dispatch(BorrowingActions.approvingRepayment({ approvingRepayment: true }));
    this.web3Service.doERC20Approval(loan)
      .pipe(catchError((err: any): any => {
        this.toastr.error('Please retry!', 'Something went wrong');
        this.store.dispatch(BorrowingActions.approvingRepayment({ approvingRepayment: false }));
        if (!environment.production) console.log(err);
        return EMPTY;
      }),
        takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.toastr.info('Repurchase approved!');
        const updatedLoan = {
          ...loan,
          approvalNeeded: false
        }
        this.store.dispatch(BorrowingActions.updateLoan({ loan: updatedLoan }));
        this.store.dispatch(BorrowingActions.approvingRepayment({ approvingRepayment: false }));
      })
  }

  async getLoan(orderbookId: string, userRating: number): Promise<void> {

    let loan: any = await firstValueFrom(this.apollo.watchQuery({
      query: loanQuery,
      variables: {
        orderbookId
      },
      fetchPolicy: 'cache-and-network'
    }).valueChanges
      .pipe(catchError((err: any): any => {
        this.toastr.error('Please retry!', err);
        if (!environment.production) console.log(err);
        return EMPTY;
      }),
        map((res: any) => ({ ...res.data.loanRequest, ...res.data.liquidationProbability, ...res.data.loanRequestValidators }))));

    if (loan) {

      const loanVariables = {
        loanUsdValue: this.currenciesService.weiToCurrency(loan.loanAmount, loan.underlying, 'USD').toString(),
        collateralUsdValue: this.currenciesService.weiToCurrency(loan.collateralInfo.amount, loan.collateral, 'USD').toString(),
        underlyingSymbol: loan.underlying.symbol,
        collateralSymbol: loan.collateral.symbol,
        userRating: userRating || 9,
        duration: loan.loanTerm
      }

      let loanDetails = await lastValueFrom(this.apollo.query({
        query: loanAdditionalDetailsQuery,
        variables: loanVariables
      }).pipe(
        catchError((err: any): Observable<any> => {
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
        map((loanDetails: any) => {
          return {
            collateralNeeded: loanDetails.data.collateralNeeded === 0 ? 0.0000000000001 : loanDetails.data.collateralNeeded,
            liquidationLimit: loanDetails.data.liquidationLimit === 0 ? 0.0000000000001 : loanDetails.data.liquidationLimit,
            liquidationProbability: loanDetails.data.liquidationProbability === 0 ? 0.0000000000001 : loanDetails.data.liquidationProbability,
            ...loanDetails.data.loanRequestValidators
          }
        })
      ))

      if (loan.lender) {
        let combineData = await firstValueFrom(combineLatest({
          loanContractData: this.web3Service.getLoanData(loan.contractAddress, loan.id),
          tokenAllowance: this.web3Service.getTokenAllowance(loan.underlying, loan.contractAddress)
        }));

        if (combineData) {
          const loanEndDay = new Date(combineData.loanContractData.loanEnded * 1e3).toISOString();
          const approvalNeeded = new BigNumber(combineData.tokenAllowance).isLessThan(combineData.loanContractData.outstandingAmount)
          loan = {
            ...loan,
            loanStatus: loan.loanStatus === 'ACTIVE' && loanEndDay < new Date().toISOString() ? 'ACTIVE' : loan.loanStatus,
            outstandingBalance: combineData.loanContractData.outstandingAmount,
            approvalNeeded: approvalNeeded
          }
          this.store.dispatch(BorrowingActions.getLoan({ loan }))
        }
      } else {
        this.store.dispatch(BorrowingActions.getLoan({ loan }))
      }

      if (loanDetails) {
        const loanFull: Loan = {
          ...loan,
          ...loanDetails.liquidationProbability
        }
        this.store.dispatch(BorrowingActions.getLoan({ loan: loanFull }))
      }
    }
  }

  async getLoans(borrowerAddress: string, userRating: number): Promise<void> {

    let loans: Loan[] = await firstValueFrom(this.apollo.watchQuery({
      query: loansQuery,
      variables: {
        where: {
          borrowerAddress
        }
      }
    }).valueChanges
      .pipe(catchError((err: any): Observable<any> => {
        this.toastr.error('Please retry!', 'Could not get loans');
        if (!environment.production) console.log(err);
        return EMPTY;
      }),
        map((res: any) => res.data.loanRequests)));


    if (loans) {
      loans.forEach(async (loan: Loan) => {
        const loanVariables = {
          loanUsdValue: this.currenciesService.weiToCurrency(loan.loanAmount, loan.underlying, 'USD').toString(),
          collateralUsdValue: this.currenciesService.weiToCurrency(loan.collateralInfo.amount, loan.collateral, 'USD').toString(),
          underlyingSymbol: loan.underlying.symbol,
          collateralSymbol: loan.collateral.symbol,
          userRating: userRating || 9,
          duration: loan.loanTerm
        }

        let loanDetails = await lastValueFrom(this.apollo.query({
          query: loanAdditionalDetailsQuery,
          variables: loanVariables
        }).pipe(
          catchError((err: any): Observable<any> => {
            if (!environment.production) console.log(err);
            return EMPTY;
          }),
          map((loanDetails: any) => {
            return {
              collateralNeeded: loanDetails.data.collateralNeeded === 0 ? 0.0000000000001 : loanDetails.data.collateralNeeded,
              liquidationLimit: loanDetails.data.liquidationLimit === 0 ? 0.0000000000001 : loanDetails.data.liquidationLimit,
              liquidationProbability: loanDetails.data.liquidationProbability === 0 ? 0.0000000000001 : loanDetails.data.liquidationProbability,
              ...loanDetails.data.loanRequestValidators
            }
          })
        ));

        if (loanDetails) {
          const loanFull: Loan = {
            ...loan,
            ...loanDetails
          }
          if (loanFull.lender) {
            combineLatest({
              loanContractData: this.web3Service.getLoanData(loanFull.contractAddress, loanFull.id).pipe(
                catchError((err: any): Observable<any> => {
                  if (!environment.production) console.log(err);
                  return EMPTY;
                }),
              ),
              tokenAllowance: this.web3Service.getTokenAllowance(loanFull.underlying, loanFull.contractAddress).pipe(
                catchError((err: any): Observable<any> => {
                  if (!environment.production) console.log(err);
                  return EMPTY;
                }),
              )
            })
              .subscribe(({ loanContractData, tokenAllowance }) => {
                const loanEndDay = new Date(loanContractData.loanEnded * 1e3).toISOString();
                const approvalNeeded = new BigNumber(tokenAllowance).isLessThan(loanContractData.outstandingAmount)
                const loanComplete = {
                  ...loanFull,
                  loanStatus: loanFull.loanStatus === 'ACTIVE' && loanEndDay < new Date().toISOString() ? 'ACTIVE' : loanFull.loanStatus,
                  outstandingBalance: loanContractData.outstandingAmount,
                  approvalNeeded
                }
                this.store.dispatch(BorrowingActions.getLoan({ loan: loanComplete }))
              })
          } else {
            this.store.dispatch(BorrowingActions.getLoan({ loan: loanFull }))
          }
        }
      })
    }
  }

  inferCreditLineType(underlyingSymbol, collateralSymbol) {
    let loanType: string;
    if (underlyingSymbol === 'ISLM') {
      loanType = collateralSymbol === 'ISLM' ? 'Type D Loan' : 'Type A Loan';
    } else {
      loanType = collateralSymbol === 'ISLM' ? 'Type B Loan' : 'Type C Loan';
    }
    this.store.dispatch(BorrowingActions.updateLoanData({ loanData: { type: loanType } }))
  }

  getCreditLineAddress(creditLines: CreditLine[], creditLineType: string) {
    if (creditLines && creditLines.length > 0) {
      const loanCreditLine = creditLines.find(c => c.type === creditLineType);
      let creditLineAddress;
      if (loanCreditLine) {
        creditLineAddress = loanCreditLine.contractAddress;
      } else {
        creditLineAddress = undefined;
      }
      this.store.dispatch(BorrowingActions.updateLoanData({ loanData: { creditLineAddress } }))
    }
  }

  getCollateralToLoan(loanForm: BorrowingFormData, collateral: Currency, asset: Currency, currentRiskRating: number): void {
    let validUntil = new Date();
    validUntil.setDate(validUntil.getDate() + 3);
    this.apollo.query({
      query: requiredCollateralRatioQuery,
      variables: {
        amount: +loanForm.amount,
        duration: +loanForm.duration,
        validity: validUntil,
        collateral: collateral.symbol,
        underlying: asset.symbol,
        riskRating: currentRiskRating
      }
    })
      .pipe(catchError((err: any): Observable<any> => {
        if (!environment.production) console.log(err);
        return EMPTY;
      }))
      .pipe(
        map((res: any) => {
          const collateralRatio = res.data.requiredCollateralRatio.collateralRatio;
          const loanAmount = loanForm.amount;
          const requiredCollateralValueUsd: number = parseFloat(
            new BigNumber(loanAmount).times(asset.exchangeRate).times(collateralRatio).div(100).toFixed(4)
          )
          const collateralAmount: number = parseFloat(
            new BigNumber(requiredCollateralValueUsd).div(collateral.collateralPrice || collateral.exchangeRate).toFixed(6)
          )
          const collateralData = {
            collateralAmount,
            collateralRatio
          }
          return collateralData;
        }),
        takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.store.dispatch(BorrowingActions.updateLoanData({
          loanData: {
            collateralAmount: res.collateralAmount,
            collateralRatio: res.collateralRatio
          }
        }));
      })
  }

  getLiquidationProbability(loanData: LoanData) {
    this.apollo.query({
      query: liquidationProbability,
      variables: {
        loanUsdValue: loanData.loanAmountUsd.toString(),
        collateralUsdValue: isNaN(loanData.collateralAmountUsd) || Math.isNull(loanData.collateralAmountUsd) || Math.isUndefined(loanData.collateralAmountUsd) ? loanData.collateralAmount.toString() : loanData.collateralAmountUsd.toString(),
        underlyingSymbol: loanData.underlyingSymbol,
        collateralSymbol: loanData.collateralSymbol,
        userRating: loanData.userRating || 9,
        duration: loanData.loanTerm,
      },
      fetchPolicy: 'no-cache'
    })
      .pipe(catchError((err: any): Observable<any> => {
        if (!environment.production) console.log(err);
        return err;
      }), retry(1))
      .pipe(
        map((res: any) => {
          return {
            collateralNeeded: res.data.liquidationProbability.collateralNeeded === 0 ? 0.0000000000001 : res.data.liquidationProbability.collateralNeeded,
            liquidationLimit: res.data.liquidationProbability.liquidationLimit === 0 ? 0.0000000000001 : res.data.liquidationProbability.liquidationLimit,
            liquidationProbability: res.data.liquidationProbability.liquidationProbability === 0 ? 0.0000000000001 : res.data.liquidationProbability.liquidationProbability,
          }
        }),
        takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.store.dispatch(BorrowingActions.updateLoanData({ loanData: { ...loanData, ...res } }));
      })
  }

  updateCollateralLiquidityPool(currencySymbol) {
    return this.apollo.query({
      query: liquidityPoolQuery,
      variables: {
        currencySymbol
      }
    })
      .pipe(catchError((err: any): Observable<any> => {
        if (!environment.production) console.log(err);
        return EMPTY;
      }), map((res: any) => res),
        takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.store.dispatch(DashboardActions.updateCollateralLiquidity({ liquidity: res.data.liquidityPool, symbol: currencySymbol }))
      })
  }

  updateLoanData(input: LoanData) {
    this.store.dispatch(BorrowingActions.updateLoanData({ loanData: input }));
  }

  updateLoanAsset(loanAsset: Currency) {
    this.store.dispatch(BorrowingActions.updateLoanAsset({ loanAsset }));
  }

  updateLoanCollateral(loanCollateral: Currency) {
    this.store.dispatch(BorrowingActions.updateLoanCollateral({ loanCollateral }));
  }

  updateNetInterestRate(creditData: RiskRateFee | any, loanTerm: number, underlying: Currency, paramsInformation: ParamsInformation) {
    // TODO     const partnerFee = paramsInformation.partnerFee ? paramsInformation.partnerFee : paramsInformation.basePartnerFee;
    const partnerFee = paramsInformation.partnerFee ? paramsInformation.partnerFee : 0;
    const marginRate = creditData.marginRate;
    const maxInsurancePremiumRate = creditData.maxInsurancePremiumRate;
    const platformFee = creditData.platformFee;
    const riskFreeRate = BigNumber(underlying.riskFreeRate).times(Math.log(loanTerm, paramsInformation.riskFreeRateLogBase)).toFixed(2);
    const insurancePremiumRate = Math.round(Math.evaluate(`${maxInsurancePremiumRate}/ 3 * (${loanTerm} * 2 / 180 + 1)`), 2);
    const loanFee = Math.round(Math.evaluate(`${insurancePremiumRate} + ${platformFee} + ${partnerFee}`), 2);
    const loanRate = parseFloat(BigNumber(BigNumber(BigNumber(marginRate).plus(riskFreeRate)).div(365))
      .times(loanTerm).plus(insurancePremiumRate).plus(platformFee).plus(partnerFee).toFixed(2));
    this.store.dispatch(BorrowingActions.updateLoanData({ loanData: { loanRate, loanFee } }));
  }

  createLoanRequest(loanData: LoanData) {
    return this.apollo
      .mutate({
        mutation: createLoanRequest,
        variables: {
          loanAmount: +loanData.loanAmount,
          loanTerm: +loanData.loanTerm,
          loanRate: loanData.loanRate,
          collateralAmount: loanData.collateralAmount,
          loanRequestValidity: loanData.loanRequestValidity,
          collateralCurrency: loanData.collateralSymbol,
          underlyingCurrency: loanData.underlyingSymbol,
          userRating: loanData.userRating,
          creditLineAddress: loanData.creditLineAddress,
          // signature: loanData.signature,
          borrowerAddress: loanData.borrowerAddress,
          type: loanData.type
        }
      })
      .pipe(catchError((err: any): Observable<any> => {
        this.toastr.error('Please retry!', 'Could not create financing request');
        this.store.dispatch(BorrowingActions.creatingLoanRequest({ creatingLoanRequest: false }));
        if (!environment.production) console.log(err);
        return EMPTY;
      }), map((res: any) => {
        if (res.data.errors && res.data.errors.length > 0) {
          this.toastr.error('', res.data.errors.message);
          return null;
        }
        return res.data.createLoanRequest.loanRequest;
      }),
        takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.toastr.info('', 'Financing request created!');
        this.store.dispatch(BorrowingActions.creatingLoanRequest({ creatingLoanRequest: false }));
        this.store.dispatch(BorrowingActions.updateLoanData({ loanData: null }));
        this.router.navigate([`/purchase/credit-line/${res.contractAddress}/loan/`, res.id], { queryParams: { isNewCreditLine: true } })
      })
  }

  getCreditLineCurrenciesAndBalances(creditLine: CreditLine, assets: Currency[], collaterals: Currency[]): Observable<void> {

    let creditLineAssets: Currency[] = [];
    let creditLineCollaterals: Currency[] = [];
    if (creditLine.type === 'Type A Loan') {
      creditLineAssets = assets.filter((a: Currency) => a.symbol === 'ISLM');
      creditLineCollaterals = collaterals.filter(c => c.symbol !== 'ISLM');
    } else if (creditLine.type === 'Type B Loan') {
      creditLineAssets = assets.filter((a: Currency) => a.symbol !== 'ISLM');
      creditLineCollaterals = collaterals.filter(c => c.symbol === 'ISLM');
    } else if (creditLine.type === 'Type C Loan') {
      creditLineAssets = assets.filter((a: Currency) => a.symbol !== 'ISLM');
      creditLineCollaterals = collaterals.filter(c => c.symbol !== 'ISLM');
    } else if (creditLine.type === 'Type D Loan') {
      creditLineAssets = assets.filter((a: Currency) => a.symbol === 'ISLM');
      creditLineCollaterals = collaterals.filter(c => c.symbol === 'ISLM');
    }

    const collateralsWithBalances = [];

    const collateralObservables = creditLineCollaterals.map((currency: Currency) => {
      return forkJoin({
        locked: this.web3Service.lockedAssetValue(currency.ethAddress, creditLine.contractAddress),
        unlocked: this.web3Service.unlockedAssetValue(currency.ethAddress, creditLine.contractAddress)
      }).pipe(
        map(({ locked, unlocked }) => ({
          ...currency,
          locked,
          unlocked
        }))
      );
    })

    return forkJoin(collateralObservables).pipe(
      map((results) => {
        collateralsWithBalances.push(...results);
        if (collateralsWithBalances.length === creditLineCollaterals.length) {
          collateralsWithBalances.sort((a, b) => +b.locked - +a.locked || b.unlocked - a.unlocked);
          creditLineCollaterals = collateralsWithBalances;
          this.store.dispatch(BorrowingActions.addCreditLineCurrencies({ creditLine, assets: creditLineAssets, collaterals: creditLineCollaterals }));
        }
      })
    );
  }

  updateCreditLineAsset(creditLine: CreditLine, loans: Loan[]) {
    loans.forEach(loan => {
      if (loan.loanStatus === 'ACTIVE' && loan.loanAmount > 0) {
        creditLine = {
          ...creditLine,
          assets: creditLine.assets?.map((a: Currency) => {
            if (a.symbol === loan.underlying.symbol) {
              return {
                ...a,
                borrowed: a.borrowed ? a.borrowed + loan.loanAmount : loan.loanAmount
              }
            } else {
              return {
                ...a,
                borrowed: 0
              }
            }
          })
        }
      }
    })
    if (creditLine.assets && creditLine.assets[0].borrowed) {
      creditLine.assets?.sort((a, b) => +b.borrowed - +a.borrowed);
    }
    this.store.dispatch(BorrowingActions.updateCreditLine({ creditLine }));
  }

  createLoan(loan: Loan) {
    this.store.dispatch(BorrowingActions.creatingLoan({ creatingLoan: true }));

    this.web3Service.createLoan(loan)
      .pipe(catchError((err: any): Observable<any> => {
        this.store.dispatch(BorrowingActions.creatingLoan({ creatingLoan: false }));
        this.toastr.error('Please retry!', 'Could not start finance');
        if (!environment.production) console.log(err);
        return EMPTY;
      }), takeUntil(this.destroy$))
      .subscribe(() => {
        const updatedLoan: Loan = {
          ...loan,
          loanStatus: 'ACTIVE',
          states: {
            collateral: false,
            funded: true,
            matching: false,
            matched: false,// tbc
            terminated: false,
            nearLiquidation: false,
            liquidating: false,
            liquidated: false,
            repaid: false,
            badLoan: false
          }
        };
        this.store.dispatch(BorrowingActions.updateLoan({ loan: updatedLoan }));
        this.store.dispatch(BorrowingActions.creatingLoan({ creatingLoan: false }));
      })
  }

  depositCollateral(loan: Loan, amount: any) {
    this.store.dispatch(BorrowingActions.depositingCollateral({ depositingCollateral: true }));
    if (loan.collateral.symbol === 'ISLM') {
      this.web3Service.transferEthereumToLoan(loan, amount)
        .pipe(catchError((err: any): Observable<any> => {
          this.store.dispatch(BorrowingActions.depositingCollateral({ depositingCollateral: false }));
          this.store.dispatch(BorrowingActions.increasingCollateral({ increasingCollateral: false }));
          this.toastr.error('Please retry!', 'Could not deposit collateral');
          if (!environment.production) console.log(err);
          return EMPTY;
        }), takeUntil(this.destroy$))
        .subscribe(() => {
          const updatedLoan: Loan = {
            ...loan,
            currentCollateralAmount: (new BigNumber(loan.collateralInfo.amount)
              .plus(this.currenciesService.currencyToWei(amount, loan.collateral)).toNumber()).toLocaleString('fullwide', { useGrouping: false }),
            states: {
              collateral: false,
              funded: false,
              matching: true,
              matched: false,// tbc
              terminated: false,
              repaid: false,
              nearLiquidation: false,
              liquidating: false,
              liquidated: false,
              badLoan: false
            }
          };
          this.store.dispatch(BorrowingActions.updateLoan({ loan: updatedLoan }));
          this.store.dispatch(BorrowingActions.depositingCollateral({ depositingCollateral: false }));
          this.store.dispatch(BorrowingActions.increasingCollateral({ increasingCollateral: false }));

        });
    } else {
      this.web3Service.transferTokensToLoan(loan, this.currenciesService.currencyToWei(amount, loan.collateral))
        .pipe(catchError((err: any): Observable<any> => {
          this.store.dispatch(BorrowingActions.depositingCollateral({ depositingCollateral: false }));
          this.store.dispatch(BorrowingActions.increasingCollateral({ increasingCollateral: false }));
          this.toastr.error('Please retry!', 'Could not deposit collateral');
          if (!environment.production) console.log(err);
          return EMPTY;
        }), takeUntil(this.destroy$))
        .subscribe(() => {
          const updatedLoan: Loan = {
            ...loan,
            currentCollateralAmount: loan.collateralInfo.amount.toString(),
            states: {
              collateral: false,
              funded: false,
              matching: true,
              matched: false,// tbc
              terminated: false,
              repaid: false,
              nearLiquidation: false,
              liquidating: false,
              liquidated: false,
              badLoan: false
            }
          };
          this.store.dispatch(BorrowingActions.updateLoan({ loan: updatedLoan }));
          this.store.dispatch(BorrowingActions.depositingCollateral({ depositingCollateral: false }));
          this.store.dispatch(BorrowingActions.increasingCollateral({ increasingCollateral: false }));
        });
    }
  }

  increaseCollateral(loan: Loan, amount: string, creditLine: CreditLine, collateral: Currency) {
    this.store.dispatch(BorrowingActions.increasingCollateral({ increasingCollateral: true }));
    this.web3Service.increaseLoanCollateral(loan, amount)
      .pipe(catchError((err: any): Observable<any> => {
        this.toastr.error('Please retry!', 'Could not increase collateral');
        this.store.dispatch(BorrowingActions.increasingCollateral({ increasingCollateral: false }));
        if (!environment.production) console.log(err);
        return EMPTY;
      }), takeUntil(this.destroy$))
      .subscribe((res) => {
        const updatedLoan: Loan = {
          ...loan,
          currentCollateralAmount: (new BigNumber(loan.currentCollateralAmount)
            .plus(amount).toNumber()).toLocaleString('fullwide', { useGrouping: false }),
          states: {
            collateral: false,
            funded: true,
            matching: false,
            matched: false,// tbc
            terminated: false,
            repaid: false,
            nearLiquidation: false,
            liquidating: false,
            liquidated: false,
            badLoan: false
          }
        }
        collateral = {
          ...collateral,
          unlocked: new BigNumber(collateral.unlocked).minus(amount).toNumber().toLocaleString('fullwide', { useGrouping: false })
        }
        const collaterals = creditLine.collaterals.filter(c => c.symbol !== collateral.symbol);
        creditLine = {
          ...creditLine,
          collaterals: [...collaterals, collateral]
        }
        this.store.dispatch(BorrowingActions.updateCreditLine({ creditLine }));
        this.store.dispatch(BorrowingActions.updateLoan({ loan: updatedLoan }));
        this.store.dispatch(BorrowingActions.increasingCollateral({ increasingCollateral: false }));
      })
  }

  depositToCreditLine(creditLine: CreditLine, collateral: Currency, amount: string) {
    this.store.dispatch(BorrowingActions.depositingCollateral({ depositingCollateral: true }));
    if (collateral.symbol === 'ISLM') {
      this.web3Service.transferEthereumToCreditLine(creditLine, collateral, amount)
        .pipe(catchError((err: any): Observable<any> => {
          this.store.dispatch(BorrowingActions.depositingCollateral({ depositingCollateral: false }));
          this.store.dispatch(BorrowingActions.increasingCollateral({ increasingCollateral: false }));
          this.toastr.error('Please retry!', 'Could not deposit to Finance Line');
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
          takeUntil(this.destroy$))
        .subscribe((res) => {
          this.store.dispatch(BorrowingActions.depositingCollateral({ depositingCollateral: false }));
          this.store.dispatch(BorrowingActions.increasingCollateral({ increasingCollateral: false }));
          this.toastr.info('', `${collateral.symbol} deposited to Finance Line`);
          collateral = {
            ...collateral,
            unlocked: new BigNumber(collateral.unlocked).plus(amount).toNumber().toLocaleString('fullwide', { useGrouping: false })
          }
          const collaterals = creditLine.collaterals.filter(c => c.symbol !== collateral.symbol);
          creditLine = {
            ...creditLine,
            collaterals: [...collaterals, collateral]
          }
          this.store.dispatch(BorrowingActions.updateCreditLine({ creditLine }));
        });
    } else {
      this.web3Service.transferTokensToCreditLine(creditLine, collateral, amount)
        .pipe(catchError((err: any): Observable<any> => {
          this.store.dispatch(BorrowingActions.depositingCollateral({ depositingCollateral: false }));
          this.store.dispatch(BorrowingActions.increasingCollateral({ increasingCollateral: false }));
          this.toastr.error('Please retry!', 'Something went wrong');
          if (!environment.production) console.log(err);
          return EMPTY;
        }), takeUntil(this.destroy$))
        .subscribe((res) => {
          this.store.dispatch(BorrowingActions.depositingCollateral({ depositingCollateral: false }));
          this.store.dispatch(BorrowingActions.increasingCollateral({ increasingCollateral: false }));
          this.toastr.info('', `${collateral.symbol} deposited to Finance Line`);
          collateral = {
            ...collateral,
            unlocked: new BigNumber(collateral.unlocked).plus(amount).toNumber().toLocaleString('fullwide', { useGrouping: false })
          }
          const collaterals = creditLine.collaterals.filter(c => c.symbol !== collateral.symbol);
          creditLine = {
            ...creditLine,
            collaterals: [...collaterals, collateral]
          }
          this.store.dispatch(BorrowingActions.updateCreditLine({ creditLine }));
        });
    }
  }

  setLoanStates(loan: Loan, creditLine: CreditLine) {
    const c = creditLine.collaterals.find(c => c.symbol === loan.collateral.symbol);
    let isMatching: boolean;
    let isCollateralRequired: boolean;
    if (loan.loanStatus === 'NEW REQUEST') {
      isMatching = new BigNumber(loan.collateralInfo.amount).isLessThanOrEqualTo(c.unlocked);
      isCollateralRequired = !isMatching;
    } else {
      isMatching = false;
      isCollateralRequired = false;
    }
    if (loan.loanStatus === 'ACTIVE' && loan.outstandingBalance == 0) {
      loan = {
        ...loan,
        loanStatus: 'REPAID'
      }
    }
    let nearLiquidation: boolean;
    if (!currencies.STABLECOINS.includes(loan.collateral.symbol) && !currencies.STABLECOINS.includes(loan.underlying.symbol) &&
      loan.loanStatus === 'ACTIVE' && loan.liquidationProbability >= config.LIQUIDATION_NOTICE_THRESHOLD &&
      loan.liquidationStatus === 'NOT REQUIRED') {
      nearLiquidation = true;
    }
    const additionalCollateralNeeded = new BigNumber(loan.collateralInfo.amount).minus(c.unlocked).toString();
    const liquidating = loan.liquidationStatus !== 'NOT REQUIRED' && loan.liquidationStatus !== 'COMPLETED';
    const updatedLoan = {
      ...loan,
      additionalCollateralNeeded,
      states: {
        ...loan.states,
        collateral: isCollateralRequired,
        funded: loan.loanStatus === 'ACTIVE' && !liquidating,
        matching: isMatching,
        matched: loan.loanStatus === 'MATCHED',
        terminated: loan.loanStatus === 'TERMINATED',
        repaid: loan.loanStatus === 'REPAID',
        liquidated: loan.loanStatus === 'LIQUIDATED',
        liquidating: liquidating,
        nearLiquidation: nearLiquidation,
        badLoan: loan.loanStatus === 'BAD LOAN' || loan.loanStatus === 'BADLOAN REPAID'
      }
    }
    this.store.dispatch(BorrowingActions.updateLoan({ loan: updatedLoan }));
  }

  withdrawCollateral(creditLine: CreditLine, collateral: Currency, amount: any) {
    this.web3Service.withdrawFromCreditLine(creditLine, collateral, amount)
      .pipe(catchError((err: any): Observable<any> => {
        this.toastr.error('Please retry!', 'Could not withdraw collateral');
        if (!environment.production) console.log(err);
        return EMPTY;
      }), takeUntil(this.destroy$))
      .subscribe((res) => {
        this.toastr.info('', `${collateral.symbol} withdrawn from Finance Line`);
        collateral = {
          ...collateral,
          unlocked: new BigNumber(collateral.unlocked).minus(amount).toString()
        }
        const collaterals = creditLine.collaterals.filter(c => c.symbol !== collateral.symbol);
        creditLine = {
          ...creditLine,
          collaterals: [...collaterals, collateral]
        }
        this.store.dispatch(BorrowingActions.updateCreditLine({ creditLine }));
      })
  }

  repayLoan(loan: Loan) {
    this.store.dispatch(BorrowingActions.repayingLoan({ repayingLoan: true }));
    if (loan.underlying.symbol === 'ISLM') {
      this.web3Service.repayEth(loan)
        .pipe(catchError((err: any): Observable<any> => {
          this.store.dispatch(BorrowingActions.repayingLoan({ repayingLoan: false }));
          this.toastr.error('Please retry!', 'Could not repurchase finance');
          if (!environment.production) console.log(err);
          return EMPTY;
        }), takeUntil(this.destroy$))
        .subscribe(() => {
          const updatedLoan: Loan = {
            ...loan,
            loanStatus: 'REPAID',
            outstandingBalance: 0,
            states: {
              collateral: false,
              funded: false,
              matching: false,
              matched: false,// tbc
              terminated: false,
              nearLiquidation: false,
              liquidating: false,
              liquidated: false,
              repaid: true,
              badLoan: false
            }
          };
          this.store.dispatch(BorrowingActions.updateLoan({ loan: updatedLoan }));
          this.store.dispatch(BorrowingActions.repayingLoan({ repayingLoan: false }));
        })
    } else {
      this.web3Service.repayToken(loan)
        .pipe(catchError((err: any): Observable<any> => {
          this.store.dispatch(BorrowingActions.repayingLoan({ repayingLoan: false }));
          this.toastr.error('Please retry!', 'Could not repurchase finance');
          if (!environment.production) console.log(err);
          return EMPTY;
        }), takeUntil(this.destroy$))
        .subscribe(() => {
          const updatedLoan: Loan = {
            ...loan,
            loanStatus: 'REPAID',
            outstandingBalance: 0,
            states: {
              collateral: false,
              funded: false,
              matching: false,
              matched: false,// tbc
              terminated: false,
              nearLiquidation: false,
              liquidating: false,
              liquidated: false,
              repaid: true,
              badLoan: false
            }
          };
          this.store.dispatch(BorrowingActions.updateLoan({ loan: updatedLoan }));
          this.store.dispatch(BorrowingActions.repayingLoan({ repayingLoan: false }));
        })
    }
  }

  terminateLoan(loanRequestId: string) {
    return this.apollo
      .mutate({
        mutation: terminateLoanRequest,
        variables: {
          loanRequestId
        }
      })
      .pipe(catchError((err: any): Observable<any> => {
        this.toastr.info('Please try again later!', 'Could not terminate loan');
        if (!environment.production) console.log(err);
        return EMPTY;
      }), map((res: any) => res.data.terminateLoanRequest.loanRequest),
        take(1))
      .subscribe((loan: Loan) => {
        const updatedLoan = {
          ...loan,
          loanStatus: 'TERMINATED',
          states: {
            collateral: false,
            funded: false,
            matching: false,
            matched: false,// tbc
            terminated: true,
            nearLiquidation: false,
            liquidating: false,
            liquidated: false,
            repaid: false,
            badLoan: false
          }
        }
        this.store.dispatch(BorrowingActions.updateLoan({ loan: updatedLoan }));
        this.toastr.info('', 'Loan terminated!');
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
