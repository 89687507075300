<div class="rounded-3xl border-slate-200 bg-white p-4 text-center">
  <div class="flex justify-between items-center mb-4">
    <h3 class="mb-0 mr-2 truncate">Deposit Funds to FIF</h3>
    <button class="default sm" (click)="onClose()">Close</button>
  </div>
  <form>
    <div class="input-group w-full pb-4">
      <label for="currency" class="text-left label">Amount</label>
      <input
        type="text"
        class="w-full h-16"
        [formControl]="depositAmount"
        placeholder="0"
      />
      <div class="suffix">{{ currency.symbol }}</div>
    </div>
    <button
      class="primary w-fit"
      (click)="onDeposit()"
      [disabled]="depositAmount.invalid"
    >
      Deposit {{ currency.symbol }}
    </button>
  </form>
  <div *ngIf="depositAmount.hasError('minBalance')" class="form-error">
    Not enough balance in wallet
  </div>
</div>
