<shared-title [title]="pageDetails.title" [subtitle]="pageDetails.description">
  <button
    *ngIf="user && isDashboardBtnEnable"
    class="primary-inverse mb-3"
    [routerLink]="['dashboard']"
  >
    <sc-icon
      [src]="icons.dashboard"
      [size]="'0.4'"
      [width]="'1.4rem'"
      class="mr-2"
    ></sc-icon>
    Financing Dashboard
  </button>
</shared-title>
<div
  *ngIf="loanAsset && loanCollateral"
  class="flex flex-wrap lg:flex-nowrap space-y-6 lg:space-y-0 lg:space-x-6 mb-6"
>
  <div class="card w-full lg:w-1/2 flex flex-col justify-between">
    <div>
      <div class="flex flex-row rounded-2xl bg-slate-100 p-2 mb-2 gap-2">
        <button
          class="nostyle tab w-1/2 flex items-center justify-center truncate"
          [ngClass]="{ selected: !advancedMode }"
          (click)="showAdvanced(false)"
        >
          <!-- <sc-icon [src]="icons.telegram" class="mr-1"></sc-icon> -->
          Simple Mode
        </button>
        <button
          class="nostyle tab w-1/2 flex items-center justify-center truncate"
          [ngClass]="{ selected: advancedMode }"
          (click)="showAdvanced(true)"
          [disabled]="!loanData || !loanData.liquidationProbability"
        >
          <!-- <sc-icon [src]="icons.discord" class="mr-1"></sc-icon> -->
          <spinner
            *ngIf="!loanData || !loanData.liquidationProbability"
            [size]="'xxs'"
            [color]="'gray'"
            class="mr-2"
          >
          </spinner>
          Advanced Mode
        </button>
      </div>
      <form [formGroup]="borrowForm">
        <!-- NEW AMOUNT & CURRENCY -->
        <div class="mb-2">
          <div class="input-group w-full">
            <label for="amount" class="label pb-1">Purchase</label>
            <div class="flex">
              <input
                type="text"
                class="!w-1/2 !rounded-r-none text-lg font-semibold !my-0"
                formControlName="amount"
                placeholder="1.15"
              />
              <label
                #assetSelect
                for="asset"
                cdkOverlayOrigin
                #assetTrigger="cdkOverlayOrigin"
                [tabIndex]="0"
                (keydown.enter)="!assetOpen ? (assetOpen = true) : ''"
                (hover)="$event.preventDefault()"
                class="smart-select !w-1/2 block !rounded-l-none -ml-[1px] !my-0"
                [ngClass]="
                  assetOpen
                    ? 'outline outline-2 -outline-offset-2 outline-sc-primary'
                    : ''
                "
              >
                <div class="flex items-center justify-between gap-2">
                  <div class="h-16 flex items-center truncate">
                    <sc-icon
                      [src]="icons[loanAsset.symbol]"
                      width="32px"
                      height="32px"
                      class="self-center mr-2"
                    >
                    </sc-icon>
                    <div>
                      <div class="text-lg font-semibold truncate">
                        {{ loanAsset.symbol }}
                      </div>
                      <div class="text-slate-500 text-sm truncate">
                        {{ loanAsset.name }}
                      </div>
                    </div>
                  </div>
                  <img
                    src="assets/icons/down-arrow.svg"
                    alt="Expand Menu"
                    [ngClass]="{ 'rotate-180': assetOpen }"
                    class="rounded-full p-1 bg-slate-200 transition"
                  />
                </div>
              </label>
              <select
                class="hidden"
                id="asset"
                placeholder="Asset"
                (click)="
                  overlayOutsideClicked
                    ? (assetOpen = false)
                    : (assetOpen = true)
                "
                formControlName="asset"
              >
                <option
                  *ngFor="let a of assets"
                  hidden
                  value="{{ a.symbol }}"
                  id="{{ a.symbol }}"
                >
                  {{ a.name }}
                </option>
              </select>
              <ng-template
                cdkConnectedOverlay
                [cdkConnectedOverlayOrigin]="assetTrigger"
                [cdkConnectedOverlayOpen]="assetOpen"
                [cdkConnectedOverlayHeight]="200"
                [cdkConnectedOverlayMinWidth]="assetSelect.offsetWidth"
                (overlayOutsideClick)="
                  assetOpen = false; onOverlayOutsideClick()
                "
              >
                <ul
                  class="w-full mt-1 select-dd overflow-y-auto overflow-x-hidden"
                >
                  <li
                    *ngFor="let a of assets"
                    (click)="selectAsset(a); assetOpen = false"
                    class="cursor-pointer hover:bg-slate-50 py-1"
                    [ngClass]="{ highlighted: loanAsset.symbol === a.symbol }"
                  >
                    <div class="flex items-center">
                      <sc-icon
                        [src]="icons[a.symbol]"
                        width="32px"
                        height="32px"
                        class="self-center mr-2"
                      >
                      </sc-icon>
                      <div>
                        <div class="font-medium">
                          {{ a.symbol }}
                        </div>
                        <div class="text-slate-500 text-sm">
                          {{ a.name }}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </ng-template>
            </div>
            <!-- <div class="suffix">{{ borrowForm.controls['asset'].value }}</div> -->
            <div
              *ngIf="borrowForm.controls['amount'].errors?.['max']"
              class="form-error"
            >
              Amount must be less than {{ this.maxLoanAmountInTokens }}
              {{ this.loanAsset.symbol }}
            </div>
            <div
              *ngIf="borrowForm.controls['amount'].errors?.['min']"
              class="form-error"
            >
              Amount must be more than {{ this.minLoanAmountInTokens }}
              {{ this.loanAsset.symbol }}
            </div>
            <div
              *ngIf="borrowForm.controls['amount'].errors?.['required']"
              class="form-error"
            >
              Amount can not be 0.
            </div>
            <div
              *ngIf="borrowForm.controls['amount'].errors?.['pattern'] && !borrowForm.controls['amount'].errors?.['max'] && !borrowForm.controls['amount'].errors?.['min']"
              class="form-error"
            >
              Amount must be a number.
            </div>
            <div
              *ngIf="hasError('asset', 'valuesCannotBeSame')"
              class="form-error"
            >
              Asset and Collateral cannot be the same.
            </div>
          </div>
        </div>
        <!-- END NEW AMOUNT & CURRENCY-->
        <!-- NEW DURATION WITH RANGE SLIDER -->
        <div class="w-full mb-8">
          <div class="flex justify-between items-center !h-12 mb-2 mt-6">
            <label for="duration" class="label !my-0">Duration</label>
            <div class="flex items-center">
              <ng-container *ngIf="editMode.duration">
                <input
                  type="text"
                  class="w-24 font-semibold text-sc-black"
                  formControlName="duration"
                  value="{{ borrowForm.controls['duration'].value }}"
                />
                <div class="suffix">days</div>
                <button
                  class="nostyle px-1.5 edit-btn"
                  (click)="editMode.duration = false"
                >
                  <sc-icon [src]="icons.edit"></sc-icon>
                  <span class="sr-only">Cancel</span>
                </button>
              </ng-container>
              <ng-container *ngIf="!editMode.duration">
                <div class="font-semibold text-sc-black">
                  {{ duration.value }} days
                </div>
                <button
                  class="nostyle px-1.5 edit-btn"
                  (click)="editMode.duration = true"
                >
                  <sc-icon [src]="icons.edit"></sc-icon>
                  <span class="sr-only">Edit</span>
                </button>
              </ng-container>
            </div>
          </div>
          <input
            formControlName="duration"
            #duration
            type="range"
            min="{{ paramsInformation.minLoanTerm }}"
            max="{{ paramsInformation.maxLoanTerm }}"
            value="{{ borrowForm.controls['duration'].value }}"
            class="slider"
            id="myRange"
          />
          <div class="flex justify-between text-slate-500">
            <div class="text-sm">{{ paramsInformation.minLoanTerm }} days</div>
            <div class="text-sm">{{ paramsInformation.maxLoanTerm }} days</div>
          </div>
        </div>
        <!-- END NEW DURATION -->
        <div class="input-group w-full mb-2">
          <label for="collateral" class="label pb-1">Collateral</label>
          <!-- SIMPLE MODE - COLLATERAL -->
          <label
            #collateralSelect
            for="collateral"
            cdkOverlayOrigin
            #collateralTrigger="cdkOverlayOrigin"
            class="smart-select w-full block !mt-1.5"
            [tabIndex]="0"
            (keydown.enter)="!collateralOpen ? (collateralOpen = true) : ''"
            [ngClass]="
              collateralOpen
                ? 'outline outline-2 -outline-offset-2 outline-sc-primary'
                : ''
            "
          >
            <div class="flex items-center justify-between">
              <div class="h-16 flex items-center">
                <sc-icon
                  [src]="icons[loanCollateral.symbol]"
                  width="32px"
                  height="32px"
                  class="self-center mr-2"
                >
                </sc-icon>
                <div>
                  <div class="text-lg font-semibold">
                    {{ loanCollateral.symbol }}
                  </div>
                  <div class="text-slate-500 text-sm">
                    {{ loanCollateral.name }}
                  </div>
                </div>
              </div>
              <img
                src="assets/icons/down-arrow.svg"
                alt="Expand Menu"
                [ngClass]="{ 'rotate-180': collateralOpen }"
                class="rounded-full p-1 bg-slate-200 transition"
              />
            </div>
            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="collateralTrigger"
              [cdkConnectedOverlayOpen]="collateralOpen"
              [cdkConnectedOverlayHeight]="200"
              [cdkConnectedOverlayMinWidth]="collateralSelect.offsetWidth"
              (overlayOutsideClick)="
                collateralOpen = false; onOverlayOutsideClick()
              "
            >
              <ul class="w-full mt-1 select-dd overflow-y-auto">
                <li
                  *ngFor="let c of collaterals"
                  (click)="selectCollateral(c); collateralOpen = false"
                  class="cursor-pointer hover:bg-slate-50"
                  [ngClass]="{
                    highlighted: loanCollateral.symbol === c.symbol
                  }"
                >
                  <div class="flex items-center">
                    <sc-icon
                      [src]="icons[c.symbol]"
                      width="32px"
                      height="32px"
                      class="self-center mr-2"
                    >
                    </sc-icon>
                    <div>
                      <div class="font-medium">
                        {{ c.symbol }}
                      </div>
                      <div class="text-slate-500 text-sm">
                        {{ c.name }}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </ng-template>
          </label>
        </div>
        <!-- END SIMPLE MODE COLLATERAL -->
        <div class="mb-2">
          <!-- ADVANCED MODE 2 - COLLATERAL RATIO -->
          <div *ngIf="advancedMode" class="w-full mt-2.5">
            <div class="flex justify-between items-center !h-12 mb-2 mt-6">
              <label for="collateralRatio" class="label !my-0"
                >Collateral Ratio</label
              >
              <div class="flex items-center">
                <ng-container *ngIf="editMode.collateralRatio">
                  <input
                    type="text"
                    class="w-24 font-semibold text-sc-black"
                    formControlName="collateralRatio"
                    name="collateralRatio"
                    value="{{ borrowForm.controls['collateralRatio'].value }}"
                  />
                  <button
                    class="nostyle px-1.5 edit-btn"
                    (click)="editMode.collateralRatio = false"
                  >
                    <sc-icon [src]="icons.edit"></sc-icon>
                    <span class="sr-only">Cancel</span>
                  </button>
                </ng-container>
                <ng-container *ngIf="!editMode.collateralRatio">
                  <div class="font-semibold text-sc-black">
                    {{ borrowForm.controls["collateralRatio"].value }}%
                  </div>
                  <button
                    class="nostyle px-1.5 edit-btn"
                    (click)="editMode.collateralRatio = true"
                  >
                    <sc-icon [src]="icons.edit"></sc-icon>
                    <span class="sr-only">Edit</span>
                  </button>
                </ng-container>
              </div>
            </div>
            <input
              type="range"
              min="{{ collateralRatioLimits.min }}"
              step="0.01"
              max="{{ collateralRatioLimits.max }}"
              value="{{ borrowForm.controls['collateralRatio'].value }}"
              class="slider"
              id="myRange"
              formControlName="collateralRatio"
            />
            <div class="flex justify-between text-slate-500">
              <div class="text-sm">{{ collateralRatioLimits.min }}%</div>
              <div class="text-sm">{{ collateralRatioLimits.max }}%</div>
            </div>
          </div>
          <!-- END ADVANCED MODE 2 - COLLATERAL RATIO-->
          <select
            class="hidden"
            id="collateral"
            placeholder="Collateral"
            (click)="
              overlayOutsideClicked
                ? (collateralOpen = false)
                : (collateralOpen = true)
            "
            formControlName="collateral"
          >
            <option
              *ngFor="let c of collaterals"
              hidden
              value="{{ c.symbol }}"
              id="{{ c.symbol }}"
            >
              {{ c.name }}
            </option>
          </select>
          <div
            *ngIf="borrowForm.controls['collateral'].errors?.['depegged']"
            class="form-error"
          >
            We have temporarily paused the use of {{ loanCollateral.name }} as
            collateral
          </div>
          <div
            *ngIf="borrowForm.controls['collateral'].errors?.['noLiquidity']"
            class="form-error"
          >
            There is not enough liquidity for {{ loanCollateral.name }}
          </div>
          <div
            *ngIf="hasError('collateral', 'valuesCannotBeSame')"
            class="form-error"
          >
            Asset and Collateral cannot be the same.
          </div>
        </div>
      </form>
    </div>
    <div>
      <button
        (click)="showModeDetails()"
        class="nostyle flex items-center text-slate-500 hover:text-sc-primary font-medium w-fit mt-4 popup-btn ml-auto text-sm"
      >
        Simple vs Advanced Mode
        <sc-icon
          [src]="icons.doubleChevronDiagonal"
          size="0.675"
          class="-mt-0.5"
        ></sc-icon>
      </button>
    </div>
  </div>
  <div class="w-full lg:w-1/2">
    <borrow-form-summary
      *ngIf="loanAsset && loanCollateral && loanData"
      [tokenRewards]="tokenRewards"
      [user]="user ? user : undefined"
      [creatingLoanRequest]="creatingLoanRequest"
      [loanData]="loanData"
      [loanAsset]="loanAsset"
      [rewardToken]="rewardToken"
      [loanCollateral]="loanCollateral"
      [borrowForm]="borrowForm"
      (borrowFormSubmitted)="borrowFormSubmitted()"
      [advancedMode]="advancedMode"
      [stats]="stats"
      [isUpdating]="isUpdating"
      [isCalculating]="isCalculating"
    ></borrow-form-summary>
  </div>
</div>
<div class="{{ environment.banners.borrow }} banner-wrapper mb-6"></div>
<section>
  <h2 class="mb-0">{{ pageDetails.howTo.title }}</h2>
  <p class="text-sm text-slate-500 mb-4">{{ pageDetails.howTo.description }}</p>
  <div class="flex gap-6 flex-wrap md:flex-nowrap">
    <div class="flex flex-col md:flex-row w-full gap-6">
      <div
        *ngFor="let step of pageDetails.howTo.steps; let i = index"
        class="w-full"
        [ngClass]="{
          'md:w-1/3': pageDetails.howTo.steps.length >= 3,
          'md:1/2': pageDetails.howTo.steps.length <= 2
        }"
      >
        <app-how-to-article
          image="../../../../assets/images/step-{{ i + 1 }}.svg"
          step="Step {{ i + 1 }}"
          [name]="step.title"
          [description]="step.description"
        >
        </app-how-to-article>
      </div>
    </div>
    <div class="flex flex-col md:flex-col w-full md:w-1/3">
      <div *ngFor="let faq of pageDetails.howTo.faq">
        <a
          href="{{ faq.href }}"
          target="_blank"
          class="mb-2 flex text-sc-primary hover:text-blue-600 cursor-pointer font-semibold"
        >
          {{ faq.question }}
          <sc-icon [src]="icons.rightArrow" class="self-center ml-2"></sc-icon>
        </a>
        <p class="text-sm hidden md:block text-slate-500 mb-6">
          {{ faq.answer }}
        </p>
      </div>
    </div>
  </div>
  <shared-paragraphs
    *ngIf="pageDetails.paragraphs"
    [paragraphsTitle]="pageDetails.paragraphsTitle"
    [paragraphs]="pageDetails.paragraphs"
    [subtitles]="pageDetails.titles"
  ></shared-paragraphs>
</section>
