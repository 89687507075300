import { gql } from 'apollo-angular';

export const currenciesQuery = gql`query {
  collateralCurrencies {
    symbol,
    name,
    ethAddress,
    riskFreeRate,
    exchangeRate,
    medianExchangeRate,
    isUnderlying,
    isCollateral,
    decimalPlaces,
    lockedCollateralAmount,
    lockedCollateralValueInUSD,
    unlockedCollateralAmount,
    unlockedCollateralValueInUSD,
    minCollateralRatio
  },
  underlyingCurrencies {
    symbol,
    name,
    ethAddress,
    riskFreeRate,
    exchangeRate,
    medianExchangeRate,
    isUnderlying,
    isCollateral,
    decimalPlaces,
    totalBorrowedAmount,
    totalBorrowedValueInUSD,
    totalLendedAmount,
    totalLendedValueInUSD,
    maxAPY,
    minInterestRate,
    lendersAPY,
    borrowersAPY
  }
}`;
