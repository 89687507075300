import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BorrowingModule } from './borrowing/borrowing.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { LendingModule } from './lending/lending.module';
import { LegalModule } from './legal/legal.module';
import { ProfileModule } from './profile/profile.module';
import { RewardsModule } from './rewards/rewards.module';
import { SharedModule } from './shared/shared.module';
import { StakingModule } from './staking/staking.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { GraphQLModule } from './graphql.module';
import * as fromApp from './store/app.reducer';
import * as Sentry from "@sentry/angular-ivy";
import { Router } from '@angular/router';
import { CookieModule } from 'ngx-cookie';
import { toastrConfig } from 'src/config/app.config';
import { CurrencyPipe } from '@angular/common';
import { WeiToScPipe } from './shared/pipes/wei-to-sc.pipe';
import { ApolloInterceptor } from './core/interceptors/apollo.interceptor';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'smartCredit' }),
    StoreModule.forRoot(fromApp.appReducer),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    AppRoutingModule,
    SharedModule,
    DashboardModule,
    LendingModule,
    BorrowingModule,
    LegalModule,
    ProfileModule,
    RewardsModule,
    // StakingModule,
    HttpClientModule,
    GraphQLModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(toastrConfig),
    CookieModule.withOptions(),
  ],
  providers: [
    Apollo,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApolloInterceptor,
      multi: true
    },
    CurrencyPipe,
    WeiToScPipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
