import { ApolloClientOptions, ApolloLink, FetchPolicy, InMemoryCache, WatchQueryFetchPolicy } from '@apollo/client/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import { HttpLink } from 'apollo-angular/http';
import { NgModule } from '@angular/core';
import { setContext } from '@apollo/client/link/context';
import jwt_decode from 'jwt-decode';
import extractFiles from 'extract-files/extractFiles.mjs'
import isExtractableFile from 'extract-files/isExtractableFile.mjs';
import { ToastrService } from 'ngx-toastr';
import { LogoutService } from './core/services/logout.service';

export function createApollo(httpLink: HttpLink, logoutService: LogoutService, toastrService: ToastrService): ApolloClientOptions<any> {
  const base = setContext(() => ({
    headers: {
      // Accept: 'charset=utf-8'
    },
    // ssrMode: true,
  }));

  const auth = setContext(() => {
    const token = localStorage.getItem('token');
    if (token === null) {
      return {};
    } else {
      const tokenExp = (jwt_decode(token) as any).exp;
      const timestamp = Math.floor((new Date().getTime()) / 1000);
      if (tokenExp > timestamp) {
        return {
          headers: {
            'X-token': `${token}`
          }
        };
      } else {
        toastrService.info('Please log in again.', 'Session expired!');
        logoutService.logOut();
        return {};
      }
    }
  })

  const uri = environment.apiUrl;
  const link = ApolloLink.from([base, auth, httpLink.create({ uri, extractFiles: (body) => extractFiles(body, isExtractableFile) })]);
  const cache = new InMemoryCache();
  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'cache-and-network' as WatchQueryFetchPolicy,
    },
  };
  return { link, cache, defaultOptions }
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, LogoutService, ToastrService],
    }]
})
export class GraphQLModule { }
