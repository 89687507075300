import { Component, Input, OnInit } from '@angular/core';
import { FifStats } from 'src/app/shared/interfaces/fif-stats';
import { DashboardStats } from 'src/app/shared/interfaces/statistics/dashboard-stats';
import { GENERAL_ICONS } from 'src/assets/images/svg-icons';
@Component({
  selector: 'fifs-stats',
  templateUrl: './fifs-stats.component.html',
  styleUrls: []
})
export class FifsStatsComponent implements OnInit {
  public icons = GENERAL_ICONS;

  @Input() fifStats: FifStats;
  @Input() stats: DashboardStats
  constructor() { }

  ngOnInit(): void {
  }
}
