<div class="flex flex-col md:flex-row gap-8">
  <div class="card flex flex-row md:flex-col w-full md:w-2/3 bg-slate-100">
    <form class="w-full" [formGroup]="supportForm" #supportFormRef="ngForm">
      <h2 class="mb-4">Contact Us</h2>
      <div class="form-group">
        <label for="category" class="label">Category</label>
        <select
          name="category"
          id="category"
          class="w-full"
          formControlName="category"
        >
          <!-- <option value="" disabled>Category</option> -->
          <option value="General Inquiry">General Inquiry</option>
          <option value="Support Request">Support Request</option>
          <option value="Partnership Request">Partnership Request</option>
          <option value="API Subscription">API Subscription</option>
          <option value="Other">Other</option>
        </select>
        <div
          *ngIf="supportFormRef.submitted && showErrors && supportForm.controls['category'].errors?.['required']"
          class="form-error"
        >
          Category is required
        </div>
      </div>
      <div class="form-group">
        <label for="title" class="label">Title</label>
        <input id="title" type="text" class="w-full" formControlName="title" />
        <div
          *ngIf="supportFormRef.submitted && showErrors && supportForm.controls['title'].errors?.['required']"
          class="form-error"
        >
          Title is required
        </div>
      </div>
      <div class="form-group">
        <label for="email" class="label">Email</label>
        <input id="email" type="text" class="w-full" formControlName="email" />
        <div
          *ngIf="supportFormRef.submitted && showErrors && supportForm.controls['email'].errors?.['required']"
          class="form-error"
        >
          Email is required
        </div>
      </div>
      <div class="form-group">
        <label for="description" class="label">Description</label>
        <textarea
          id="description"
          type="text"
          class="w-full"
          rows="5"
          formControlName="description"
        ></textarea>
        <div
          *ngIf="supportFormRef.submitted && showErrors && supportForm.controls['description'].errors?.['required']"
          class="form-error"
        >
          Description is required
        </div>
      </div>
      <div class="flex w-full justify-end mt-6">
        <button
          (click)="submit(supportForm.value)"
          class="w-full md:w-auto primary"
        >
          <div class="px-8 text-base">Send</div>
        </button>
      </div>
    </form>
  </div>
  <div class="card flex flex-col md:flex-col w-full md:w-1/3">
    <h3 class="mb-4">Information Hub</h3>
    <a
      class="mb-4 cursor-pointer rounded-2xl bg-slate-50 transition duration-200 hover:bg-[#EEF5FF] p-4"
      href="https://learn.smartcredit.io/"
      target="_blank"
    >
      <div class="flex mb-2">
        <h3 class="mb-0 text-base text-sc-primary font-semibold">Learn</h3>
        <sc-icon [src]="arrow" class="self-center ml-2"></sc-icon>
      </div>
      <p class="text-slate-500 text-sm line-clamp-2">
        Bridge Islamic Finance principles with DeFi innovation through our
        comprehensive learning resources. From Mudaraba and ijara to modern loan
        position trading, master Shariah-compliant transactions on BarakaFi's
        Islamic DeFi platform with confidence.
      </p>
    </a>
    <a
      class="mb-4 cursor-pointer rounded-2xl bg-slate-50 transition duration-200 hover:bg-[#EEF5FF] p-4"
      href="https://blog.islamiccoin.net/"
      target="_blank"
    >
      <div class="flex mb-2">
        <h3 class="mb-0 text-base text-sc-primary font-semibold">Blog</h3>
        <sc-icon [src]="arrow" class="self-center ml-2"></sc-icon>
      </div>
      <p class="text-slate-500 text-sm line-clamp-2">
        Get the latest insights on Islamic DeFi, from understanding halal loan
        positions to market trends. Our expert articles help you make informed
        decisions in your Shariah-compliant financial journey.
      </p>
    </a>
    <a
      class="mb-4 cursor-pointer rounded-2xl bg-slate-50 transition duration-200 hover:bg-[#EEF5FF] p-4"
      href="https://smartcredit.io/about-us/"
      target="_blank"
    >
      <div class="flex mb-2">
        <h3 class="mb-0 text-base text-sc-primary font-semibold">
          About BarakaFi
        </h3>
        <sc-icon [src]="arrow" class="self-center ml-2"></sc-icon>
      </div>
      <p class="text-slate-500 text-sm line-clamp-2">
        Discover how we're revolutionizing Islamic Finance through Islamic DeFi
        by making halal loan position trading accessible to all Muslims. Our
        mission is to bridge traditional Islamic principles with modern
        financial needs through a trusted, Shariah-compliant platform. Built by
        a team of experts in both Islamic Finance and technology, BarakaFi
        represents the future of ethical financial transactions.
      </p>
    </a>
    <a
      class="mb-4 cursor-pointer rounded-2xl bg-slate-50 transition duration-200 hover:bg-[#EEF5FF] p-4"
      href="https://t.me/islamiccoin_int"
      target="_blank"
    >
      <div class="flex mb-2">
        <h3 class="mb-0 text-base text-sc-primary font-semibold">
          Join our Telegram Community
        </h3>
        <sc-icon [src]="arrow" class="self-center ml-2"></sc-icon>
      </div>
      <p class="text-slate-500 text-sm line-clamp-2">
        Be part of our growing community on Telegram. Connect with others, stay
        updated.
      </p>
    </a>
    <a
      class="mb-4 cursor-pointer rounded-2xl bg-slate-50 transition duration-200 hover:bg-[#EEF5FF] p-4"
      href="https://x.com/Islamic_Coin"
      target="_blank"
    >
      <div class="flex mb-2">
        <h3 class="mb-0 text-base text-sc-primary font-semibold">
          Follow us on X
        </h3>
        <sc-icon [src]="arrow" class="self-center ml-2"></sc-icon>
      </div>
      <p class="text-slate-500 text-sm line-clamp-2">
        Stay up-to-date with the latest news and updates on BarakaFi by
        following us on X.
      </p>
    </a>
  </div>
</div>
