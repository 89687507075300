export const PRESET_VALUES = {
  amount: null,
  collateral: undefined,
  duration: null,
  asset: undefined
}

export const DEFAULT_REWARDS = {
  baseReward: 0,
  firstLoanReward: 0,
  campaignReward: 0,
  totalRewards: 0
}