<section
  class="flex md:flex-row justify-between flex-col w-full mb-4 md:mb-6 gap-6"
>
  <div
    class="card w-full !p-4 grid grid-cols-1 md:grid-cols-3 grid-rows-2 md:grid-rows-auto gap-4 md:gap-6"
  >
    <div
      class="col-span-1 row-span-1 rounded-xl md:p-2 text-center md:text-left"
    >
      <h2 class="text-lg font-semibold mb-0 truncate">Platform TVL</h2>
      <div class="text-3xl text-sc-primary font-bold">
        {{
          stats?.getTVL?.lendingTVL +
            totalCollateral -
            stats?.borrowingStatistics?.totalBorrowedVolumeInUSD || 0
            | currency : "USD" : "symbol-narrow" : "1.0-0"
        }}
      </div>
    </div>
    <div
      class="col-span-1 md:col-span-2 row-span-1 p-2 bg-slate-50 rounded-xl"
      title="Historical Data"
    >
      <div class="flex md:ml-2">
        <div class="w-1/2 flex flex-col justify-between">
          <div
            class="font-semibold text-sc-black text-xl text-center md:text-left"
          >
            {{
              stats?.borrowingStatistics?.totalBorrowedVolumeInPeriod || 0
                | currency : "USD" : "symbol-narrow" : "1.0-0"
            }}
            <div class="text-slate-600 font-normal truncate">
              Total Financed
            </div>
          </div>
        </div>
        <div class="w-1/2 flex flex-col justify-between">
          <div
            class="font-semibold text-sc-black text-xl text-center md:text-left"
          >
            {{
              historicInterestPaid || 0
                | currency : "USD" : "symbol-narrow" : "1.0-0"
            }}
            <div class="text-slate-600 font-normal truncate">
              Total Fees Paid
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-span-1 row-span-1 p-2 bg-slate-50 rounded-xl flex flex-col justify-between"
    >
      <div class="text-xl font-semibold text-sc-black">
        {{
          stats?.borrowingStatistics?.totalBorrowedVolumeInUSD || 0
            | currency : "USD" : "symbol-narrow" : "1.0-0"
        }}
      </div>
      <div class="text-slate-600 truncate">Purchaser Volume</div>
    </div>
    <div
      class="col-span-1 row-span-1 p-2 bg-slate-50 rounded-xl flex flex-col justify-between"
    >
      <div class="text-xl font-semibold text-sc-black">
        {{
          stats?.getTVL?.lendingTVL || 0
            | currency : "USD" : "symbol-narrow" : "1.0-0"
        }}
      </div>
      <div class="text-slate-600 truncate">Seller Volume</div>
    </div>
    <div
      class="col-span-1 row-span-1 p-2 bg-slate-50 rounded-xl flex flex-col justify-between"
    >
      <div class="text-xl font-semibold text-sc-black">
        {{
          totalCollateral || 0 | currency : "USD" : "symbol-narrow" : "1.0-0"
        }}
      </div>
      <div class="text-slate-600 truncate">Collateral Locked</div>
    </div>
  </div>
  <div class="card !p-4 md:w-2/5">
    <div class="flex justify-between items-center">
      <div class="truncate p-2">
        <h2 class="text-lg font-medium mb-0 truncate">Last Week Rewards</h2>
        <div class="text-3xl font-bold text-sc-primary truncate">
          {{
            stats?.borrowerLenderWeeklyRewards?.borrowerRewards +
              stats?.borrowerLenderWeeklyRewards?.lenderRewards || 0
              | number : "1.0-0"
          }}
          <span class="text-lg font-normal truncate">{{
            stats?.rewardConfigData?.rewardToken
          }}</span>
        </div>
      </div>
      <button
        class="nostyle transition hover:bg-[#EEF5FF] py-2 px-3 rounded-xl"
        title="Open DefiLlama"
      >
        <a
          href="https://defillama.com/chain/HAQQ"
          target="_blank"
          class="flex items-center justify-between"
        >
          <div class="text-left mr-3">
            <div class="text-sm truncate">We are on</div>
            <div class="text-sm font-medium">DefiLlama</div>
          </div>
          <img
            src="assets/images/partners/defillama-logo.png"
            alt="DefiLlama Logo"
            height="20"
            width="20"
            class="w-6"
          />
        </a>
      </button>
    </div>
    <div class="border-t my-4"></div>
    <div class="flex gap-2 w-full justify-evenly">
      <div class="w-1/2 text-center">
        <div class="font-semibold text-slate-800 text-lg">
          {{
            stats?.borrowerLenderWeeklyRewards?.borrowerRewards || 0
              | number : "1.0-0"
          }}
        </div>
        <div class="text-slate-600 truncate">Purchaser Rewards</div>
      </div>
      <div class="w-1/2 text-center">
        <div class="font-semibold text-slate-800 text-lg">
          {{
            stats?.borrowerLenderWeeklyRewards?.lenderRewards || 0
              | number : "1.0-0"
          }}
        </div>
        <div class="text-slate-600 truncate">Seller Rewards</div>
      </div>
      <!-- <div class="w-1/3 text-center">
        <div class="font-semibold text-slate-800 text-lg">
          {{ stakingRewards || 0 | number : "1.0-0" }}
        </div>
        <div class="text-slate-600 truncate">Staking Rewards</div>
      </div> -->
    </div>
  </div>
</section>
