import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-lets-exchange',
  templateUrl: './lets-exchange.dialog.html',
  styleUrls: ['./lets-exchange.dialog.scss']
})
export class LetsExchangeDialog {
  @Output('reject') reject: EventEmitter<boolean> = new EventEmitter();

  onClose() {
    this.reject.emit(true);
  }
}
