<!-- // DETAILS COMPONENT -->
<div *ngIf="user" class="flex flex-col md:flex-row gap-6 md:mb-6">
  <div class="card flex items-center md:w-2/4">
    <div class="md:pl-3 truncate">
      <div class="text-base font-semibold">{{ user.ethAddress | shortAddress }}</div>
      <button *ngIf="!user.email" class="button primary-inverse sm md:md mt-2" (click)="openAddEmailDialog()">Add
        Email</button>
      <div *ngIf="user.email" class="font-normal text-gray-500">{{user.email}}</div>
    </div>
    <!-- <div class="flex ml-auto">
      <div class="mt-2">
        <div>Crypto Credit Score</div>
        <div class="font-bold text-3xl" [ngClass]="{
          'text-[#a8385d]': user.creditData.riskRating === 1,
          'text-[#9f4d78]': user.creditData.riskRating === 2,
          'text-[#975f8f]': user.creditData.riskRating === 3,
          'text-[#8f70a5]': user.creditData.riskRating === 4,
          'text-[#897db5]': user.creditData.riskRating === 5,
          'text-[#8488c3]': user.creditData.riskRating === 6,
          'text-[#8093d1]': user.creditData.riskRating === 7,
          'text-[#7d9ada]': user.creditData.riskRating === 8,
          'text-[#79a3e5]': user.creditData.riskRating === 9
        }">
          {{ user.creditData.riskRating }}</div>
        <a href="https://chainaware.ai/credit-score" target="_blank"
          class="font-semibold text-sm text-sc-primary outline-none">Improve on ChainAware.ai</a>
      </div>
      <ngx-charts-gauge class="trust-gauge" [view]="view" [scheme]="colorScheme" [max]="9" [results]="chartData"
        [legend]="true" [legend]="false" [bigSegments]="0" [startAngle]="-140" [angleSpan]="280" [showAxis]="false"
        [legendTitle]="">
      </ngx-charts-gauge>
    </div> -->
  </div>
  <div class="mb-6 md:mb-0 card flex items-center md:w-2/4 text-[#155881] twitter cursor-pointer" (click)="goToTwitter()">
    <div class="pl-3 font-normal w-1/2">
      Follow us on Twitter to get the latest news and promotions!
    </div>
    <div class="ml-auto"> <img class="rounded-full" src="/assets/images/twitter.jpg" alt="Ethereum">
    </div>
  </div>
</div>
<div *ngIf="user" class="flex flex-col md:flex-row gap-6 md:mb-6">
  <div class="card flex flex-col md:w-1/2 primary">
    <div class="flex flex-row">
      <sc-icon [src]="icons.reward" class="w-20 h-20 mr-2"></sc-icon>
      <div class="flex flex-col text-slate-50">
        <div class="font-semibold mb-2">Referral Link</div>
        <div class="font-light text-slate-200 text-base">
          Get rewards by inviting others to join the platform using the referral link.
        </div>
      </div>
    </div>
    <div class="flex flex-col">
      <input type="text" readonly class="w-full" name="" id="" [value]="baseUrl + '/ref=' + user.referralCode"
        class="border-white text-white">
    </div>
    <div class="flex flex-row mt-4">
      <button class="primary-inverse w-full md:w-1/3 min-w-[8rem] !text-base"
        [cdkCopyToClipboard]="baseUrl + '/ref=' + user.referralCode" (click)="copy()">{{ copyText
        }}</button>
    </div>
  </div>
  <div class="card w-full flex flex-col justify-between md:w-1/2">
    <div class="relative pr-20">
      <sc-icon [src]="icons.telegram" class="absolute right-4 top-4"></sc-icon>
      <div class="font-semibold mb-2 text-sc-black">Notifications</div>
      <p class="mb-1 text-slate-600">Enable notifications through Telegram and get alerts on: </p>
      <ul>
        <li class="text-base mb-1 flex items-center gap-1 text-slate-600">
          <sc-icon [src]="icons.rightArrow" [size]="'0.875'"></sc-icon>
          Rewards Activity
        </li>
        <li class="text-base mb-1 flex items-center gap-1 text-slate-600">
          <sc-icon [src]="icons.rightArrow" [size]="'0.875'"></sc-icon> 
          Loan Position Monitoring
        </li>
        <li class="text-base mb-1 flex items-center gap-1 text-slate-600">
          <sc-icon [src]="icons.rightArrow" [size]="'0.875'"></sc-icon> 
          Loan Liquidation Warnings
        </li>
        <li class="text-base mb-1 flex items-center gap-1 text-slate-600">
          <sc-icon [src]="icons.rightArrow" [size]="'0.875'"></sc-icon> 
          Leveraged Position Monitoring & more
        </li>
      </ul>
    </div>
    <div>
      <button *ngIf="user.telegram == false" class="button primary-inverse mt-2 !text-base w-full md:w-auto"
        (click)="enableTelegramNotification()">Enable Telegram Notification</button>
      <button *ngIf="user.telegram == true" class="button primary-inverse mt-2 !text-base" disabled>Telegram Notifications
        Enabled</button>
    </div>
    <p class="text-slate-500 mt-1 text-sm">You will be redirected to Telegram</p>
  </div>
</div>