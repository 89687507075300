import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, EMPTY, map, Observable, startWith, Subject, take, takeUntil } from 'rxjs';
import { Article } from 'src/app/shared/interfaces/articles/article';
import { environment } from 'src/environments/environment';
import * as DashboardActions from '../../dashboard/store/dashboard.actions'
import * as fromApp from '../../store/app.reducer';

const ARTICLES_KEY = makeStateKey<any>('articles');

@Injectable({
  providedIn: 'root'
})
export class ArticlesService implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private state: TransferState,
  ) { }

  getArticles() {
    const exists = this.state.get(ARTICLES_KEY, {} as any);
    this.http.get('https://newsaricles.s3.eu-central-1.amazonaws.com/baraka.json')
      .pipe(
        catchError((err: any): Observable<any> => {
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
        take(1),
        map((res: any) => res.articles),
        startWith(exists),
        takeUntil(this.destroy$)
      )
      .subscribe((articles: Article[]) => {
        this.state.set(ARTICLES_KEY, articles);
        this.store.dispatch(DashboardActions.getArticles({ articles }))
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
