import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CurrenciesService } from 'src/app/core/services/currencies.service';
import { Currency } from 'src/app/shared/interfaces/currencies/currency';
import { regEx } from 'src/config/app.config';
import BigNumber from 'bignumber.js';
import { Web3Service } from 'src/app/core/web3/web3.service';

@Component({
  selector: 'app-deposit-collateral',
  templateUrl: './deposit-collateral.dialog.html',
  styles: [``]
})
export class DepositCollateralDialog implements OnInit {
  public depositAmount: FormControl = new FormControl(null, [Validators.required, Validators.pattern(regEx.numbers)]);
  public requiredAmount: number;
  public collateral: Currency;
  public unlockedCollateral: any;
  public availableBalance: number;

  @Output('deposited') deposited: EventEmitter<number> = new EventEmitter()
  @Output('reject') reject: EventEmitter<boolean> = new EventEmitter();

  constructor(
    @Inject(DIALOG_DATA) public data: { ethAddress: string, requiredCollateral: number, collateral: Currency, unlockedCollateral: any },
    private currenciesService: CurrenciesService,
    private web3Service: Web3Service,

  ) {
    this.requiredAmount = data.requiredCollateral;
    this.collateral = data.collateral;
    this.unlockedCollateral = data.unlockedCollateral;

    this.web3Service.fetchBalance(
      data.collateral.ethAddress,
      data.ethAddress,
      data.collateral.symbol
    ).subscribe(res => this.availableBalance = this.currenciesService.weiToCurrency(res, data.collateral))
  }

  ngOnInit(): void {
    this.depositAmount.setValue(this.currenciesService.weiToCurrency(+this.requiredAmount - +this.data.unlockedCollateral, this.collateral));
    this.depositAmount.setValidators([
      Validators.required,
      Validators.pattern(regEx.numbers),
      Validators.min(this.currenciesService.weiToCurrency(+this.requiredAmount - +this.data.unlockedCollateral, this.collateral)),
      this.minBalanceValidator()
    ]);
  }

  onDeposit() {
    this.deposited.emit(this.depositAmount.value);
  }

  onClose() {
    this.reject.emit(true);
  }

  minBalanceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const depositAmount = new BigNumber(control.value).toNumber();
      return depositAmount > this.availableBalance ? { minBalance: true } : null;
    };
  }
}
