import { Component, Input, OnInit } from '@angular/core';
import { Article } from 'src/app/shared/interfaces/articles/article';
import { GENERAL_ICONS } from 'src/assets/images/svg-icons';
@Component({
  selector: 'dashboard-articles',
  templateUrl: './dashboard-articles.component.html',
  styles: ['']
})
export class DashboardArticlesComponent {
  @Input() articles: Article[];
  public icons = GENERAL_ICONS;
}
