import { createAction, props } from "@ngrx/store"
import { CreditLine } from "src/app/shared/interfaces/borrowing/credit-line"
import { LeverageData } from "src/app/shared/interfaces/leveraging/leverage-data"
import { LeverageForm } from "src/app/shared/interfaces/leveraging/leverage-form"
import { LeverageRequest } from "src/app/shared/interfaces/leveraging/leverage-request"
import { LeverageReturns } from "src/app/shared/interfaces/leveraging/leverage-returns"
import { Reward } from "src/app/shared/interfaces/rewards/reward"

export const updateLeverageLines = createAction(
  'updateLeverageLines',
  props<{
    leverageLines: CreditLine[]
  }>()
)

export const updateLeverageData = createAction(
  'updateLeverageData',
  props<{
    leverageData: LeverageData 
  }>()
)

export const creatingLeverageLine = createAction(
  'creatingLeverageLine',
  props<{
    creatingLeverageLine: boolean 
  }>()
)

export const creatingLeverageRequest = createAction(
  'creatingLeverageRequest',
  props<{
    creatingLeverageRequest: boolean
  }>()
)

export const updateLeverageReturns = createAction(
  'updateLeverageReturns',
  props<{
    leverageReturns: LeverageReturns
  }>()
)

export const updateLeverageRequests = createAction(
  'updateLeverageRequests',
  props<{
    leverageRequests: LeverageRequest[]
  }>()
)

export const updateLeverageRequest = createAction(
  'updateLeverageRequest',
  props<{
    leverageRequest: LeverageRequest
  }>()
)

export const updateSmaApr = createAction(
  'updateSmaApr',
  props<{
    smaApr: { timeUnix: number, apr: number }[]
  }>()
)

export const updateLeverageLineBalances = createAction(
  'updateLeverageLineBalances',
  props<{
    leverageLineBalances: { contractAddress: string, balance: string }[]
  }>()
)

export const updateUnclaimedRewards = createAction(
  'updateUnclaimedRewards',
  props<{
    unclaimedRewards: Reward[][]
  }>()
)

export const claimRewardsEnd = createAction(
  'claimRewardsEnd',
  props<{
    leverageLine: CreditLine
  }>()
)

export const resetState = createAction(
  'resetState'
)
