import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortAddress'
})
export class ShortAddressPipe implements PipeTransform {

  transform(value: string, ...args: number[]): string {
    const first: string = value.substring(0, args[0] ? args[0] : 8);
    const last: string = value.substring(value.length - (args[1] ? args[1] : 6));
    return `${first}...${last}`;
  }
}
