<div>
  <h1>Design System</h1>
  <hr class="mb-4">
  <section>
    <h2>&#10095; Text</h2>
    <hr class="mb-4">
    <h1>Header 1</h1>
    <h2>Header 2</h2>
    <h3>Header 3</h3>
    <h4>Header 4</h4>
    <div class="text-xl">Extra Large Text</div>
    <div class="text-lg">Large Text</div>
    <div class="text-md">Medium Text</div>
    <div class="text-sm">Small Text</div>
    <div class="text-xs">Extra Small Text</div>
    <div class="hint">Hint text used to show additional details.</div>
    <a href="#">This is a link</a>
    <details class="cursor-pointer mt-4">
      <summary>Summary is always visible in a details tag.</summary>
      <p class="cursor-default">Anything else inside the details tag is only visible when the user
        opens the tag.</p>
    </details>
  </section>
  <section>
    <h2 class="mt-10">&#10095; Buttons</h2>
    <hr class="mb-4">
    <h3>Button Types</h3>
    <button type="button">Default</button>
    <button class="primary">
      <sc-icon [src]="iconAdd" class="mr-2"></sc-icon>
      Primary & Icon
    </button>
    <button class="danger">Danger</button>
    <button class="success">Success</button>
    <button class="primary-inverse">Primary Inverse</button>
    <h3>Button Sizes</h3>
    <button class="primary xs w-fit">Button XS</button>
    <button class="primary sm w-fit">Button SM</button>
    <button class="primary w-fit">Button</button>
    <button class="primary lg w-fit">Button LG</button>
    <h3>Other Buttons</h3>
    <button class="primary w-full">Full Width Btn</button>
  </section>
  <section>
    <h2 class="mt-8">&#10095; Forms</h2>
    <hr class="mb-4">
    <ul>
      <li>If a form description is needed, it must be provided before the opening &lt;form&gt; tag.
      </li>
      <li>Description must be provided in a list.</li>
      <li>Source: w3.org</li>
    </ul>
    <form>
      <!-- ? TEXT INPUTS ? -->
      <div class="input-group">
        <label for="firstName" class="label">First Name</label>
        <input type="text" id="firstName" placeholder="Default text input">
      </div>
      <div class="input-group">
        <label for="" class="label">Label 2</label>
        <input type="text" disabled=true placeholder="Disabled input" title="Disabled">
      </div>
      <div class="input-group">
        <label for="firstName" class="label">First Name</label>
        <textarea id="firstName" placeholder="Default text input"></textarea>
      </div>
      <!-- ? RADIO BTNS ? -->
      <fieldset class="input-group">
        <legend class="label">Sa kompira ti nxe bira?</legend>
        <div class="flex items-center">
          <input type="radio" id="radio1" name="radios">
          <label for="radio1" class="inline-label">Dy a tri</label>
        </div>
        <div class="flex items-center">
          <input type="radio" id="radio2" name="radios">
          <label for="radio2" class="inline-label">Ma shum</label>
        </div>
      </fieldset>
      <!-- ? CHECKBOXES ? -->
      <div class="input-group">
        <!-- Checkbox with label on top -->
        <label for="checkbox1" class="inline-label top">Label on top</label>
        <div>
          <input type="checkbox" id="checkbox1">
          <label for="checkbox1">
          </label>
        </div>
      </div>
      <!-- Checkbox with label on right -->
      <div class="input-group">
        <div class="flex items-center">
          <label for="checkbox2" class="inline-label right">Label on the right</label>
          <div>
            <input type="checkbox" id="checkbox2">
            <label for="checkbox2"></label>
          </div>
        </div>
      </div>
      <!-- Checkbox with label on left -->
      <div class="input-group">
        <div class="flex items-center">
          <div>
            <input type="checkbox" id="checkbox3">
            <label for="checkbox3"></label>
          </div>
          <label for="checkbox2" class="inline-label left">Label on the left</label>
        </div>
      </div>
      <!-- END CHECKBOXES -->
      <div class="input-group">
        <label for="dropdown" class="label">Dropdown</label>
        <select name="" id="dropdown">
          <optgroup label="Animals">
            <option value="Dog">Dog</option>
            <option value="Cat">Cat</option>
            <option value="Bird">Bird</option>
          </optgroup>
          <optgroup label="Colors">
            <option value="Red">Red</option>
            <option value="Green">Green</option>
            <option value="Blue">Blue</option>
          </optgroup>
        </select>
      </div>
      <button type="reset">Start Over</button>
      <button type="submit" class="success w-28">Submit</button>
    </form>
  </section>
  <section>
    <h2 class="mt-8">&#10095; Images</h2>
    <hr class="mb-4">
    <!-- Images must have an alt description and a set height -->
    <img
      src="https://post.medicalnewstoday.com/wp-content/uploads/sites/3/2020/02/322868_1100-800x825.jpg"
      alt="Image of a dog" class="h-40">
  </section>
  <section>
    <h2>&#10095; Cards</h2>
    <hr class="mb-4">
    <div class="flex gap-5 w-full">
      <div class="card primary w-full lg:w-1/4">This is a primary card</div>
      <div class="card w-full lg:w-1/4">This is a default card</div>
      <div class="card danger w-full lg:w-1/4">This is a danger card</div>
      <div class="card success w-full lg:w-1/4">This is a success card</div>
      <div class="card accent w-full lg:w-1/4">This is a success card</div>
    </div>
  </section>
  <section>
    <h2>&#10095; Misc</h2>
    <hr class="mb-4">
    <h3>Badges</h3>
    <span class="badge default">123</span>
    <span class="badge primary">456</span>
    <span class="badge success">789</span>
    <span class="badge danger">000</span>
    <h3>Pills</h3>
    <div>
      <span class="pill default">Default</span>
      <span class="pill primary">Primary</span>
      <span class="pill success">100%</span>
      <span class="pill danger">0%</span>
    </div>
    <div class="my-5">
      <span class="pill default lg">Default</span>
      <span class="pill primary lg">Primary</span>
      <span class="pill success lg">100%</span>
      <span class="pill danger lg">0%</span>
    </div>
  </section>
  <section class="pb-10">
    <h2>Table</h2>
    <hr class="mb-4">
    <div class="table-wrapper">
      <table>
        <thead>
          <tr>
            <th scope="col">Currency</th>
            <th scope="col">Current Price</th>
            <th scope="col">Lender Volume</th>
            <th scope="col">Borrower Volume</th>
            <th scope="col"><span class="sr-only">Edit</span></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" class="flex items-center">
              <img class="w-10 h-10 rounded-full"
                src="https://post.medicalnewstoday.com/wp-content/uploads/sites/3/2020/02/322868_1100-800x825.jpg"
                alt="Ethereum">
              <div class="pl-3">
                <div class="text-base font-semibold">Ethereum</div>
                <div class="font-normal text-slate-500">ISLM</div>
              </div>
            </th>
            <td>
              <div class="font-medium">{{ 1900 | currency:'USD':'symbol':'1.0-0' }}</div>
              <div class="font-normal text-slate-500">Second row</div>
            </td>
            <td>
              <div class="font-medium">{{ 42447 | currency:'USD':'symbol':'1.0-0' }}</div>
            </td>
            <td>
              <div class="flex items-center">
                <span class="badge status success"></span>
                <div class="pl-1 text-sm font-medium">Active</div>
              </div>
            </td>
            <td><button class="primary-inverse sm">Edit</button></td>
          </tr>
          <tr>
            <th scope="row">SmartCredit</th>
            <td>{{ 42.069 | currency:'USD':'symbol':'1.2-4' }}</td>
            <td>{{ 1532932 | currency:'USD':'symbol':'1.0-0' }}</td>
            <td>{{ 310230 | currency:'USD':'symbol':'1.0-0' }}</td>
            <td><button class="primary-inverse sm">Edit</button></td>
          </tr>
          <tr>
            <th scope="row">USDC</th>
            <td>{{ 1.00 | currency:'USD':'symbol':'1.2-4' }} </td>
            <td>{{ 10023 | currency:'USD':'symbol':'1.0-0' }}</td>
            <td>{{ 21942 | currency:'USD':'symbol':'1.0-0' }}</td>
            <td><button class="primary-inverse sm">Edit</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</div>
<shared-title title="This is a title component" subtitle="With a subtitle">
  <button class="primary">And a button</button>
</shared-title>
