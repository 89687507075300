import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { DashboardStatsComponent } from './components/dashboard-stats/dashboard-stats.component';
import { CollateralsTableComponent } from './components/collaterals-table/collaterals-table.component';
import { AssetsTableComponent } from './components/assets-table/assets-table.component';
import { DashboardArticlesComponent } from './components/dashboard-articles/dashboard-articles.component';
import { AppRoutingModule } from '../app-routing.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';



@NgModule({
    declarations: [
        DashboardComponent,
        DashboardStatsComponent,
        CollateralsTableComponent,
        AssetsTableComponent,
        DashboardArticlesComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        AppRoutingModule,
        NgxChartsModule
    ]
})
export class DashboardModule { }
