<section class="pb-10">
  <h2>Fixed Income Funds</h2>
  <div class="table-wrapper">
    <table>
      <thead>
        <tr>
          <th scope="col">Asset</th>
          <th scope="col">Total</th>
          <th scope="col">Invested</th>
          <th scope="col">Available</th>
          <th scope="col">Status</th>
          <th scope="col"><span class="sr-only">View Details</span></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let fund of fixedIncomeFunds" (click)="goToLoan(fund)">
          <td class="items-center">
            <div class="flex">
              <sc-icon
                [src]="cryptoIcons[fund.underlying.symbol]"
                width="32px"
                height="32px"
                class="self-center mb-2"
              >
              </sc-icon>
              <div class="pl-3">
                <div class="text-base font-medium">
                  {{ fund.underlying.symbol }}
                </div>
                <div class="font-normal text-gray-500">
                  {{ fund.underlying.name }}
                </div>
              </div>
            </div>
          </td>
          <td class="items-center">
            <div class="flex flex-col">
              <div class="text-base font-medium">
                {{
                  +fund.activeInvested + +fund.availableFunds
                    | weiToCurrency : fund.underlying
                    | number : "1.2-2"
                }}
                {{ fund.underlying.symbol }}
              </div>
              <div class="font-normal text-sc-success">
                {{
                  +fund.activeInvested + +fund.availableFunds
                    | weiToCurrency : fund.underlying : "USD"
                    | currency : "USD" : "symbol-narrow" : "1.2-2"
                }}
              </div>
            </div>
          </td>
          <td class="items-center">
            <div class="flex flex-col">
              <div class="text-base font-medium">
                {{
                  fund.activeInvested
                    | weiToCurrency : fund.underlying
                    | number : "1.2-2"
                }}
                {{ fund.underlying.symbol }}
              </div>
              <div class="font-normal text-sc-success">
                {{
                  fund.activeInvested
                    | weiToCurrency : fund.underlying : "USD"
                    | currency : "USD" : "symbol-narrow" : "1.2-2"
                }}
              </div>
            </div>
          </td>
          <td class="items-center">
            <div class="flex flex-col">
              <div class="text-base font-medium">
                {{
                  fund.availableFunds
                    | weiToCurrency : fund.underlying
                    | number : "1.2-2"
                }}
                {{ fund.underlying.symbol }}
              </div>
              <div class="font-normal text-sc-success">
                {{
                  fund.availableFunds
                    | weiToCurrency : fund.underlying : "USD"
                    | currency : "USD" : "symbol-narrow" : "1.2-2"
                }}
              </div>
            </div>
          </td>
          <td>
            <div class="flex items-center">
              <span
                class="badge status"
                [ngClass]="{
                  success: fund.status === 'ACTIVE',
                  default: fund.status === 'TERMINATED',
                  danger: fund.status === 'INACTIVE'
                }"
              ></span>
              <div class="pl-1.5 text-sm font-medium">{{ fund.status }}</div>
            </div>
          </td>
          <td>
            <button class="default sm" (click)="goToLoan(fund)">
              View Details
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
