import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-how-to-article',
  template: `
  <div class="md:max-w-md w-full">
    <img src="{{ image }}" alt="" class="hidden md:block bg-slate-50 w-full mb-2 h-28 object-cover rounded-xl" />
    <div class="mb-2">
      <span class="pill primary flex items-center w-fit">{{ step }}</span>
    </div>
    <h4 class="mb-2">{{ name }}</h4>
    <p class="text-sm text-slate-500">{{ description }}</p>
  </div>`
})
export class HowToArticleComponent {
  @Input() image: string;
  @Input() step: string;
  @Input() name: string;
  @Input() description: string;
}
