<shared-title [title]="pageDetails.title" [subtitle]="pageDetails.description">
  <button
    *ngIf="user && isDashboardBtnEnable"
    class="primary-inverse mb-3"
    [routerLink]="['dashboard']"
  >
    <sc-icon
      [src]="icons.dashboard"
      [size]="'0.4'"
      [width]="'1.4rem'"
      class="mr-2"
    ></sc-icon>
    Financing Dashboard
  </button>
</shared-title>
<div *ngIf="currencies" class="flex flex-col md:flex-row gap-6 mb-6">
  <div class="card flex flex-col justify-between md:w-1/2">
    <form id="lendForm" [formGroup]="lendForm">
      <div class="flex flex-row rounded-2xl bg-slate-100 p-2 mb-2 gap-2">
        <button
          class="nostyle tab w-1/2 flex items-center justify-center truncate"
          [ngClass]="{ selected: !advancedMode }"
          (click)="advancedMode = false"
        >
          Simple Mode
        </button>
        <button
          class="nostyle tab w-1/2 flex items-center justify-center truncate"
          [ngClass]="{ selected: advancedMode }"
          (click)="advancedMode = true"
        >
          Advanced Mode
        </button>
      </div>
      <label for="currency" class="label pb-1 !mt-1"> Currency </label>
      <label
        #assetSelect
        for="currency"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
        class="smart-select w-full block"
        [ngClass]="
          assetOpen
            ? 'outline outline-2 -outline-offset-2 outline-sc-primary'
            : ''
        "
      >
        <div class="h-16 flex items-center justify-between">
          <div class="flex items-center">
            <sc-icon
              [src]="cryptoIcons[selectedAsset.symbol]"
              width="32px"
              height="32px"
              class="self-center mr-2"
            >
            </sc-icon>
            <div class="truncate">
              <div class="font-semibold text-lg truncate whitespace-nowrap">
                {{ selectedAsset.symbol }}
              </div>
              <div class="text-slate-500 font-medium text-sm">
                {{ selectedAsset.name }}
              </div>
            </div>
          </div>
          <img
            src="assets/icons/down-arrow.svg"
            alt="Expand Menu"
            [ngClass]="{ 'rotate-180': assetOpen }"
            class="rounded-full p-1 bg-slate-200 transition"
          />
        </div>
      </label>
      <select
        class="hidden"
        id="currency"
        placeholder="Currency"
        (click)="
          overlayOutsideClicked ? (assetOpen = false) : (assetOpen = true)
        "
        formControlName="currency"
      >
        <option
          *ngFor="let a of currencies"
          hidden
          value="{{ a.symbol }}"
          id="{{ a.symbol }}"
        >
          {{ a.name }}
        </option>
      </select>
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="assetOpen"
        [cdkConnectedOverlayHeight]="200"
        [cdkConnectedOverlayMinWidth]="assetSelect.offsetWidth"
        (overlayOutsideClick)="assetOpen = false; onOverlayOutsideClick()"
      >
        <ul class="w-full mt-1 select-dd overflow-y-auto">
          <li
            *ngFor="let a of currencies"
            (click)="selectAsset(a); assetOpen = false"
            class="cursor-pointer hover:bg-slate-50"
            [ngClass]="{ highlighted: selectedAsset.symbol === a.symbol }"
          >
            <div class="flex items-center">
              <sc-icon
                [src]="cryptoIcons[a.symbol]"
                width="32px"
                height="32px"
                class="self-center mr-2"
              >
              </sc-icon>
              <div>
                <div class="font-medium">
                  {{ a.symbol }}
                </div>
                <div class="text-slate-500 text-sm">
                  {{ a.name }}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </ng-template>
      <label for="fund-distribution" class="label pb-2 pt-4">
        Fund Distribution
      </label>
      <ul
        formControlName="distribution"
        cdkListbox
        cdkListboxOrientation="horizontal"
        aria-labelledby="allocate-funds"
        class="allocation-list max-h-[100rem] transition-all overflow-hidden"
        [ngClass]="{ '!max-h-[0px] border-none !mb-0': advancedMode }"
      >
        <li [cdkOption]="'equal'" class="option">
          {{ "Equal distribution" }}
        </li>
        <li [cdkOption]="'highestAPY'" class="option">
          {{ "Highest APY" }}
        </li>
        <li [cdkOption]="'fixed'" class="option">
          {{ "Fixed rate" }}
        </li>
        <li [cdkOption]="'variable'" class="option">
          {{ "Variable rate" }}
        </li>
      </ul>
      <div
        class="flex flex-wrap w-full gap-6 overflow-hidden transition-all"
        [ngClass]="advancedMode ? 'max-h-[99rem]' : 'max-h-0'"
      >
        <div
          class="card !rounded-2xl border-slate-200 hover:border-slate-500 w-full first"
        >
          <div class="mb-2 flex justify-between items-center">
            <div class="font-medium text-slate-500">7 - 30 days</div>
            <div class="font-semibold text-lg">
              {{ buckets[0].investmentRatio }}%
            </div>
          </div>
          <div class="slider">
            <input
              type="range"
              step="1"
              min="0"
              max="{{ buckets[0].max }}"
              formControlName="first"
              value="{{ buckets[0].investmentRatio }}"
              class="range"
              [ngStyle]="{
                width: 'calc(' + buckets[0].max + '% * 1)',
                'min-width': '16px'
              }"
            />
          </div>
        </div>
        <div
          class="card !rounded-2xl border-slate-200 hover:border-slate-500 w-full second"
        >
          <div class="mb-2 flex justify-between items-center">
            <div class="font-medium text-slate-500">31 - 60 days</div>
            <div class="font-semibold text-lg">
              {{ buckets[1].investmentRatio }}%
            </div>
          </div>
          <div class="slider">
            <input
              type="range"
              step="1"
              min="0"
              max="{{ buckets[1].max }}"
              formControlName="second"
              value="{{ buckets[1].investmentRatio }}"
              class="range"
              [ngStyle]="{
                width: 'calc(' + buckets[1].max + '% * 1)',
                'min-width': '16px'
              }"
            />
          </div>
        </div>
        <div
          class="card !rounded-2xl border-slate-200 hover:border-slate-500 w-full third"
        >
          <div class="mb-2 flex justify-between items-center">
            <div class="font-medium text-slate-500">61 - 90 days</div>
            <div class="font-semibold text-lg">
              {{ buckets[2].investmentRatio }}%
            </div>
          </div>
          <div class="slider">
            <input
              type="range"
              step="1"
              min="0"
              max="{{ buckets[2].max }}"
              formControlName="third"
              value="{{ buckets[2].investmentRatio }}"
              class="range"
              [ngStyle]="{
                width: 'calc(' + buckets[2].max + '% * 1)',
                'min-width': '16px'
              }"
            />
          </div>
        </div>
        <div
          class="card !rounded-2xl border-slate-200 hover:border-slate-500 w-full fourth"
        >
          <div class="mb-2 flex justify-between items-center">
            <div class="font-medium text-slate-500">91 - 180 days</div>
            <div class="font-semibold text-lg">
              {{ buckets[3].investmentRatio }}%
            </div>
          </div>
          <div class="slider">
            <input
              type="range"
              step="1"
              min="0"
              max="{{ buckets[3].max }}"
              formControlName="fourth"
              value="{{ buckets[3].investmentRatio }}"
              class="range"
              [ngStyle]="{
                width: 'calc(' + buckets[3].max + '% * 1)',
                'min-width': '16px'
              }"
            />
          </div>
        </div>
      </div>
    </form>
    <button
      (click)="openInfoDialog()"
      class="nostyle mt-4 popup-btn text-slate-500 hover:text-sc-primary ml-auto flex items-center"
    >
      <div class="text-sm font-medium">What is Fund Distribution?</div>
      <sc-icon
        [src]="icons.doubleChevronDiagonal"
        size="0.675"
        class="-mt-0.5"
      ></sc-icon>
    </button>
  </div>
  <div class="card flex flex-col md:flex-col w-full md:w-1/2">
    <h3 for="firstName" class="text-lg font-semibold">Summary</h3>
    <div class="flex flex-row gap-6">
      <div class="py-3 pl-4 pr-3 rounded-2xl border flex flex-col w-full">
        <p class="text-sc-black font-medium mb-2">Currency</p>
        <div class="flex md:flex-row">
          <sc-icon
            [src]="cryptoIcons[selectedAsset.symbol]"
            class="self-center mr-2"
          ></sc-icon>
          <p class="self-center font-medium text-lg">
            {{ selectedAsset.symbol }}
          </p>
        </div>
      </div>
      <div class="py-3 pl-4 pr-3 rounded-2xl border flex flex-col w-full">
        <p class="text-sc-black font-medium mb-2">Fee (earned)</p>
        <div class="flex">
          <div class="flex gap-2 items-center">
            <div
              class="p-2 h-8 w-8 rounded-xl border border-blue-100 bg-blue-50 flex items-center justify-center"
            >
              <sc-icon
                [src]="icons.chartUp"
                [size]="'0.8'"
                [width]="'1rem'"
                [height]="'1rem'"
              ></sc-icon>
            </div>
            <p class="font-medium text-lg">{{ this.apy }}%</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="py-3 pl-4 pr-3 rounded-2xl border flex flex-col mt-6"
      style="padding-bottom: 3.5rem"
    >
      <p class="text-sc-black font-medium mb-2">Fund Distribution</p>
      <div class="chart-wrapper min-h-fit">
        <ngx-charts-pie-chart
          [scheme]="colorScheme"
          [results]="chartData"
          [gradient]="false"
          [legend]="true"
          [labels]="false"
          [doughnut]="true"
          [arcWidth]="0.3"
          [legendTitle]="''"
          [legendPosition]="legendPosition"
        >
        </ngx-charts-pie-chart>
      </div>
    </div>
    <button
      class="primary mt-8 w-fit-imp mx-auto"
      (click)="onSubmit()"
      [disabled]="
        !user ||
        !user.ethAddress ||
        lendForm.invalid ||
        isFifCreating ||
        this.apy === 0
      "
    >
      <div class="flex items-center mx-14">
        <ng-container *ngIf="user">
          <sc-icon
            *ngIf="!isFifCreating"
            [src]="icons.add"
            class="mr-2"
            [size]="'0.5'"
            [width]="'1.5rem'"
          >
          </sc-icon>
          <spinner
            size="xs"
            color="gray"
            *ngIf="isFifCreating"
            class="mr-2"
          ></spinner>
          <div class="pr-2">Create FIF</div>
        </ng-container>
        <ng-container *ngIf="!user">Log In to Lend</ng-container>
      </div>
    </button>
  </div>
</div>
<div class="{{ environment.banners.lend }} banner-wrapper mb-6"></div>
<section class="mt-10">
  <h2 class="mb-0">{{ pageDetails.howTo.title }}</h2>
  <p class="text-sm text-slate-500 mb-4">{{ pageDetails.howTo.description }}</p>
  <div class="flex gap-6 flex-wrap md:flex-nowrap">
    <div class="flex flex-col md:flex-row w-full gap-6">
      <div
        *ngFor="let step of pageDetails.howTo.steps; let i = index"
        class="w-full"
        [ngClass]="{
          'md:w-1/3': pageDetails.howTo.steps.length >= 3,
          'md:1/2': pageDetails.howTo.steps.length <= 2
        }"
      >
        <app-how-to-article
          image="../../../../assets/images/step-{{ i + 1 }}.svg"
          step="Step {{ i + 1 }}"
          [name]="step.title"
          [description]="step.description"
        >
        </app-how-to-article>
      </div>
    </div>
    <div class="flex flex-col md:flex-col w-full md:w-1/3">
      <div *ngFor="let faq of pageDetails.howTo.faq">
        <a
          href="{{ faq.href }}"
          target="_blank"
          class="mb-2 flex text-sc-primary hover:text-blue-600 cursor-pointer font-semibold"
        >
          {{ faq.question }}
          <sc-icon [src]="icons.rightArrow" class="self-center ml-2"></sc-icon>
        </a>
        <p class="text-sm hidden md:block text-slate-500 mb-6">
          {{ faq.answer }}
        </p>
      </div>
    </div>
  </div>
  <shared-paragraphs
    *ngIf="pageDetails.paragraphs"
    [paragraphsTitle]="pageDetails.paragraphsTitle"
    [paragraphs]="pageDetails.paragraphs"
    [subtitles]="pageDetails.titles"
  ></shared-paragraphs>
</section>
