import { Component, Input } from '@angular/core';
import { Reward } from 'src/app/shared/interfaces/rewards/reward';

@Component({
  selector: 'unclaimed-rewards',
  templateUrl: './unclaimed-rewards.component.html',
  styleUrls: ['./unclaimed-rewards.component.scss']
})
export class UnclaimedRewardsComponent {
  @Input() unclaimedRewards: Reward[];
}
