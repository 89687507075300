import { Action, createReducer, on } from "@ngrx/store";
import { EarnCalculationInput } from "src/app/shared/interfaces/earn/earn-calculation-input";
import { EarnCalculationResult } from "src/app/shared/interfaces/earn/earn-calculation-result";
import * as EarnActions from "./earn.actions"

export interface State {
  earnCalculationInput: EarnCalculationInput;
  earnCalculationResult: EarnCalculationResult;
}

const initialState = {
  earnCalculationInput: undefined,
  earnCalculationResult: undefined
}

const _earnReducer = createReducer(
  initialState,
  on(
    EarnActions.saveCalculation,
    (state, action) => ({
      ...state,
      earnCalculationInput: action.earnCalculationInput
    })
  ),
  on(
    EarnActions.calculate,
    (state, action) => ({
      ...state,
      earnCalculationResult: action.earnCalculationResult
    })
  ),
  on(
    EarnActions.resetState,
    () => initialState
  )
)

export function earnReducer(state: State, action: Action) {
  return _earnReducer(state, action);
}
