import { SegmentationConfig } from "src/app/shared/interfaces/segmentation/segmentation-config"

export const AAVE: SegmentationConfig[] = [{
  type: 'borrow',
  title: 'Your collateral is yours, even after liquidation',
  description: 'We do not profit from liquidations. Most of Money Market Funds do. A lot.',
  actions: [{
    name: 'Borrow',
    routerLink: '/borrow',
  }]
}, {
  type: 'lend',
  title: 'Asset - Liability Maturity Mapping',
  description: 'All DeFi Money Market Funds have bank run risk. We have Asset Liability Maturity Mapping and no risk.',
  actions: [{
    name: 'Lend',
    routerLink: '/lend',
  }]
}]

export const COMPOUND: SegmentationConfig[] = [{
  type: 'borrow',
  title: 'Know your cost of capital in advance',
  description: 'Its all about predictability. Borrow fixed rate via SmartCredit.io.',
  actions: [{
    name: 'Borrow',
    routerLink: '/borrow',
  }]
}, {
  type: 'lend',
  title: '0.5% spread',
  description: 'Forget high spread between borrow and lend rates. Enjoy our low spread of only 0.5% of borrow volume.',
  actions: [{
    name: 'Lend',
    routerLink: '/lend',
  }]
}]

export const MAKER: SegmentationConfig[] = [{
  type: 'borrow',
  title: 'Borrow more with the same collateral',
  description: 'We guarantee efficient collateral use via Positions Monitoring System.',
  actions: [{
    name: 'Borrow',
    routerLink: '/borrow',
  }, {
    name: 'Learn More',
    link: 'https://learn.smartcredit.io/how-does-it-work/positions-monitoring-system'
  }],
}]
