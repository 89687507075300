<div class="rounded-3xl border-slate-200 bg-white p-4 text-center">
  <div class="font-semibold text-lg">Withdrawing Rewards</div>
  <div class="hint mt-1 mb-3"></div>
  <div class="card w-full flex justify-between items-center mb-2 px-4 py-2">
    <div class="text-left mr-2">
      <div class="text-base font-medium">Signing</div>
      <div class="text-base text-slate-400">Automatically generating a reward signature that will allow you to redeem.</div>
    </div>
    <spinner *ngIf="claiming" size="xs"></spinner>
    <sc-icon *ngIf="!claiming" [src]="icons.accept"></sc-icon>
  </div>
  <div class="card w-full flex justify-between items-center mb-2 px-4 py-2">
    <div class="text-left mr-2">
      <div class="text-base font-medium">Redeeming</div>
      <div class="text-base text-slate-400">Finalize the transaction to transfer your rewards to your wallet.</div>
    </div>
    <spinner *ngIf="!claiming" size="xs"></spinner>
    <div *ngIf="claiming" class="relative -right-0.5 h-5 w-5 bg-transparent border-4 border-slate-200 rounded-full"></div>
  </div>
</div>
