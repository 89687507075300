import { Component, Input } from '@angular/core';
import { Currency } from 'src/app/shared/interfaces/currencies/currency';
import { StakingReward } from 'src/app/shared/interfaces/rewards/staking-reward';

@Component({
  selector: 'staking-rewards',
  templateUrl: './staking-rewards.component.html',
  styleUrls: ['./staking-rewards.component.scss']
})
export class StakingRewardsComponent {
  @Input() stakingRewards: StakingReward[];
  @Input() currency: Currency;
}
