import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-trust-score-info',
  templateUrl: './trust-score-info.dialog.html',
  styleUrls: ['./trust-score-info.dialog.scss']
})
export class TrustScoreInfoDialog implements OnInit {
  @Output('reject') reject: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClose() {
    this.reject.emit(true);
  }
}
