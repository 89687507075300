import { Component, EventEmitter, Output } from '@angular/core';
import { LOGO } from 'src/assets/images/svg-icons';
import { WALLETS } from 'src/config/app.config';

@Component({
  selector: 'shared-signing-dialog',
  template: `
  <div class="rounded-2xl border-slate-200 bg-white p-4 w-[370px] text-center">
    <sc-icon [src]="logo" [height]="'35px'" [width]="'175px'" [classes]="'mx-auto'"></sc-icon>
    <hr class="h-px -mx-4 mb-2 bg-gray-200 border-0">
    <div class="p-2">
      <div class="font-medium text-base text-slate-800">Sign the message from your wallet!</div>
      <div class="text-base text-slate-500 mb-3">Signing proves you own the wallet, it's free.</div>
      <div class="rounded-2xl shadow flex items-center border border-slate-100 justify-between cursor-progress p-4 h-20 mb-4">
        <div class="flex items-center">
        <spinner size="xs"></spinner>
        <div class="px-4 text-slate-800 text-left">
          <p class="font-medium text-base">Signing with {{ connectedWallet }}</p>
          <!-- <p>Ethereum Mainnet Network</p> -->
        </div>
        </div>
        <img *ngIf="wallet" [src]="wallet.path" [alt]="connectedWallet" width="40">
      </div>
      <div class="rounded-2xl shadow flex items-center border border-slate-100 justify-between cursor-pointer p-4 h-20 text-slate-500 hover:text-sc-primary hover:bg-slate-100"
        (click)="onResetWallet()">
        <div class="text-left">
          <p class="font-medium text-base text-slate-800">Not using {{ connectedWallet }} wallet?</p>
          <p>Connect with another wallet</p>
        </div>
        <div class="flex flex-wrap w-12 items-center justify-center">
          <ng-container *ngFor="let wallet of allWallets">
            <div *ngIf="wallet.name !== connectedWallet" class="w-1/2">
              <img [src]="wallet.path" [alt]="wallet.name" width="20" height="20">
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  `
})
export class SigningDialogComponent {
  public connectedWallet = localStorage.getItem('connectedWallets');
  public wallet = WALLETS.find(w => w.name === this.connectedWallet);
  public allWallets = WALLETS;
  public logo = LOGO.main;

  @Output('resetWallet') resetWallet: EventEmitter<any> = new EventEmitter();

  onResetWallet() {
    this.resetWallet.emit();
  }
}
