<div class="earn-card">
  <h2 class="mb-4">Leveraged Staking</h2>
  <form [formGroup]="devOptsForm">
    <div class="flex flex-col gap-1">
      <div class="input-group">
        <div class="flex items-center">
          <label for="ontime" class="inline-label right">Simulate on-time leverage request repayment</label>
          <div>
            <input type="checkbox" id="ontime" formControlName="ontime">
            <label for="ontime"></label>
          </div>
        </div>
      </div>
      <div class="input-group">
        <div class="flex items-center">
          <label for="critical" class="inline-label right disabled" [ngClass]="{'disabled': devOptsForm.controls['ontime'].value === false}">Simulate critical timeframe for repayment (less than 24h
            left)</label>
          <div>
            <input type="checkbox" id="critical" formControlName="critical" [disabled]="devOptsForm.controls['ontime'].value === false">
            <label for="critical"></label>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>