<div class="flex flex-col md:flex-row gap-8">
  <div class="card flex flex-row md:flex-col w-full md:w-2/4 bg-slate-50">
    <form [formGroup]="credentialsForm" class="w-full" action="">
      <h2>Credentials</h2>
      <div class="input-group">
        <label for="name" class="label">First Name</label>
        <input id="name" type="text" class="w-full" formControlName="firstName" placeholder="First Name">
      </div>
      <div class="input-group">
        <label for="surname" class="label">Last Name</label>
        <input id="surname" type="text" class="w-full" formControlName="lastName"
          placeholder="Last Name">
      </div>
    </form>
    <div class="flex w-full justify-end">
      <button class="primary mt-6 mb-2" [disabled]="credentialsForm.invalid"
        (click)="onCredentialsSubmit()">Update Credentials</button>
    </div>
  </div>
  <!-- <div class="card accent flex flex-row md:flex-col w-full md:w-2/4 bg-slate-50">
    <h2>Customization</h2>
    <div class="input-group flex items-center">
      <label for="checkbox2" class="inline-label left">Dark Theme</label>
      <input type="checkbox" id="theme">
      <label for="theme"></label>
    </div>
    <div class="input-group flex items-center">
      <label for="checkbox2" class="inline-label left">Show Smartcredit token</label>
      <input type="checkbox" id="smartcredit-token">
      <label for="smartcredit-token"></label>
    </div>
  </div> -->
</div>
