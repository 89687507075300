import { createAction, props } from "@ngrx/store";
import { ClaimedReward } from "src/app/shared/interfaces/rewards/claimed-reward";
import { StakingReward } from "src/app/shared/interfaces/rewards/staking-reward";

export const getRewardsData = createAction(
  'getRewardsData',
  props<{
    referralsLoanVolume,
    claimedRewards,
    unclaimedRewards,
    stakingRewards,
    totalClaimedRewardsUsd,
    totalUnclaimedRewardsUsd,
    totalStakingRewardsUsd
  }>()
)

export const clearUnclaimed = createAction(
  'clearUnclaimed',
  props<{
    unclaimedRewards: any,
    totalUnclaimedRewardsUsd: any
  }>()
)

export const getStakingRewardsData = createAction(
  'getStakingRewardsData',
  props<{
    stakingRewards: StakingReward[],
    totalStakingRewards: string,
    stakingContract: string
  }>()
)

export const updateRedeemedReward = createAction(
  'updateRedeemedReward',
  props<{
    claimedReward: ClaimedReward
  }>()
)

export const addRedeemedReward = createAction(
  'addRedeemedReward',
  props<{
    claimedReward: ClaimedReward
  }>()
)

export const redeemingRewards = createAction(
  'redeemingRewards',
  props<{ isRedeeming: boolean }>()
)

export const resetState = createAction(
  'resetState'
)
