<div class="rounded-3xl border-slate-200 bg-white p-4">
  <h3>Claim Rewards</h3>
  <p class="text-slate-500 font-medium mb-4">Are you sure you want to claim {{ totalStakingRewards | weiToCurrency:currency | number:'1.2-2' }} SMARTCREDIT staking rewards?</p>
  <p class="text-slate-500 mb-4">After claiming them you should complete the transaction to redeem the rewards to your wallet.</p>
  <div class="flex items-center justify-between">
    <button class="default" (click)="onClose()">Cancel</button>
    <button class="primary" (click)="onClaimConfirm()">
      Claim
    </button>
  </div>
</div>
