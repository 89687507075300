<div class="rounded-3xl bg-sc-primary p-10 relative">
	<div class="flex flex-col max-w-3xl mx-auto lg:items-center">
		<img src="assets/images/rewards/email-marketing.svg" alt="some image" class="max-h-48">
		<h2 class="font-bold text-white mb-3 mt-8 lg:my-7 text-center">Refer a friend, get rewards!</h2>
		<div class="font-light text-slate-200 text-sm text-center mb-6">
			<p class="mb-2 lg:mb-0">Get rewards by inviting others to join the platform using the referral link.</p>
			<p>Learn more about how you can earn by inviting others to SmartCredit.io in our <a class="text-base text-white font-medium hover:text-slate-300" href="https://learn.smartcredit.io/staking-and-rewards/affiliate-program" target="_blank">Affiliate Program</a>.</p>
		</div>
		<input [value]="baseUrl + '/?ref=' + referralCode" readonly
			class="border rounded-2xl w-full lg:w-3/5 p-2 mb-3 lg:mb-7 text-white">
		<button class="primary-inverse border-none min-w-[8rem] mt-2" [cdkCopyToClipboard]="baseUrl + '/ref=' + referralCode" (click)="copy()">{{ copyText }}</button>
	</div>
</div>
