import { Component } from '@angular/core';
import { LOGO } from 'src/assets/images/svg-icons';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'shared-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public logo = LOGO.main
  public environment = environment;
  public year: number = new Date().getFullYear();
}
