<shared-title
  *ngIf="loan"
  title="{{ loan.underlying.symbol }} Financing"
  address="{{ loan.contractAddress }}"
  status="{{ loan.loanStatus }}"
>
  <button class="primary-inverse ml-auto" [routerLink]="['../../']">
    <sc-icon [src]="icons.eyePrimary" class="mr-2"></sc-icon>
    View Financing Line
  </button>
</shared-title>
<div
  *ngIf="!user || !user.ethAddress"
  class="mx-auto text-center rounded-3xl bg-slate-50 py-24 mb-8"
>
  <sc-icon
    [size]="'2rem'"
    [src]="icons.locked"
    class="mx-auto"
    class="inline-block"
  >
  </sc-icon>
  <div class="text-md text-slate-400 font-medium px-4">
    You must log in to view the financing dashboard!
  </div>
  <div class="mt-6">
    <shared-account-center></shared-account-center>
  </div>
</div>
<!-- loading screen -->
<div
  *ngIf="updatingStatus"
  class="mx-auto text-center rounded-3xl bg-slate-50 py-24 mb-8"
>
  <!-- loading spinner -->
  <spinner size="sm"></spinner>
  <div class="text-md text-slate-400 font-medium px-4">
    Loading Financing Details...
  </div>
</div>

<ng-container
  *ngIf="user && user.ethAddress && loan && loan.states && !updatingStatus"
>
  <div *ngIf="quickActions !== 0" class="flex items-center">
    <h3 class="text-slate-500 font-medium my-0 mr-1 text-base">
      Notifications
    </h3>
    <!-- <div
      class="badge"
      [ngClass]="{ primary: quickActions !== 0, default: quickActions === 0 }"
    >
      {{ quickActions }}
    </div> -->
  </div>
  <div
    *ngIf="loan.states.nearLiquidation"
    class="info-box lg danger flex justify-between items-center"
  >
    <div>
      Financing is close to being liquidated! Another {{ requiredTokenAmount }}
      {{ loan.collateral.symbol }} is required to avoid liquidation!
    </div>
    <div>
      <button
        *ngIf="loan.approvalNeeded"
        class="danger-inverse mr-2"
        [disabled]="approvingRepayment"
        (click)="approveRepayment()"
      >
        <spinner
          *ngIf="approvingRepayment"
          size="xs"
          color="gray"
          class="mr-2"
        ></spinner>
        Approve Repurchase
      </button>
      <button
        *ngIf="!loan.approvalNeeded"
        class="danger-inverse mr-2"
        [disabled]="repayingLoan"
        (click)="repayLoan()"
      >
        <spinner
          *ngIf="repayingLoan"
          size="xs"
          color="gray"
          class="mr-2"
        ></spinner>
        Repurchase Finance
      </button>
      <button
        class="danger"
        [disabled]="increasingCollateral || depositingCollateral"
        (click)="openIncreaseCollateralDialog()"
      >
        <spinner
          *ngIf="increasingCollateral || depositingCollateral"
          size="xs"
          color="gray"
          class="mr-2"
        ></spinner>
        Increase Collateral
      </button>
    </div>
  </div>
  <div
    *ngIf="loan.states.liquidated"
    class="info-box lg danger flex justify-between items-center"
  >
    <div>
      Finance has been liquidated and automatically repaid. Any remaining
      collateral can be withdrawn from the Finance Line dashboard.
    </div>
    <div>
      <button class="danger-inverse" [routerLink]="['../../']">
        View Financing Line
      </button>
    </div>
  </div>
  <div
    *ngIf="loan.states.liquidating"
    class="info-box lg danger flex justify-between items-center"
  >
    <div>
      Your finance is being liquidated. After repurchase, any excess funds in
      the requested asset will be available for withdrawal from your Finance
      Line dashboard.
    </div>
  </div>
  <div
    *ngIf="loan.states.matching"
    class="info-box lg info flex justify-between items-center"
  >
    <div>Finance is currently being matched</div>
  </div>
  <div
    *ngIf="loan.states.matched"
    class="info-box lg info flex justify-between items-center"
  >
    <div>Finance matched</div>
  </div>
  <div
    *ngIf="loan.states.funded && !loan.states.nearLiquidation"
    class="info-box lg info flex justify-between items-center"
  >
    <div>
      Funds have been transferred to your wallet! Repurchase before
      {{ loan.loanEndDate | date }}
    </div>
    <div *ngIf="!isFinancing">
      <button
        *ngIf="loan.approvalNeeded"
        class="primary-inverse"
        [disabled]="approvingRepayment"
        (click)="approveRepayment()"
      >
        <spinner
          *ngIf="approvingRepayment"
          size="xs"
          color="gray"
          class="mr-2"
        ></spinner>
        Approve Repurchase
      </button>
      <button
        *ngIf="!loan.approvalNeeded"
        class="primary-inverse"
        [disabled]="repayingLoan"
        (click)="repayLoan()"
      >
        <spinner
          *ngIf="repayingLoan"
          size="xs"
          color="gray"
          class="mr-2"
        ></spinner>
        Repurchase Finance
      </button>
    </div>
  </div>
  <div
    *ngIf="loan.states.repaid"
    class="info-box lg flex justify-between items-center"
  >
    <div>No actions required. Finance has been repurchased!</div>
  </div>
  <div
    *ngIf="loan.states.terminated"
    class="info-box lg flex justify-between items-center"
  >
    <div>No actions required. Finance has been terminated!</div>
  </div>
  <div
    *ngIf="loan.states.collateral"
    class="info-box lg success flex justify-between items-center"
  >
    <div>Collateral needed!</div>
    <div>
      <button
        class="success"
        (click)="openDepositDialog()"
        [disabled]="depositingCollateral"
      >
        <spinner
          *ngIf="depositingCollateral"
          size="xs"
          color="gray"
          class="mr-2"
        ></spinner>
        Deposit Collateral
      </button>
    </div>
  </div>
  <div class="flex items-center">
    <h3 class="text-slate-500 font-medium -mb-1 mt-2 mr-1 text-base">
      Finance Details
    </h3>
  </div>
  <div class="flex flex-col md:flex-row mt-6 gap-8">
    <div class="card flex flex-col justify-between md:w-1/3">
      <div>
        <h3 class="font-medium text-slate-600 text-base">
          <ng-container *ngIf="loan.loanStatus === 'ACTIVE'"
            >Requested</ng-container
          >
          <ng-container *ngIf="loan.loanStatus !== 'ACTIVE'"
            >Financed</ng-container
          >
        </h3>
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <sc-icon
              [src]="icons[loan.underlying.symbol]"
              width="32px"
              height="32px"
            >
            </sc-icon>
            <div class="pl-3">
              <div class="text-lg font-semibold">
                {{ loan.loanAmount | weiToCurrency : loan.underlying }}
                {{ loan.underlying.symbol }}
              </div>
              <div class="font-normal">
                {{
                  loan.loanAmount
                    | weiToCurrency : loan.underlying : "USD"
                    | currency : "USD" : "symbol-narrow" : "1.2-2"
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-4">
          <div class="w-1/2 mb-3">
            <p class="text-slate-500">Financing Term</p>
            <div class="text-sc-black font-medium">
              {{ loan.loanTerm }} days
            </div>
          </div>
          <div class="w-1/2 mb-3">
            <p class="text-slate-500">Fee Rate</p>
            <div class="text-sc-black font-medium">
              {{ loan.netLoanInterestRate }}%
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="loan.states.collateral || loan.states.matching"
        class="flex items-center justify-center gap-4 mt-4"
      >
        <button class="warning-inverse" (click)="openTerminateLoanDialog()">
          Cancel Request
        </button>
      </div>
    </div>
    <div class="card flex flex-col justify-between md:w-1/3">
      <div>
        <h3 class="font-medium text-slate-600 text-base">
          Outstanding Balance
        </h3>
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <sc-icon
              [src]="icons[loan.underlying.symbol]"
              width="32px"
              height="32px"
            >
            </sc-icon>
            <div class="pl-3">
              <div class="text-lg font-semibold">
                {{
                  loan.outstandingBalance ||
                    loan.loanAmount +
                      loan.loanAmount * (loan.netLoanInterestRate / 100)
                    | weiToCurrency : loan.underlying
                    | number : "1.2-6"
                }}
                {{ loan.underlying.symbol }}
              </div>
              <div class="font-normal">
                {{
                  loan.outstandingBalance ||
                    loan.loanAmount +
                      loan.loanAmount * (loan.netLoanInterestRate / 100)
                    | weiToCurrency : loan.underlying : "USD"
                    | currency : "USD" : "symbol-narrow" : "1.2-2"
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-4">
          <div *ngIf="loan.states.funded" class="w-1/2">
            <p class="text-sm text-slate-500">Repurchase Deadline</p>
            <div class="text-sc-black font-medium">
              {{ loan.loanEndDate | date : "medium" }}
            </div>
          </div>
        </div>
        <div
          *ngIf="
            !loan.states.repaid &&
            !loan.states.badLoan &&
            !loan.states.liquidating &&
            !loan.states.funded
          "
          class="info-box"
        >
          Repurchase details will appear here after the finance is funded!
        </div>
        <div *ngIf="loan.states.repaid" class="info-box">
          This finance has been repurchased.
        </div>
        <div *ngIf="loan.states.badLoan" class="info-box">
          The finance repurchase deadline has passed and this finance has been
          classified as a bad loan.
        </div>
      </div>
      <div
        *ngIf="loan.states.funded"
        class="flex items-center justify-center gap-4 mt-4"
      >
        <div *ngIf="isFinancing" class="flex items-center px-10">
          <spinner size="xs" class="mr-2"></spinner>
          Updating, Please Wait...
        </div>
        <div *ngIf="!isFinancing">
          <div
            *ngIf="loan.approvalNeeded"
            class="pill !text-sm w-fit flex items-center mx-auto my-5 gap-1 cursor-default"
            [ngClass]="'danger'"
          >
            <sc-icon
              [src]="icons.warningOutline"
              width="1rem"
              height="0px"
              [size]="'0.875'"
              [classes]="'danger -mt-2.5 mx-1'"
              class="hidden md:block"
            ></sc-icon>
            Approval Needed
          </div>
          <button
            *ngIf="loan.approvalNeeded"
            class="primary"
            [disabled]="approvingRepayment"
            (click)="approveRepayment()"
          >
            <spinner
              *ngIf="approvingRepayment"
              size="xs"
              color="gray"
              class="mr-2"
            ></spinner>
            Approve Repurchase
          </button>
          <button
            *ngIf="!loan.approvalNeeded"
            class="primary"
            [disabled]="repayingLoan"
            (click)="repayLoan()"
          >
            <spinner
              *ngIf="repayingLoan"
              size="xs"
              color="gray"
              class="mr-2"
            ></spinner>
            Repurchase Finance
          </button>
        </div>
      </div>
      <div *ngIf="loan.states.matched" class="primary">
        <div *ngIf="isFinancing" class="flex items-center px-10">
          <spinner size="xs" class="mr-2"></spinner>
          Updating, Please Wait...
        </div>
        <button *ngIf="!isFinancing" class="primary" (click)="createFinance()">
          Start Finance
        </button>
      </div>
    </div>
    <div class="card flex flex-col justify-between md:w-1/3">
      <div>
        <div class="flex justify-between">
          <h3 class="font-medium text-slate-600 text-base">Collateral</h3>
          <div
            class="pill h-8"
            [ngClass]="
              loan.liquidationStatus === 'NOT REQUIRED' ? '' : 'danger'
            "
          >
            {{ loan.liquidationStatus === "NOT REQUIRED" ? "" : "LIQUIDATE" }}
          </div>
        </div>
        <div class="flex">
          <sc-icon
            [src]="icons[loan.collateral.symbol]"
            width="32px"
            height="32px"
            class="self-end mb-2"
          >
          </sc-icon>
          <div class="pl-3">
            <div class="text-lg font-semibold">
              {{
                loan.currentCollateralAmount || loan.collateralInfo.amount
                  | weiToCurrency : loan.collateral
                  | number : "1.2-6"
              }}
              {{ loan.collateral.symbol }}
            </div>
            <div class="font-normal">
              {{
                loan.currentCollateralAmount || loan.collateralInfo.amount
                  | weiToCurrency : loan.collateral : "USD"
                  | currency : "USD" : "symbol-narrow" : "1.2-2"
              }}
            </div>
          </div>
        </div>
        <div class="flex flex-row mt-4">
          <div class="w-1/2">
            <p class="text-slate-500">Collateral Ratio</p>
            <div>
              <div class="text-sc-black font-medium">
                {{ currentCollateralRatio | number : "1.2-2" }}%
              </div>
            </div>
          </div>
          <div class="w-1/2">
            <p class="text-slate-500">Available in Finance Line</p>
            <div class="text-sc-black font-medium">
              {{
                collateralInCreditLine?.unlocked
                  | weiToCurrency : loan.collateral
                  | number : "1.2-6"
              }}
              {{ loan.collateral.symbol }}
            </div>
          </div>
        </div>
        <div
          class="pill !text-sm w-fit my-2 flex items-center gap-1 cursor-default"
          [ngClass]="loan.states.nearLiquidation ? 'danger' : 'primary'"
          tooltip="If collateral ratio drops below this limit, loan will be liquidated!"
        >
          Liquidation Limit: {{ loan.liquidationLimit }}%
          <span class="text-sm">
            ({{
              liquidationLimitUSD
                | currency : "USD" : "symbol-narrow" : "1.2-2"
            }})</span
          >
          <sc-icon
            [src]="icons.info"
            width="1rem"
            height="0px"
            [size]="'0.875'"
            [classes]="'info-icon -mt-1.5'"
            class="hidden md:block"
          ></sc-icon>
        </div>
        <div class="text-slate-500 text-sm block md:hidden">
          <div class="text-sm">
            If collateral ratio drops below the liquidation limit, loan will be
            liquidated!
          </div>
        </div>
      </div>
      <div *ngIf="loan.states.matching" class="info-box info">
        Collateral for this loan has been transferred to the Finance Line. It
        will be locked once the loan is funded.
      </div>
      <div class="flex items-center justify-center gap-4 mt-4">
        <button
          *ngIf="loan.loanStatus === 'ACTIVE' && loan.states.collateral"
          class="success-inverse"
          (click)="openDepositDialog()"
          [disabled]="depositingCollateral"
        >
          <spinner
            *ngIf="depositingCollateral"
            size="xs"
            color="gray"
            class="mr-2"
          ></spinner>
          Deposit Collateral
        </button>
        <button
          *ngIf="
            loan.states.funded &&
            loan.collateral.symbol !== loan.underlying.symbol
          "
          class="primary-inverse"
          [disabled]="increasingCollateral || depositingCollateral"
          (click)="openIncreaseCollateralDialog()"
        >
          <spinner
            *ngIf="increasingCollateral || depositingCollateral"
            size="xs"
            color="gray"
            class="mr-2"
          ></spinner>
          Increase Collateral
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="user && loan && !loan.states && !updatingStatus">
  <div class="mx-auto text-center rounded-3xl bg-slate-50 py-24 mb-8">
    <sc-icon
      [size]="'2rem'"
      [src]="icons.empty"
      class="mx-auto"
      class="inline-block"
    >
    </sc-icon>
    <div class="text-md text-slate-400 font-medium px-4">
      There was an issue loading the loan details. Please reload the page!
    </div>
    <!-- add reload page button -->
    <div class="mt-6">
      <button class="primary-inverse" (click)="reloadPage()">
        Reload Page
      </button>
    </div>
  </div>
</ng-container>
