import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import dayjs from 'dayjs';
import { catchError, map, take, takeUntil, Subject, Observable, EMPTY, interval, merge, tap } from 'rxjs';
import {
  borrowerLenderWeeklyRewards,
  getReferralData,
  claimRewards,
  getUserRewardTypes,
  getClaimedRewardsHistory
} from 'src/app/core/graphql/rewardsType.js';
import {
  claimableStakingRewards,
  claimStakingRewards
} from 'src/app/core/graphql/stakingType.js';
import * as isoWeek from 'dayjs/plugin/isoWeek'; // ? required for isoWeek, DO NOT delete
import { Store } from '@ngrx/store';
import * as fromApp from 'src/app/store/app.reducer';
import { ToastrService } from 'ngx-toastr';
import { FixedIncomeFund } from 'src/app/shared/interfaces/fifs/fixedIncomeFund';
import { CurrenciesService } from './currencies.service';
import { REWARD_CODES } from 'src/app/shared/constants';
import { Reward } from 'src/app/shared/interfaces/rewards/reward';
import * as RewardsActions from "./../../rewards/store/rewards.actions";
import * as BorrowingActions from 'src/app/borrowing/store/borrowing.actions';
import { Web3Service } from '../web3/web3.service';
import { ClaimedReward } from 'src/app/shared/interfaces/rewards/claimed-reward';
import { fixedIncomeFundsQuery } from '../graphql/fifType';
import { StakingReward } from 'src/app/shared/interfaces/rewards/staking-reward';
import BigNumber from 'bignumber.js';
import { Currency } from 'src/app/shared/interfaces/currencies/currency';
import { LoanData } from 'src/app/shared/interfaces/borrowing/loan-data';
import { environment } from 'src/environments/environment';
import { DEFAULT_DIALOG_CONFIG, Dialog, DialogRef } from '@angular/cdk/dialog';
import { ClaimingRewardsDialog } from 'src/app/shared/components/claiming-rewards.dialog';

@Injectable({
  providedIn: 'root'
})
export class RewardsService implements OnDestroy {
  private destroy$ = new Subject();

  constructor(
    private store: Store<fromApp.AppState>,
    private apollo: Apollo,
    private toastr: ToastrService,
    private currenciesService: CurrenciesService,
    private web3Service: Web3Service,
    private dialog: Dialog
  ) { }

  getRewards(walletAddress): Observable<any> {
    return this.apollo
      .watchQuery({
        query: getReferralData,
        variables: {
          walletAddress
        }, fetchPolicy: "network-only"
      }).valueChanges
      .pipe(
        catchError((err: any): Observable<any> => {
          this.toastr.error('Please retry!', 'Could not get rewards');
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
        map((res: any) => {
          let unclaimedRewards: Reward[] = undefined;
          let claimedRewards: ClaimedReward[] = undefined;
          let referralsLoanVolume = undefined;
          let totalClaimedRewardsUsd = 0;
          let totalUnclaimedRewardsUsd = 0;
          const rewardCodes = REWARD_CODES;
          if (res.data.unclaimedRewards && res.data.unclaimedRewards.length > 0) {
            unclaimedRewards = res.data.unclaimedRewards.map((reward: Reward) => {
              const rewardFormat = {
                rewardCode: rewardCodes[reward.rewardCode],
                rewardedAt: reward.createdAt,
                status: reward.status,
                weiValue: reward.rewardValue,
                value: this.currenciesService.weiToCurrency(reward.rewardValue, reward.Currency),
                usdValue: this.currenciesService.weiToCurrency(reward.rewardValue, reward.Currency, 'USD'),
                Currency: reward.Currency
              };
              totalUnclaimedRewardsUsd += rewardFormat.usdValue;
              return rewardFormat;
            });
          }

          if (res.data.claimedRewardsHistory && res.data.claimedRewardsHistory.length > 0) {
            claimedRewards = res.data.claimedRewardsHistory.map(claimedReward => {
              totalClaimedRewardsUsd = 0;
              return {
                id: claimedReward.id,
                createdAt: claimedReward.createdAt,
                signature: claimedReward.signature,
                status: claimedReward.status,
                receiver: claimedReward.receiver,
                rewards: claimedReward.rewards.map((reward: Reward) => {
                  const rewardFormat = {
                    rewardCode: rewardCodes[reward.rewardCode],
                    rewardedAt: reward.createdAt,
                    status: reward.status,
                    weiValue: reward.rewardValue,
                    value: this.currenciesService.weiToCurrency(reward.rewardValue, reward.Currency),
                    usdValue: this.currenciesService.weiToCurrency(reward.rewardValue, reward.Currency, 'USD'),
                    currency: reward.Currency
                  };
                  totalClaimedRewardsUsd += rewardFormat.usdValue;
                  return rewardFormat;
                }),
                total: totalClaimedRewardsUsd
              };
            });
          }

          if (res.data.referralsLoanVolume && res.data.referralsLoanVolume.length > 0) {
            referralsLoanVolume = res.data.referralsLoanVolume.map(loan => ({
              month: loan.month,
              year: loan.year,
              loanCount: loan.loanCount,
              loanVolume: loan.loanVolume,
              decimalPlaces: loan.decimalPlaces,
              exchangeRate: loan.exchangeRate
            }));
          }
          return { unclaimedRewards, claimedRewards, referralsLoanVolume, totalClaimedRewardsUsd, totalUnclaimedRewardsUsd };
        }),
        takeUntil(this.destroy$),
        tap((res: any) => {
          if (res) {
            this.store.dispatch(RewardsActions.getRewardsData(res));
          }
        }));
  }

  getStakingRewards(ownerAddress: string, isStaking = true) {
    this.apollo.query({
      query: fixedIncomeFundsQuery,
      variables: {
        where: {
          ownerAddress,
          isStaking
        }
      }
    }).pipe(
      catchError((err: any): Observable<any> => {
        this.toastr.error('Please retry!', 'Could not get staking rewards');
        if (!environment.production) console.log(err);
        return EMPTY;
      }),
      map((res: any) => res.data.fixedIncomeFunds[0]), takeUntil(this.destroy$))
      .subscribe((fif: FixedIncomeFund) => {
        if (fif) {
          this.apollo.query({
            query: claimableStakingRewards,
            variables: {
              contractAddress: fif.contractAddress
            }
          }).pipe(
            catchError((err: any): Observable<any> => {
              if (!environment.production) console.log(err);
              return EMPTY;
            }),
            map((res: any) => res.data.claimableStakingRewards))
            .subscribe((claimableStakingRewards: StakingReward[]) => {
              let totalStakingRewards: string = '0';
              claimableStakingRewards.forEach((reward: StakingReward) => {
                totalStakingRewards = new BigNumber(totalStakingRewards).plus(reward.amount).toNumber().toLocaleString('fullwide', { useGrouping: false });
              })
              this.store.dispatch(RewardsActions.getStakingRewardsData({ stakingRewards: claimableStakingRewards, totalStakingRewards, stakingContract: fif.contractAddress }));
            })
        }
      })
  }

  claimRewards(walletAddress: string): void {
    const popup: DialogRef<any> = this.dialog.open(ClaimingRewardsDialog, {
      id: 'claiming-rewards', ...DEFAULT_DIALOG_CONFIG, disableClose: true, data: { claiming: true }
    });
    this.apollo.mutate({
      mutation: claimRewards,
      variables: {
        walletAddress
      }
    })
      .pipe(
        catchError((err: any): Observable<any> => {
          this.toastr.error('Please retry!', 'Could not claim rewards');
          if (!environment.production) console.log(err);
          popup.close();
          return EMPTY;
        }),
        map((res: any) => res.data.claimReward),
        takeUntil(this.destroy$)
      ).subscribe((res: ClaimedReward) => {
        const claimedReward: ClaimedReward = this.formatClaimedReward(res);
        let signatureCreated$ = new Subject<void>();
        interval(3000)
          .pipe(takeUntil(merge(this.destroy$, signatureCreated$)), take(10))
          .subscribe(() => {
            this.apollo.query({
              query: getClaimedRewardsHistory,
              variables: {
                walletAddress
              },
              fetchPolicy: 'no-cache',
            })
              .subscribe((res: any) => {
                if (res.data.claimedRewardsHistory.length !== 0) {
                  const claimed: ClaimedReward = res.data.claimedRewardsHistory.find((r: ClaimedReward) => r.id === claimedReward.id);
                  if (claimed.status === 'READY FOR REDEEM') {
                    popup.componentInstance['claiming'] = false;
                    signatureCreated$.next();
                    signatureCreated$.complete();
                    const formattedReward = this.formatClaimedReward(claimed);
                    this.toastr.info('Please complete the transaction to redeem them to your wallet!', 'Rewards claimed');
                    this.store.dispatch(RewardsActions.clearUnclaimed({ unclaimedRewards: [], totalUnclaimedRewardsUsd: '0' }));
                    this.store.dispatch(RewardsActions.addRedeemedReward({ claimedReward: formattedReward }));
                    popup.close();
                    this.redeemReward(formattedReward);
                  }
                }
              })
          })
      })
  }

  claimStakingRewards(contractAddress: string) {
    this.apollo.mutate({
      mutation: claimStakingRewards,
      variables: {
        contractAddress,
      },
      fetchPolicy: 'no-cache'
    }).pipe(
      catchError((err: any): Observable<any> => {
        this.toastr.error('Please retry!', 'Could not claim staking rewards');
        if (!environment.production) console.log(err);
        return EMPTY;
      }),
      map((res: any) => res.data.claimStakingReward),
      takeUntil(this.destroy$))
      .subscribe((claimedReward: ClaimedReward) => {
        this.store.dispatch(RewardsActions.getStakingRewardsData({ stakingRewards: [], totalStakingRewards: '0', stakingContract: contractAddress }));
        this.store.dispatch(RewardsActions.addRedeemedReward({ claimedReward }));
        this.redeemReward(claimedReward);
        this.toastr.info('Please complete the transaction to redeem them to your wallet!', 'Rewards claimed');
      })
  }

  formatClaimedReward(claimedReward: ClaimedReward) {
    const rewardCodes = REWARD_CODES;
    let totalClaimedRewardsUsd = 0;
    return {
      id: claimedReward.id,
      createdAt: claimedReward.createdAt,
      signature: claimedReward.signature,
      status: claimedReward.status,
      receiver: claimedReward.receiver,
      rewards: claimedReward.rewards.map((reward: Reward) => {
        const rewardFormat = {
          rewardCode: rewardCodes[reward.rewardCode],
          rewardedAt: reward.createdAt,
          status: reward.status,
          weiValue: reward.rewardValue,
          value: this.currenciesService.weiToCurrency(reward.rewardValue, reward.Currency ? reward.Currency : reward.currency),
          usdValue: this.currenciesService.weiToCurrency(reward.rewardValue, reward.Currency ? reward.Currency : reward.currency, 'USD'),
          Currency: reward.Currency ? reward.Currency : reward.currency
        }
        totalClaimedRewardsUsd += rewardFormat.usdValue;
        return rewardFormat;
      }),
      total: totalClaimedRewardsUsd
    }
  }

  redeemReward(claimedReward: ClaimedReward) {
    const popup: DialogRef<any> = this.dialog.open(ClaimingRewardsDialog, {
      id: 'claiming-rewards', ...DEFAULT_DIALOG_CONFIG, disableClose: true, data: { claiming: false }
    });
    this.store.dispatch(RewardsActions.redeemingRewards({ isRedeeming: true }));
    this.web3Service.redeemRewards(claimedReward)
      .pipe(catchError((err: any): Observable<any> => {
        this.toastr.error('Please retry!', 'Could not redeem rewards');
        this.store.dispatch(RewardsActions.redeemingRewards({ isRedeeming: false }));
        if (!environment.production) console.log(err);
        popup.close();
        return EMPTY;
      }), takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.toastr.info('', 'Rewards redeemed to wallet successfully');
        claimedReward = {
          ...claimedReward,
          status: 'REWARDS REDEEMED'
        }
        this.store.dispatch(RewardsActions.updateRedeemedReward({ claimedReward }));
        this.store.dispatch(RewardsActions.redeemingRewards({ isRedeeming: false }));
        popup.close();
      })
  }

  // was fetchWeeklyRewards in old FE
  getLastWeekRewards(): void {
    const startDate = dayjs().startOf('isoWeek').subtract(7, 'd').toDate();
    const endDate = dayjs().startOf('isoWeek').subtract(1, 's').toDate();
    this.apollo
      .query({
        query: borrowerLenderWeeklyRewards,
        variables: {
          startDate,
          endDate
        },
      })
      .pipe(
        catchError((err: any): Observable<any> => {
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
        take(1), map((res: any) => res.data.borrowerLenderWeeklyRewards[0]))
      .subscribe((lastWeekRewards: any) => {
        this.store.dispatch(BorrowingActions.getLastWeekRewards({ lastWeekRewards }));
      });
  }

  getUserRewardTypes(walletAddress: string, underying: Currency, loanData: LoanData): void {
    const loanAmountInUsd = loanData.loanAmount * underying.exchangeRate;
    const requestOver1k = loanAmountInUsd > 1000;
    let allRewards: any;
    let loanOver1kAwarded: boolean;
    if (walletAddress) {
      this.apollo
        .query({
          query: getUserRewardTypes,
          variables: {
            walletAddress
          }
        })
        .pipe(catchError((err: any): Observable<any> => {
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
          map((res: any) => res.data),
          takeUntil(this.destroy$))
        .subscribe((res: any) => {
          const unclaimedRewards = res.unclaimedRewards || [];
          const claimedRewards = res.claimedRewardsHistory || [];
          if (claimedRewards.length > 0) {
            allRewards = (unclaimedRewards || []).concat(claimedRewards[0].rewards || []);
          } else {
            allRewards = (unclaimedRewards || []);
          }
          if (requestOver1k) {
            loanOver1kAwarded = !!allRewards.find((r: any) => r.rewardCode === 'REFEREE10');
          } else {
            loanOver1kAwarded = true;
          }
          this.store.dispatch(BorrowingActions.updateLoanOver1kAwarded({ loanOver1kAwarded }))
        })
    } else {
      loanOver1kAwarded = !requestOver1k;
      this.store.dispatch(BorrowingActions.updateLoanOver1kAwarded({ loanOver1kAwarded }))
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
