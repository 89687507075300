import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { KycInfo } from "src/app/shared/interfaces/user/kyc-info";
import { MENU_ICONS, GENERAL_ICONS } from "src/assets/images/svg-icons";

@Component({
  selector: "app-kyc",
  templateUrl: "./kyc.component.html",
  styleUrls: ["./kyc.component.scss"],
})
export class KycComponent implements OnInit {
  @Output() kycSubmitted: EventEmitter<any> = new EventEmitter();

  public upload = MENU_ICONS.upload;
  public icons = GENERAL_ICONS
  public acceptedTypes: Array<string> = ["jpg", "jpeg", "png"];
  public proofOfId: any;
  public proofOfAddress: any;
  public kycForm: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.kycForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      birthday: ['', Validators.required],
      country: ['', Validators.required],
      addressOne: ['', Validators.required],
      addressTwo: ['', Validators.required],
      proofOfId: ['', Validators.required],
      proofOfAddress: ['', Validators.required]
    })
  }

  onKycSubmit() {
    this.kycSubmitted.emit(this.kycForm.value as KycInfo);
  }

  handleUploadProofOfId(e: any) {
    const target = e.target as HTMLInputElement;
    if (target.files && target.files.length > 0 && this.acceptedTypes.includes(target.files[0].name.split(".").pop())) {
      this.proofOfId = target.files[0];
      this.kycForm.patchValue({
        proofOfId: this.proofOfId,
      });
    }
  }

  handleUploadProofOfAddress(e: any) {
    const target = e.target as HTMLInputElement;
    if (target.files && target.files.length > 0 && this.acceptedTypes.includes(target.files[0].name.split(".").pop())) {
      this.proofOfAddress = target.files[0];
      this.kycForm.patchValue({
        proofOfAddress: this.proofOfAddress,
      });
    }
  }
}
