import gql from "graphql-tag";

export const createFixedIncomeFund = gql`
  mutation (
    $ownerAddress: String!
    $fundCurrency: String!
    $investmentRatios: [Float]!
  ) {
    createFixedIncomeFund(
      input: {
        data: {
          ownerAddress: $ownerAddress
          fundCurrency: $fundCurrency
          investmentRatios: $investmentRatios
        }
      }
    ) {
      fixedIncomeFund {
        id
        contractAddress
        underlying {
          ethAddress
        }
      }
    }
  }
`;

export const updateFixedIncomeFund = gql`
  mutation UpdateFixedIncomeFundStatus(
    $contractAddress: String!
    $status: String!
  ) {
    updateFixedIncomeFundStatus(
      contractAddress: $contractAddress
      status: $status
    ) {
      id
      contractAddress
      status
      updatedAt
    }
  }
`;

export const lendingFormInit = gql`
  query {
    paramsInformation {
      riskFreeRateLogBase
    }
  }
`;

export const fixedIncomeFundsQuery = gql`
  query ($where: JSON) {
    fixedIncomeFunds(where: $where) {
      id
      ownerAddress
      contractAddress
      status
      isStaking
      createdAt
      updatedAt
      underlying {
        symbol
        name
        ethAddress
        exchangeRate
        isUnderlying
        isCollateral
        decimalPlaces
        riskFreeRate
      }
      buckets {
        index
        maxTerm
        minTerm
        investmentRatio
        activeInvestedAmount
        totalInvestedAmount
      }
    }
  }
`;

export const fixedIncomeFundQuery = gql`
  query ($id: ID!) {
    fixedIncomeFund(id: $id) {
      id
      ownerAddress
      contractAddress
      status
      createdAt
      updatedAt
      underlying {
        symbol
        name
        ethAddress
        exchangeRate
        isUnderlying
        isCollateral
        decimalPlaces
      }
      buckets {
        index
        maxTerm
        minTerm
        investmentRatio
        activeInvestedAmount
        totalInvestedAmount
      }
    }
  }
`;

export const terminateFixedIncomeFund = gql`
  query ($id: ID!) {
    terminateFixedIncomeFund(id: $id) {
      id
      ownerAddress
      status
    }
  }
`;
