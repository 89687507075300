import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { interval, Subject, take, takeUntil, timer } from 'rxjs';
import { ArticlesService } from 'src/app/core/services/articles.service';
import { AppState } from 'src/app/store/app.reducer';
import { LOGO } from 'src/assets/images/svg-icons';
import { Article } from '../interfaces/articles/article';

@Component({
  selector: 'app-error-page',
  template: `
  <div class="text-center">
    <sc-icon [src]="logo" [height]="'40px'" [width]="'200px'" class="flex justify-center mb-6 mt-0 md:mt-6"></sc-icon>
    <h1 class="mb-1 mt-36 md:mt-20">We are experiencing some temporary server issues.</h1>
    <p class="text-slate-500 mb-36 md:mb-20er">Our team is working hard to resolve them. Please try again later!</p>
    <p class="mt-10 text-slate-500">In the meantime, you might find these articles helpful:</p>
    <section class="px-2 md:px-0">
      <shared-title title="" type="h2">
      </shared-title>
      <div *ngIf="articles && articles.length" class="flex max-w-full gap-6 flex-wrap md:flex-nowrap">
        <ng-container *ngFor="let article of articles; let i = index">
          <shared-article *ngIf="i < 4" class="w-full md:w-1/4" [title]="article.title" [image]="article.image"
          [url]="article.url"></shared-article>
        </ng-container>
      </div>
    </section>
  </div>
  `,
  styles: [``]
})
export class ErrorPageComponent implements OnInit, OnDestroy {
  public articles: Article[];
  public error: string;
  public logo = LOGO.main;
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private articlesService: ArticlesService,
    private store: Store<AppState>,
  ) {
    this.articlesService.getArticles ? this.articlesService.getArticles() : false;
  }

  ngOnInit(): void {
    this.store.select('dashboard')
    .pipe(takeUntil(this.destroy$))
    .subscribe((dashboardStore => {
      this.articles = dashboardStore.articles;
      timer(20000)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(() => {
        this.router.navigate(['/']);
      })
    }))
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
