import { Component } from '@angular/core';
import { Web3Service } from './core/web3/web3.service';
import { Subscription, take } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UserService } from './core/services/user.service';

declare global { const showMtpModal: ({ }) => {}; }

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'BarakaFi - Fixed Term & Fixed Interest Rate for Borrowers, Fixed Income Funds for Lenders';
  constructor(private web3Service: Web3Service,
    private toastr: ToastrService,
    private userService: UserService
  ) {
    this.web3Service.networkChanged$.subscribe((chainId) => {
      this.toastr.warning("Please connect wallet again", "Network changed");

      this.userService.logOut();
      this.web3Service.disconnectWallet().pipe(take(1)).subscribe();
    });
  }

}
