import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { DashboardStats } from 'src/app/shared/interfaces/statistics/dashboard-stats';
import * as DashboardActions from 'src/app/dashboard/store/dashboard.actions';
import * as fromApp from '../../store/app.reducer';
import { Apollo } from 'apollo-angular';
import { statsQuery } from '../graphql/statsTypes';
import { catchError, EMPTY, map, Observable, startWith, Subject } from 'rxjs';
import { makeStateKey, TransferState } from '@angular/core';
import { environment } from 'src/environments/environment';

const STATS_KEY = makeStateKey<any>('stats');

@Injectable({
  providedIn: 'root'
})
export class StatsService implements OnDestroy {
  public dashboardStats: DashboardStats;
  public fullSchema: any;
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<fromApp.AppState>,
    private apollo: Apollo,
    private state: TransferState,
  ) { }

  getDashboardStats() {
    const exists = this.state.get(STATS_KEY, {} as any);
    this.apollo.watchQuery({ query: statsQuery }).valueChanges
      .pipe(
        catchError((err: any): Observable<any> => {
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
        map((res: any) => {
          const response: DashboardStats = {
            getTVL: {
              borrowingTVL: Number(res.data.getTVL.borrowingTVL),
              lendingTVL: Number(res.data.getTVL.lendingTVL),
              platformTVL: Number(res.data.getTVL.platformTVL)
            },
            borrowerLenderWeeklyRewards: {
              boosterTokens: res.data.borrowerLenderWeeklyRewards.length > 0 ? Number(res.data.borrowerLenderWeeklyRewards[0].boosterTokens) : 0,
              borrowerRewards: res.data.borrowerLenderWeeklyRewards.length > 0 ? Number(res.data.borrowerLenderWeeklyRewards[0].borrowerRewards) : 0,
              lenderRewards: res.data.borrowerLenderWeeklyRewards.length > 0 ? Number(res.data.borrowerLenderWeeklyRewards[0].lenderRewards) : 0,
              id: res.data.borrowerLenderWeeklyRewards.length > 0 ? res.data.borrowerLenderWeeklyRewards[0].id : 0,
            },
            borrowingStatistics: {
              totalLockedCollateralVolumeInUSD: res.data.borrowingStatistics.totalLockedCollateralVolumeInUSD,
              totalBorrowedVolumeInUSD: res.data.borrowingStatistics.totalBorrowedVolumeInUSD,
              totalBorrowedVolumeInPeriod: res.data.borrowingStatistics.totalBorrowedVolumeInPeriod,
              totalNumberOfLoansInPeriod: res.data.borrowingStatistics.totalNumberOfLoansInPeriod,
              interestRateInfo: res.data.borrowingStatistics.interestRateInfo
            },
            rewardConfigData: {
              refereeReward: res.data.rewardConfigData.find(item => item.key === 'REFEREE_REWARD_TOKEN_AMOUNT').value,
              referrerReward: res.data.rewardConfigData.find(item => item.key === 'REFERRER_REWARD_TOKEN_AMOUNT').value,
              loanValue: res.data.rewardConfigData.find(item => item.key === 'LOAN_VALUE_IN_USD').value,
              rewardToken: res.data.rewardConfigData.find(item => item.key === 'BORROW_REWARD_TOKEN').value
            }
          }
          return response;
        }),
        startWith(exists)
      )
      .subscribe((stats: DashboardStats) => {
        this.state.set(STATS_KEY, stats);
        this.store.dispatch(DashboardActions.getStats({ stats }))
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
