import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BorrowingRoutingModule } from './borrowing-routing.module';
import { BorrowComponent } from './pages/borrow/borrow.component';
import { BorrowingDashboardComponent } from './pages/borrowing-dashboard/borrowing-dashboard.component';
import { LoanComponent } from './pages/loan/loan.component';
import { CreditLineComponent } from './pages/credit-line/credit-line.component';
import { SharedModule } from '../shared/shared.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BorrowFormSummaryComponent } from './components/borrow-form-summary/borrow-form-summary.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DepositCollateralDialog } from './components/deposit-collateral/deposit-collateral.dialog';
import { CreateLoanDialog } from './components/create-loan/create-loan.dialog';
import { TerminateLoanDialog } from './components/terminate-loan/terminate-loan.dialog';
import { CollateralActionsDialog } from './components/collateral-actions/collateral-actions.dialog';
import { IncreaseCollateralDialog } from './components/increase-collateral/increase-collateral.dialog';
import { TrustScoreInfoDialog } from './components/trust-score-info/trust-score-info.dialog';


@NgModule({
  declarations: [
    BorrowComponent,
    BorrowingDashboardComponent,
    LoanComponent,
    CreditLineComponent,
    BorrowFormSummaryComponent,
    DepositCollateralDialog,
    CreateLoanDialog,
    TerminateLoanDialog,
    CollateralActionsDialog,
    IncreaseCollateralDialog,
    TrustScoreInfoDialog,
  ],
  imports: [
    CommonModule,
    BorrowingRoutingModule,
    SharedModule,
    OverlayModule,
    ReactiveFormsModule,
    FormsModule,
    NgxChartsModule,
  ],
  providers: []
})
export class BorrowingModule { }
