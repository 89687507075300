import { Component, Input } from '@angular/core';
import { GENERAL_ICONS } from 'src/assets/images/svg-icons';

@Component({
  selector: 'shared-title',
  template: `
  <div class="flex flex-col md:flex-row items-center justify-between gap-4 mb-4">
    <div class="min-w-full md:min-w-fit flex items-center">
      <div>
        <h1 *ngIf="!type || type === 'h1'" class="font-semibold mb-0 text-slate-700">{{ title }}</h1>
        <h2 *ngIf="type === 'h2'" class="font-semibold text-slate-700">{{ title }}</h2>
        <h3 *ngIf="type === 'h3'" class="font-semibold text-slate-700">{{ title }}</h3>
        <h4 *ngIf="type === 'h4'" class="font-semibold text-slate-700">{{ title }}</h4>
        <p *ngIf="subtitle" class="text-subtitle">{{ subtitle }}</p>
        <p *ngIf="address" class="text-subtitle flex items-center"  [ngClass]="{'animate-bounce': pulse}">
          {{ address | shortAddress }}
          <button [cdkCopyToClipboard]="address" (click)="setPulse()" class="nostyle h-[0.8rem]" title="Copy to Clipboard">
            <sc-icon [src]="icons.copy" [size]="'0.325'" [width]="'0.8rem'" [height]="'0.8rem'" [classes]="'-mt-3'"></sc-icon>
          </button>
        </p>
      </div>
      <div *ngIf="status" class="pill sm ml-4"
      [ngClass]="{'default': status === 'TERMINATED' || status === 'REPAID',  'primary': status === 'ACTIVE LOAN'|| status === 'NEW REQUEST' || status === 'MATCHED',  'success': status === 'ACTIVE', 'danger': status === 'BAD LOAN' || status === 'BADLOAN REPAID' }"
      >{{ status | titlecase }}</div>
    </div>
    <ng-content></ng-content>
  </div>`
})

export class PageTitleComponent {
  public pulse: boolean;
  public icons = GENERAL_ICONS;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() address: string
  @Input() type: string;
  @Input() status: string;

  setPulse() {
    this.pulse = true;
    setTimeout(() => {
      this.pulse = false;
    }, 250);
  }
}
