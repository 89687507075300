import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import * as BorrowingActions from 'src/app/borrowing/store/borrowing.actions';
import * as CoreActions from 'src/app/core/store/core.actions';
import * as RewardsActions from 'src/app/rewards/store/rewards.actions';
import * as LendingActions from 'src/app/lending/store/lending.actions';
import * as StakingActions from 'src/app/staking/store/staking.actions';
import * as DashboardActions from 'src/app/dashboard/store/dashboard.actions';
import * as LeveragingActions from 'src/app/leveraging/store/leveraging.actions';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
  ) { }

  // Logs the user out by removing the token from local storage and resetting the application state
  logOut() {
    this.router.navigate(['/'])
    const agreement = localStorage.getItem('onboard.js:agreement');
    const cookieConsent = localStorage.getItem('cookieConsent');
    localStorage.clear();
    if (agreement) localStorage.setItem('onboard.js:agreement', agreement);
    if (cookieConsent) localStorage.setItem('cookieConsent', cookieConsent);
    this.store.dispatch(BorrowingActions.resetState());
    this.store.dispatch(CoreActions.resetState());
    this.store.dispatch(RewardsActions.resetState());
    this.store.dispatch(LendingActions.resetState());
    this.store.dispatch(StakingActions.resetState());
    this.store.dispatch(DashboardActions.resetState());
    this.store.dispatch(LeveragingActions.resetState());
  }
}
