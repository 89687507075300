import { Action, createReducer, on } from "@ngrx/store";
import { FixedIncomeFund } from "src/app/shared/interfaces/fifs/fixedIncomeFund";
import { Cooldown } from "src/app/shared/interfaces/stakings/cooldown";
import { Staking } from "src/app/shared/interfaces/stakings/staking";
import * as StakingActions from "./staking.actions";

export interface AppState {
  staking: State;
}

export interface State {
  stakingFifs: FixedIncomeFund[],
  isStaking: boolean,
  isWithdrawing: boolean,
  isStartingCooldown: boolean,
  isCreatingFif: boolean,
  walletBalance: string
  cooldowns: Cooldown[]
}

const initialState: State = {
  stakingFifs: undefined,
  isStaking: undefined,
  isWithdrawing: undefined,
  isStartingCooldown: undefined,
  isCreatingFif: undefined,
  walletBalance: undefined,
  cooldowns: undefined
};

const _stakingReducer = createReducer(
  initialState,
  on(StakingActions.getAllStakings, (state, action) => {
    let stakingFund = state.stakingFifs ? state.stakingFifs.find(fif => fif.id === action.stakingFund.id) : action.stakingFund;
    stakingFund = { ...stakingFund, stakings: action.stakings }
    return {
      ...state,
      stakingFifs: [
        ...(state.stakingFifs ? state.stakingFifs.filter(fif => fif.id !== action.stakingFund.id) : []),
        stakingFund
      ]
    }
  }),
  on(StakingActions.getAllRewards, (state, action) => {
    let stakingFund = state.stakingFifs ? state.stakingFifs.find(fif => fif.id === action.stakingFund.id) : action.stakingFund;
    stakingFund = { ...stakingFund, rewards: action.allRewards }
    return {
      ...state,
      stakingFifs: [
        ...(state.stakingFifs ? state.stakingFifs.filter(fif => fif.id !== action.stakingFund.id) : []),
        stakingFund
      ]
    }
  }),
  on(StakingActions.getStakingFifs, (state, action) => ({
    ...state,
    stakingFifs: action.fixedIncomeFunds,
  })),
  on(StakingActions.getStakingFif, (state, action) => ({
    ...state,
    stakingFifs: [action.fixedIncomeFund]
  })),
  on(StakingActions.updateButtons, (state, action) => ({
    ...state,
    isStaking: action.isStaking,
    isWithdrawing: action.isWithdrawing,
    isStartingCooldown: action.isStartingCooldown,
    isCreatingFif: action.isCreatingFif
  })),
  on(StakingActions.updateStakingFif, (state, action) => {
    const stakingFifs = state.stakingFifs.map(fif => {
      if (fif.id === action.stakingFif.id) {
        return action.stakingFif;
      } else {
        return fif;
      }
    });
    return {
      ...state,
      stakingFifs
    }
  }),
  on(StakingActions.updateCooldown, (state, action) => ({
    ...state,
    cooldowns:
      state.cooldowns ? state.cooldowns.map(cooldown => {
        if (cooldown.contractAddress === action.cooldown.contractAddress) {
          return action.cooldown;
        } else {
          return cooldown;
        }
      }) : [action.cooldown]
  })),
  on(StakingActions.updateBalances, (state, action) => ({
    ...state,
    stakingFifs: state.stakingFifs.map(fif => {
      if (fif.contractAddress === action.stakingFund.contractAddress) {
        return {
          ...fif,
          stakedUnlocked: action.balances.stakedUnlocked ? action.balances.stakedUnlocked : fif.stakedUnlocked,
          stakedLocked: action.balances.stakedLocked ? action.balances.stakedLocked : fif.stakedLocked,
          gaslessRestaked: action.balances.gaslessRestaked ? action.balances.gaslessRestaked : fif.gaslessRestaked,
          gaslessRestakedUnlocked: action.balances.gaslessRestakedUnlocked ? action.balances.gaslessRestakedUnlocked : fif.gaslessRestakedUnlocked,
          gaslessRestakedLocked: action.balances.gaslessRestakedLocked ? action.balances.gaslessRestakedLocked : fif.gaslessRestakedLocked,
        }
      } else {
        return fif;
      }
    })
  })),
  on(StakingActions.updateRewardBalances, (state, action) => ({
    ...state,
    stakingFifs: state.stakingFifs.map(fif => {
      if (fif.contractAddress === action.stakingFund.contractAddress) {
        return {
          ...fif,
          totalRewards: action.rewardBalances.totalRewards ? action.rewardBalances.totalRewards : fif.totalRewards,
          totalClaimable: action.rewardBalances.totalClaimable ? action.rewardBalances.totalClaimable : fif.totalClaimable,
          thisWeekRewards: action.rewardBalances.thisWeekRewards ? action.rewardBalances.thisWeekRewards : fif.thisWeekRewards,
          lastWeekRewards: action.rewardBalances.lastWeekRewards ? action.rewardBalances.lastWeekRewards : fif.lastWeekRewards,
          percentageChange: action.rewardBalances.percentageChange ? action.rewardBalances.percentageChange : fif.percentageChange
        }
      } else {
        return fif;
      }
    })
  })),
  on(
    StakingActions.resetState,
    () => initialState
  )

);
export function stakingReducer(state: State, action: Action) {
  return _stakingReducer(state, action);
}
