import { IndividualConfig } from "ngx-toastr"
import { FifBucket } from "./interfaces/fifs/fif-bucket"
import { UnclaimedRewards } from "./interfaces/rewards/unclaimed-rewards"

export const rewards = {
  STAKING_REWARDS: 4327,
  BORROWER_LENDER_WEEKLY_REWARDS: 3158,
}

export const config = {
  LIQUIDATION_NOTICE_THRESHOLD: 0.15,
  DEPEG_THRESHOLD: 3
}

export const currencies = {
  UNISWAP_CURRENCIES: ['ORION', 'ID', 'PNT', 'PBTC', 'SMARTCREDIT', 'EPAN'],
  STABLECOINS: ['USDT', 'USDC', 'FRAX', 'DAI'],
}

export const DEFAULT_DIALOG_CONFIG = {
  minWidth: '350px',
  maxWidth: '350px',
  autoFocus: false,
}

export const WEB3_TOASTR_CONFIG: Partial<IndividualConfig> = {
  closeButton: false,
  progressBar: true,
  progressAnimation: 'increasing',
  tapToDismiss: false,
  timeOut: 120000,
  disableTimeOut: 'extendedTimeOut'
}

export const BUCKETS: FifBucket[] = [
  {
  index: 1,
  maxTerm: 30,
  minTerm: 7,
  investmentRatio: 0,
  activeInvestedAmount: 0,
  totalInvestedAmount: 0,
  name: '7-30 days',
  min: 0,
  max: 100
},
 {
  index: 0,
  maxTerm: 60,
  minTerm: 31,
  investmentRatio: 0,
  activeInvestedAmount: 0,
  totalInvestedAmount: 0,
  name: '31-60 days',
  min: 0,
  max: 100
}, {
  index:0,
  maxTerm: 90,
  minTerm: 61,
  investmentRatio: 0,
  activeInvestedAmount: 0,
  totalInvestedAmount: 0,
  name: '61-90 days',
  min: 0,
  max: 100
}, {
  index: 0,
  maxTerm: 180,
  minTerm: 91,
  investmentRatio: 0,
  activeInvestedAmount: 0,
  totalInvestedAmount: 0,
  name: '91-180 days',
  min: 0,
  max: 100
}]

export const REWARD_CODES = {
  BORROWER_WEEKLY_REWARD: 'Borrower Weekly Reward',
  LENDER_WEEKLY_REWARD: 'Lender Weekly Reward',
  REFERRER10: 'Referrer Reward',
  REFEREE10: 'First Loan Over $1000',
  STAKING_REWARDS: 'Withdrawn Gasless Re-Staked'
}

export const UNCLAIMED_REWARDS_INITIAL: UnclaimedRewards = {
  ISLM: {
    total: 0,
    totalUsd: 0,
    rewards: []
  },
  USDT: {
    total: 0,
    totalUsd: 0,
    rewards: []
  },
  USDC: {
    total: 0,
    totalUsd: 0,
    rewards: []
  },
  DAI: {
    total: 0,
    totalUsd: 0,
    rewards: []
  },
  SMARTCREDIT: {
    total: 0,
    totalUsd: 0,
    rewards: []
  }
};
