<div class="rounded-3xl border-slate-200 bg-white p-4">
  <div class="flex justify-between items-center mb-4">
    <h3 class="mb-0 mr-2 truncate">Fund Distribution</h3>
    <button class="default sm" (click)="onClose()">Close</button>
  </div>
  <div class="flex flex-col">
    <p class="font-bold text-sm mb-2">
      Highest APY
      <span class="font-normal text-sm"
        >distributes funds according to estimated APY calculations to get you
        the highest Annual Percentage Yield.</span
      >
    </p>
    <p class="font-bold text-sm mb-2">
      Equal Distribution
      <span class="font-normal text-sm"
        >distributes 25% of the funds in each bucket.</span
      >
    </p>
    <p class="font-bold text-sm mb-2">
      Fixed Rate
      <span class="font-normal text-sm"
        >invests your funds in fixed-rate buckets (7-30, 31-60, 61-90 and 91-180
        days) where your funds are lent automatically to BarakaFi borrowers. To
        withdraw the funds you must wait for any active loan to complete.</span
      >
    </p>
    <p class="font-bold text-sm mb-2">
      Variable Rate
      <span class="font-normal text-sm"
        >invests your funds in the first bucket (7-30 days). You can withdraw
        funds from this bucket immediately.</span
      >
    </p>
    <p class="font-bold text-sm mb-2">
      Advanced Mode
      <span class="font-normal text-sm"
        >lets you choose the allocations manually.</span
      >
    </p>
  </div>
</div>
