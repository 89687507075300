import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LendingRoutingModule } from './lending-routing.module';
import { LendComponent } from './pages/lend/lend.component';
import { FifsComponent } from './pages/fifs/fifs.component';
import { FifComponent } from './pages/fif/fif.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FifsTableComponent } from './components/fifs-table/fifs-table.component';

import { OverlayModule } from '@angular/cdk/overlay';
import { FifsStatsComponent } from './components/fifs-stats/fifs-stats.component';
import { CdkListboxModule } from '@angular/cdk/listbox';
import { DepositFifDialog } from './components/deposit-fif-dialog/deposit-fif.dialog';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ReallocateDialog } from './components/reallocate/reallocate.dialog';
import { DistributionInfoDialog } from './components/distribution-info/distribution-info.dialog';

@NgModule({
  declarations: [
    LendComponent,
    FifsComponent,
    FifComponent,
    FifsTableComponent,
    FifsStatsComponent,
    DepositFifDialog,
    ReallocateDialog,
    DistributionInfoDialog,
  ],
  imports: [
    CommonModule,
    LendingRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    OverlayModule,
    CdkListboxModule,
    ClipboardModule
  ]
})
export class LendingModule { }
