import { gql } from 'apollo-angular';

export const statsQuery = gql`query {
  getTVL {
    borrowingTVL,
    lendingTVL,
    platformTVL
  },
  borrowingStatistics {
    totalBorrowedVolumeInUSD
    totalLockedCollateralVolumeInUSD
    totalBorrowedVolumeInPeriod
    totalNumberOfLoansInPeriod
    interestRateInfo {
      totalInterest
      underlyingSymbol
    }
  },
  borrowerLenderWeeklyRewards {
    id,
    borrowerRewards,
    lenderRewards,
    boosterTokens
  },
  rewardConfigData {
    key,
    value
  }
}`;
