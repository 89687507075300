import { createAction, props } from "@ngrx/store";
import { FixedIncomeFund } from "src/app/shared/interfaces/fifs/fixedIncomeFund";

export const getFixedIncomeFunds = createAction(
  'getFixedIncomeFunds',
  props<{
    fixedIncomeFunds: any
  }>()
)

export const getFixedIncomeFund = createAction(
  'getFixedIncomeFund',
  props<{
    fixedIncomeFund: any
  }>()
)

export const depositingFif = createAction(
  'depositingFif',
  props<{
    depositingFif: any
  }>()
)

export const withdrawingFif = createAction(
  'withdrawingFif',
  props<{
    withdrawingFif: any
  }>()
)

export const terminatingFif = createAction(
  'terminatingFif',
  props<{
    terminatingFif: any
  }>()
)

export const creatingFif = createAction(
  'creatingFif',
  props<{
    creatingFif: any
  }>()
)

export const reallocatingFif = createAction(
  'reallocatingFif',
  props<{
    reallocatingFif: boolean
  }>()
)

export const updateFif = createAction(
  'updateFif',
  props<{
    fixedIncomeFund: FixedIncomeFund
  }>()
)

export const updateRiskFreeRateLogBase = createAction(
  'updateRiskFreeRateLogBase',
  props<{
    riskFreeRateLogBase: number
  }>()
)

export const resetState = createAction(
  'resetState'
)
