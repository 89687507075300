<div class="bg-white rounded-2xl">
  <div class="flex justify-between items-center mb-4 pt-4 px-4">
    <h3 class="mb-0 mr-2 truncate">Exchange</h3>
    <button class="default sm" (click)="onClose()">Close</button>
  </div>
  <div
    class="lets-widget"
    id="lets_widget_2S1XEjy5DYtuBH5C"
    style="max-width: 480px; height: 480px"
  >
    <iframe
      src="https://letsexchange.io/v2/widget?affiliate_id=2S1XEjy5DYtuBH5C&is_iframe=true"
      width="100%"
      height="100%"
      frameborder="0"
      allow="clipboard-read; clipboard-write"
    ></iframe>
  </div>
</div>
