import { createAction, props } from "@ngrx/store";
import { User } from "src/app/shared/interfaces/user/user";

export const getUser = createAction(
  'getUser',
  props<{
    user: User | undefined
  }>()
)

export const connecting = createAction(
  'connecting',
  props<{
    connecting: boolean
  }>()
)

export const signing = createAction(
  'signing',
  props<{
    signing: boolean
  }>()
)

export const updateUser = createAction(
  'updateUser',
  props<{
    user: User | undefined
  }>()
)

export const updateUserDomain = createAction(
  'updateUserDomain',
  props<{
    domain: string;
  }>()
)

export const resetState = createAction(
  'resetState'
)
