import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-distribution-info',
  templateUrl: './distribution-info.dialog.html',
  styleUrls: ['./distribution-info.dialog.scss']
})
export class DistributionInfoDialog implements OnInit {
  @Output('reject') reject: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClose() {
    this.reject.emit(true);
  }
}
