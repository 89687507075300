import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-notice',
  template: `
  <div *ngIf="!hasConsent" class="w-full border-t border-slate-200 bg-white fixed top-auto bottom-[65px] md:bottom-0 z-[100] flex items-center justify-between">
    <div class="font-normal text-base text-slate-600 px-2 py-2 md:px-6">BarakaFi uses cookies to ensure you get the best experience.
      <a class="text-base font-medium px-2 hover:underline" href="https://smartcredit.io/privacy-policy/" target="_blank">Privacy Policy</a>
    </div>
    <div class="text-center">
      <button class="nostyle bg-sc-primary text-white font-medium py-5 md:py-3 px-2 md:px-12 hover:bg-sc-primary/90 hover:text-white/90 whitespace-nowrap" (click)="onAccept()">Got it!</button>
    </div>
  </div>`,
  styles: ['']
})
export class CookieNoticeComponent implements OnInit{
  public hasConsent: boolean;

  ngOnInit(): void {
    this.hasConsent = !!localStorage.getItem('cookieConsent');
  }

  onAccept() {
    localStorage.setItem('cookieConsent', 'true');
    this.hasConsent = true;
  }
}
