import { Action, createReducer, on } from "@ngrx/store";
import * as LeveragingActions from "./leveraging.actions";
import { LeverageReturns } from "src/app/shared/interfaces/leveraging/leverage-returns";
import { CreditLine } from "src/app/shared/interfaces/borrowing/credit-line";
import { LeverageRequest } from "src/app/shared/interfaces/leveraging/leverage-request";
import { Reward } from "src/app/shared/interfaces/rewards/reward";
import BigNumber from "bignumber.js";
import { Currency } from "src/app/shared/interfaces/currencies/currency";

export interface LeverageState {
  creatingLeverageLine: boolean;
  creatingLeverageRequest: boolean;
  closingLeverageLine: boolean;
  leverageLines: CreditLine[];
  leverageReturns: LeverageReturns;
  leverageRequests: LeverageRequest[],
  smaApr: { timeUnix: number, apr: number }[]
}

const initialState = {
  creatingLeverageLine: undefined,
  creatingLeverageRequest: undefined,
  closingLeverageLine: undefined,
  leverageLines: undefined,
  leverageReturns: undefined,
  leverageRequests: undefined,
  smaApr: undefined
};

const _leveragingReducer = createReducer(
  initialState,
  on(LeveragingActions.updateLeverageLines, (state, action) => {
    const existing = state.leverageLines ?? [];
    const updated = action.leverageLines.filter((line) => !existing.find((l) => l.contractAddress === line.contractAddress));
    return {
      ...state,
      leverageLines: [...existing, ...updated]
    };
  }),
  on(LeveragingActions.updateLeverageReturns, (state, action) => ({
    ...state,
    leverageReturns: action.leverageReturns
  })),
  on(LeveragingActions.creatingLeverageLine, (state, action) => ({
    ...state,
    creatingLeverageLine: action.creatingLeverageLine
  })),
  on(LeveragingActions.creatingLeverageRequest, (state, action) => ({
    ...state,
    creatingLeverageRequest: action.creatingLeverageRequest
  })),
  on(LeveragingActions.updateLeverageRequests, (state, action) => ({
    ...state,
    leverageRequests: action.leverageRequests
  })),
  on(LeveragingActions.updateLeverageRequest, (state, action) => {
    const toUpdate = action.leverageRequest;
    const existing = state.leverageRequests ?? [toUpdate];
    const requestExists = existing.find(r => r.leverageId === toUpdate.leverageId);
    let updated;
    if (requestExists) {
      updated = existing.map((request) => request.leverageId === toUpdate.leverageId ? toUpdate : request)
    } else {
      updated = [...existing, toUpdate]
    }
    return {
      ...state,
      leverageRequests: updated,
    };
  }),
  on(LeveragingActions.updateSmaApr, (state, action) => ({
    ...state,
    smaApr: action.smaApr
  })),
  on(LeveragingActions.updateLeverageLineBalances, (state, action) => {
    const allLines = [...state.leverageLines];
    allLines.forEach((l: CreditLine) => {
      action.leverageLineBalances.forEach((b: { contractAddress: string, balance: string }) => { 
        if (b.contractAddress === l.contractAddress) {
          const updated = { ...l, balance: b.balance}
          allLines.splice(allLines.indexOf(l), 1, updated);
        }
      })
    })
    return {
      ...state,
      leverageLines: allLines
    };
  }),
  on(LeveragingActions.updateUnclaimedRewards, (state, action) => {
    const allLines = state.leverageLines ? [...state.leverageLines] : [];
    allLines.forEach((l: CreditLine) => {
      action.unclaimedRewards.forEach((r: Reward[]) => {
        if (r.length > 0) {
          if (r[0].userEthAddress === l.contractAddress) {
            let unclaimedRewards: string = "0";
            let currency: Currency = r[0].Currency;
            r.forEach(reward => unclaimedRewards = new BigNumber(unclaimedRewards)
              .plus(reward.rewardValue).toString())
            const updated = { ...l, unclaimedRewards, currency }
            allLines.splice(allLines.indexOf(l), 1, updated);
          }
        }
      })
    })
    return {
      ...state,
      leverageLines: allLines
    };
  }),
  on(LeveragingActions.claimRewardsEnd, (state, action) => {
    const allLines = state.leverageLines ? [...state.leverageLines] : [];
    allLines.forEach((l: CreditLine) => {
      if (action.leverageLine.contractAddress === l.contractAddress) {
        allLines.splice(allLines.indexOf(l), 1, action.leverageLine);
      }
    })
    return {
      ...state,
      leverageLines: allLines
    }
  }),
  on(LeveragingActions.resetState, () => initialState)
);

export function leveragingReducer(state: LeverageState, action: Action) {
  return _leveragingReducer(state, action);
}
