import { Dialog } from '@angular/cdk/dialog';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, take, takeUntil } from 'rxjs';
import { GENERAL_ICONS, LOGO, MENU_ICONS } from 'src/assets/images/svg-icons';
import { MOBILE_MENU } from 'src/config/menu.config';
import { environment } from 'src/environments/environment';
import { LetsExchangeDialog } from '../../components/lets-exchange/lets-exchange.dialog';
import { DEFAULT_DIALOG_CONFIG } from '../../constants';

@Component({
  selector: 'shared-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  public logo = LOGO.main;
  public smallLogo = LOGO.smallLogo
  public mainMenu = MOBILE_MENU;
  public icons = { ...GENERAL_ICONS, ...MENU_ICONS };
  public showMenu: boolean;
  public showModal: boolean;
  public currentRoute: string;
  public overlayOutsideClicked: boolean;
  public companyMenuOpen: boolean;
  public triggerOrigin: any;
  public baseUrl = environment.baseUrl;
  public triggerOriginIndex: number;
  private destroy$ = new Subject<void>();

  @Output() expandMenu = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    public dialog: Dialog
  ) { }

  ngOnInit(): void {
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.currentRoute = event.urlAfterRedirects;
        }
      })
  }

  onOverlayOutsideClick(): void {
    this.overlayOutsideClicked = true;
    setTimeout(() => {
      this.overlayOutsideClicked = false;
      this.triggerOriginIndex = -1;
    }, 100);
  }

  toggle(trigger: any, index: number) {
    this.triggerOrigin = trigger;
    this.triggerOriginIndex = index;
    this.companyMenuOpen = true;
  }
  
  openLetsExchangeDialog() {
    this.dialog.open(LetsExchangeDialog, {
      id: 'lets-exchange',
      ...DEFAULT_DIALOG_CONFIG,
    });

    this.dialog.getDialogById('lets-exchange').componentInstance['reject']
    .pipe(take(1)).subscribe(() => {
      this.dialog.getDialogById('lets-exchange').close();
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
