import gql from 'graphql-tag';

export const borrowerLenderWeeklyRewards = gql`
  query ($startDate: String!, $endDate: String!) {
    borrowerLenderWeeklyRewards {
        id,
        borrowerRewards,
        lenderRewards,
        boosterTokens,
        totalBorrowedVolumeInUSD,
        totalLendedVolumeInUSD,
        borrowersAPY,
        lendersAPY
    }
    lastWeekStakingRewardsVolume(startDate: $startDate, endDate: $endDate) {
        totalStakingRewardsAmount
    }
  }
`;

export const userLastWeekRewards = gql`
  query ($walletAddress: String!) {
    userLastWeekReward(walletAddress: $walletAddress) {
        rewardValue,
        rewardType,
        rewardCode,
        status,
        createdAt
        Currency {
            symbol,
            decimalPlaces,
            exchangeRate
        }
    }
  }
`;

export const getUserRewardTypes = gql`
  query($walletAddress: String!) {
    unclaimedRewards(walletAddress: $walletAddress) {
        rewardCode
    }
    claimedRewardsHistory(walletAddress: $walletAddress) {
        rewards {
            rewardCode
        }
    }
  }
`;

export const getReferralData = gql`
    query($walletAddress: String!) {
        unclaimedRewards(walletAddress: $walletAddress) {
            rewardType,
            rewardValue,
            rewardCode,
            status,
            createdAt,
            Currency {
                symbol,
                name,
                ethAddress,
                exchangeRate,
                isUnderlying,
                isCollateral,
                decimalPlaces
            }
        },
        referralsCount {
            month,
            year,
            referrals
        },
        claimedRewardsHistory(walletAddress: $walletAddress) {
            id,
            rewards {
                rewardType,
                rewardValue,
                rewardCode,
                status,
                createdAt,
                Currency {
                    symbol,
                    name,
                    ethAddress,
                    exchangeRate,
                    isUnderlying,
                    isCollateral,
                    decimalPlaces
                }
            }
            signature,
            createdAt,
            status,
            receiver
        },
        referralsLoanVolume {
            month,
            year,
            underlyingSymbol,
            decimalPlaces,
            exchangeRate,
            loanCount,
            loanVolume
        }
    }
`;

export const claimRewards = gql`
    mutation($walletAddress: String!) {
        claimReward(walletAddress: $walletAddress) {
            id,
            rewards {
                rewardType,
                rewardValue,
                rewardCode,
                status,
                createdAt,
                Currency {
                    symbol,
                    name,
                    ethAddress,
                    riskFreeRate,
                    exchangeRate,
                    decimalPlaces
                }
            },
            signature,
            receiver,
            createdAt,
            status
        }
    }
`;

export const getClaimedRewardsHistory = gql`
  query($walletAddress: String!) {
    claimedRewardsHistory(walletAddress: $walletAddress) {
        status
        id
        rewards {
            userId
            userEthAddress
            rewardType
            rewardValue
            rewardCode
            status
            createdAt
            Currency {
                symbol
                name
                ethAddress
                riskFreeRate
                exchangeRate
                medianExchangeRate
                creditCoinAddress
                creditCoinSymbol
                isUnderlying
                isCollateral
                decimalPlaces
            }
        }
        signature
        receiver
        createdAt
    }
}`;
