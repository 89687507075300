import { Pipe, PipeTransform } from '@angular/core';
import BigNumber from 'bignumber.js';

@Pipe({
  name: 'weiToSc'
})
export class WeiToScPipe implements PipeTransform {

  transform(value: string | number): number {
    typeof value === 'number' ? value = value.toString() : null;
      return new BigNumber(value).div(new BigNumber(10).pow(18)).toNumber();
  }
}
