import { createAction, props } from "@ngrx/store";
import { FixedIncomeFund } from "src/app/shared/interfaces/fifs/fixedIncomeFund";
import { StakingReward } from "src/app/shared/interfaces/rewards/staking-reward";
import { Cooldown } from "src/app/shared/interfaces/stakings/cooldown";
import { Staking } from "src/app/shared/interfaces/stakings/staking";

export const getAllStakings = createAction(
  'getAllStakings',
  props<{
    stakings: Staking[];
    stakingFund: FixedIncomeFund;
  }>()
);

export const getAllRewards = createAction(
  'getAllRewards',
  props<{
    allRewards: StakingReward[];
    stakingFund: FixedIncomeFund;
  }>()
);

export const getStakingFifs = createAction(
  'getStakingFifs',
  props<{
    fixedIncomeFunds: FixedIncomeFund[];
  }>()
);

export const getStakingFif = createAction(
  'getStakingFif',
  props<{
    fixedIncomeFund: FixedIncomeFund;
  }>()
)

export const depositingStaking = createAction(
  'depositingStaking',
  props<{
    depositingStaking: boolean;
  }>()
);

export const withdrawingStaking = createAction(
  'withdrawingStaking',
  props<{
    withdrawingStaking: boolean;
  }>()
);

export const creatingStaking = createAction(
  'creatingStaking',
  props<{
    creatingStaking: any;
  }>()
);

export const updateButtons = createAction(
  'updateButtonStates',
  props<{
    isStaking?: boolean
    isWithdrawing?: boolean;
    isStartingCooldown?: boolean;
    isCreatingFif?: boolean;
  }>()
)

export const updateStakingFif = createAction(
  'updateStakingFif',
  props<{
    stakingFif: FixedIncomeFund;
  }>()
)

export const updateCooldown = createAction(
  'updateCooldown',
  props<{
    cooldown: Cooldown
  }>()
)

export const updateBalances = createAction(
  'updateBalances',
  props<{
    balances: {
      gaslessRestaked?: any,
      gaslessRestakedUnlocked?: any,
      gaslessRestakedLocked?: any,
      stakedUnlocked?: any,
      stakedLocked?: any,
    };
    stakingFund: FixedIncomeFund;
  }>()
)

export const updateRewardBalances = createAction(
  'updateRewardBalances',
  props<{
    rewardBalances: {
      totalRewards: any,
      totalClaimable: any,
      thisWeekRewards: any,
      lastWeekRewards: any,
      percentageChange: any
    },
    stakingFund: FixedIncomeFund;
  }>()
)

export const resetState = createAction(
  'resetState'
)
