export const LEVERAGE = {
  abi: [{
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'leverageId',
        type: 'bytes32'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'repaymentAmount',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'loanFee',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'investmentFee',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'lateFee',
        type: 'uint256'
      }
    ],
    name: 'LeverageClosed',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'leverageId',
        type: 'bytes32'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'initialInvestment',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'leverageShares',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'leveragePeriod',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'interestRate',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'borrowFeeRate',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'leverageFeeRate',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'insurer',
        type: 'address'
      }
    ],
    name: 'LeverageInitialized',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'leverageId',
        type: 'bytes32'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'withdrawAmount',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'withdrawId',
        type: 'uint256'
      }
    ],
    name: 'LidoWithdrawRequested',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'leverageId',
        type: 'bytes32'
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'exchangeAddress',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'loanStatus',
        type: 'uint256'
      }
    ],
    name: 'LiquidationInitiated',
    type: 'event'
  },
  {
    inputs: [],
    name: 'LIDO_LEVERAGE',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string'
      }
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true
  },
  {
    inputs: [],
    name: 'getBorrower',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_assetAddress',
        type: 'address'
      }
    ],
    name: 'getERC20Balance',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true
  },
  {
    inputs: [],
    name: 'getETHBalance',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'bytes32',
            name: 'leverageId',
            type: 'bytes32'
          },
          {
            internalType: 'uint256',
            name: 'initialInvestment',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'leverageShares',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'leveragePeriod',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'interestRate',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'borrowFeeRate',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'leverageFeeRate',
            type: 'uint256'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'borrowedFunds',
                type: 'uint256'
              },
              {
                internalType: 'address',
                name: 'lenderAddress',
                type: 'address'
              }
            ],
            internalType: 'struct LeverageStorage.Lender[]',
            name: 'lenders',
            type: 'tuple[]'
          },
          {
            internalType: 'address',
            name: 'insurer',
            type: 'address'
          }
        ],
        internalType: 'struct LeverageStorage.LeverageRequest',
        name: '_leverageRequest',
        type: 'tuple'
      }
    ],
    name: 'getHash',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32'
      }
    ],
    stateMutability: 'pure',
    type: 'function',
    constant: true
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: '_leverageId',
        type: 'bytes32'
      }
    ],
    name: 'getLeverageData',
    outputs: [
      {
        internalType: 'bytes32',
        name: 'leverageDataHash',
        type: 'bytes32'
      },
      {
        internalType: 'enum LeverageStorage.Status',
        name: 'leverageStatus',
        type: 'uint8'
      },
      {
        internalType: 'uint256',
        name: 'leverageEndDate',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'leverageWithdrawId',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_assetAddress',
        type: 'address'
      }
    ],
    name: 'getLockedAssetValue',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_assetAddress',
        type: 'address'
      }
    ],
    name: 'getUnlockedAssetValue',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_borrower',
        type: 'address'
      }
    ],
    name: 'initializeCreditLine',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'bytes32',
            name: 'leverageId',
            type: 'bytes32'
          },
          {
            internalType: 'uint256',
            name: 'initialInvestment',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'leverageShares',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'leveragePeriod',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'interestRate',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'borrowFeeRate',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'leverageFeeRate',
            type: 'uint256'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'borrowedFunds',
                type: 'uint256'
              },
              {
                internalType: 'address',
                name: 'lenderAddress',
                type: 'address'
              }
            ],
            internalType: 'struct LeverageStorage.Lender[]',
            name: 'lenders',
            type: 'tuple[]'
          },
          {
            internalType: 'address',
            name: 'insurer',
            type: 'address'
          }
        ],
        internalType: 'struct LeverageStorage.LeverageRequest',
        name: '_leverageRequest',
        type: 'tuple'
      }
    ],
    name: 'initializeLeverage',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'payable',
    type: 'function',
    payable: true
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'bytes32',
            name: 'leverageId',
            type: 'bytes32'
          },
          {
            internalType: 'uint256',
            name: 'initialInvestment',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'leverageShares',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'leveragePeriod',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'interestRate',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'borrowFeeRate',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'leverageFeeRate',
            type: 'uint256'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'borrowedFunds',
                type: 'uint256'
              },
              {
                internalType: 'address',
                name: 'lenderAddress',
                type: 'address'
              }
            ],
            internalType: 'struct LeverageStorage.Lender[]',
            name: 'lenders',
            type: 'tuple[]'
          },
          {
            internalType: 'address',
            name: 'insurer',
            type: 'address'
          }
        ],
        internalType: 'struct LeverageStorage.LeverageRequest',
        name: '_leverageRequest',
        type: 'tuple'
      },
      {
        internalType: 'address',
        name: '_exchangeAddress',
        type: 'address'
      },
      {
        internalType: 'address',
        name: '_liquidator',
        type: 'address'
      }
    ],
    name: 'initiateLiquidation',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_assetAddress',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256'
      }
    ],
    name: 'withdrawAsset',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    stateMutability: 'payable',
    type: 'receive',
    payable: true
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'bytes32',
            name: 'leverageId',
            type: 'bytes32'
          },
          {
            internalType: 'uint256',
            name: 'initialInvestment',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'leverageShares',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'leveragePeriod',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'interestRate',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'borrowFeeRate',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'leverageFeeRate',
            type: 'uint256'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'borrowedFunds',
                type: 'uint256'
              },
              {
                internalType: 'address',
                name: 'lenderAddress',
                type: 'address'
              }
            ],
            internalType: 'struct LeverageStorage.Lender[]',
            name: 'lenders',
            type: 'tuple[]'
          },
          {
            internalType: 'address',
            name: 'insurer',
            type: 'address'
          }
        ],
        internalType: 'struct LeverageStorage.LeverageRequest',
        name: '_leverageRequest',
        type: 'tuple'
      }
    ],
    name: 'startLidoWithdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'bytes32',
            name: 'leverageId',
            type: 'bytes32'
          },
          {
            internalType: 'uint256',
            name: 'initialInvestment',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'leverageShares',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'leveragePeriod',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'interestRate',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'borrowFeeRate',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'leverageFeeRate',
            type: 'uint256'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'borrowedFunds',
                type: 'uint256'
              },
              {
                internalType: 'address',
                name: 'lenderAddress',
                type: 'address'
              }
            ],
            internalType: 'struct LeverageStorage.Lender[]',
            name: 'lenders',
            type: 'tuple[]'
          },
          {
            internalType: 'address',
            name: 'insurer',
            type: 'address'
          }
        ],
        internalType: 'struct LeverageStorage.LeverageRequest',
        name: '_leverageRequest',
        type: 'tuple'
      },
      {
        internalType: 'uint256',
        name: '_slippage',
        type: 'uint256'
      }
    ],
    name: 'unwindLeverage',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'bytes32',
            name: 'leverageId',
            type: 'bytes32'
          },
          {
            internalType: 'uint256',
            name: 'initialInvestment',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'leverageShares',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'leveragePeriod',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'interestRate',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'borrowFeeRate',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'leverageFeeRate',
            type: 'uint256'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'borrowedFunds',
                type: 'uint256'
              },
              {
                internalType: 'address',
                name: 'lenderAddress',
                type: 'address'
              }
            ],
            internalType: 'struct LeverageStorage.Lender[]',
            name: 'lenders',
            type: 'tuple[]'
          },
          {
            internalType: 'address',
            name: 'insurer',
            type: 'address'
          }
        ],
        internalType: 'struct LeverageStorage.LeverageRequest',
        name: '_leverageRequest',
        type: 'tuple'
      },
      {
        internalType: 'uint256',
        name: '_liquidationValue',
        type: 'uint256'
      }
    ],
    name: 'finishLiquidation',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
    payable: true
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_lidoShares',
        type: 'uint256'
      }
    ],
    name: 'earned',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true
  },
  {
    inputs: [
      {
        internalType: 'uint256[]',
        name: '_requestIds',
        type: 'uint256[]'
      }
    ],
    name: 'getLidoWithdrawStatus',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'amountOfStETH',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'amountOfShares',
            type: 'uint256'
          },
          {
            internalType: 'address',
            name: 'owner',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'timestamp',
            type: 'uint256'
          },
          {
            internalType: 'bool',
            name: 'isFinalized',
            type: 'bool'
          },
          {
            internalType: 'bool',
            name: 'isClaimed',
            type: 'bool'
          }
        ],
        internalType: 'struct ILidoWithdraws.WithdrawalRequestStatus[]',
        name: '',
        type: 'tuple[]'
      }
    ],
    stateMutability: 'view',
    type: 'function',
    constant: true
  }, {
    inputs: [
      {
        components: [
          {
            internalType: "bytes32",
            name: "leverageId",
            type: "bytes32"
          },
          {
            internalType: "uint256",
            name: "initialInvestment",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "leverageShares",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "leveragePeriod",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "interestRate",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "borrowFeeRate",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "leverageFeeRate",
            type: "uint256"
          },
          {
            components: [
              {
                internalType: "uint256",
                name: "borrowedFunds",
                type: "uint256"
              },
              {
                internalType: "address",
                name: "lenderAddress",
                type: "address"
              }
            ],
            internalType: "struct LeverageStorage.Lender[]",
            name: "lenders",
            type: "tuple[]"
          },
          {
            internalType: "address",
            name: "insurer",
            type: "address"
          }
        ],
        internalType: "struct LeverageStorage.LeverageRequest",
        name: "_leverageRequest",
        type: "tuple"
      }
    ],
    name: "startLidoWithdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  }, {
    inputs: [
      {
        internalType: "uint256[]",
        name: "_requestIds",
        type: "uint256[]"
      },
      {
        internalType: "uint256",
        name: "_firstIndex",
        type: "uint256"
      }
    ],
    name: "getLidoWithdrawHints",
    outputs: [
      {
        internalType: "uint256[]",
        name: "_hints",
        type: "uint256[]"
      }
    ],
    stateMutability: "view",
    type: "function",
    constant: true
  }
  ]
};

export const LIDO_WITHDRAW_ABI = {
  abi: [
    {
      inputs: [
        {
          components: [
            {
              internalType: 'bytes32',
              name: 'leverageId',
              type: 'bytes32'
            },
            {
              internalType: 'uint256',
              name: 'initialInvestment',
              type: 'uint256'
            },
            {
              internalType: 'uint256',
              name: 'leverageShares',
              type: 'uint256'
            },
            {
              internalType: 'uint256',
              name: 'leveragePeriod',
              type: 'uint256'
            },
            {
              internalType: 'uint256',
              name: 'interestRate',
              type: 'uint256'
            },
            {
              internalType: 'uint256',
              name: 'borrowFeeRate',
              type: 'uint256'
            },
            {
              internalType: 'uint256',
              name: 'leverageFeeRate',
              type: 'uint256'
            },
            {
              components: [
                {
                  internalType: 'uint256',
                  name: 'borrowedFunds',
                  type: 'uint256'
                },
                {
                  internalType: 'address',
                  name: 'lenderAddress',
                  type: 'address'
                }
              ],
              internalType: 'struct LeverageStorage.Lender[]',
              name: 'lenders',
              type: 'tuple[]'
            },
            {
              internalType: 'address',
              name: 'insurer',
              type: 'address'
            }
          ],
          internalType: 'struct LeverageStorage.LeverageRequest',
          name: '_leverageRequest',
          type: 'tuple'
        },
        {
          internalType: 'uint256[]',
          name: '_hintIds',
          type: 'uint256[]'
        }
      ],
      name: 'unwindLeverage',
      outputs: [],
      stateMutability: 'payable',
      type: 'function',
      payable: true
    }
  ]
}