import { Dialog } from '@angular/cdk/dialog';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, merge, take, takeUntil, timer } from 'rxjs';
import { GENERAL_ICONS, LOGO, MENU_ICONS } from 'src/assets/images/svg-icons';
import { MAIN_MENU, SECONDARY_MENU } from 'src/config/menu.config';
import { environment } from 'src/environments/environment';
import { LetsExchangeDialog } from '../../components/lets-exchange/lets-exchange.dialog';
import { DEFAULT_DIALOG_CONFIG } from '../../constants';

@Component({
  selector: 'shared-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public logo = LOGO.main;
  public smallLogo = LOGO.small;
  public mainMenu = MAIN_MENU;
  public secondaryMenu = SECONDARY_MENU;
  public icons = { ...GENERAL_ICONS, ...MENU_ICONS };
  public triggerOrigin: any;
  public triggerOriginIndex: number;
  public currentRoute: string;
  public environment = environment;
  public isBrowser: boolean;
  private destroy$ = new Subject<void>();
  private trigger$ = new Subject<void>();

  constructor(
    private router: Router,
    public dialog: Dialog
  ) { }

  ngOnInit(): void {
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.currentRoute = val.url;
        }
      });
  }

  closeMenu(): void {
    timer(100)
      .pipe(takeUntil(merge(this.trigger$, this.destroy$)))
      .subscribe(() => {
        this.triggerOriginIndex = -1;
      })
  }

  openMenu(trigger: any, index: number) {
    this.triggerOrigin = trigger;
    this.triggerOriginIndex = index;
    this.trigger$.next();
  }

  openLetsExchangeDialog() {
    this.dialog.open(LetsExchangeDialog, {
      id: 'lets-exchange',
      ...DEFAULT_DIALOG_CONFIG,
    });

    this.dialog.getDialogById('lets-exchange').componentInstance['reject']
    .pipe(take(1)).subscribe(() => {
      this.dialog.getDialogById('lets-exchange').close();
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.trigger$.next();
    this.trigger$.complete();
  }
}
