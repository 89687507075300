import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'shared-popup',
  template: `
  <div class="rounded-xl border-slate-200 bg-white p-4">
    <div class="font-semibold text-lg mb-4 text-center">{{ data.title }}</div>
    <div class="font-medium text-base text-slate-500 mb-2 text-center">{{ data.message }}</div>
    <div class="font-medium text-base text-slate-500 mb-2 text-center">{{ data.message2 }}</div>
    <div class="text-center mb-4">
    <spinner size="sm"></spinner>
    </div>
  </div>`,
  styles: ['']
})
export class Popup {
  constructor(
    @Inject(DIALOG_DATA) public data: { title?: string; message?: string; message2?: string; },
  ) { }
}
