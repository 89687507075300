import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { GENERAL_ICONS } from 'src/assets/images/svg-icons';

@Component({
  selector: 'app-create-leverage',
  templateUrl: './create-leverage.dialog.html',
  styleUrls: ['./create-leverage.dialog.scss']
})
export class CreateLeverageDialog implements OnInit {
  public icons = { ...GENERAL_ICONS };
  creatingLeverageLine: boolean;
  creatingLeverageRequest: boolean;
  private destroyRef = inject(DestroyRef)

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.store.select(state => state.leveraging)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((leveraging) => {
        this.creatingLeverageLine = leveraging.creatingLeverageLine;
        this.creatingLeverageRequest = leveraging.creatingLeverageRequest;
      })
  }
}
