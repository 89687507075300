import { Action, createReducer, on } from "@ngrx/store";
import { ClaimedReward } from "src/app/shared/interfaces/rewards/claimed-reward";
import { Reward } from "src/app/shared/interfaces/rewards/reward";
import { StakingReward } from "src/app/shared/interfaces/rewards/staking-reward";
import * as RewardsActions from "./rewards.actions";

export interface AppState {
  lending: State;
}

export interface State {
  referralsLoanVolume: any,
  claimedRewards: ClaimedReward[],
  unclaimedRewards: Reward[],
  stakingRewards: StakingReward[],
  totalClaimedRewardsUsd: number,
  totalUnclaimedRewardsUsd: number,
  totalStakingRewards: string,
  stakingContract: string,
  isRedeeming: boolean
}

const initialState = {
  referralsLoanVolume: undefined,
  claimedRewards: undefined,
  unclaimedRewards: undefined,
  stakingRewards: undefined,
  totalClaimedRewardsUsd: undefined,
  totalUnclaimedRewardsUsd: undefined,
  totalStakingRewards: undefined,
  stakingContract: undefined,
  isRedeeming: false
}

const _rewardsReducer = createReducer(
  initialState,
  on(RewardsActions.getRewardsData, (state, action) => ({
    ...state,
    referralsLoanVolume: action.referralsLoanVolume,
    claimedRewards: action.claimedRewards,
    unclaimedRewards: action.unclaimedRewards,
    totalClaimedRewardsUsd: action.totalClaimedRewardsUsd,
    totalUnclaimedRewardsUsd: action.totalUnclaimedRewardsUsd,
  })),
  on(RewardsActions.getStakingRewardsData, (state, action) => ({
    ...state,
    stakingRewards: action.stakingRewards,
    totalStakingRewards: action.totalStakingRewards,
    stakingContract: action.stakingContract
  })),
  on(RewardsActions.updateRedeemedReward, (state, action) => ({
    ...state,
    claimedRewards: state.claimedRewards.map((claimedReward: ClaimedReward) => {
      if (claimedReward.id === action.claimedReward.id) {
        return action.claimedReward;
      }
      return claimedReward;
    }),
  })),
  on(RewardsActions.addRedeemedReward, (state, action) => ({
    ...state,
    claimedRewards: state.claimedRewards ? [...state.claimedRewards.filter((claimedReward: ClaimedReward) => claimedReward.id !== action.claimedReward.id), action.claimedReward] : [action.claimedReward]
  })),
  on(RewardsActions.clearUnclaimed, (state, action) => ({
    ...state,
    unclaimedRewards: action.unclaimedRewards,
    totalUnclaimedRewardsUsd: action.totalUnclaimedRewardsUsd
  })),
  on(RewardsActions.redeemingRewards, (state, action) => ({
    ...state,
    isRedeeming: action.isRedeeming
  })),
  on(RewardsActions.resetState, () => initialState)
)

export function rewardsReducer(state: State, action: Action) {
  return _rewardsReducer(state, action);
}
