import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { GENERAL_ICONS } from 'src/assets/images/svg-icons';

@Component({
  selector: 'app-claiming-rewards',
  templateUrl: './claiming-rewards.component.html',
  styles: []
})
export class ClaimingRewardsDialog {
  public claiming: boolean;
  public icons = GENERAL_ICONS;

  constructor(
    @Inject(DIALOG_DATA) public data: { claiming: boolean },
  ) {
    this.claiming = data.claiming;
  }
}
