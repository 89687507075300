<dashboard-stats *ngIf="stats && stats.borrowingStatistics && currencies && currencies.underlyingCurrencies" [stats]="stats" [currencies]="currencies">
</dashboard-stats>
<div class="{{ environment.banners.homepage }} banner-wrapper mb-4"></div>
<assets-table *ngIf="currencies" [assets]="currencies.underlyingCurrencies" [cryptoIcons]="cryptoIcons">
</assets-table>
<collaterals-table *ngIf="currencies" [collaterals]="currencies.collateralCurrencies" [cryptoIcons]="cryptoIcons">
</collaterals-table>
<dashboard-articles *ngIf="articles" [articles]="articles">
</dashboard-articles>
<shared-paragraphs *ngIf="pageDetails.paragraphs" [paragraphsTitle]="pageDetails.paragraphsTitle" [paragraphs]="pageDetails.paragraphs"></shared-paragraphs>
