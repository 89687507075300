import { DestroyRef, Injectable, inject } from '@angular/core';
import { Web3Service } from '../web3/web3.service';
import { EMPTY, Observable, catchError, combineLatest, concatMap, forkJoin, from, map, mergeMap, of, reduce, take, tap, timer } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import * as fromApp from 'src/app/store/app.reducer';
import * as LeveragingActions from "./../../leveraging/store/leveraging.actions";
import { Apollo } from 'apollo-angular';
import {
  createLeverageRequest,
  leverageRequest,
  leverageRequests,
  leverageLines,
  fetchLeverageReturns,
  terminateLeverageRequest,
  getLeverageRewards,
  claimLeverageRewards,
  leverageLiquidationProbabilityData
} from '../graphql/leveragingTypes';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LeverageForm } from 'src/app/shared/interfaces/leveraging/leverage-form';
import { LeverageReturns } from 'src/app/shared/interfaces/leveraging/leverage-returns';
import { Dialog } from '@angular/cdk/dialog';
import { CreditLine } from 'src/app/shared/interfaces/borrowing/credit-line';
import { LeverageRequest } from 'src/app/shared/interfaces/leveraging/leverage-request';
import { CreateLeverageDialog } from 'src/app/leveraging/components/create-leverage-dialog/create-leverage.dialog';
import { PopupService } from './popup.service';
import { LidoWithdrawStatus } from 'src/app/shared/interfaces/leveraging/lido-withdraw-status';
import { LeverageBorrows } from 'src/app/shared/interfaces/leveraging/leverage-borrows';
import { CurrenciesService } from './currencies.service';
import { Currency } from 'src/app/shared/interfaces/currencies/currency';

@Injectable({
  providedIn: 'root'
})
export class LeveragingService {
  private destroyRef = inject(DestroyRef)
  private retryCreating: number = 0;

  constructor(
    private web3Service: Web3Service,
    private store: Store<fromApp.AppState>,
    private apollo: Apollo,
    private toastr: ToastrService,
    private router: Router,
    private dialog: Dialog,
    private popupService: PopupService,
    private currenciesService: CurrenciesService
  ) { }

  createLeverageLine(leverageForm: LeverageForm, leverageId: string, userAddress: string) {
    this.openCreateLeverageDialog();
    this.store.dispatch(LeveragingActions.creatingLeverageLine({ creatingLeverageLine: true }));
    this.web3Service.createLine('Type F Loan', true)
      .pipe(
        catchError((err: any): any => {
          this.dialog.getDialogById('create-leverage').close();
          this.store.dispatch(LeveragingActions.creatingLeverageLine({ creatingLeverageLine: false }));
        }),
        takeUntilDestroyed(this.destroyRef))
      .subscribe((res: any) => {
        this.getLeverageLines(userAddress, 'Type F Loan');
        const newLeverageLineAddress = res.events.LoanContractCreated.returnValues.creditLine;
        leverageForm = {
          ...leverageForm,
          leverageLine: newLeverageLineAddress
        }
        timer(1000).pipe(take(1)).subscribe(() => this.createLeverageRequest(leverageForm, leverageId))
        this.store.dispatch(LeveragingActions.creatingLeverageLine({ creatingLeverageLine: false }));
      })
  }

  getLeverageLines(ownerAddress: string, type: string): Observable<any> {
    return of(this.apollo
      .watchQuery({
        query: leverageLines,
        variables: {
          where: { ownerAddress, type }
        },
        fetchPolicy: 'no-cache'
      }).valueChanges.pipe(
        catchError((err: any): any => {
          this.toastr.error('Retry Later!', 'Could not get Leveraged Lines');
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
        map((res: any) => res.data.creditLines),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((leverageLines: CreditLine[]) => {
        this.store.dispatch(LeveragingActions.updateLeverageLines({ leverageLines }));
        return of(true);
      }))
  }

  getLeverageRequests(leverageLines: string[]) {
    const requests = leverageLines.map(contractAddress =>
      this.apollo.query({
        query: leverageRequests,
        variables: {
          where: {
            contractAddress,
          },
        },
        fetchPolicy: 'no-cache'
      })
    );
    forkJoin(requests)
      .pipe(
        concatMap((responses: any[]) => responses),
        catchError((err: any): any => {
          this.toastr.error('', 'Could not get Leveraged Staking Requests');
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
        reduce((acc: any[], response: any) => [...acc, ...response.data.leverageRequests], []),
        map((leverageRequests: any) => {
          return leverageRequests.map((req) => ({
            ...req,
            status: req.withdrawId && req.status !== 'REPAID' ? 'WITHDRAWAL IN PROGRESS' : req.status
          }))
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((leverageRequests: LeverageRequest[]) => {
        this.store.dispatch(LeveragingActions.updateLeverageRequests({ leverageRequests }))
      })
  }

  getLeverageLineRewards(leverageLines: string[]) {
    const requests = leverageLines.map(walletAddress =>
      this.apollo.query({
        query: getLeverageRewards,
        variables: {
          walletAddress,
        },
      }).pipe(map((res: any) => res.data.unclaimedRewards))
    );
    forkJoin(requests)
      .pipe(
        catchError((err: any): any => {
          this.toastr.error('', 'Could not get rewards');
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((unclaimedRewards: any) => {
        this.store.dispatch(LeveragingActions.updateUnclaimedRewards({ unclaimedRewards }))
      })
  }

  getLeverageLineBalances(leverageLines: string[]) {
    const requests = leverageLines.map(contractAddress =>
      this.web3Service.fetchEthBalance(contractAddress)
        .pipe(map((res) => ({ contractAddress: contractAddress, balance: res })))
    );
    forkJoin(requests)
      .pipe(
        catchError((err: any): any => {
          this.toastr.error('', 'Could not get balances');
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((leverageLineBalances: any) => {
        this.store.dispatch(LeveragingActions.updateLeverageLineBalances({ leverageLineBalances }))
      })
  }

  redeemBalance(redeemData: { leverageLine: CreditLine, currency: Currency, amount: string }) {
    this.web3Service.withdrawFromCreditLine(redeemData.leverageLine, redeemData.currency, redeemData.amount)
      .subscribe(() => {
        this.store.dispatch(LeveragingActions.updateLeverageLineBalances({
          leverageLineBalances: [{
            contractAddress: redeemData.leverageLine.contractAddress,
            balance: '0'
          }]
        }))
      })
  }

  getLeverageRequest(leverageRequestId: string) {
    this.apollo.query({
      query: leverageRequest,
      variables: {
        leverageRequestId
      },
      fetchPolicy: 'no-cache'
    })
      .pipe(
        catchError((err: any): any => {
          this.toastr.error('', 'Could not get Leveraged Staking Request');
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
        map((res: any) => res.data.leverageRequest),
        mergeMap((leverageRequest: LeverageRequest) => {
          const $leverageRequest = of(leverageRequest);
          const $earnedAmount = leverageRequest.status === 'ACTIVE' ? this.web3Service.leverageEarnedAmount(leverageRequest) : of(0)
          const $lidoWithdrawStatus = leverageRequest.withdrawId ? this.web3Service.lidoWithdrawStatus(leverageRequest) : of(undefined);
          return combineLatest([$leverageRequest, $earnedAmount, $lidoWithdrawStatus]);
        }),
        map(([leverageRequest, _earned, _lidoWithdrawStatus]: [LeverageRequest, number, LidoWithdrawStatus]) => {
          const earned = _earned;
          const withdrawStatus = _lidoWithdrawStatus ? _lidoWithdrawStatus[0] : undefined;
          const leverageEndDate = leverageRequest.leverageEndDate;
          if (leverageEndDate) {
            const dateOffset = (24 * 60 * 60 * 1000) * 2;
            const regularEndDate = new Date(leverageEndDate).setTime(new Date(leverageEndDate).getTime() - dateOffset);
            const devOpts = JSON.parse(localStorage.getItem('devOpts'));
            if (devOpts && devOpts.ontime === true) {
              if (devOpts.critical !== true) {
                const dateOffset2 = (24 * 60 * 60 * 1000) * 1;
                const tempEndDate = new Date().setTime(new Date().getTime() + dateOffset);
                const regularEndDate = new Date(leverageEndDate).setTime(new Date(leverageEndDate).getTime() - dateOffset2);
                return { ...leverageRequest, regularEndDate, earned, withdrawStatus, leverageEndDate: tempEndDate };
              } else {
                const dateOffset2 = (24 * 60 * 60 * 1000) * 1;
                const tempEndDate = new Date().setTime(new Date().getTime() + dateOffset2);
                const regularEndDate = new Date(leverageEndDate).setTime(new Date(leverageEndDate).getTime() - dateOffset2);
                return { ...leverageRequest, regularEndDate, earned, withdrawStatus, leverageEndDate: tempEndDate };
              }
            }
            return { ...leverageRequest, regularEndDate, earned, withdrawStatus };
          }
          return { ...leverageRequest, earned, withdrawStatus } as LeverageRequest;
        }),
        map((leverageRequest: any) => ({
          ...leverageRequest,
          status: leverageRequest.withdrawId && leverageRequest.status !== 'REPAID' ? 'WITHDRAWAL IN PROGRESS' : leverageRequest.status
        })),
        concatMap((leverageRequest: LeverageRequest) => {
          return this.leverageLiquidationProbabilityData(leverageRequest)
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((leverageRequest: LeverageRequest) => {
        this.store.dispatch(LeveragingActions.updateLeverageRequest({ leverageRequest }))
      })
  }

  createLeverageRequest(leverageForm: LeverageForm, leverageId: string) {
    const leverageData = {
      initialInvestmentAmount: leverageForm.amount,
      leverageFactor: leverageForm.leverageFactor,
      leverageId,
      leverageLineAddress: leverageForm.leverageLine.toString()
    }
    if (!this.dialog.openDialogs.includes(this.dialog.getDialogById('create-leverage'))) {
      this.openCreateLeverageDialog();
    }
    return this.apollo
      .mutate({
        mutation: createLeverageRequest,
        variables: { ...leverageData }
      })
      .pipe(
        catchError((err: any): Observable<any> => {
          // TODO Handle state
          if (!environment.production) console.log(err);
          this.retryCreating++;
          if (this.retryCreating > 5) {
            this.dialog.getDialogById('create-leverage').close();
            this.toastr.error('Please retry!', 'Could not create leveraged staking request');
          } else {
            timer(2000).pipe(take(1)).subscribe(() => this.createLeverageRequest(leverageForm, leverageId));
          }
          return EMPTY;
        }),
        map((res: any) => {
          if (res.data.errors && res.data.errors.length > 0) {
            this.toastr.error('', res.data.errors.message);
            return EMPTY;
          }
          return res.data.createLeverageRequest.leverageRequest;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((leverageRequest: LeverageRequest) => {
        this.dialog.getDialogById('create-leverage').close();
        this.toastr.info('', 'Leveraged staking request created!');
        this.store.dispatch(LeveragingActions.updateLeverageRequest({ leverageRequest }));
        this.store.dispatch(LeveragingActions.updateLeverageData({ leverageData: null }));
        this.router.navigate([`/leveraged-staking/`, leverageRequest.leverageId])
      })
  }

  fetchLeverageReturns(leverageForm: LeverageForm, currency: Currency) {
    return this.apollo.query({
      query: fetchLeverageReturns,
      variables: {
        ...leverageForm
      }
    })
      .pipe(
        catchError((err: any): Observable<any> => {
          this.toastr.error('Please retry!', 'Could not fetch leveraged staking returns');
          // TODO Handle state
          // this.store.dispatch(BorrowingActions.creatingLoan({ creatingLoan: false }));
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
        map((res: any) => {
          if (res.data.errors && res.data.errors.length > 0) {
            this.toastr.error('', res.data.errors.message);
            return EMPTY;
          }
          return res.data.fetchLeverageReturns;
        }),
        mergeMap((leverageReturns: LeverageReturns) => {
          return combineLatest([of(leverageReturns), this.leverageLiquidationProbabilityEstimate(leverageReturns, currency)])
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([leverageReturns, liquidationProbability]: [LeverageReturns, number[]]) => {
        this.store.dispatch(LeveragingActions.updateLeverageReturns({ leverageReturns: { ...leverageReturns, liquidationProbability } }));
      })
  }

  depositToLeverageLine(leverageRequest: LeverageRequest) {
    this.store.dispatch(LeveragingActions
      .updateLeverageRequest({ leverageRequest: { ...leverageRequest, depositing: true } }))
    this.web3Service.depositToLeverageLine(leverageRequest)
      .pipe(
        catchError((err: any): any => {
          this.store.dispatch(LeveragingActions
            .updateLeverageRequest({ leverageRequest: { ...leverageRequest, depositing: false } }))
        }),
        takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        // TODO ONLY RETURN REQUEST WHEN STATUS 'DEPOSITED'
        this.store.dispatch(LeveragingActions
          .updateLeverageRequest({ leverageRequest: { ...leverageRequest, depositing: false, status: 'DEPOSITED' } }))
        timer(5000).subscribe(() => this.getLeverageRequest(leverageRequest.leverageId))
      })
  }

  terminateRequest(request: LeverageRequest) {
    return this.apollo
      .mutate({
        mutation: terminateLeverageRequest,
        variables: {
          leverageId: request.leverageId
        }
      })
      .pipe(catchError((err: any): Observable<any> => {
        this.toastr.info('Please try again later!', 'Could not terminate leveraged staking request');
        if (!environment.production) console.log(err);
        return EMPTY;
      }), map((res: any) => res.data.terminateLeverageRequest.leverageRequest),
        takeUntilDestroyed(this.destroyRef))
      .subscribe((leverageRequest: LeverageRequest) => {
        this.store.dispatch(LeveragingActions.updateLeverageRequest({ leverageRequest }))
      })
  }

  claimLeverageRewards(leverageLine: CreditLine) {
    return this.apollo
      .mutate({
        mutation: claimLeverageRewards,
        variables: {
          contractAddress: leverageLine.contractAddress
        }
      })
      .pipe(catchError((err: any): Observable<any> => {
        this.toastr.info('Please try again later!', 'Could not claim rewards');
        if (!environment.production) console.log(err);
        return EMPTY;
      }), map((res: any) => res.data.claimLeverageRewards),
        takeUntilDestroyed(this.destroyRef))
      .subscribe((res: any) => {
        this.toastr.info('Redeem to wallet from rewards dashboard.', 'Claimed rewards successfully');
        this.store.dispatch(LeveragingActions.claimRewardsEnd({ leverageLine: { ...leverageLine, claimed: true, unclaimedRewards: undefined } }))
      })
  }

  leverageLiquidationProbabilityData(leverageRequest: LeverageRequest, period = 180): Observable<LeverageRequest> {
    let totalLoanValue: number = 0;
    let totalCollateralValue: number = 0;
    leverageRequest.borrows.forEach((b: LeverageBorrows) => {
      totalLoanValue += this.currenciesService.weiToCurrency(b.principal, leverageRequest.underlying, 'USD');
    })
    const collateralValue = (((+leverageRequest.initialInvestmentAmount + +leverageRequest.borrowedAmount) + +leverageRequest.earned - +leverageRequest.initialInvestmentAmount -
      +leverageRequest.borrowedAmount))
    totalCollateralValue += this.currenciesService.weiToCurrency(collateralValue, leverageRequest.borrows[0].collateral, 'USD');
    const loanValueUsd = totalLoanValue.toString();
    const collateralValueUsd = totalCollateralValue.toString();
    return this.apollo.query({
      query: leverageLiquidationProbabilityData,
      variables: {
        loanValueUsd,
        collateralValueUsd,
        period
      },
      fetchPolicy: 'no-cache'
    })
      .pipe(
        catchError((err: any): any => {
          this.toastr.error('', 'Could not get Leveraged staking Request Liquidation Probability.');
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
        map((res: any) => ({
          ...leverageRequest,
          liquidationProbability: res.data.leverageLiquidationProbabilityData
        })),
        takeUntilDestroyed(this.destroyRef)
      )
  }

  leverageLiquidationProbabilityEstimate(leverageReturns: LeverageReturns, currency: Currency): Observable<any> {
    const loanValueUsd = (leverageReturns.debt * currency.exchangeRate).toString();
    const collateralValueUsd = (leverageReturns.asset * currency.exchangeRate).toString();
    const period = 180;
    return this.apollo.query({
      query: leverageLiquidationProbabilityData,
      variables: {
        loanValueUsd,
        collateralValueUsd,
        period
      },
      fetchPolicy: 'no-cache'
    }).pipe(
      catchError((err: any): any => {
        this.toastr.error('', 'Could not get Leveraged staking Request Liquidation Probability.');
        if (!environment.production) console.log(err);
        return EMPTY;
      }),
      map((res: any) => res.data.leverageLiquidationProbabilityData)
    )
  }

  startLidoWithdraw(request: LeverageRequest) {
    this.popupService.open(
      'Requesting withdrawal from LIDO', 'request-withdrawal',
      'Confirm the transaction from your wallet to request Lido withdrawal!',
      'This process might take 6 to 24 hours to complete.');
    this.web3Service.startLidoWithdraw(request)
      .pipe(
        catchError((err: any): any => {
          this.toastr.info('Please try again later!', 'Could not request withdrawal');
          this.popupService.close('request-withdrawal')
        }),
        takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        this.popupService.close('request-withdrawal');
        this.store.dispatch(LeveragingActions.updateLeverageRequest({ leverageRequest: { ...request, status: 'WITHDRAWAL IN PROGRESS' } }))
      })
  }

  repayRequestCurve(request: LeverageRequest, slippage: number) {
    this.popupService.open('Repaying leveraged staking request', 'repaying-leverage',
      'Confirm the transaction from your wallet to complete repayment!');
    this.web3Service.repayLeverageCurve(request, slippage)
      .pipe(
        catchError((err: any): any => {
          this.toastr.info('Please try again later!', 'Could not repay leveraged staking request');
          this.popupService.close('repaying-leverage')
        }),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe((res) => {
        this.popupService.close('repaying-leverage');
        this.store.dispatch(LeveragingActions.updateLeverageRequest({ leverageRequest: { ...request, status: 'REPAID' } }))
      })
  }

  repayRequestLido(request: LeverageRequest) {
    this.popupService.open('Repaying leveraged staking request', 'repaying-leverage',
      'Confirm the transaction from your wallet to complete repayment!');
    this.web3Service.lidoWithdrawHints(request).pipe(
      map((res: any) => res.map((id: number) => +id)),
      concatMap(hintIds => this.web3Service.repayLeverageLido(request, hintIds)),
      catchError((err: any): any => {
        this.toastr.info('Please try again later!', 'Could not repay leveraged staking request');
        this.popupService.close('repaying-leverage')
      }),
      takeUntilDestroyed(this.destroyRef)
    )
      .subscribe((res) => {
        this.popupService.close('repaying-leverage')
        this.store.dispatch(LeveragingActions.updateLeverageRequest({ leverageRequest: { ...request, status: 'REPAID' } }))
      })
  }

  openCreateLeverageDialog() {
    this.dialog.open(CreateLeverageDialog, {
      id: 'create-leverage',
      minWidth: '300px',
      maxWidth: '500px',
      autoFocus: false,
      disableClose: true,
    });
  }
  getLidoSmaApr() {
    return from(fetch('https://eth-api.lido.fi/v1/protocol/steth/apr/sma')
      .then(res => res.json()))
      .pipe(map(res => res.data.aprs))
      .subscribe((smaApr: { timeUnix: number, apr: number }[]) => {
        this.store.dispatch(LeveragingActions.updateSmaApr({ smaApr }));
      })
  }
}
