import { Dialog } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { Popup } from 'src/app/shared/components/popup.component';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  constructor(
    private dialog: Dialog
  ) { }

  open(title: string, id: string, message?: string, message2?: string) {
    this.dialog.open(Popup, {
      id: id,
      minWidth: '350px',
      maxWidth: '350px',
      autoFocus: false,
      data: {
        title,
        message,
        message2
      }
    })
  }

  close(id: string) {
    this.dialog.getDialogById(id).close();
  }
}
