import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, Output } from '@angular/core';

@Component({
  selector: 'app-claim-rewards',
  templateUrl: './claim-rewards.dialog.html',
  styleUrls: ['./claim-rewards.dialog.scss']
})
export class ClaimRewardsDialog {
  public totalUnclaimedRewardsUsd: number;

  @Output('claimConfirmed') claimConfirmed: EventEmitter<boolean> = new EventEmitter();
  @Output('reject') reject: EventEmitter<boolean> = new EventEmitter();

  constructor(
    @Inject(DIALOG_DATA) public data: { totalUnclaimedRewardsUsd: number },
  ) {
    this.totalUnclaimedRewardsUsd = data.totalUnclaimedRewardsUsd;
  }

  onClaimConfirm() {
    this.claimConfirmed.emit(true);
  }

  onClose() {
    this.reject.emit(true);
  }
}
