import { ScaleType } from '@swimlane/ngx-charts';

export const EARN_FORM_BASE_AMOUNT = 750;
export const SLIPPAGE = 50;
export const LEVERAGE_PLATFORM_FEE = 0.05;
export const regEx = { numbers: /^\d*\.?\d*$/ };

export const WALLETS = [{
  name: 'WalletConnect',
  path: '/assets/images/wallets/walletconnect.png'
}, {
  name: 'MetaMask',
  path: '/assets/images/wallets/metamask.png'
}, {
  name: 'Block Wallet',
  path: '/assets/images/wallets/blockwallet.png'
}, {
  name: 'Coinbase Wallet',
  path: '/assets/images/wallets/coinbasewallet.png'
}, {
  name: 'Unstoppable',
  path: '/assets/images/wallets/unstoppable.png'
}];

export const userBorrowRating = {
  1: {
    name: 'cool',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#a8385d',]
  },
  2: {
    name: 'cool',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#9f4d78',]
  },
  3: {
    name: 'cool',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#975f8f',]
  },
  4: {
    name: 'cool',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#8f70a5',]
  },
  5: {
    name: 'cool',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#897db5',]
  },
  6: {
    name: 'cool',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#8488c3',]
  },
  7: {
    name: 'cool',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#8093d1',]
  },
  8: {
    name: 'cool',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#7d9ada',]
  },
  9: {
    name: 'cool',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#79a3e5',]
  },
};

export const toastrConfig = {
  timeOut: 5000,
  maxOpened: 5,
  autoDismiss: true,
  preventDuplicates: true,
  countDuplicates: true,
  resetTimeoutOnDuplicate: true,
  includeTitleDuplicates: true,
  closeButton: false,
  extendedTimeOut: 1000,
  newestOnTop: true,
  progressBar: true,
  positionClass: 'toast-bottom-right'
};

export const ASSETS_ORDER = [
  "SMARTCREDIT",
  "ISLM",
  "USDC",
  "USDT",
  "DAI"
];

export const COLLATERALS_ORDER = [
  "SMARTCREDIT",
  "ISLM",
  "WETH",
  "USDC",
  "USDT",
  "DAI",
  "EPAN",
  "LINK",
  "REP",
  "BAT",
  "BNT",
  "ENJ",
  "LRC",
  "MKR",
  "UNI",
  "ZRX"
];

export const EARN_PLANS = [{
  name: 'Borrow & Stake',
  id: '1',
}, {
  name: 'Borrow',
  id: '2',
}, {
  name: 'Lend',
  id: '3',
}];
