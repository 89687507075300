import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RewardsRoutingModule } from './rewards-routing.module';
import { RewardsComponent } from './pages/rewards/rewards.component';
import { SharedModule } from '../shared/shared.module';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { UnclaimedRewardsComponent } from './components/unclaimed-rewards/unclaimed-rewards.component';
import { ClaimedRewardsComponent } from './components/claimed-rewards/claimed-rewards.component';
import { ClaimRewardsDialog } from './components/claim-rewards/claim-rewards.dialog';
import { StakingRewardsComponent } from './components/staking-rewards/staking-rewards.component';
import { ClaimStakingRewardsDialog } from './components/claim-staking-rewards/claim-staking-rewards.dialog';
import { ClipboardModule } from '@angular/cdk/clipboard';


@NgModule({
  declarations: [
    RewardsComponent,
    EmptyStateComponent,
    UnclaimedRewardsComponent,
    ClaimedRewardsComponent,
    ClaimRewardsDialog,
    StakingRewardsComponent,
    ClaimStakingRewardsDialog,
  ],
  imports: [
    CommonModule,
    RewardsRoutingModule,
    SharedModule,
    ClipboardModule
  ]
})
export class RewardsModule { }
