import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LegendPosition } from '@swimlane/ngx-charts';
import BigNumber from 'bignumber.js';
import { Subject, take } from 'rxjs';
import { DEFAULT_DIALOG_CONFIG } from 'src/app/shared/constants';
import { LoanData } from 'src/app/shared/interfaces/borrowing/loan-data';
import { Currency } from 'src/app/shared/interfaces/currencies/currency';
import { User } from 'src/app/shared/interfaces/user/user';
import { CRYPTO_ICONS, GENERAL_ICONS } from 'src/assets/images/svg-icons';
import { userBorrowRating } from 'src/config/app.config';
import { TrustScoreInfoDialog } from '../trust-score-info/trust-score-info.dialog';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DashboardStats } from 'src/app/shared/interfaces/statistics/dashboard-stats';

@Component({
  selector: 'borrow-form-summary',
  templateUrl: './borrow-form-summary.component.html',
  styleUrls: ['./borrow-form-summary.component.scss']
})
export class BorrowFormSummaryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() user: User | undefined;
  @Input() creatingLoanRequest: boolean;
  @Input() loanData: LoanData;
  @Input() loanCollateral: Currency;
  @Input() loanAsset: Currency;
  @Input() rewardToken: Currency;
  @Input() borrowForm: FormGroup;
  @Input() advancedMode: boolean;
  @Input() tokenRewards: {
    baseReward: number;
    firstLoanReward: number;
    campaignReward: number;
    totalRewards: number;
  }
  @Input() stats: DashboardStats;
  @Input() isUpdating: boolean;
  @Input() isCalculating: boolean;
  @ViewChild('trustHint') trustHint: any;
  @ViewChild('rewardsHint') rewardsHint: any;
  @ViewChild('summaryHint') summaryHint: any;
  @Output() borrowFormSubmitted: EventEmitter<boolean> = new EventEmitter();
  view: [number, number] = [110, 80];
  legend: boolean = true;
  legendPosition: LegendPosition = LegendPosition.Below;
  colorScheme = userBorrowRating;
  showUsd: boolean;
  chartData: any;
  loanEndDate: Date = new Date();
  repaymentAmount: any;
  destroy$ = new Subject<void>();
  placeholderDate = new Date();
  icons = { ...GENERAL_ICONS, ...CRYPTO_ICONS };
  router = inject(Router);
  dialog = inject(Dialog);
  destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.chartData = [{ name: 'Risk Rating', value: this.user ? this.user.creditData.riskRating : 1 }]
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.loanData.loanTerm) {
      this.loanEndDate = new Date(Date.now() + this.loanData.loanTerm * 24 * 60 * 60 * 1000);
    }
    this.repaymentAmount = new BigNumber(this.loanData.loanAmount).times(new BigNumber(this.loanData.loanRate).div(100))
      .plus(this.loanData.loanAmount).toFixed(6);
  }

  openTrustScoreDialog() {
    const trustScoreDialog = this.dialog.open(TrustScoreInfoDialog, {
      id: 'fund-info',
      ...DEFAULT_DIALOG_CONFIG,
    });

    trustScoreDialog.componentInstance['reject']
      .pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        trustScoreDialog.close();
      })
  }

  goToCreditLine() {
    this.router.navigate(['/purchase/credit-line/', this.loanData.creditLineAddress]);
  }

  submit() {
    this.borrowFormSubmitted.emit(true);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
