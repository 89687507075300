import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DashboardStats } from 'src/app/shared/interfaces/statistics/dashboard-stats';
import { rewards } from 'src/app/shared/constants';
import { Currencies } from 'src/app/shared/interfaces/currencies/currencies';
import { Currency } from 'src/app/shared/interfaces/currencies/currency';
import { CurrenciesService } from 'src/app/core/services/currencies.service';
import BigNumber from 'bignumber.js';

@Component({
  selector: 'dashboard-stats',
  templateUrl: './dashboard-stats.component.html',
  styleUrls: ['./dashboard-stats.component.scss']
})
export class DashboardStatsComponent implements OnInit {
  @Input() stats: DashboardStats;
  @Input() currencies: Currencies;
  public stakingRewards = rewards.STAKING_REWARDS;
  public historicInterestPaid = '0';
  public totalCollateral = 0;

  constructor(
    private currenciesService: CurrenciesService
  ) { }

  ngOnInit() {
    if (this.stats && this.stats.borrowingStatistics && this.currencies) {
      const interestRateInfo = this.stats.borrowingStatistics.interestRateInfo.map((info: any) => {
        const currency = this.currencies?.underlyingCurrencies?.find((currency: Currency) => currency.symbol === info.underlyingSymbol);
        return {
          ...info,
          interestValueInUSD: this.currenciesService.weiToCurrency(info.totalInterest, currency, 'USD')
        }
      })
      interestRateInfo?.forEach((info: any) => {
        this.historicInterestPaid = new BigNumber(this.historicInterestPaid).plus(info.interestValueInUSD).toString();
      })
      this.currencies?.collateralCurrencies?.forEach((c: Currency) => {
        this.totalCollateral += c.lockedCollateralValueInUSD + c.unlockedCollateralValueInUSD
      })
    }
  }
}
