import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { filter, Subject, takeUntil } from "rxjs";
import { User } from "src/app/shared/interfaces/user/user";
import { AppState } from "src/app/store/app.reducer";

@Component({
  selector: "app-customization",
  templateUrl: "./customization.component.html",
  styleUrls: ["./customization.component.scss"],
})
export class CustomizationComponent implements OnInit {
  @Output() credentialsSubmitted: EventEmitter<any> = new EventEmitter();
  public credentialsForm: any;

  public user: User;
  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.credentialsForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
    });

    this.store.select(state => state.core.user)
      .pipe(filter(user => !!user), takeUntil(this.destroy$))
      .subscribe((user: User) => {
        this.user = user;
        this.credentialsForm.controls['firstName'].setValue(user.firstName ? user.firstName : undefined)
        this.credentialsForm.controls['lastName'].setValue(user.lastName ? user.lastName : undefined)
      })
  }

  onCredentialsSubmit() {
    this.credentialsSubmitted.emit(this.credentialsForm.value);
  }
}
