import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { debounceTime, filter, map, skip, Subject, take, takeUntil } from 'rxjs';
import { CurrenciesService } from 'src/app/core/services/currencies.service';
import { RewardsService } from 'src/app/core/services/rewards.service';
import { DEFAULT_DIALOG_CONFIG } from 'src/app/shared/constants';
import { Currencies } from 'src/app/shared/interfaces/currencies/currencies';
import { Currency } from 'src/app/shared/interfaces/currencies/currency';
import { ClaimedReward } from 'src/app/shared/interfaces/rewards/claimed-reward';
import { Reward } from 'src/app/shared/interfaces/rewards/reward';
import { StakingReward } from 'src/app/shared/interfaces/rewards/staking-reward';
import { User } from 'src/app/shared/interfaces/user/user';
import { AppState } from 'src/app/store/app.reducer';
import { GENERAL_ICONS } from 'src/assets/images/svg-icons';
import { environment } from 'src/environments/environment';
import { ClaimRewardsDialog } from '../../components/claim-rewards/claim-rewards.dialog';
import { ClaimStakingRewardsDialog } from '../../components/claim-staking-rewards/claim-staking-rewards.dialog';
import { DashboardStats } from 'src/app/shared/interfaces/statistics/dashboard-stats';
import { StatsService } from 'src/app/core/services/stats.service';

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss']
})
export class RewardsComponent implements OnInit, OnDestroy {
  public empty: boolean = true;
  public user: User;
  public icons = { ...GENERAL_ICONS }
  public environment = environment;
  public unclaimedRewards: Reward[];
  public claimedRewards: ClaimedReward[];
  public stakingRewards: StakingReward[];
  public totalClaimedRewardsUsd: number;
  public totalUnclaimedRewardsUsd: number;
  public totalStakingRewards: string;
  public isRedeeming: boolean;
  public currency: Currency;
  public stakingContract: string;
  public showReferral: boolean;
  private claimRewardsDialogRef: DialogRef<any>;
  private claimStakingRewardsDialogRef: DialogRef<any>;
  private destroy$ = new Subject<void>();
  stats: DashboardStats;

  constructor(
    private store: Store<AppState>,
    private rewardsService: RewardsService,
    private dialog: Dialog,
    private currenciesService: CurrenciesService,
    private activatedRoute: ActivatedRoute,
    private statsService: StatsService
  ) {
    this.currenciesService.fetchCurrencies();
    this.statsService.getDashboardStats();
    this.store.select(state => state.dashboard.stats)
      .pipe(
        filter(platformStats => !!platformStats),
        takeUntil(this.destroy$)
      )
      .subscribe((platformStats => {
        this.stats = platformStats;
      }))

    this.store.select(store => store.dashboard.currencies)
      .pipe(
        filter(currencies => !!currencies),
        take(1),
        map((currencies: Currencies) => currencies.underlyingCurrencies),
        takeUntil(this.destroy$)
      )
      .subscribe((currencies => {
        this.currency = currencies.find(c => c.symbol === this.stats?.rewardConfigData?.rewardToken)
      }))
  }

  ngOnInit(): void {
    this.showReferral = this.activatedRoute.snapshot.queryParams['ref'];
    this.store.select(store => store.core.user)
      .pipe(
        filter(user => !!user),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe((user: User) => {
        this.user = user;
        this.rewardsService.getRewards(this.user.ethAddress).subscribe(() => {

          this.store.select(store => store.rewards.unclaimedRewards)
            .pipe(
              filter(unclaimedRewards => !!unclaimedRewards),
              debounceTime(200),
              takeUntil(this.destroy$)
            )
            .subscribe((unclaimedRewards: any) => {
              this.unclaimedRewards = [...unclaimedRewards].reverse();
              this.empty = this.empty && this.unclaimedRewards.length === 0;
            })

          this.store.select(store => store.rewards.claimedRewards)
            .pipe(
              filter(claimedRewards => !!claimedRewards),
              debounceTime(200),
              takeUntil(this.destroy$)
            )
            .subscribe((claimedRewards: ClaimedReward[]) => {
              // Temoprary fix to reverse the order of the rewards within a claimed reward group
              this.claimedRewards = claimedRewards.map((claimedReward: ClaimedReward) => ({
                ...claimedReward,
                rewards: [...claimedReward.rewards].reverse()
              }))
              this.empty = this.empty && this.claimedRewards.length === 0;
            })

          // this.store.select(store => store.rewards.stakingRewards)
          //   .pipe(filter(stakingRewards => !!stakingRewards), takeUntil(this.destroy$))
          //   .subscribe((stakingRewards: any) => {
          //     this.stakingRewards = stakingRewards;
          //     this.empty = this.empty && this.stakingRewards.length === 0;
          //   })

          this.store.select(store => store.rewards)
            .pipe(skip(1), takeUntil(this.destroy$))
            .subscribe((rewards: any) => {
              this.totalClaimedRewardsUsd = rewards.totalClaimedRewardsUsd;
              this.totalUnclaimedRewardsUsd = rewards.totalUnclaimedRewardsUsd;
              this.totalStakingRewards = rewards.totalStakingRewards;
              this.isRedeeming = rewards.isRedeeming;
              this.stakingContract = rewards.stakingContract;
            })
        })

        // if (user.partnered) {
        // TODO Show partner dashboard
        // }
        // this.rewardsService.getStakingRewards(this.user.ethAddress)
      })



  }

  redeemReward(claimedReward: ClaimedReward) {
    this.rewardsService.redeemReward(claimedReward);
  }

  openClaimRewardsDialog() {
    this.claimRewardsDialogRef = this.dialog.open(ClaimRewardsDialog, {
      id: 'claim-rewards',
      ...DEFAULT_DIALOG_CONFIG,
      data: {
        totalUnclaimedRewardsUsd: this.totalUnclaimedRewardsUsd
      }
    });

    this.claimRewardsDialogRef.componentInstance['claimConfirmed']
      .pipe(takeUntil(this.destroy$))
      .subscribe((claimConfirmed) => {
        this.rewardsService.claimRewards(this.user.ethAddress);
        this.claimRewardsDialogRef.close();
      })

    this.claimRewardsDialogRef.componentInstance['reject']
      .pipe(takeUntil(this.destroy$))
      .subscribe((reject) => {
        this.claimRewardsDialogRef.close();
      })
  }

  openClaimStakingRewardsDialog() {
    this.claimStakingRewardsDialogRef = this.dialog.open(ClaimStakingRewardsDialog, {
      id: 'claim-staking-rewards',
      ...DEFAULT_DIALOG_CONFIG,
      data: {
        totalStakingRewards: this.totalStakingRewards,
        currency: this.currency
      }
    });

    this.claimStakingRewardsDialogRef.componentInstance['claimConfirmed']
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(() => {
        this.rewardsService.claimStakingRewards(this.stakingContract);
        this.claimStakingRewardsDialogRef.close();
      })

    this.claimStakingRewardsDialogRef.componentInstance['reject']
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(() => {
        this.claimStakingRewardsDialogRef.close();
      })
  }

  toggleReferral() {
    this.showReferral = !this.showReferral;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
