import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { User } from '../../interfaces/user/user';
import { UserService } from 'src/app/core/services/user.service';
import { Web3Service } from 'src/app/core/web3/web3.service';
import { concatMap, debounceTime, Subject, takeUntil, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { GENERAL_ICONS, MENU_ICONS } from 'src/assets/images/svg-icons';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { SigningDialogComponent } from '../../components/signing-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthChallenge } from '../../interfaces/user/auth-challenge';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'shared-account-center',
  templateUrl: './account-center.component.html',
  styleUrls: ['./account-center.component.scss']
})

export class AccountCenterComponent implements OnInit, OnDestroy {
  public user: User;
  public icons = { ...GENERAL_ICONS, ...MENU_ICONS };
  public signing: boolean;
  public dropdownOpen: boolean;
  public outClick: boolean;
  public destroy$ = new Subject<void>();
  public referralCode: string;
  public signingDialog: DialogRef<any>;
  public connecting: boolean;
  private isBrowser: boolean;

  constructor(
    private store: Store<AppState>,
    private web3Service: Web3Service,
    private userService: UserService,
    public dialog: Dialog,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    localStorage.getItem('token') ? this.userService.getUser() : false;
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.store.select('core')
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe((coreStore => {
        this.user = coreStore.user;
        this.signing = coreStore.signing;
        this.signing ? this.openDialog() : this.closeDialog();
        this.connecting = coreStore.connecting;
      }));

    this.activatedRoute.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.referralCode = params['ref'];
      });
  }

  openDialog() {
    this.signingDialog = this.dialog.open(SigningDialogComponent, {
      minWidth: '300px',
      autoFocus: false
    });
    this.signingDialog.componentInstance['resetWallet']
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.resetWallet();
        this.onLogIn();
      });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  resetWallet() {
    localStorage.removeItem('connectedWallets');
    localStorage.getItem('walletconnect') ? localStorage.removeItem('walletconnect') : false;
    this.userService.setConnecting(false)
    this.userService.setSigning(false);
    this.toastr.info('', 'Selected wallet was reset!');
  }

  onOutClick(): void {
    this.outClick = true;
    setTimeout(() => {
      this.outClick = false;
    }, 100);
  }

  onLogIn(): void {
    this.userService.setConnecting(true);
    this.web3Service.connectWallet()
      .pipe(
        concatMap(({ account, message }) => this.userService.walletAuth(account, message)),
        tap(() => this.userService.setSigning(true)),
        concatMap((authChallenge: AuthChallenge) => this.web3Service.sign(authChallenge)),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: ({ address, signature }) => {
          this.userService.logIn(address, signature, this.referralCode);
          this.userService.setConnecting(false)
          this.userService.setSigning(false);
        },
        error: (err) => {
          this.userService.setConnecting(false);
          this.userService.setSigning(false);
          this.userService.updateUser(undefined);
          if (err.message === 'Connection Failed') {
            this.toastr.warning('Please try again.', 'Could not connect!');
          }
          if (err.message === 'Signing Failed') {
            this.toastr.warning('Please reload the page and retry.', 'Failed to prove wallet ownership!');
          }
        }
      })
  }

  onLogOut(): void {
    this.userService.logOut();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
