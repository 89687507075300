<div class="table-wrapper max-h-80 rewards-table">
  <table>
    <thead>
      <tr>
        <th scope="col">Amount</th>
        <th scope="col">Status</th>
        <th scope="col" class="text-right">Date Received</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let r of stakingRewards">
        <th scope="row">
          <div class="font-medium">
            {{ r.amount | weiToCurrency:currency | number:'1.2-2' }} SMARTCREDIT
          </div>
          <div class="text-sm text-slate-500">
            ${{ r.amount | weiToCurrency:currency:'USD' | number:'1.2-2' }}
          </div>
        </th>
        <td>
          <div class="badge default-inverse">UNCLAIMED</div>
        </td>
        <td>
          <div class="text-sm text-slate-500">
            {{ r.createdAt | date }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="!stakingRewards || stakingRewards.length === 0"
    class="w-full mx-auto text-center text-slate-500 my-6">
    <div class="text-xl font-semibold">No staking rewards</div>
    <div class="text-sm font-normal">You have no staking rewards yet.</div>
  </div>
</div>
