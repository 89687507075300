<div class="table-wrapper max-h-80 rewards-table">
  <table>
    <thead>
      <tr>
        <th scope="col">Total</th>
        <th scope="col">Status</th>
        <th scope="col"><span class="sr-only">Redeem</span></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let rG of claimedRewards; let i = index">
        <tr
          (click)="
            showDetailsIndex !== i
              ? (showDetailsIndex = i)
              : (showDetailsIndex = -1)
          "
        >
          <th scope="row">
            <div class="font-medium truncate">
              {{
                +rG.total /
                  (rG.rewards[0].currency
                    ? rG.rewards[0].currency?.exchangeRate
                    : rG.rewards[0].Currency.exchangeRate) | number : "1.2-2"
              }}
              {{ rewardToken }}
            </div>
            <!-- <div class="text-sm text-slate-500">
            ${{ r.usdValue }}
          </div> -->
          </th>
          <td>
            <div class="text-sm text-slate-500">
              {{ rG.status }}
            </div>
          </td>
          <td>
            <button class="default xs mr-2">Details</button>
            <button
              *ngIf="rG.status === 'READY FOR REDEEM'"
              class="primary xs"
              [disabled]="isRedeeming"
              (click)="
                onRedeem(rG);
                showDetailsIndex !== i
                  ? (showDetailsIndex = i)
                  : (showDetailsIndex = -1)
              "
            >
              Redeem
            </button>
          </td>
        </tr>
        <div
          class="max-h-0 transition-all ease-out overflow-hidden"
          [ngClass]="{ 'max-h-[9999px]': showDetailsIndex === i }"
        >
          <div *ngFor="let r of rG.rewards" class="flex text-slate-500 text-sm">
            <div class="w-4/12 truncate text-sm px-6 py-2">
              <span class="font-medium text-sm">{{
                r.value | number : "1.2-2"
              }}</span>
              {{ r.currency ? r.currency.symbol : r.Currency.symbol }}
            </div>
            <div class="w-5/12 px-6 py-2">
              <div
                class="badge truncate"
                [title]="r.rewardCode"
                [ngClass]="{
                  primary: r.rewardCode === 'Borrower Weekly Reward',
                  success: r.rewardCode === 'Lender Weekly Reward',
                  warning: r.rewardCode === 'Withdrawn Gasless Re-Staked',
                  'success-inverse': r.rewardCode === 'Referrer Reward',
                  'primary-inverse': r.rewardCode === 'First Loan Over $1000'
                }"
              >
                {{ r.rewardCode }}
              </div>
            </div>
            <div class="w-3/12 text-sm truncate px-6 py-2">
              {{ r.rewardedAt | date }}
            </div>
          </div>
        </div>
      </ng-container>
    </tbody>
  </table>
  <div
    *ngIf="!claimedRewards || claimedRewards.length === 0"
    class="w-full mx-auto text-center text-slate-500 my-6"
  >
    <div class="text-xl font-semibold">No claimed rewards</div>
    <div class="text-sm font-normal">You haven't claimed any rewards yet!</div>
  </div>
</div>
