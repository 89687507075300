import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import BigNumber from 'bignumber.js';
import { CurrenciesService } from 'src/app/core/services/currencies.service';
import { Web3Service } from 'src/app/core/web3/web3.service';
import { Currency } from 'src/app/shared/interfaces/currencies/currency';
import { regEx } from 'src/config/app.config';

@Component({
  selector: 'app-deposit-fif',
  templateUrl: './deposit-fif.dialog.html',
  styleUrls: ['./deposit-fif.dialog.scss']
})
export class DepositFifDialog {
  public depositAmount: FormControl = new FormControl(null, [Validators.required, Validators.min(0.000001), Validators.pattern(regEx.numbers), this.minBalanceValidator()],);
  public currency: Currency;
  public availableBalance: number;
  @Output('deposited') deposited: EventEmitter<number> = new EventEmitter()
  @Output('reject') reject: EventEmitter<boolean> = new EventEmitter();

  constructor(
    @Inject(DIALOG_DATA) public data: { ethAddress: string, contractAddress: string, currency: Currency },
    private web3Service: Web3Service,
    private currenciesService: CurrenciesService
  ) {
    this.currency = data.currency;

    this.web3Service.fetchBalance(
      data.currency.ethAddress,
      data.ethAddress,
      data.currency.symbol
    ).subscribe(res => this.availableBalance = this.currenciesService.weiToCurrency(res, data.currency))

  }

  ngOnInit(): void {
    this.depositAmount.setValidators([
      Validators.required,
      Validators.pattern(regEx.numbers),
      Validators.min(0.000001),
      this.minBalanceValidator()
    ]);
  }

  onDeposit() {
    this.deposited.emit(this.depositAmount.value);
  }

  onClose() {
    this.reject.emit(null);
  }

  minBalanceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const depositAmount = new BigNumber(control.value).toNumber();
      return depositAmount > this.availableBalance ? { minBalance: true } : null;
    };
  }
}
