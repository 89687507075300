import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileRoutingModule } from './profile-routing.module';
import { SharedModule } from '../shared/shared.module';

import { ProfileComponent } from './pages/profile/profile.component';
import { DetailsComponent } from './components/details/details.component';
import { CustomizationComponent } from './components/customization/customization.component';
import { KycComponent } from './components/kyc/kyc.component';
import { CoreModule } from '../core/core.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { EmailAddDialog } from './components/email-add/email-add.dialog';
import { DeveloperOptionsComponent } from './components/developer-options/developer-options.component';


@NgModule({
  declarations: [
    ProfileComponent,
    DetailsComponent,
    CustomizationComponent,
    KycComponent,
    EmailAddDialog,
    DeveloperOptionsComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ProfileRoutingModule,
    SharedModule,
    CoreModule,
    NgxChartsModule,
    ClipboardModule
  ]
})
export class ProfileModule { }
