import { Component, OnInit } from '@angular/core';
import { GENERAL_ICONS } from 'src/assets/images/svg-icons';

@Component({
  selector: 'shared-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss']
})
export class ThemeComponent implements OnInit {
  public iconAdd = GENERAL_ICONS.add;

  constructor() { }

  ngOnInit(): void {
  }

}
