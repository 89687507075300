import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BorrowGuard {
  constructor(private router: Router) { }

  canActivate(): boolean {
    if (!localStorage.getItem('token')) {
      this.router.navigate(['/purchase']);
      const agreement = localStorage.getItem('onboard.js:agreement');
      const cookieConsent = localStorage.getItem('cookieConsent');
      localStorage.clear();
      if (agreement) localStorage.setItem('onboard.js:agreement', agreement);
      if (cookieConsent) localStorage.setItem('cookieConsent', cookieConsent);
      return false;
    } else {
      return true
    }
  }
}
