import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GENERAL_ICONS, MENU_ICONS } from 'src/assets/images/svg-icons';
import { AAVE, COMPOUND, MAKER } from 'src/config/segmentation.config';
import { SegmentationConfig } from '../interfaces/segmentation/segmentation-config';
import { CurrencyPipe } from '@angular/common';
import { WeiToScPipe } from '../pipes/wei-to-sc.pipe';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'shared-promo-cards',
  templateUrl: './promo-cards.component.html',
  styleUrls: ['./promo-cards.component.scss']
})
export class PromoCardsComponent implements OnInit, OnChanges {
  @Input() rewardConfigData: any;
  @Input('segmentation') segmentationInfo: any;
  public icons = { ...MENU_ICONS, ...GENERAL_ICONS };
  public showDefaultBorrow: boolean;
  public showDefaultLend: boolean;
  public inviteCard: SegmentationConfig;
  public borrowCard: SegmentationConfig;
  public lendCard: SegmentationConfig;
  public promoCards: SegmentationConfig[] = [];
  public environment = environment;

  constructor(
    private currencyPipe: CurrencyPipe,
    private weiToScPipe: WeiToScPipe
  ) { }

  ngOnInit(): void {
    this.inviteCard = {
      title: 'Invite and earn',
      description: `Invite friends and earn ${this.rewardConfigData?.referrerReward || 0} SMARTCREDIT on their first loan over ${this.currencyPipe.transform(this.rewardConfigData?.loanValue || 1000, 'USD', 'symbol-narrow', '1.0-0')}.`,
      actions: [{
        name: 'Invite',
        routerLink: '/rewards'
      }],
      image: 'assets/images/invite-friends.webp'
    }
    this.borrowCard = {
      type: 'borrow',
      title: 'Borrow and earn',
      description: `Borrow your first loan over ${this.currencyPipe.transform(this.rewardConfigData?.loanValue || 10, 'USD', 'symbol-narrow', '1.0-0')} and earn ${this.rewardConfigData?.refereeReward || 0} SMARTCREDIT.`,
      actions: [{
        name: 'Purchase',
        routerLink: '/purchase'
      }],
      image: 'assets/images/first-loan.webp'
    }
    this.lendCard = {
      type: 'lend',
      title: 'Fixed Rate Lending',
      description: 'Configure fund distribution & get a fixed rate of interest on your crypto asset.',
      actions: [{
        name: 'Sell',
        routerLink: '/sell'
      }]
    }
    this.promoCards = [
      ...this.promoCards,
      this.inviteCard,
      this.borrowCard,
      this.lendCard
    ]
    this.addSegmentationCards(this.segmentationInfo);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.addSegmentationCards(this.segmentationInfo);
  }

  addSegmentationCards(segmentation: any) {
    if (segmentation && Number(segmentation.walletQuality) > 0) {
      const segmentInfo = JSON.parse((segmentation.segmentInfo));
      if (segmentInfo.Maker === 1) {
        this.promoCards.unshift(MAKER.find(card => card.type === 'borrow'));
      }
      if (segmentInfo.Compound_Lend === 1) {
        this.promoCards.unshift(COMPOUND.find(card => card.type === 'lend'));
      }
      if (segmentInfo.Compound_Borrow === 1) {
        this.promoCards.unshift(COMPOUND.find(card => card.type === 'borrow'));
      }
      if (segmentInfo.Aave_borrow === 1) {
        this.promoCards.unshift(AAVE.find(card => card.type === 'borrow'));
      }
      if (segmentInfo.Aave_lend === 1) {
        this.promoCards.unshift(AAVE.find(card => card.type === 'lend'));
      }
    }
  }
}
