import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LendGuard } from '../core/guards/lend.guard';
import { FifComponent } from './pages/fif/fif.component';
import { FifsComponent } from './pages/fifs/fifs.component';
import { LendComponent } from './pages/lend/lend.component';

const routes: Routes = [{
  path: '',
  component: LendComponent,
}, {
  path: 'dashboard',
  component: FifsComponent,
  canActivate: [LendGuard]
}, {
  path: 'fif',
  component: FifComponent,
  canActivate: [LendGuard]
}, {
  path: 'fif/:id',
  component: FifComponent,
  canActivate: [LendGuard]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LendingRoutingModule { }
