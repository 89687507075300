<div class="rounded-3xl border-slate-200 bg-white p-4 text-center">
  <h2 class="font-semibold text-slate-600 text-lg mb-4">Creating a new Loan Request!</h2>
  <div class="bg-slate-50 rounded-2xl w-full flex justify-between items-center mb-4 px-4 py-2">
    <div class="text-left mr-2">
      <div class="font-semibold text-slate-600 mb-1">Set Up a Finance Line</div>
      <div class="text-slate-500">Complete the transaction to create a new Finance Line. This action is 
        only required once for this asset and collateral pair.
      </div>
    </div>
    <spinner *ngIf="!loanData.creditLineAddress" size="xs"></spinner>
    <sc-icon *ngIf="loanData.creditLineAddress" [src]="icons.accept"></sc-icon>
  </div>
  <!-- <div class="bg-slate-50 rounded-2xl w-full flex justify-between items-center px-4 py-2">
    <div class="text-left mr-2">
      <div class="font-semibold text-slate-600 mb-1">Create the Loan Request</div>
      <div class="text-slate-500">Sign the message from your wallet to confirm the loan request. This does not cost any ISLM!</div>
    </div>
    <spinner *ngIf="loanData.creditLineAddress" size="xs"></spinner>
    <div *ngIf="!loanData.creditLineAddress" class="h-5 w-5 bg-transparent border-4 border-slate-200 rounded-full"></div>
  </div> -->
</div>
