import { Action, createReducer, on } from "@ngrx/store";
import { User } from "src/app/shared/interfaces/user/user";
import * as CoreActions from "./core.actions"

export interface State {
  user: User | undefined;
  connecting: boolean;
  signing: boolean;
  segmentation: any;
}

const initialState = {
  user: undefined,
  connecting: false,
  signing: false,
  segmentation: undefined
}

const _coreReducer = createReducer(
  initialState,
  on(
    CoreActions.getUser,
    (state, action) => ({
      ...state,
      user: {
        ...state.user,
        ...action.user,
      } 
    })
  ),
  on(
    CoreActions.connecting,
    (state, action) => ({
      ...state,
      connecting: action.connecting
    })
  ),
  on(
    CoreActions.signing,
    (state, action) => ({
      ...state,
      signing: action.signing
    })
  ),
  on(
    CoreActions.updateUser,
    (state, action) => ({
      ...state,
      user: action.user,
    })
  ),
  on(
    CoreActions.updateUserDomain,
    (state, action) => ({
      ...state,
      user: {
        ...state.user,
        domain: action.domain
      }
    })
  ),
  on(
    CoreActions.resetState,
    () => initialState
  )
)

export function coreReducer(state: State, action: Action) {
  return _coreReducer(state, action);
}
