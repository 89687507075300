import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject, take, takeUntil } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import { MENU_ICONS } from 'src/assets/images/svg-icons';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnDestroy {
  public arrow = MENU_ICONS.rightArrow;
  public supportForm: FormGroup;
  public showErrors: boolean = true;
  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private toastr: ToastrService
  ) {
    this.supportForm = this.fb.group({
      category: ['', Validators.required, null, {nonNullable: true}],
      title: ['', Validators.required, null, {nonNullable: true}],
      description: ['', Validators.required, null, {nonNullable: true}],
      email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)], null, {nonNullable: true}]
    })
  }

  submit(formValues: any) {
    if (this.supportForm.valid) {
      this.userService.submitSupportRequest(formValues).pipe(take(1), takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.toastr.success('', 'Message sent successfully!');
        this.supportForm.reset();
        this.showErrors = false;
      })
    } else {
      this.toastr.warning('', 'Please fill all the fields!');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
