import { Pipe, PipeTransform } from '@angular/core';
import BigNumber from 'bignumber.js';
import { Currency } from '../interfaces/currencies/currency';

@Pipe({
  name: 'currencyToWei'
})
export class CurrencyToWeiPipe implements PipeTransform {

  transform(value: number, currency: Currency, sourceCurrency?: 'USD'): number {
    if (sourceCurrency === 'USD') {
      return new BigNumber(value).times(currency.exchangeRate).times(new BigNumber(10).pow(currency?.decimalPlaces)).toNumber();
    }
    return new BigNumber(value).times(new BigNumber(10).pow(currency?.decimalPlaces)).toNumber();
  }
}
