<!-- MOBILE MENU, desktop is in header -->
<section *ngIf="currentRoute !== '/error'" class="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white border-t">
  <div *ngIf="mainMenu[0].items" class="flex justify-between items-center">
    <div *ngFor="let menuItem of mainMenu[0].items; let i = index" class="menu-item flex w-full">
      <a *ngIf="!menuItem.group" class="h-[65px] flex items-center flex-col w-full text-center text-sc-black pt-3 pb-2"
        [routerLink]="[menuItem.routerLink]" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }"
        [href]="baseUrl + menuItem.routerLink">
        <sc-icon [src]="menuItem.icon"></sc-icon>
        <div class="text-xs mt-2">{{ menuItem.title }}</div>
      </a>
      <div *ngIf="menuItem.group" class="h-[65px] flex items-center flex-col w-full text-center pt-3 pb-2"
        (click)="toggle(trigger, i)" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
        <sc-icon [src]="menuItem.icon"></sc-icon>
        <div class="text-xs mt-2">{{ menuItem.title }}</div>
        <ng-template *ngIf="menuItem.group && i === triggerOriginIndex && !overlayOutsideClicked" cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="companyMenuOpen"
          (overlayOutsideClick)="companyMenuOpen = false; onOverlayOutsideClick()">
          <ul *ngIf="menuItem.items" class="menu-overlay mt-1">
            <li *ngFor="let subItem of menuItem.items" class="flex items-center justify-between">
              <ng-container *ngIf="subItem.link">
                <a class="st" href="{{ subItem.link }}" target="{{ subItem.blank ? '_blank' : '' }}" class="ml-2">
                  <sc-icon *ngIf="menuItem.title === 'AI & Big Data'" [size]="'0.07'" [src]="icons.st"
                    [height]="'0.2rem'" [width]="'1rem'"></sc-icon>
                  {{ subItem.title }}
                  <span *ngIf="subItem.badge" class="badge secondary ml-1.5">{{ subItem.badge }}</span>
                </a>
                <sc-icon *ngIf="subItem.link" [size]="'0.3'" [src]="icons.externalOp" [width]="'1rem'"
                  class="mr-6"></sc-icon>
              </ng-container>
              <ng-container *ngIf="!subItem.link && !subItem.routerLink">
                <a class="cursor-pointer" *ngIf="subItem.title === 'Exchange'" (click)="openLetsExchangeDialog()"
                  class="ml-2">
                  {{ subItem.title }}
                </a>
              </ng-container>
              <ng-container *ngIf="subItem.routerLink">
                <a [routerLink]="[subItem.routerLink]" [href]="baseUrl + subItem.routerLink"
                  (click)="onOverlayOutsideClick()" class="cursor-pointer ml-2">
                  {{ subItem.title }}
                  <span *ngIf="subItem.badge" class="badge secondary ml-1.5">{{ subItem.badge }}</span>
                </a>
              </ng-container>
            </li>
          </ul>
        </ng-template>
      </div>
    </div>
  </div>
</section>