import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyTooltipDefaultOptions as MatTooltipDefaultOptions, MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/legacy-tooltip';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import BigNumber from 'bignumber.js';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  merge,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  timer
} from 'rxjs';
import { BorrowingService } from 'src/app/core/services/borrowing.service';
import { CurrenciesService } from 'src/app/core/services/currencies.service';
import { config, DEFAULT_DIALOG_CONFIG } from 'src/app/shared/constants';
import { CreditLine } from 'src/app/shared/interfaces/borrowing/credit-line';
import { Loan, LoanStates } from 'src/app/shared/interfaces/borrowing/loan';
import { Currency } from 'src/app/shared/interfaces/currencies/currency';
import { User } from 'src/app/shared/interfaces/user/user';
import { AppState } from 'src/app/store/app.reducer';
import { CRYPTO_ICONS, GENERAL_ICONS } from 'src/assets/images/svg-icons';
import { DepositCollateralDialog } from '../../components/deposit-collateral/deposit-collateral.dialog';
import { IncreaseCollateralDialog } from '../../components/increase-collateral/increase-collateral.dialog';
import { TerminateLoanDialog } from '../../components/terminate-loan/terminate-loan.dialog';
import { Web3Service } from 'src/app/core/web3/web3.service';

export const tooltipOptions: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 0,
};

@Component({
  selector: 'app-loan',
  templateUrl: './loan.component.html',
  styleUrls: ['./loan.component.scss'],
  providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: tooltipOptions }],
})
export class LoanComponent implements OnInit, OnDestroy {

  public icons = { ...GENERAL_ICONS, ...CRYPTO_ICONS }
  public loan: Loan;
  public repayingLoan: boolean;
  public depositingCollateral: boolean;
  public approvingRepayment: boolean;
  public increasingCollateral: boolean;
  public user: User;
  public loanStates: LoanStates;
  public quickActions: number;
  public creditLine: CreditLine;
  public collateralInCreditLine: Currency;
  public currentCollateralRatio: number;
  public requiredTokenAmount: number;
  public liquidationNoticeThreshold = config.LIQUIDATION_NOTICE_THRESHOLD;
  public increasedCollateralAmount: string = '0';
  public fiveSecondsPassed: boolean;
  private depositDialogRef: DialogRef<any>
  private terminateLoanDialogRef: DialogRef<any>
  private increaseCollateralDialogRef: DialogRef<any>;
  private destroy$ = new Subject<void>();
  private loanId: string;
  private creditLineAddress: string;
  public updatingStatus: boolean = false;
  private isNewCreditLine: boolean = false;
  private initialDelay: number;
  public liquidationLimitUSD: number;
  public isFinancing: boolean = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private borrowingService: BorrowingService,
    private store: Store<AppState>,
    private currenciesService: CurrenciesService,
    public dialog: Dialog) {
    this.loanId = this.activatedRoute.snapshot.params['loanId']
    this.creditLineAddress = this.activatedRoute.snapshot.params['creditLineAddress'];
    this.activatedRoute.queryParams.subscribe(params => {
      this.isNewCreditLine = params['isNewCreditLine'] === 'true';
    });
    this.initialDelay = !this.isNewCreditLine ? 0 : 5000;
    this.currenciesService.fetchCurrencies ? this.currenciesService.fetchCurrencies() : null;
    this.updatingStatus = true;
    // timer(5000).pipe(takeUntil(this.destroy$)).subscribe(() => {
    //   this.fiveSecondsPassed = true;
    // })
  }

  ngOnInit(): void {


    this.store.select(state => state.core.user)
      .pipe(filter(user => !!user), takeUntil(this.destroy$))
      .subscribe(async (user: User) => {
        this.user = user;
        if (user) {
          await this.fetchLoan();
        }
      })

    timer(0, 30000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async () => {
        this.updatingStatus = true;
        await this.fetchLoan();
      })
  }

  async fetchLoan() {

    await this.borrowingService.getLoan(this.loanId, this.user.creditData.riskRating).then(() => {

      this.borrowingService.getCreditLines({ ownerAddress: this.user.ethAddress });

      const currencies$ = this.store.select(state => state.dashboard.currencies)
        .pipe(
          filter(c => !!c),
          take(1)
        );

      const creditLine$ = this.store.select(state => state.borrowing.creditLines)
        .pipe(
          filter(cLs => cLs && cLs.length > 0),
          map((cLs: CreditLine[]): CreditLine | undefined => cLs.find(cL => cL.contractAddress === this.creditLineAddress)),
          take(1));

      const loan$ = this.store.select(state => state.borrowing.loans)
        .pipe(
          filter(loans => loans && loans.length > 0),
          map(loans => loans.find(loan => loan.id === this.activatedRoute.snapshot.params['loanId'])),
          take(1));

      combineLatest({
        currencies: currencies$,
        creditLine: creditLine$,
        loan: loan$
      }).pipe(takeUntil(this.destroy$),
        catchError(error => {
          console.error('Error occurred while fetching data:', error);
          return EMPTY;
        }))
        .subscribe(async ({ currencies, creditLine, loan }) => {
          this.creditLine = creditLine;
          this.loan = loan;

          if (creditLine) {
            this.borrowingService.getCreditLineCurrenciesAndBalances(creditLine, currencies.underlyingCurrencies, currencies.collateralCurrencies)
              .subscribe(() => {

                this.store.select(state => state.borrowing.creditLines)
                  .pipe(
                    filter(cLs => cLs && cLs.length > 0),
                    map((cLs: CreditLine[]): CreditLine | undefined => cLs.find(cL => cL.contractAddress === this.creditLineAddress)),
                    take(1)).subscribe((updatedLine) => {
                      this.creditLine = updatedLine;
                      this.collateralInCreditLine = updatedLine.collaterals.find(c => c.symbol === loan.collateral.symbol);
                      this.borrowingService.setLoanStates(loan, updatedLine)
                    })

                this.store.select(state => state.borrowing.loans)
                  .pipe(
                    filter(loans => loans && loans.length > 0),
                    map(loans => loans.find(loan => loan.id === this.activatedRoute.snapshot.params['loanId'])),
                    take(1)).subscribe((updatedLoan) => {
                      this.loan = updatedLoan
                      if (updatedLoan) {
                        const loanUsdAmount = this.currenciesService.weiToCurrency(updatedLoan.loanAmount, updatedLoan.underlying, 'USD');
                        this.requiredTokenAmount = new BigNumber(loanUsdAmount).times(0.2).div(updatedLoan.collateral.exchangeRate).precision(5).toNumber();
                        this.liquidationLimitUSD = (loanUsdAmount * loan.liquidationLimit) / 100;

                        // this.quickActions = 0;
                        if (updatedLoan.states) {
                          // Object.keys(loan.states).forEach(s => {
                          //   loan.states[s] ? this.quickActions++ : null;
                          // })
                          const collateralUsd = this.currenciesService.weiToCurrency(updatedLoan.currentCollateralAmount || updatedLoan.collateralInfo.amount, updatedLoan.collateral, 'USD');
                          this.currentCollateralRatio = collateralUsd / loanUsdAmount * 100;

                        }
                      }
                    })
              })
          }
          this.updatingStatus = false;
        })

      this.store.select(state => state.borrowing)
        .pipe(takeUntil(this.destroy$))
        .subscribe((lendingState) => {
          this.repayingLoan = lendingState.repayingLoan;
          this.depositingCollateral = lendingState.depositingCollateral;
          this.approvingRepayment = lendingState.approvingRepayment;
          this.increasingCollateral = lendingState.increasingCollateral;
        })
    });


  }

  async repayLoan() {
    this.isFinancing = true;
    this.borrowingService.repayLoan(this.loan);
    await this.fetchLoan();
    this.updateLoanState()
    if (this.loan.approvalNeeded) {
      this.isFinancing = false;
    } else {
      timer(30000)
        .pipe(takeUntil(this.destroy$))
        .subscribe(async () => {
          this.isFinancing = false;
          this.destroy$.next();
        })
    }
  }

  async approveRepayment() {
    this.isFinancing = true;

    this.borrowingService.approveRepayment(this.loan);
    await this.fetchLoan();
    this.updateLoanState()
    this.isFinancing = false;

  }

  reloadPage() {
    // TEMPORARY
    location.reload();
  }

  openDepositDialog() {
    this.depositDialogRef = this.dialog.open(DepositCollateralDialog, {
      id: 'deposit-collateral',
      ...DEFAULT_DIALOG_CONFIG,
      data: {
        ethAddress: this.user.ethAddress,
        collateral: this.loan.collateral,
        requiredCollateral: this.loan.collateralInfo.amount,
        unlockedCollateral: this.collateralInCreditLine.unlocked,
      }
    });

    this.depositDialogRef.componentInstance['deposited']
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (amount) => {
        this.borrowingService.depositCollateral(this.loan, amount);
        this.depositDialogRef.close();
        this.updateLoanState();
      })

    this.depositDialogRef.componentInstance['reject']
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(() => {
        this.depositDialogRef.close();
      })
  }

  openTerminateLoanDialog() {
    this.terminateLoanDialogRef = this.dialog.open(TerminateLoanDialog, {
      id: 'terminate-loan',
      minWidth: '350px',
      maxWidth: '350px',
      autoFocus: false,
    });

    this.terminateLoanDialogRef.componentInstance['terminationConfirmed']
      .pipe(takeUntil(merge(this.terminateLoanDialogRef.closed, this.destroy$)))
      .subscribe(() => {
        this.borrowingService.terminateLoan(this.loan.id);
        this.terminateLoanDialogRef.close();
        this.updateLoanState();
      })

    this.terminateLoanDialogRef.componentInstance['reject']
      .pipe(takeUntil(merge(this.terminateLoanDialogRef.closed, this.destroy$)))
      .subscribe(() => {
        this.terminateLoanDialogRef.close();
      })


  }

  openIncreaseCollateralDialog() {
    this.increaseCollateralDialogRef = this.dialog.open(IncreaseCollateralDialog, {
      id: 'increase-collateral',
      ...DEFAULT_DIALOG_CONFIG,
      data: {
        collateral: this.loan.collateral,
        requiredAmount: this.requiredTokenAmount,
        unlockedCollateral: this.collateralInCreditLine.unlocked,
        depositingCollateral: this.depositingCollateral,
        loanStates: this.loan.states
      }
    });

    this.increaseCollateralDialogRef.componentInstance['increase']
      .pipe(takeUntil(merge(this.increaseCollateralDialogRef.closed, this.destroy$)))
      .subscribe((amount) => {
        this.borrowingService.increaseCollateral(this.loan, amount, this.creditLine, this.collateralInCreditLine);
        this.increaseCollateralDialogRef.close();
        this.updateLoanState();
      })

    this.increaseCollateralDialogRef.componentInstance['transfer']
      .pipe(takeUntil(merge(this.increaseCollateralDialogRef.closed, this.destroy$)))
      .subscribe((amount) => {
        this.borrowingService.depositToCreditLine(this.creditLine, this.collateralInCreditLine, amount);
        this.increasedCollateralAmount = amount;
        this.increaseCollateralDialogRef.close();
        this.updateLoanState();
      })

    this.increaseCollateralDialogRef.componentInstance['reject']
      .pipe(takeUntil(merge(this.increaseCollateralDialogRef.closed, this.destroy$)))
      .subscribe(() => {
        this.increaseCollateralDialogRef.close();
      })
  }

  createFinance(): void {
    this.isFinancing = true;
    this.borrowingService.createLoan(this.loan);
    timer(0, 30000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async () => {
        if (!this.loan.states.matched) {
          this.isFinancing = false;
          this.destroy$.next()
        }
      })
  }

  updateLoanState(): void {
    this.activatedRoute.params
      .pipe(
        switchMap(params =>
          this.store.select(state => state.borrowing.loans).pipe(
            filter(loans => loans && loans.length > 0),
            map(loans => loans.find(loan => loan.id === params['loanId'])),
            distinctUntilChanged()
          )
        )
      )
      .subscribe((updatedLoan: Loan | undefined) => {
        if (updatedLoan) {
          this.loan = updatedLoan;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
