import { gql } from 'apollo-angular';

export const queryUser = gql`
  query {
    me {
    userId,
    firstName,
    lastName,
    email,
    role,
    creditData {
      creditLimit,
      riskRating
    },
    ethAddress,
    referralCode,
    partnered,
    telegram,
    email_me
    telegram_me
  },
  riskRateFees {
    rating,
    marginRate,
    maxInsurancePremiumRate,
    platformFee
  }
}
`;

export const rewardConfigQuery = gql`
  query {
    rewardConfigData {
      key,
      value
    }
  }
`;

export const logIn = gql`
  mutation ($wallet: String!, $sig: String!, $refCode: String, $refId: String, $fingerprintHash: String) {
    login(input: {
      walletAddress: $wallet,
      referrer_code: $refCode,
      user_ref_id: $refId,
      signature: $sig,
      fingerprintHash: $fingerprintHash
    }) {
    jwt
  }
}
`;

export const updateUser = gql`
  mutation($first_name: String, $last_name: String, $address1: String, $address2: String, $country: String, $birthday: String, $email: String, $proofOfId: fileUpload, $proofOfAddress: fileUpload) {
    updateUserProfile(input: {
      first_name: $first_name,
      last_name: $last_name,
      address1: $address1,
      address2: $address2,
      country: $country,
      birthday: $birthday,
      email: $email
    },
    proofOfId: $proofOfId,
    proofOfAddress: $proofOfAddress
  ) {
    userId
  }
}
`;

export const walletAuth = gql`
  mutation ($wallet: String!) {
    walletAuthChallenge(walletAddress:$wallet)
  }
`;

export const enableTelegramNotification = gql`
  mutation {
    enableTelegramNotification
  }
`;

export const verifyEmail = gql`
  mutation($token: String!) {
    verifyEmail(token: $token)
  }
`;

export const supportRequest = gql`
mutation($input: ContactRequestInput!) {
  submitRequest(input: $input)
}
`;
