export const banner = {
  enable: false,
  bannerSrc: 'assets/images/promo-sample.jpg',
}

export const cards = {
  firstLoan: {
    enable: true,
  },
  inviteFriends: {
    enable: true,
  },
  customPromo: {
    enable: true,
    title: '',
    routerLink: '',
  }
}
