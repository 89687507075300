import { Component, Input } from '@angular/core';
import { GENERAL_ICONS } from 'src/assets/images/svg-icons';

@Component({
  selector: 'shared-article',
  template: `
  <div class="overflow-hidden mb-0 md:mb-4 rounded-3xl border bg-white hover:bg-slate-100 transition-all">
    <a href="{{url}}" target="_blank" class="text-sc-black hover:text-sc-primary">
      <img class="w-full md:h-28 max-h-28 object-cover object-center" rel="preload" as="image"
      src="{{ image }}" alt="Image: {{ title }}">
      <div class="font-medium pt-2 line-clamp-2 text-base pl-4 min-h-[50px] relative pr-6">
        {{ title }}
        <sc-icon [size]="'0.3'" [src]="icons.external" [width]="'1rem'" class="absolute top-1 right-3 opacity-50"></sc-icon>
      </div>
      <p class="text-slate-500 text-sm mt-2 line-clamp-2 px-4">{{ description || '' }}</p>
    </a>
  </div>`
})
export class ArticleComponent {
  @Input() title: string;
  @Input() description?: string;
  @Input() image: string;
  @Input() url: string;
  @Input() icons = GENERAL_ICONS;

  descriptionPlaceholder: string = 'Read the article to learn more.';
}
