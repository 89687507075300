import { Pipe, PipeTransform } from '@angular/core';
import BigNumber from 'bignumber.js';
import { Currency } from '../interfaces/currencies/currency';

@Pipe({
  name: 'weiToCurrency'
})
export class WeiToCurrencyPipe implements PipeTransform {

  transform(value: string | number, currency: Currency, targetCurrency?: 'USD'): number {
    typeof value === 'number' ? value = value.toString() : null;
    if (targetCurrency === 'USD') {
      return new BigNumber(value).div(new BigNumber(10).pow(currency?.decimalPlaces)).times(currency?.exchangeRate).toNumber();
    } else {
      return new BigNumber(value).div(new BigNumber(10).pow(currency?.decimalPlaces)).toNumber();
    }
  }
}
