<shared-title [title]="tabTitle"></shared-title>
<div class="flex gap-4 mt-1 mb-5">
  <button routerLinkActive="active" routerLink="/profile/details"> Details</button>
  <!-- <button routerLinkActive="active" routerLink="/profile/customization">Customization</button> -->
  <!-- <button routerLinkActive="active" routerLink="/profile/kyc">KYC</button> -->
  <!-- <button *ngIf="!environment.production" routerLinkActive="active" routerLink="/profile/developer-options">Developer Options</button> -->
</div>
<app-details *ngIf="tabTitle === 'Details'"></app-details>
<app-customization *ngIf="tabTitle === 'Customization'"
  (credentialsSubmitted)="credentialsSubmitted($event)"></app-customization>
<!-- <app-kyc *ngIf="tabTitle === 'KYC'" (kycSubmitted)="kycSubmitted($event)"></app-kyc> -->
<!-- <app-developer-options *ngIf="tabTitle === 'Developer Options'" (critical)="setCritical($event)" (ontime)="setOnTime($event)"></app-developer-options> -->
