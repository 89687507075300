<div *ngIf="currentRoute !== '/error'"
  class="h-[70px] flex justify-between items-center px-2 lg:px-8 bg-white border-b overflow-clip">
  <div [routerLink]="['/']" class="pr-2 md:pr-6 md:h-[30px]">
    <sc-icon [src]="logo" [height]="'36px'" [width]="'180px'" class="mt-2 block md:hidden"></sc-icon>
    <sc-icon [src]="logo" [height]="'40px'" [width]="'200px'" class="hidden lg:block"></sc-icon>
    <sc-icon [src]="smallLogo" [height]="'30px'" [width]="'30px'" class="hidden md:block lg:hidden"></sc-icon>
  </div>
  <menu *ngIf="mainMenu" class="hidden md:flex flex-grow justify-between items-center">
    <ul *ngFor="let menuGroup of mainMenu; let i = index" class="flex">
      <ng-container *ngIf="menuGroup.items">
        <li *ngFor="let item of menuGroup.items; let j = index" class="flex items-center" class="mr-1">
          <a *ngIf="!item.group && item.routerLink" class="header-item text-base" [routerLink]="[item.routerLink]"
            routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }"
            [href]="environment.baseUrl + item.routerLink">
            <sc-icon [src]="item.icon || ''" class="mr-2"></sc-icon>
            {{ item.title }}
            <span *ngIf="item.badge" class="badge secondary ml-1.5">{{ item.badge }}</span>
          </a>
          <a *ngIf="!item.group && item.link" class="header-item text-base" href="{{ item.link }}"
            target="{{ item.blank ? '_blank' : '' }}">
            <sc-icon *ngIf="item.icon" [src]="item.icon || ''" class="mr-2"></sc-icon>
            {{ item.title }}
            <span *ngIf="item.badge" class="badge secondary ml-1.5">{{ item.badge }}</span>
          </a>
          <!-- Dropdown -->
          <div *ngIf="item.group" class="header-item text-base" cdkOverlayOrigin #trigger="cdkOverlayOrigin"
            (mouseleave)="closeMenu()" (mouseover)="openMenu(trigger, 100+j)">
            <sc-icon *ngIf="item.icon" [src]="item.icon || ''" class="mr-2"></sc-icon>
            {{ item.title }}
            <span *ngIf="item.badge" class="badge secondary ml-1.5">{{ item.badge }}</span>
            <div *ngIf="item.group" class="ml-1.5">
              <img src="assets/icons/down-arrow.svg" alt="Expand Menu"
                [ngClass]="{ 'rotate-180': 100+j === triggerOriginIndex }"
                class="rounded-full p-1 bg-slate-200 transition">
            </div>
            <ng-template *ngIf="item.group && 100+j === triggerOriginIndex" cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="100+j === triggerOriginIndex">
              <div (mouseover)="openMenu(trigger, 100+j)" (mouseleave)="closeMenu()">
                <ul *ngIf="item.items" class="menu-overlay main-menu-overlay mt-1">
                  <li *ngFor="let subItem of item.items">
                    <a *ngIf="subItem.routerLink" [routerLink]="[subItem.routerLink]" routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: false }" [href]="environment.baseUrl + subItem.routerLink">
                      <sc-icon *ngIf="subItem.icon" [size]="'0.07'" [src]="subItem.icon" [height]="'0.2rem'"
                        [width]="'1rem'"></sc-icon>
                      <div [ngClass]="subItem.icon ? 'ml-4' : 'px-2'">
                        {{ subItem.title }}
                      </div>
                      <span *ngIf="subItem.badge" class="badge secondary ml-1.5">{{ subItem.badge }}</span>
                      <sc-icon *ngIf="subItem.link" [size]="'0.3'" [src]="icons.externalOp" [width]="'1rem'"
                        [height]="'0.5rem'" class="mr-6"></sc-icon>

                    </a>
                    <a *ngIf="subItem.link" href="{{ subItem.link }}" target="{{ subItem.blank ? '_blank' : '' }}">
                      <sc-icon *ngIf="subItem.icon" [size]="'0.07'" [src]="subItem.icon" [height]="'0.2rem'"
                        [width]="'1rem'"></sc-icon>
                      <div [ngClass]="subItem.icon ? 'ml-4' : 'px-2'">
                        {{ subItem.title }}
                      </div>
                      <span *ngIf="subItem.badge" class="badge secondary ml-1.5">{{ subItem.badge }}</span>
                      <sc-icon *ngIf="subItem.link" [size]="'0.3'" [src]="icons.externalOp" [width]="'1rem'"
                        [height]="'0.5rem'" class="mr-6"></sc-icon>

                    </a>
                  </li>
                </ul>
              </div>
            </ng-template>
          </div>
          <!-- End Dropdown -->
        </li>
      </ng-container>
    </ul>
    <ng-container *ngIf="secondaryMenu">
      <!-- Dropdown for medium screens -->
      <ul class="hidden md:flex xl:hidden mr-1">
        <li>
          <div class="header-item text-base" (mouseleave)="closeMenu()" (mouseover)="openMenu(trigger, 999)"
            cdkOverlayOrigin #trigger="cdkOverlayOrigin">
            <sc-icon [src]="icons.menu" class="mr-2"></sc-icon>
            <div class="mr-1">
              More
            </div>
            <img src="assets/icons/down-arrow.svg" alt="Expand Menu"
              [ngClass]="{ 'rotate-180': 999 === triggerOriginIndex }" class="rounded-full p-1 bg-slate-200 transition">
            <ng-template *ngIf="999 === triggerOriginIndex" cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="triggerOrigin" [cdkConnectedOverlayOpen]="999 === triggerOriginIndex">
              <div class="menu-overlay mt-1" (mouseover)="openMenu(trigger, 999)" (mouseleave)="closeMenu()">
                <ul *ngFor="let menuGroup of secondaryMenu; let i = index" class="mr-1">
                  <ng-container *ngIf="menuGroup.items">
                    <ng-container *ngFor="let item of menuGroup.items; let j = index">
                      <li class="flex items-center">
                        <a *ngIf="!item.group && item.routerLink" class="text-base truncate whitespace-nowrap"
                          [routerLink]="[item.routerLink]" [href]="environment.baseUrl + item.routerLink">
                          <sc-icon [src]="item.icon || ''" class="mr-2"></sc-icon>
                          {{ item.title }}
                        </a>
                        <a *ngIf="!item.group && item.link" class="text-base truncate" href="{{ item.link }}"
                          target="{{ item.blank ? '_blank' : '' }}">
                          {{ item.title }}
                        </a>
                        <a class="text-base truncate" *ngIf="!item.link && item.title === 'Exchange'"
                          (click)="openLetsExchangeDialog()">
                          <div>{{ item.title }}</div>
                        </a>
                      </li>
                      <!-- Dropdown -->
                      <ng-container *ngIf="item.group" class="header-item text-base truncate" (mouseleave)="closeMenu()"
                        (mouseover)="openMenu(trigger, j)" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
                        <li *ngFor="let subItem of item.items" class="flex items-center justify-between">
                          <ng-container *ngIf="subItem.link">
                            <a href="{{ subItem.link }}" target="{{ subItem.blank ? '_blank' : '' }}">
                              {{ subItem.title }}
                            </a>
                            <sc-icon *ngIf="subItem.link" [size]="'0.3'" [src]="icons.externalOp" [width]="'1rem'"
                              class="mr-6"></sc-icon>
                          </ng-container>
                          <ng-container *ngIf="subItem.routerLink">
                            <a [href]="environment.baseUrl + subItem.routerLink" [routerLink]="[subItem.routerLink]"
                              class="cursor-pointer">
                              {{ subItem.title }}
                            </a>
                          </ng-container>
                      </ng-container>
                      <!-- End Dropdown -->
                    </ng-container>
                  </ng-container>
                </ul>
              </div>
            </ng-template>
          </div>
        </li>
      </ul>
      <!-- SECONDARY MENU - LARGE SCREEN -->
      <ul *ngFor="let menuGroup of secondaryMenu; let i = index" class="mr-1 hidden xl:flex">
        <ng-container *ngIf="menuGroup.items">
          <li *ngFor="let item of menuGroup.items; let j = index" class="flex items-center">
            <a *ngIf="!item.group && item.routerLink" [href]="environment.baseUrl + item.routerLink"
              class="header-item text-base truncate whitespace-nowrap" [routerLink]="[item.routerLink]"
              [href]="environment.baseUrl + item.routerLink">
              <sc-icon [src]="item.icon || ''" class="mr-2"></sc-icon>
              {{ item.title }}
            </a>
            <a *ngIf="!item.group && item.link" class="header-item text-base truncate" href="{{ item.link }}"
              target="{{ item.blank ? '_blank' : '' }}">
              {{ item.title }}
            </a>
            <a *ngIf="!item.link && item.title === 'Exchange'" class="header-item text-base truncate"
              (click)="openLetsExchangeDialog()">
              <div>{{ item.title }}</div>
            </a>
            <!-- Dropdown -->
            <div *ngIf="item.group" class="header-item text-base truncate" (mouseleave)="closeMenu()"
              (mouseover)="openMenu(trigger, j)" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
              {{ item.title }}
              <div *ngIf="item.group" class="ml-1.5">
                <img src="assets/icons/down-arrow.svg" alt="Expand Menu" height="16" width="16"
                  [ngClass]="{ 'rotate-180': j === triggerOriginIndex }"
                  class="rounded-full p-1 bg-slate-200 transition">
              </div>
              <ng-template *ngIf="item.group && j === triggerOriginIndex" cdkConnectedOverlay
                [cdkConnectedOverlayOrigin]="triggerOrigin" [cdkConnectedOverlayOpen]="j === triggerOriginIndex">
                <div (mouseleave)="closeMenu()" (mouseover)="openMenu(trigger, j)">
                  <ul *ngIf="item.items" class="menu-overlay mt-1">
                    <li *ngFor="let subItem of item.items" class="flex items-center"
                      [ngClass]="subItem.link ? 'justify-between' : 'justify-start'">
                      <ng-container *ngIf="subItem.link">
                        <a href="{{ subItem.link }}" target="{{ subItem.blank ? '_blank' : '' }}">
                          {{ subItem.title }}
                        </a>
                        <sc-icon *ngIf="subItem.link" [size]="'0.3'" [src]="icons.externalOp" [width]="'1rem'"
                          class="mr-6"></sc-icon>
                      </ng-container>
                      <ng-container *ngIf="subItem.routerLink">
                        <a [href]="environment.baseUrl + subItem.routerLink " [routerLink]="[subItem.routerLink]"
                          (click)="closeMenu()" class="cursor-pointer">
                          {{ subItem.title }}
                        </a>
                      </ng-container>
                    </li>
                  </ul>
                </div>
              </ng-template>
            </div>
            <!-- End Dropdown -->
          </li>
        </ng-container>
      </ul>
    </ng-container>
  </menu>
  <div class="ml-2">
    <shared-account-center></shared-account-center>
  </div>
</div>
<app-cookie-notice></app-cookie-notice>