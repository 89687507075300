import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { catchError, EMPTY, filter, from, map, Observable, of, Subject, take, takeUntil, timer } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { Store } from '@ngrx/store';
import { User } from 'src/app/shared/interfaces/user/user';
import * as fromApp from '../../store/app.reducer';
import * as BorrowingActions from 'src/app/borrowing/store/borrowing.actions';
import * as CoreActions from 'src/app/core/store/core.actions';
import * as RewardsActions from 'src/app/rewards/store/rewards.actions';
import * as LendingActions from 'src/app/lending/store/lending.actions';
import * as StakingActions from 'src/app/staking/store/staking.actions';
import * as DashboardActions from 'src/app/dashboard/store/dashboard.actions';
import * as LeveragingActions from 'src/app/leveraging/store/leveraging.actions';
import {
  queryUser,
  logIn,
  walletAuth,
  updateUser,
  enableTelegramNotification,
  verifyEmail,
  supportRequest
} from '../graphql/userTypes';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AuthChallenge } from 'src/app/shared/interfaces/user/auth-challenge';
import { isPlatformBrowser } from '@angular/common';

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {
  private isBrowser: boolean;
  private destroy$ = new Subject<void>();

  constructor(
    private apollo: Apollo,
    private store: Store<fromApp.AppState>,
    private router: Router,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  // Retrieves user details from the server and dispatches a getUser action to update the application state.
  getUser() {
    if (localStorage.getItem('token')) {
      this.apollo.query({
        query: queryUser,
        fetchPolicy: 'no-cache'
      }).pipe(
        catchError((err: any): Observable<any> => {
          this.store.dispatch(CoreActions.getUser({ user: undefined }));
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
        map((res: any) => {
          const riskRate = res.data.riskRateFees.find((fee: any) => fee.rating === res.data.me.creditData.riskRating);
          return {
            ...res.data.me,
            creditData: {
              ...res.data.me.creditData,
              ...riskRate
            }
          }
        }),
        takeUntil(this.destroy$))
        .subscribe((user: User) => {
          this.store.dispatch(CoreActions.getUser({ user }))
        })
    }
  }

  // Updates the user's KYC information on the server and navigates to the home page.
  updateKyc(kycData: any): void {
    this.apollo.mutate({
      variables: { kycData },
      context: {
        useMultipart: true
      },
      mutation: updateUser,
    })
      .pipe(catchError((err: any): Observable<any> => {
        this.store.dispatch(CoreActions.getUser({ user: null }));
        this.toastr.error('Please retry!', 'Could not update KYC details');
        if (!environment.production) console.log(err);
        return EMPTY;
      }),
        takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.router.navigate["/"]
        this.toastr.success('Succesfully sent KYC information!', 'KYC');
      })
  }

  // Retrieves an authentication challenge from the server for the given wallet account and message
  walletAuth(account: string, message: string): Observable<AuthChallenge> {
    return this.apollo.mutate({
      mutation: walletAuth,
      variables: { wallet: account }
    })
      .pipe(catchError((err: any): Observable<any> => {
        this.toastr.error('Please retry!', 'Authentication failed');
        this.setConnecting(false);
        if (!environment.production) console.log(err);
        return EMPTY;
      }), map((res: any) => {
        return { account, message, challenge: res.data.walletAuthChallenge };
      }),
        takeUntil(this.destroy$))
  }

  // Logs the user in by sending a login mutation to the server with the user's wallet, signature, referral code, and fingerprint hash
  logIn(wallet: string, sig: string, refCode: string): void {
    this.apollo.mutate({
      mutation: logIn,
      variables: { wallet, sig, refCode }
    })
      .pipe(catchError((err: any): Observable<any> => {
        this.toastr.error('Please retry!', 'Could not log in');
        if (!environment.production) console.log(err);
        return EMPTY;
      }), map((res: any) => res.data.login.jwt),
        takeUntil(this.destroy$))
      .subscribe((jwt: string) => {
        localStorage.setItem('token', jwt);
        // this.gaEmitAuth();
        this.getUser();
      })
  }

  // Updates the user's first and last name on the server and dispatches a getUser action to update the application state
  updateCredentials({ firstName, lastName }: { firstName: string; lastName: string }) {
    this.apollo.mutate({
      variables: {
        first_name: firstName,
        last_name: lastName
      },
      mutation: updateUser
    })
      .pipe(catchError((err: any): Observable<any> => {
        this.toastr.error('Please retry!', err);
        if (!environment.production) console.log(err);
        return EMPTY;
      }), map((res: any) => res), takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.getUser();
        this.toastr.success('Succesfully updated First and Last name!', 'Updated Profile');
      })
  }

  // Updates the user's email on the server and dispatches a getUser action to update the application state
  updateEmail({ email }: { email: string }) {
    this.apollo.mutate({
      variables: {
        email: email
      },
      mutation: updateUser
    })
      .pipe(catchError((err: any): Observable<any> => {
        this.toastr.error('Please retry!', 'Could not update email');
        if (!environment.production) console.log(err);
        return EMPTY;
      }), map((res: any) => res), takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.getUser();
        this.toastr.info('Verify your email address!', 'Email Verification');
      })
  }

  // Enables telegram notifications for the user
  enableTelegramNotification(): Observable<any> {
    return this.apollo.mutate({
      mutation: enableTelegramNotification
    }).pipe(catchError((err: any): any => {
      this.toastr.error('Please retry!', 'Could not enable telegram notifications');
      if (!environment.production) console.log(err);
      return EMPTY;
    }), map((res: any) => {
      return res.data.enableTelegramNotification
    }), takeUntil(this.destroy$))
  }

  // Verifies the user's email address using the JWT
  verifyEmail(token: string) {
    this.apollo.mutate({
      variables: {
        token: token
      },
      mutation: verifyEmail
    }).pipe(catchError((err: any): Observable<any> => {
      this.toastr.error('Please re-enter your email in the profile section!', err.message.includes('expired') ? 'Link Expired' : 'Something went wrong');
      this.router.navigate(['/profile']);
      if (!environment.production) console.log(err);
      return EMPTY;
    }), map((res: any) => res), takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.router.navigate([''])
        this.toastr.success('Succesfully added email!', 'Email Confirmed');
      })
  }

  // Logs the user out by removing the token from local storage and resetting the application state
  logOut() {
    this.router.navigate(['/'])
    const agreement = localStorage.getItem('onboard.js:agreement');
    const cookieConsent = localStorage.getItem('cookieConsent');
    localStorage.clear();
    if (agreement) localStorage.setItem('onboard.js:agreement', agreement);
    if (cookieConsent) localStorage.setItem('cookieConsent', cookieConsent);
    this.store.dispatch(BorrowingActions.resetState());
    this.store.dispatch(CoreActions.resetState());
    this.store.dispatch(RewardsActions.resetState());
    this.store.dispatch(LendingActions.resetState());
    this.store.dispatch(StakingActions.resetState());
    this.store.dispatch(DashboardActions.resetState());
    this.store.dispatch(LeveragingActions.resetState());
  }

  // Submits a support request to the server
  submitSupportRequest(supportData: any): Observable<any> {
    return this.apollo.mutate({
      mutation: supportRequest,
      variables: {
        input: {
          category: supportData.category,
          subject: supportData.title,
          description: supportData.description,
          email: supportData.email
        }
      }
    }).pipe(catchError((err: any): Observable<any> => {
      this.toastr.error('Please retry!', 'Could not submit support request');
      if (!environment.production) console.log(err);
      return EMPTY;
    }))
  }

  updateUserDomain(domain: string) {
    this.store.dispatch(CoreActions.updateUserDomain({ domain }));
  }

  updateUser(user: User | undefined) {
    this.store.dispatch(CoreActions.updateUser({ user }));
  }

  // Dispatches a signing action to update the application state
  setSigning(signing: boolean) {
    this.store.dispatch(CoreActions.signing({ signing }))
  }

  // Dispatches a connecting action to update the application state
  setConnecting(connecting: boolean): void {
    this.store.dispatch(CoreActions.connecting({ connecting }));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
