<form [formGroup]="kycForm" #kyc="ngForm">
  <div class="kyc-wrapper">
    <div class="card kyc-card">
      <div class="w-full">
        <div class="input-group">
          <label class="label" for="firstName">First Name</label>
          <input type="text" class="w-full" formControlName="firstName" placeholder="First name">
          <div *ngIf="kyc.submitted && kycForm.controls['firstName'].errors" class="form-error">
            First name is required
          </div>
        </div>
        <div class="input-group">
          <label class="label" for="lastName">Last Name</label>
          <input type="text" class="w-full" formControlName="lastName" placeholder="Last name">
          <div *ngIf="kyc.submitted && kycForm.controls['lastName'].errors" class="form-error">
            Last name is required
          </div>
        </div>
        <div class="input-group">
          <label class="label" for="birthday">Birthday</label>
          <input type="date" class="w-full" formControlName="birthday" placeholder="Birthday">
          <div *ngIf="kyc.submitted && kycForm.controls['birthday'].errors" class="form-error">
            Birthday is required
          </div>
        </div>
        <div class="input-group">
          <div class="flex flex-col">
            <label class="label" for="proof_id">Proof of ID</label>
            <input type="file" name="proof_id" id="proof_id" placeholder="proof_id" accept=".jpg,.jpeg,.png"
              (change)="handleUploadProofOfId($event)" class="file-upload" formControlName="proofOfId">
            <label class="label" for="proof_id" class="custom-file-input"
              [ngClass]="{ 'uploaded': proofOfId, 'invalid': !proofOfId }">
              <sc-icon *ngIf="!proofOfId" [src]="upload" class="self-center icon"></sc-icon>
              <label for="proof_id" *ngIf="!proofOfId" class="upload-text">
                Upload Image (jpg, jpeg or png)<br>
                Max. size: 5 MB
              </label>
              <div *ngIf="proofOfId" class="uploaded-image">
                <div class="mt-2 mb-6">
                  <sc-icon [src]="icons.accept" size="2" class="self-center icon"></sc-icon>
                </div>
                <div class="semibold">{{ proofOfId.name }}</div>
              </div>
            </label>
          </div>
          <div *ngIf="kyc.submitted && kycForm.controls['proofOfId'].errors" class="form-error">
            Proof of ID is required
          </div>
        </div>
      </div>
    </div>
    <div class="card flex flex-row md:flex-col w-full md:w-2/4 bg-slate-50">
      <div class="w-full">
        <div class="input-group">
          <label class="label" for="country">Country</label>
          <input type="text" class="w-full" formControlName="country" placeholder="Country">
          <div *ngIf="kyc.submitted && kycForm.controls['country'].errors" class="form-error">
            Country is required
          </div>
        </div>
        <div class="input-group">
          <label class="label" for="addressOne">Address 1</label>
          <input type="text" class="w-full" formControlName="addressOne" placeholder="Address 1">
          <div *ngIf="kyc.submitted && kycForm.controls['addressOne'].errors" class="form-error">
            Address is required
          </div>
        </div>
        <div class="input-group">
          <label class="label" for="addressTwo">Address 2</label>
          <input type="text" class="w-full" formControlName="addressTwo" placeholder="Address 2">
          <div *ngIf="kyc.submitted && kycForm.controls['addressTwo'].errors" class="form-error">
            Address is required
          </div>
        </div>
        <div class="input-group">
          <div class="flex flex-col">
            <label class="label" for="proof_address">Proof of Address</label>
            <input type="file" name="proof_address" id="proof_address" placeholder="proof_address"
              accept=".jpg,.jpeg,.png" (change)="handleUploadProofOfAddress($event)" class="file-upload"
              formControlName="proofOfAddress">
            <label class="label" for="proof_address" class="custom-file-input"
              [ngClass]="{ 'uploaded': proofOfAddress, 'invalid': !proofOfAddress }">
              <sc-icon *ngIf="!proofOfAddress" [src]="upload" class="self-center icon"></sc-icon>
              <label for="proof_address" *ngIf="!proofOfAddress" class="upload-text">
                Upload Image (jpg, jpeg or png)<br>
                Max. size: 5 MB
              </label>
              <div *ngIf="proofOfAddress" class="uploaded-image">
                <div class="mt-2 mb-6">
                  <sc-icon [src]="icons.accept" size="2" class="self-center icon"></sc-icon>
                </div>
                <div>{{ proofOfAddress.name }}</div>
              </div>
            </label>
          </div>
          <div *ngIf="kyc.submitted && kycForm.controls['proofOfAddress'].errors" class="form-error">
            Proof of address is required
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex w-full justify-end">
    <button class="primary mt-6" [disabled]="kycForm.invalid" (click)="onKycSubmit()">
      <div class="px-8">Verify</div>
    </button>
  </div>
</form>
