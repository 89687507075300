<div class="rounded-2xl p-6 bg-white">
  <div class="flex justify-between items-top">
    <h1 class="text-xl my-0">Simple vs Advanced Mode</h1>
    <button class="default sm" (click)="onClose()">
      Close
    </button>
  </div>
  <div class="flex gap-4 mt-4 flex-wrap md:flex-nowrap">
    <div class="md:w-3/5">
      <h2 class="text-base mt-0 mb-1">Simple Mode</h2>
      <p class="text-slate-500 flex items-top gap-1 mb-1">
        <sc-icon [src]="icons.rightArrow" [size]="'0.875'" classes="mt-1"></sc-icon>
        Platform creates the loan on 5% liquidation probability
      </p>
      <p class="text-slate-500 flex items-top gap-1 mb-1">
        <sc-icon [src]="icons.rightArrow" [size]="'0.875'" classes="mt-1"></sc-icon>
        Very low liquidation probability
      </p>
      <p class="text-slate-500 flex items-top gap-1 mb-1">
        <sc-icon [src]="icons.rightArrow" [size]="'0.875'" classes="mt-1"></sc-icon>
        Best for beginners
      </p>
      <h2 class="text-base mt-4 mb-1">Advanced Mode</h2>
      <p class="text-slate-500 flex items-top gap-1 mb-1">
        <sc-icon [src]="icons.rightArrow" [size]="'0.875'" classes="mt-1"></sc-icon>
        Choose your own collateral ratio and liquidation probability
      </p>
      <p class="text-slate-500 flex items-top gap-1 mb-1">
        <sc-icon [src]="icons.rightArrow" [size]="'0.875'" classes="mt-1"></sc-icon>
        More control over your loan with Position Monitoring System
      </p>
      <p class="text-slate-500 flex items-top gap-1 mb-1">
        <sc-icon [src]="icons.rightArrow" [size]="'0.875'" classes="mt-1"></sc-icon>
        Must be connected to Telegram
      </p>
    </div>
    <div class="md:w-2/5 p-4 rounded-xl bg-slate-100 w-full hidden md:block">
      <img src="assets/images/options.svg" alt="Options">
    </div>
  </div>
</div>