import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { Currency } from 'src/app/shared/interfaces/currencies/currency';

@Component({
  selector: 'app-claim-staking-rewards',
  templateUrl: './claim-staking-rewards.dialog.html',
  styleUrls: ['./claim-staking-rewards.dialog.scss']
})
export class ClaimStakingRewardsDialog {
  public totalStakingRewards: string;
  public currency: Currency;
  @Output('claimConfirmed') claimConfirmed: EventEmitter<boolean> = new EventEmitter();
  @Output('reject') reject: EventEmitter<boolean> = new EventEmitter();

  constructor(
    @Inject(DIALOG_DATA) public data: { totalStakingRewards: string, currency: Currency },
  ) {
    this.totalStakingRewards = data.totalStakingRewards;
    this.currency = data.currency;
  }

  onClaimConfirm() {
    this.claimConfirmed.emit(true);
  }

  onClose() {
    this.reject.emit(true);
  }
}
