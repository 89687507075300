<shared-title title="Financing Dashboard">
  <button class="primary mb-3" [routerLink]="['/sell']">
    <sc-icon
      [src]="generalIcons.add"
      [size]="'0.5'"
      [width]="'1.5rem'"
      class="mr-2"
    ></sc-icon>
    New Fixed Income Fund
  </button>
</shared-title>
<!-- loading screen -->
<div
  *ngIf="isFetching"
  class="mx-auto text-center rounded-3xl bg-slate-50 py-24 mb-8"
>
  <!-- loading spinner -->
  <spinner size="sm"></spinner>
  <div class="text-md text-slate-400 font-medium px-4">
    Loading Dashboard...
  </div>
</div>
<div
  *ngIf="!this.user || !this.user.ethAddress"
  class="mx-auto text-center rounded-3xl bg-slate-50 py-24 mb-8"
>
  <sc-icon
    [size]="'2rem'"
    [src]="generalIcons.locked"
    class="mx-auto"
    class="inline-block"
  >
  </sc-icon>
  <div class="text-md text-slate-400 font-medium px-4">
    You must log in to view the financing dashboard!
  </div>
  <div class="mt-6">
    <shared-account-center></shared-account-center>
  </div>
</div>
<ng-container *ngIf="this.user && this.user.ethAddress && !isFetching">
  <fifs-stats [fifStats]="fifStats" [stats]="stats"></fifs-stats>
  <fifs-table
    [fixedIncomeFunds]="fixedIncomeFunds"
    [cryptoIcons]="cryptoIcons"
  ></fifs-table>
  <section>
    <h2>How To Lend</h2>
    <div class="flex">
      <div class="flex flex-col md:flex-row w-full gap-6">
        <div class="w-1/3">
          <app-how-to-article
            image="../../../../assets/images/step-1.svg"
            step="Step 1"
            name="Create A Fixed Income Fund"
            description="Fill the Create FIF form and complete the transaction."
          >
          </app-how-to-article>
        </div>
        <div class="w-1/3">
          <app-how-to-article
            image="../../../../assets/images/step-2.svg"
            step="Step 2"
            name="Deposit Funds"
            description="Go to the FIF page and deposit the amount you would like to be lent."
          >
          </app-how-to-article>
        </div>
        <div class="w-1/3">
          <app-how-to-article
            image="../../../../assets/images/step-3.svg"
            step="Step 3"
            name="Lend Automatically"
            description="Funds are lent automatically to BarakaFi borrowers."
          >
          </app-how-to-article>
        </div>
      </div>
      <!-- <div class="flex flex-col md:flex-col w-full md:w-1/3">
        <a class="mb-2 flex text-sc-primary font-semibold">
          Smart Credit Support<sc-icon [src]="arrow" class="self-center ml-2"></sc-icon>
        </a>
        <p class="text-sm hidden md:block text-slate-500 mb-6">
          Contact SmartCredit.io Support
        </p>
        <a class="mb-2 flex text-sc-primary font-semibold">
          Wallet connection<sc-icon [src]="arrow" class="self-center ml-2"></sc-icon>
        </a>
        <p class="text-sm hidden md:block text-slate-500 mb-6">
          Learn how to connect your wallet to SmartCredit.io
        </p>
        <a class="mb-2 flex text-sc-primary font-semibold">
          How to adjust FIF allocations <sc-icon [src]="arrow" class="self-center ml-2"></sc-icon>
        </a>
        <p class="text-sm hidden md:block text-slate-500 mb-6">
          Learn how to adjust the allocation of funds in your fixed income fund
        </p>
      </div> -->
    </div>
  </section>
</ng-container>
