import { Component, DestroyRef, EventEmitter, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-developer-options',
  templateUrl: './developer-options.component.html',
  styleUrls: ['./developer-options.component.scss']
})
export class DeveloperOptionsComponent {
  devOptsForm: FormGroup;
  private destroyRef: DestroyRef
  @Output('ontime') ontime: EventEmitter<boolean> = new EventEmitter();
  @Output('critical') critical: EventEmitter<boolean> = new EventEmitter();


  constructor(
    private fb: FormBuilder
  ) {
    const devOpts = JSON.parse(localStorage.getItem('devOpts') ? localStorage.getItem('devOpts'): "{}")
    this.devOptsForm = this.fb.group({
      ontime: [devOpts ? devOpts.ontime : false, Validators.required],
      critical: [{value: devOpts ? devOpts.critical : false, disabled: !devOpts.ontime }, Validators.required],
    })

    this.devOptsForm.controls['ontime'].valueChanges
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((value) => {
      if (value) {
        this.devOptsForm.controls['critical'].enable()
      } else {
        this.devOptsForm.controls['critical'].setValue(false); 
        this.devOptsForm.controls['critical'].disable();
      }      
      this.ontime.emit(value);
    })

    this.devOptsForm.controls['critical'].valueChanges
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((value) => { 
      this.critical.emit(value);
    })
  }
}
