import { ActionReducerMap } from '@ngrx/store';
import * as fromDashboard from '../dashboard/store/dashboard.reducer'
import * as fromCore from '../core/store/core.reducer';
import * as fromStaking from '../staking/store/staking.reducer';
import * as fromBorrowing from '../borrowing/store/borrowing.reducer';
import * as fromLending from '../lending/store/lending.reducer';
import * as fromRewards from '../rewards/store/rewards.reducer';
import * as fromEarn from '../earn/store/earn.reducer';
import * as fromLeveraging from '../leveraging/store/leveraging.reducer';

export interface AppState {
  core: fromCore.State,
  dashboard: fromDashboard.State,
  staking: fromStaking.State,
  borrowing: fromBorrowing.State,
  lending: fromLending.State,
  rewards: fromRewards.State,
  earn: fromEarn.State,
  leveraging: fromLeveraging.LeverageState
}

export const appReducer: ActionReducerMap<AppState> = {
  core: fromCore.coreReducer,
  dashboard: fromDashboard.dashboardReducer,
  staking: fromStaking.stakingReducer,
  borrowing: fromBorrowing.borrowingReducer,
  lending: fromLending.lendingReducer,
  rewards: fromRewards.rewardsReducer,
  earn: fromEarn.earnReducer,
  leveraging: fromLeveraging.leveragingReducer
};
