export const BORROWING = {
  abi: [
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'loanId',
          type: 'bytes32'
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'sender',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'CollateralIncreased',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'loanId',
          type: 'bytes32'
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'exchangeAddress',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'loanStatus',
          type: 'uint256'
        }
      ],
      name: 'LiquidationInitiated',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'loanId',
          type: 'bytes32'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'loanAmount',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint64',
          name: 'interestRate',
          type: 'uint64'
        },
        {
          indexed: false,
          internalType: 'uint64',
          name: 'duration',
          type: 'uint64'
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'collateralAddress',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'underlyingAddress',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'lender',
          type: 'address'
        }
      ],
      name: 'LoanInitialized',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'loanId',
          type: 'bytes32'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'outstandingAmount',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'timestamp',
          type: 'uint256'
        }
      ],
      name: 'Repaid',
      type: 'event'
    },
    {
      inputs: [],
      name: 'getBorrower',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address'
        }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_assetAddress',
          type: 'address'
        }
      ],
      name: 'getERC20Balance',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'getETHBalance',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: 'bytes32',
              name: 'loanId',
              type: 'bytes32'
            },
            {
              internalType: 'uint64',
              name: 'loanInterestRate',
              type: 'uint64'
            },
            {
              internalType: 'uint64',
              name: 'loanTerm',
              type: 'uint64'
            },
            {
              internalType: 'uint256',
              name: 'loanAmount',
              type: 'uint256'
            },
            {
              internalType: 'uint256',
              name: 'fee',
              type: 'uint256'
            },
            {
              internalType: 'uint256',
              name: 'initialCollateralAmount',
              type: 'uint256'
            },
            {
              internalType: 'address',
              name: 'lender',
              type: 'address'
            },
            {
              internalType: 'address',
              name: 'collateralAddress',
              type: 'address'
            },
            {
              internalType: 'address',
              name: 'underlyingAddress',
              type: 'address'
            },
            {
              internalType: 'address',
              name: 'insurer',
              type: 'address'
            }
          ],
          internalType: 'struct LoanStorage.LoanRequest',
          name: '_loanRequest',
          type: 'tuple'
        }
      ],
      name: 'getHash',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32'
        }
      ],
      stateMutability: 'pure',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        {
          internalType: 'bytes32',
          name: '_loanId',
          type: 'bytes32'
        }
      ],
      name: 'getLoanData',
      outputs: [
        {
          internalType: 'bytes32',
          name: 'loanId',
          type: 'bytes32'
        },
        {
          internalType: 'uint256',
          name: 'currentCollateralAmount',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: 'loanEnded',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: 'outstandingAmount',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_assetAddress',
          type: 'address'
        }
      ],
      name: 'getLockedAssetValue',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_assetAddress',
          type: 'address'
        }
      ],
      name: 'getUnlockedAssetValue',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: 'bytes32',
              name: 'loanId',
              type: 'bytes32'
            },
            {
              internalType: 'uint64',
              name: 'loanInterestRate',
              type: 'uint64'
            },
            {
              internalType: 'uint64',
              name: 'loanTerm',
              type: 'uint64'
            },
            {
              internalType: 'uint256',
              name: 'loanAmount',
              type: 'uint256'
            },
            {
              internalType: 'uint256',
              name: 'fee',
              type: 'uint256'
            },
            {
              internalType: 'uint256',
              name: 'initialCollateralAmount',
              type: 'uint256'
            },
            {
              internalType: 'address',
              name: 'lender',
              type: 'address'
            },
            {
              internalType: 'address',
              name: 'collateralAddress',
              type: 'address'
            },
            {
              internalType: 'address',
              name: 'underlyingAddress',
              type: 'address'
            },
            {
              internalType: 'address',
              name: 'insurer',
              type: 'address'
            }
          ],
          internalType: 'struct LoanStorage.LoanRequest',
          name: '_loanRequest',
          type: 'tuple'
        },
        {
          internalType: 'uint256',
          name: '_collateralAmount',
          type: 'uint256'
        }
      ],
      name: 'increaseCollateral',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_assetAddress',
          type: 'address'
        },
        {
          internalType: 'uint256',
          name: '_amount',
          type: 'uint256'
        }
      ],
      name: 'withdrawAsset',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: 'bytes32',
              name: 'loanId',
              type: 'bytes32'
            },
            {
              internalType: 'uint64',
              name: 'loanInterestRate',
              type: 'uint64'
            },
            {
              internalType: 'uint64',
              name: 'loanTerm',
              type: 'uint64'
            },
            {
              internalType: 'uint256',
              name: 'loanAmount',
              type: 'uint256'
            },
            {
              internalType: 'uint256',
              name: 'fee',
              type: 'uint256'
            },
            {
              internalType: 'uint256',
              name: 'initialCollateralAmount',
              type: 'uint256'
            },
            {
              internalType: 'address',
              name: 'lender',
              type: 'address'
            },
            {
              internalType: 'address',
              name: 'collateralAddress',
              type: 'address'
            },
            {
              internalType: 'address',
              name: 'underlyingAddress',
              type: 'address'
            },
            {
              internalType: 'address',
              name: 'insurer',
              type: 'address'
            }
          ],
          internalType: 'struct LoanStorage.LoanRequest',
          name: '_loanRequest',
          type: 'tuple'
        },
        {
          internalType: 'uint256',
          name: '_amount',
          type: 'uint256'
        }
      ],
      name: 'repay',
      outputs: [],
      stateMutability: 'payable',
      type: 'function',
      payable: true
    }
  ]
};
