<div class="rounded-3xl border-slate-200 bg-white p-4">
  <div class="flex items-center justify-around mb-2">
    <button
      class="nostyle font-semibold uppercase hover:text-blue-900"
      [ngClass]="{'text-sc-primary': !withdrawTab }"
      (click)="withdrawTab = false"
    >
      Deposit
    </button>
    <button
      class="nostyle font-semibold uppercase hover:text-blue-900"
      [ngClass]="{'text-sc-primary': withdrawTab }"
      (click)="withdrawTab = true"
    >
      Withdraw
    </button>
  </div>
  <div [ngClass]="{'visually-hidden': withdrawTab}">
    <h3 class="text-slate-500 font-medium text-base mt-4">
      Deposit Collateral
    </h3>
    <form>
      <div class="input-group w-full pb-4">
        <label for="currency" class="text-left label">Amount</label>
        <input
          type="text"
          class="w-full h-16"
          [formControl]="depositAmount"
          placeholder="0"
        />
        <div class="suffix">{{ collateral.symbol }}</div>
      </div>
      <div class="info-box">
        You can transfer additional collateral to be used for other loans in the
        same Finance Line.
      </div>
      <div class="text-center">
        <button
          class="primary min-w-full"
          [disabled]="depositAmount.invalid"
          (click)="onDeposit()"
        >
          Deposit Collateral
        </button>
      </div>
    </form>
    <div *ngIf="depositAmount.hasError('minBalance')" class="form-error">
      Not enough balance in wallet
    </div>
  </div>
  <div [ngClass]="{'visually-hidden': !withdrawTab}">
    <h3 class="text-slate-500 font-medium text-base mt-4">
      Withdraw Collateral
    </h3>
    <form>
      <div class="input-group w-full pb-4">
        <label for="currency" class="text-left label">Amount</label>
        <input
          type="text"
          class="w-full h-16"
          [formControl]="withdrawAmount"
          placeholder="0"
        />
        <div class="suffix">{{ collateral.symbol }}</div>
      </div>
      <div class="text-center">
        <button
          class="primary min-w-full"
          [disabled]="withdrawAmount.invalid"
          (click)="onWithdraw()"
        >
          Withdraw Collateral
        </button>
      </div>
    </form>
  </div>
</div>
