import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-paragraphs',
  template: `
<h2 class="mt-8 mb-2">{{ title }}</h2>
<div class="flex gap-6 flex-wrap md:flex-nowrap">
  <div *ngFor="let paragraph of paragraphs; let i = index"
    class=" w-full md:w-1/3 relative">
    <h3 *ngIf="subtitles">
      {{ subtitles[i] }}
    </h3>
    <p class="text-sm text-justify text-slate-600">
      {{ paragraph }}
    </p>
  </div>
</div>`,
  styles: []
})
export class ParagraphsComponent {
  @Input('paragraphsTitle') title: string;
  @Input() paragraphs: string[];
  @Input() subtitles: string[];
}
