import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as DashboardActions from 'src/app/dashboard/store/dashboard.actions';
import { catchError, EMPTY, map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import * as fromApp from '../../store/app.reducer';
import { Currencies } from 'src/app/shared/interfaces/currencies/currencies';
import { currencies, config } from 'src/app/shared/constants';
import { currenciesQuery } from '../graphql/currencyTypes';
import { Apollo } from 'apollo-angular';
import { Currency } from 'src/app/shared/interfaces/currencies/currency';
import BigNumber from 'bignumber.js';
import { ASSETS_ORDER, COLLATERALS_ORDER } from 'src/config/app.config';
import { makeStateKey, TransferState } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';

const CURRENCIES_KEY = makeStateKey<any>('currencies');

@Injectable({
  providedIn: 'root'
})
export class CurrenciesService implements OnDestroy {
  private isBrowser: boolean;
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<fromApp.AppState>,
    private apollo: Apollo,
    private state: TransferState,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  fetchCurrencies() {
    const exists = this.state.get(CURRENCIES_KEY, {} as any);
    this.apollo.watchQuery({ query: currenciesQuery }).valueChanges
      .pipe(
        catchError((err: any): Observable<any> => {
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
        map((res: any) => {
          const collaterals = res.data.collateralCurrencies.map((collateral: Currency) => ({
            ...collateral,
            collateralPrice: this.updateCollateralPrice(collateral, currencies.UNISWAP_CURRENCIES),
            depegged: this.getDepegThreshold(collateral, config.DEPEG_THRESHOLD)
          }))
          res.data = {
            ...res.data,
            underlyingCurrencies: res.data.underlyingCurrencies.slice().sort((a: Currency, b: Currency) => {
              return ASSETS_ORDER.indexOf(a.symbol) - ASSETS_ORDER.indexOf(b.symbol)
            }),
            collateralCurrencies: collaterals.slice().sort((a: Currency, b: Currency) => {
              return COLLATERALS_ORDER.indexOf(a.symbol) - COLLATERALS_ORDER.indexOf(b.symbol)
            })
          }
          return res.data;
        }),
        startWith(exists),
        takeUntil(this.destroy$)
      )
      .subscribe((currencies: Currencies) => {
        if (Object.keys(currencies).length !== 0) {
          this.state.set(CURRENCIES_KEY, currencies);
          this.store.dispatch(DashboardActions.getCurrencies({ currencies }))
        }
      })
  }

  updateCollateralPrice(collateral, uniswapCurrencies): number {
    if (uniswapCurrencies.includes(collateral.symbol)) {
      return Math.min(collateral.exchangeRate, collateral.medianExchangeRate);
    }
    return collateral.exchangeRate;
  }

  getDepegThreshold(collateral, depegThreshold): boolean {
    if (currencies.STABLECOINS.includes(collateral.symbol) &&
      collateral.exchangeRate < 1 &&
      (((1 - collateral.exchangeRate) / 1) * 100) > depegThreshold) {
      return true;
    }
    return false;
  }

  weiToCurrency(value: string | number, currency: Currency, targetCurrency?: 'USD'): number {
    typeof value === 'number' ? value = value.toString() : null;
    if (targetCurrency === 'USD') {
      return new BigNumber(value).div(new BigNumber(10).pow(currency?.decimalPlaces)).times(currency?.exchangeRate).toNumber();
    } else {
      return new BigNumber(value).div(new BigNumber(10).pow(currency?.decimalPlaces)).toNumber();
    }
  }

  currencyToWei(value: number, currency: Currency): string {
    return new BigNumber(value).times(new BigNumber(10).pow(currency?.decimalPlaces)).toFixed(0);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
