<div class="rounded-3xl border-slate-200 bg-white p-4 text-center">
    <div class="flex justify-between items-center mb-4">
        <h3 class="mb-0 mr-2 truncate">Add Email</h3>
        <button class="default sm" (click)="onClose()">
          Close
        </button>
      </div>
      <form (submit)="onAdd($event)">
        <div class="input-group w-full mb-0">
          <label for="currency" class="text-left label">Email</label>
          <input type="text" class="w-full h-16" [formControl]="email" placeholder="Email">
        </div>
        <div *ngIf="email.errors?.['pattern']" class="form-error text-left">
         Email is not valid!
        </div>
        <div class="text-center mt-2">
          <button type="submit" class="primary w-fit" [disabled]="email.errors">Add</button>
        </div>
      </form>
  </div>
