import { Component, Input } from '@angular/core';

@Component({
  selector: 'empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent {
  @Input() referralCode: string;
  @Input() baseUrl: string;
  copyText: string = 'Copy Link';

  copy() {
    this.copyText = 'Copied!';
    setTimeout(() => {
      this.copyText = 'Copy Link';
    }, 1000);
  }
}
