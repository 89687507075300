import { Article } from "src/app/shared/interfaces/articles/article";
import { Currencies } from "src/app/shared/interfaces/currencies/currencies";
import { createAction, props } from "@ngrx/store";
import { DashboardStats } from "src/app/shared/interfaces/statistics/dashboard-stats";

export const getCurrencies = createAction(
  'getCurrencies',
  props<{
    currencies: Currencies
  }>()
)

export const getArticles = createAction(
  'getArticles',
  props<{
    articles: Article[]
  }>()
)

export const getStats = createAction(
  'getStats',
  props<{
    stats: DashboardStats
  }>()
)

export const updateCollateralLiquidity = createAction(
  'updateCollateralLiquidity',
  props<{
    liquidity: {
      maxLoanCollateral: string;
      totalLockedCollateralVolumeInUSD: number;
    },
    symbol: string
  }>()
)

export const resetState = createAction(
  'resetState'
)
