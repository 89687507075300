export const LENDING = {
  abi: [
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      name: 'FixedIncomeFundRatiosUpdated',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: '_fundOwner',
          type: 'address'
        }
      ],
      name: 'FixedIncomeFundTerminated',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: '_amount',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'address',
          name: '_withdrawer',
          type: 'address'
        }
      ],
      name: 'Withdrawn',
      type: 'event'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'creditLine',
          type: 'address'
        }
      ],
      name: 'checkCreditLineAddress',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool'
        }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        {
          internalType: 'uint8',
          name: '_position',
          type: 'uint8'
        }
      ],
      name: 'getBucketInfo',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        {
          internalType: 'uint8',
          name: '_position',
          type: 'uint8'
        }
      ],
      name: 'getBucketInvested',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_loanTerm',
          type: 'uint256'
        }
      ],
      name: 'getBucketPosition',
      outputs: [
        {
          internalType: 'uint8',
          name: '',
          type: 'uint8'
        }
      ],
      stateMutability: 'pure',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'getFixedIncomeFundDetails',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address'
        },
        {
          internalType: 'address',
          name: '',
          type: 'address'
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        },
        {
          internalType: 'uint256[4]',
          name: '',
          type: 'uint256[4]'
        }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'getFundOwner',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address'
        }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'totalFundInvested',
      outputs: [
        {
          internalType: 'uint256',
          name: '_totalInvested',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        {
          internalType: 'uint256[4]',
          name: '_ratios',
          type: 'uint256[4]'
        }
      ],
      name: 'updateBucketRatio',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_loanAmount',
          type: 'uint256'
        },
        {
          internalType: 'uint8',
          name: '_bucketPosition',
          type: 'uint8'
        }
      ],
      name: 'validateInvestmentRequest',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool'
        }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_amount',
          type: 'uint256'
        }
      ],
      name: 'withdraw',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool'
        }
      ],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      stateMutability: 'payable',
      type: 'receive',
      payable: true
    },
    {
      inputs: [],
      name: 'terminate',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'getCurrencyAddress',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address'
        }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'getCompoundAddress',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address'
        }
      ],
      stateMutability: 'pure',
      type: 'function',
      constant: true
    },
    {
      inputs: [],
      name: 'fixedIncomeFundBalance',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    }
  ]
};
