import { animate, state, style, transition, trigger } from "@angular/animations";

export const scFadeInOut = trigger('scFadeInOut', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'scale(0.5)'
    }),
    animate('0.25s ease-in-out', style({
      opacity: 1,
      transform: 'scale(1)'
    }))
  ]),
  transition(':leave', [
    style({
      opacity: 1,
      transform: 'scale(1)'
    }),
    animate('0.25s ease-in-out', style({
      opacity: 0,
      transform: 'scale(0.8)'
    }))
  ])
])
