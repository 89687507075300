<div class="rounded-3xl border-slate-200 bg-white p-4">
  <ng-container *ngIf="!showTransfer">
    <div class="flex justify-between items-center mb-4">
      <h3 class="mb-0 mr-2 truncate">Increase Collateral for Loan</h3>
      <button class="default sm" (click)="onClose()">
        Close
      </button>
    </div>
    <form>
      <div class="info-box info mt-4">
        You can increase up to {{ unlockedCollateral | weiToCurrency:collateral }} {{
        collateral.symbol }}.
      </div>
      <div class="input-group w-full mb-0">
        <label for="currency" class="text-left label">Amount To Increase</label>
        <input type="text" class="w-full h-16" [formControl]="increaseAmount" placeholder="0">
        <div class="suffix">{{ collateral.symbol }}</div>
      </div>
      <div class="text-xs text-slate-500 font-medium -mt-2 ml-2 mb-2">≈{{ increaseAmount.value *
        collateral.exchangeRate | currency:'USD':'symbol-narrow':'1.2-2' }}</div>
      <div *ngIf="loanStates.nearLiquidation && requiredAmount > 0" class="info-box danger mt-4">
        You need to deposit at least {{ requiredAmount  }} {{
        collateral.symbol }} to avoid liquidation.
      </div>
      <div class="text-center mt-2">
        <button type="button" class="primary min-w-full" [disabled]="increaseAmount.invalid"
          (click)="onIncrease()">Increase Collateral</button>
      </div>
    </form>
    <div class="font-medium mt-2 text-slate-500">
      Want to increase more?
      <button class="text-sc-primary text-base font-medium nostyle" (click)="viewTransfer()">Deposit
        collateral to Finance Line</button>
    </div>
  </ng-container>
  <ng-container *ngIf="showTransfer">
    <div class="flex justify-between items-center mb-4">
      <h3 class="mb-0 mr-2 truncate">Deposit Collateral to Finance Line</h3>
      <button class="default sm" (click)="onClose()">
        Close
      </button>
    </div>
    <div *ngIf="!loanStates.nearLiquidation || requiredAmount <= 0" class="info-box warning my-2">
      You have {{ unlockedCollateral | weiToCurrency:collateral }} {{ collateral.symbol }} available
      in your Finance Line.
      Deposit additional {{ collateral.symbol }} to Finance Line to increase collateral for this
      loan.
    </div>
    <div *ngIf="loanStates.nearLiquidation && requiredAmount > 0" class="info-box danger mt-4">
      You need to deposit at least
      {{ requiredAmount }} {{ collateral.symbol }}
      to avoid liquidation, but you have
      {{ unlockedCollateral | weiToCurrency:collateral }} {{ collateral.symbol }}
      available in your Finance Line. Transfer an additional
      {{ requiredAmount - unlockedCollateral }} {{ collateral.symbol }}
      to your Finance Line and then increase collateral for your loan.
    </div>
    <div *ngIf="depositingCollateral" class="text-center mx-auto">
      <div class="text-slate-500 font-medium">
        Depositing collateral to Finance Line. Please wait...
      </div>
      <spinner size="sm"></spinner>
    </div>
    <form>
      <div *ngIf="!depositingCollateral" class="input-group w-full mb-0">
        <label for="currency" class="text-left label">Amount To Deposit</label>
        <input type="text" class="w-full h-16" [formControl]="transferAmount" placeholder="0">
        <div class="suffix">{{ collateral.symbol }}</div>
      </div>
      <div *ngIf="!depositingCollateral" class="text-xs text-slate-500 font-medium -mt-2 ml-2 mb-2">
        ≈{{ transferAmount.value * collateral.exchangeRate | currency:'USD':'symbol-narrow':'1.2-2'
        }}</div>
      <div class="text-center mt-4">
        <button type="button" class="primary min-w-full"
          [disabled]="depositingCollateral || transferAmount.invalid" (click)="onTransfer()">
          <spinner *ngIf="depositingCollateral" size="xs" color="gray" class="mr-2"></spinner>
          Deposit Collateral To Finance Line
        </button>
      </div>
    </form>
    <div *ngIf="unlockedCollateral > 0" class="font-medium mt-2 text-slate-500">
      Want to increase less than {{ unlockedCollateral | weiToCurrency:collateral }} {{
      collateral.symbol }}?
      <button class="text-sc-primary text-base font-medium nostyle"
        (click)="viewIncrease()">Increase
        collateral for loan
      </button>
    </div>
  </ng-container>
</div>
