import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'sc-icon',
  template: `<div *ngIf="src" [innerHtml]="sanitizedSrc" [ngStyle]="{'height': height, 'width': width || 'auto', 'transform': 'scale('+size+')'}" class="{{ classes }}"></div>`
})

export class IconComponent implements OnInit, OnChanges {
  @Input() src: string;
  @Input() height?: string;
  @Input() width?: string;
  @Input() size: string;
  @Input() classes: string;

  public sanitizedSrc: SafeHtml;

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.updateIcon();
  }

  ngOnChanges() {
    this.updateIcon();
  }

  updateIcon(src = this.src) {
    this.sanitizedSrc = this.sanitizer.bypassSecurityTrustHtml(this.src);
  }
}
