<shared-title
  *ngIf="creditLine"
  title="{{ creditLine.type | creditLineType }} Financing Line"
  address="{{ creditLine.contractAddress }}"
>
  <button class="primary-inverse mb-3" [routerLink]="['/purchase/dashboard']">
    <sc-icon
      [src]="icons.dashboard"
      [size]="'0.4'"
      [width]="'1.5rem'"
      class="mr-2"
    ></sc-icon>
    Financing Dashboard
  </button>
</shared-title>
<div
  *ngIf="!this.user || !this.user.ethAddress"
  class="mx-auto text-center rounded-3xl bg-slate-50 py-24 mb-8"
>
  <sc-icon
    [size]="'2rem'"
    [src]="icons.locked"
    class="mx-auto"
    class="inline-block"
  >
  </sc-icon>
  <div class="text-md text-slate-400 font-medium px-4">
    You must log in to view the financing dashboard!
  </div>
  <div class="mt-6">
    <shared-account-center></shared-account-center>
  </div>
</div>
<!-- loading screen -->
<div
  *ngIf="isFetching"
  class="mx-auto text-center rounded-3xl bg-slate-50 py-24 mb-8"
>
  <!-- loading spinner -->
  <spinner size="sm"></spinner>
  <div class="text-md text-slate-400 font-medium px-4">
    Loading Financing Details...
  </div>
</div>
<ng-container *ngIf="this.user && this.user.ethAddress && creditLine">
  <div class="flex flex-wrap md:flex-nowrap mb-10 gap-8">
    <section *ngIf="creditLine.assets" class="w-full md:w-1/2">
      <div class="flex items-center mt-2">
        <h2 class="text-slate-500 font-medium mb-0 mt-0 mr-1 text-base">
          Assets
        </h2>
        <div class="badge primary">{{ creditLine.assets.length }}</div>
      </div>
      <hr class="mt-2 mb-4" />
      <div
        class="flex flex-wrap"
        [ngClass]="{ 'currencies-container': !showAllAssets }"
      >
        <ng-container *ngFor="let asset of creditLine.assets; let i = index">
          <div
            *ngIf="
              i <= initialVisibleCurrencies - 1 ||
              (i > initialVisibleCurrencies - 1 && showAllAssets)
            "
            class="card asset-card flex items-center justify-between w-full mb-2"
          >
            <div class="flex truncate">
              <sc-icon
                [src]="icons[asset.symbol]"
                width="32px"
                height="32px"
                class="self-center"
              >
              </sc-icon>
              <div class="pl-3 truncate">
                <div class="text-base font-semibold truncate">
                  {{ asset.name }}
                </div>
                <div class="font-normal text-sm text-gray-500">
                  {{ asset.symbol }}
                </div>
              </div>
            </div>
            <div class="flex items-center">
              <div class="flex items-center w-36 truncate">
                <div class="pill success h-8 w-8 mr-1">
                  <sc-icon
                    [src]="icons.cases"
                    [size]="'0.4'"
                    [height]="'1rem'"
                    [width]="'0.9rem'"
                    [classes]="'relative -ml-2.5 -mt-1.5'"
                  ></sc-icon>
                </div>
                <div class="text-sm font-medium truncate">
                  <div class="truncate">
                    {{
                      asset.borrowed || 0
                        | weiToCurrency : asset
                        | number : "1.2-6"
                    }}
                    {{ asset.symbol }}
                  </div>
                  <div class="text-slate-500 text-sm">Financed</div>
                </div>
              </div>
              <button
                class="primary-inverse sm"
                [routerLink]="['/purchase']"
                [queryParams]="{ a: asset.symbol }"
              >
                Finance
              </button>
            </div>
          </div>
        </ng-container>
      </div>
      <div
        *ngIf="creditLine.assets.length >= initialVisibleCurrencies"
        class="w-full flex justify-center"
      >
        <button
          *ngIf="!showAllAssets"
          class="nostyle show-btn"
          (click)="showMoreAssets()"
        >
          Show More
        </button>
        <button
          *ngIf="showAllAssets"
          class="nostyle show-btn"
          (click)="showLessAssets()"
        >
          Show Less
        </button>
      </div>
    </section>
    <section *ngIf="creditLine.collaterals" class="w-full md:w-1/2">
      <div class="flex items-center mt-2">
        <h2 class="text-slate-500 font-medium mb-0 mr-1 text-base">
          Collaterals
        </h2>
        <div class="badge primary">{{ creditLine.collaterals.length }}</div>
      </div>
      <hr class="mt-2 mb-4" />
      <div
        class="flex flex-wrap"
        [ngClass]="{ 'currencies-container': !showAllCollaterals }"
      >
        <ng-container
          *ngFor="let collateral of creditLine.collaterals; let i = index"
        >
          <div
            (click)="openCollateralActionsDialog(collateral)"
            *ngIf="
              i <= initialVisibleCurrencies - 1 ||
              (i > initialVisibleCurrencies - 1 && showAllCollaterals)
            "
            class="card asset-card flex items-center justify-between w-full mb-2 hover:bg-slate-50 cursor-pointer"
          >
            <div class="flex truncate">
              <sc-icon
                [src]="icons[collateral.symbol]"
                width="32px"
                height="32px"
                class="self-center"
              >
              </sc-icon>
              <div class="pl-3 truncate">
                <div class="text-base font-semibold truncate">
                  {{ collateral.name }}
                </div>
                <div class="font-normal text-sm text-gray-500">
                  {{ collateral.symbol }}
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex items-center w-36 truncate">
                <div class="pill warning h-8 w-8 mr-1">
                  <sc-icon
                    [src]="icons.lockedCollateral"
                    [size]="'0.4'"
                    [height]="'1rem'"
                    [width]="'0.9rem'"
                    [classes]="'relative -ml-2.5 -mt-1.5'"
                  ></sc-icon>
                </div>
                <div class="text-sm font-medium truncate">
                  <div class="truncate">
                    {{
                      collateral.locked
                        | weiToCurrency : collateral
                        | number : "1.2-6"
                    }}
                    {{ collateral.symbol }}
                  </div>
                  <div class="text-slate-500 text-sm">Locked</div>
                </div>
              </div>
              <div class="flex items-center w-36 truncate">
                <div class="pill primary h-8 w-8 mr-1">
                  <sc-icon
                    [src]="icons.unlockedCollateral"
                    [size]="'0.4'"
                    [height]="'1rem'"
                    [width]="'0.9rem'"
                    [classes]="'relative -ml-2.5 -mt-1.5'"
                  ></sc-icon>
                </div>
                <div class="text-sm font-medium truncate">
                  <div class="truncate">
                    {{
                      collateral.unlocked
                        | weiToCurrency : collateral
                        | number : "1.2-6"
                    }}
                    {{ collateral.symbol }}
                  </div>
                  <span class="text-slate-500 text-sm">Unlocked</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div
        *ngIf="creditLine.collaterals.length >= initialVisibleCurrencies"
        class="w-full flex justify-center"
      >
        <button
          *ngIf="!showAllCollaterals"
          class="nostyle show-btn"
          (click)="showMoreCollaterals()"
        >
          Show More
        </button>
        <button
          *ngIf="showAllCollaterals"
          class="nostyle show-btn mt-3"
          (click)="showLessCollaterals()"
        >
          Show Less
        </button>
      </div>
    </section>
  </div>

  <section class="mt-6 pb-10">
    <div class="flex items-center">
      <h2 class="text-slate-500 font-medium mb-0 mr-1 text-base">
        Finance Line Loans
      </h2>
      <div *ngIf="loans && loans.length > 0" class="badge primary">
        {{ loans.length }}
      </div>
    </div>

    <hr class="mt-2 mb-4" />
    <div *ngIf="loans && loans.length > 0" class="table-wrapper">
      <table>
        <thead>
          <tr>
            <th scope="col">Amount</th>
            <th scope="col">Collateral</th>
            <th scope="col">Interest</th>
            <th scope="col">Status</th>
            <th scope="col">Created</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let loan of loans"
            [routerLink]="['loan/', loan.id]"
            class="cursor-pointer"
          >
            <td class="items-center">
              <div class="flex">
                <sc-icon
                  [src]="icons[loan.underlying.symbol]"
                  width="32px"
                  height="32px"
                  class="self-center mb-2"
                >
                </sc-icon>
                <div class="pl-3">
                  <div class="text-base font-medium">
                    {{
                      loan.loanAmount
                        | weiToCurrency : loan.underlying
                        | number : "1.2-6"
                    }}
                    {{ loan.underlying.symbol }}
                  </div>
                  <div class="font-normal text-sc-success">
                    {{
                      loan.loanAmount
                        | weiToCurrency : loan.underlying : "USD"
                        | currency : "USD" : "symbol-narrow" : "1.2-2"
                    }}
                  </div>
                </div>
              </div>
            </td>
            <td class="items-center">
              <div class="flex">
                <sc-icon
                  [src]="icons[loan.collateral.symbol]"
                  width="32px"
                  height="32px"
                  class="self-center mb-2"
                >
                </sc-icon>
                <div class="pl-3">
                  <div class="text-base font-medium">
                    {{
                      +loan.collateralInfo.amount
                        | weiToCurrency : loan.collateral
                        | number : "1.2-6"
                    }}
                    {{ loan.collateral.symbol }}
                  </div>
                  <div class="font-normal text-sc-success">
                    {{
                      +loan.collateralInfo.amount
                        | weiToCurrency : loan.collateral : "USD"
                        | currency : "USD" : "symbol-narrow" : "1.2-2"
                    }}
                  </div>
                </div>
              </div>
            </td>
            <td class="font-medium">
              {{ loan.netLoanInterestRate | number : "1.2-2" }}%
            </td>
            <td>
              <div class="flex items-center">
                <div
                  class="pl-1 text-sm font-medium sm pill"
                  [ngClass]="{
                    default:
                      loan.loanStatus === 'TERMINATED' ||
                      loan.loanStatus === 'REPAID',
                    primary:
                      loan.loanStatus === 'MATCHED' ||
                      loan.loanStatus === 'NEW REQUEST',
                    success: loan.loanStatus === 'ACTIVE',
                    danger:
                      loan.loanStatus === 'BAD LOAN' ||
                      loan.loanStatus === 'BADLOAN REPAID'
                  }"
                >
                  {{ loan.loanStatus | titlecase }}
                </div>
              </div>
            </td>
            <td>
              <div class="flex items-center">
                <div class="pl-1 text-sm font-normal text-slate-500">
                  {{ loan.createdAt | dateAgo }}
                </div>
              </div>
            </td>
            <td>
              <button
                class="primary-inverse sm"
                [routerLink]="['loan/', loan.id]"
              >
                Details
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      *ngIf="!loans || loans.length === 0"
      class="w-full mx-auto text-center text-slate-500 my-6"
    >
      <div class="text-2xl font-semibold">No Loans</div>
      <div class="text-sm font-normal">
        There are no loans for this finance line.
      </div>
    </div>
  </section>
</ng-container>
