import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Apollo } from "apollo-angular";
import BigNumber from "bignumber.js";
import * as Math from "mathjs";
import { ToastrService } from "ngx-toastr";
import { catchError, EMPTY, forkJoin, map, Observable, startWith, Subject, take, takeUntil } from "rxjs";
import { Currency } from "src/app/shared/interfaces/currencies/currency";
import { FifBucket } from "src/app/shared/interfaces/fifs/fif-bucket";
import { FixedIncomeFund } from "src/app/shared/interfaces/fifs/fixedIncomeFund";
import * as fromApp from "../../store/app.reducer";
import { fixedIncomeFundQuery, fixedIncomeFundsQuery, lendingFormInit, updateFixedIncomeFund } from "../graphql/fifType.js";
import { Web3Service } from "../web3/web3.service";
import * as LendingActions from "./../../lending/store/lending.actions";
import { CurrenciesService } from "./currencies.service";
import { makeStateKey, TransferState } from '@angular/core';
import { environment } from "src/environments/environment";

const FIFS_KEY = makeStateKey<any>('fifs');
const RFR_KEY = makeStateKey<any>('rfr');

@Injectable({
  providedIn: "root",
})
export class LendingService implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private apollo: Apollo,
    private store: Store<fromApp.AppState>,
    private web3Service: Web3Service,
    private router: Router,
    private toastr: ToastrService,
    private currenciesService: CurrenciesService,
    private state: TransferState,
  ) { }

  createFixedIncomeFund(currencyType, buckets: FifBucket[]) {
    this.toastr.info('', 'Confirm the transaction from your wallet to create the FIF!');
    this.store.dispatch(LendingActions.creatingFif({ creatingFif: true }));
    this.web3Service.createFixedIncomeFund$(currencyType, [
      buckets[0].investmentRatio,
      buckets[1].investmentRatio,
      buckets[2].investmentRatio,
      buckets[3].investmentRatio,
    ])
      .pipe(
        catchError((err: any): any => {
          this.store.dispatch(LendingActions.creatingFif({ creatingFif: false }));
          this.toastr.error('Please retry!', 'Something went wrong while creating the FIF!');
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((res) => {
        setTimeout(() => {
          this.store.dispatch(LendingActions.creatingFif({ creatingFif: false }));
          this.router.navigate(["/sell/dashboard/"]);
        }, 5000)
      });
  }

  getFixedIncomeFund(fundId: string) {
    this.apollo
      .watchQuery({
        variables: {
          id: fundId,
        },
        query: fixedIncomeFundQuery,
      }).valueChanges
      .pipe(
        catchError((err: any): any => {
          this.store.dispatch(LendingActions.getFixedIncomeFund({ fixedIncomeFund: null }));
          this.toastr.error('Please retry!', 'Could not fetch this Fixed Income Fund!');
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
        map((res: any) => res.data),
        takeUntil(this.destroy$)
      )
      .subscribe((res: any) => {
        this.store.dispatch(
          LendingActions.getFixedIncomeFund({
            fixedIncomeFund: res.data.fixedIncomeFund,
          })
        );
      });
  }

  getFixedIncomeFunds(ownerAddress: string, isStaking: boolean = false) {
    const exists = this.state.get(FIFS_KEY, {} as any);
    this.apollo
      .watchQuery<any>({
        variables: {
          where: {
            ownerAddress,
            isStaking
          }
        },
        query: fixedIncomeFundsQuery,
        fetchPolicy: "no-cache",
      })
      .valueChanges.pipe(
        catchError((err: any): any => {
          this.store.dispatch(LendingActions.getFixedIncomeFunds({ fixedIncomeFunds: null }));
          this.toastr.error('Please retry!', 'Could not fetch Fixed Income Funds!');
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
        map((res: any) => res.data.fixedIncomeFunds),
        map((funds: FixedIncomeFund[]) => {
          const updatedFunds: FixedIncomeFund[] = [];
          funds.forEach(fund => {
            forkJoin({
              availableFunds: this.web3Service.fetchBalance(
                fund.underlying.ethAddress,
                fund.contractAddress,
                fund.underlying.symbol
              ),
              activeInvested: this.web3Service.getActivelyInvested(
                fund.contractAddress
              ),
            }).subscribe((res) => {
              fund = {
                ...fund,
                availableFunds: res.availableFunds,
                activeInvested: res.activeInvested,
              }
              updatedFunds.push(fund);
              if (updatedFunds.length === funds.length) {
                this.store.dispatch(
                  LendingActions.getFixedIncomeFunds({
                    fixedIncomeFunds: updatedFunds,
                  })
                );
                this.state.set(FIFS_KEY, updatedFunds);
              }
            })
          })
        }, takeUntil(this.destroy$)),
        startWith(exists),
      ).subscribe(() => { });
  }

  depositFunds(fund: FixedIncomeFund, amount) {
    this.store.dispatch(LendingActions.depositingFif({ depositingFif: true }));
    if (fund.underlying.symbol === "ISLM") {
      this.web3Service.transferEthereum(fund, amount)
        .pipe(
          catchError((err: any): any => {
            this.store.dispatch(LendingActions.depositingFif({ depositingFif: false }));
            this.toastr.error('Please retry!', 'Funds could not be deposited');
            if (!environment.production) console.log(err);
            return EMPTY;
          }),
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
          const fif: FixedIncomeFund = {
            ...fund, availableFunds: new BigNumber(fund.availableFunds)
              .plus(this.currenciesService.currencyToWei(amount, fund.underlying)).toString()
          };
          this.store.dispatch(LendingActions.updateFif({ fixedIncomeFund: fif }));
          this.store.dispatch(LendingActions.depositingFif({ depositingFif: false }));
        });
    } else {
      const amountInWei = this.currenciesService.currencyToWei(amount, fund.underlying);
      this.web3Service.transferTokens(fund, amountInWei)
        .pipe(
          catchError((err: any): any => {
            this.store.dispatch(LendingActions.depositingFif({ depositingFif: false }));
            this.toastr.error('Please retry!', 'Funds could not be deposited');
            if (!environment.production) console.log(err);
            return EMPTY;
          }),
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
          const fif: FixedIncomeFund = {
            ...fund, availableFunds: new BigNumber(fund.availableFunds)
              .plus(this.currenciesService.currencyToWei(amount, fund.underlying)).toString()
          };
          this.store.dispatch(LendingActions.updateFif({ fixedIncomeFund: fif }));
          this.store.dispatch(LendingActions.depositingFif({ depositingFif: false }));
        });
    }
  }

  withdrawFunds(fund, availableFunds) {
    this.store.dispatch(LendingActions.withdrawingFif({ withdrawingFif: true }));
    this.web3Service.withdrawFixedIncomeFund(fund.contractAddress, availableFunds)
      .pipe(
        catchError((err: any): any => {
          this.store.dispatch(LendingActions.withdrawingFif({ withdrawingFif: false }));
          this.toastr.error('Please retry!', 'Funds could not be withdrawn');
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
        takeUntil(this.destroy$))
      .subscribe(() => {
        const fif: FixedIncomeFund = { ...fund, availableFunds: '0' };
        this.store.dispatch(LendingActions.updateFif({ fixedIncomeFund: fif }));
        this.store.dispatch(LendingActions.withdrawingFif({ withdrawingFif: false }));
      });
  }

  reallocate(fif: FixedIncomeFund, buckets: FifBucket[]) {
    this.store.dispatch(LendingActions.reallocatingFif({ reallocatingFif: true }))
    this.web3Service.updateBucketRatio(fif.contractAddress, buckets)
      .pipe(
        catchError((err: any): Observable<any> => {
          this.toastr.error('Please retry!', 'Could not reallocate funds');
          this.store.dispatch(LendingActions.reallocatingFif({ reallocatingFif: false }))
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((res) => {
        const fixedIncomeFund = { ...fif, buckets };
        this.store.dispatch(LendingActions.updateFif({ fixedIncomeFund }));
        this.store.dispatch(LendingActions.reallocatingFif({ reallocatingFif: false }))
      }
      );
  }

  updateInvestmentStatus(fund: FixedIncomeFund, status: String) {
    this.store.dispatch(LendingActions.terminatingFif({ terminatingFif: true }));
    this.apollo
      .mutate({
        mutation: updateFixedIncomeFund,
        variables: {
          contractAddress: fund.contractAddress,
          status: status
        }
      })
      .pipe(
        catchError((err: any): Observable<any> => {
          this.store.dispatch(LendingActions.terminatingFif({ terminatingFif: false }));
          this.toastr.error('Please retry!', 'FIF could not be updated');
          if (!environment.production) console.log(err);
          return EMPTY;
        }), takeUntil(this.destroy$)
      )
      .subscribe((res: any) => {
        const fif = { ...fund, status: res.data.updateFixedIncomeFundStatus.status, updatedAt: res.data.updateFixedIncomeFundStatus.updatedAt };
        this.store.dispatch(LendingActions.updateFif({ fixedIncomeFund: fif }));
        this.store.dispatch(LendingActions.terminatingFif({ terminatingFif: false }));
      });
  }

  getApy(currency: Currency, buckets: FifBucket[], riskFreeRateLogBase: any) {
    return this.web3Service.getCompoundSupplyRate(currency, 0)
      .pipe(
        take(1),
        map((apy: number) => {
          const baseRiskFreeRate = currency.riskFreeRate;
          const apy1 = new BigNumber(buckets[0].investmentRatio).dividedBy(100).times(baseRiskFreeRate)
            .times(Math.log((7 + 31) / 2, riskFreeRateLogBase)).toFixed(2);
          const apy2 = new BigNumber(buckets[1].investmentRatio).dividedBy(100).times(baseRiskFreeRate)
            .times(Math.log((31 + 60) / 2, riskFreeRateLogBase)).toFixed(2);
          const apy3 = new BigNumber(buckets[2].investmentRatio).dividedBy(100).times(baseRiskFreeRate)
            .times(Math.log((61 + 90) / 2, riskFreeRateLogBase)).toFixed(2);
          const apy4 = new BigNumber(buckets[3].investmentRatio).dividedBy(100).times(baseRiskFreeRate)
            .times(Math.log((91 + 180) / 2, riskFreeRateLogBase)).toFixed(2);
          return +(new BigNumber(apy).plus(apy1).plus(apy2).plus(apy3).plus(apy4).toFixed(2));
        }),
        takeUntil(this.destroy$))
  }

  getRiskFreeRateLogBase() {
    const exists = this.state.get(RFR_KEY, {} as any);
    this.apollo
      .watchQuery({
        query: lendingFormInit,
      }).valueChanges
      .pipe(
        catchError((err: any): Observable<any> => {
          if (!environment.production) console.log(err);
          return EMPTY;
        }),
        map((res: any) => res.data.paramsInformation.riskFreeRateLogBase),
        startWith(exists),
        takeUntil(this.destroy$))
      .subscribe((riskFreeRateLogBase: any) => {
        this.store.dispatch(
          LendingActions.updateRiskFreeRateLogBase({
            riskFreeRateLogBase
          })
        );
        this.state.set(RFR_KEY, riskFreeRateLogBase);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
