<div class="table-wrapper max-h-80 rewards-table">
  <table>
    <thead>
      <tr>
        <th scope="col">Amount</th>
        <th scope="col">Type</th>
        <th scope="col">Status</th>
        <th scope="col" class="text-right">Date Received</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let r of unclaimedRewards">
        <th scope="row">
          <div class="font-medium">
            {{ r.value | number:'1.2-2'}} {{ r.Currency.symbol }}
          </div>
          <div class="text-sm text-slate-500">
            ${{ r.usdValue | number:'1.2-2' }}
          </div>
        </th>
        <td>
          <div class="badge w-fit" [ngClass]="{
            'primary': r.rewardCode === 'Borrower Weekly Reward',
            'success': r.rewardCode === 'Lender Weekly Reward',
            'warning': r.rewardCode === 'Withdrawn Gasless Re-Staked',
            'success-inverse': r.rewardCode === 'Referrer Reward',
            'primary-inverse': r.rewardCode === 'First Loan Over $1000'}">
            {{ r.rewardCode }}</div>
        </td>
        <td>
          <div class="badge default-inverse">{{ r.status }}</div>
        </td>
        <td>
          <div class="text-sm text-slate-500">
            {{ r.rewardedAt | date }}
          </div>
        </td>
      </tr>
      <!-- <tr>
        <th scope="row">1.73 SMARTCREDIT</th>
        <td>{{ 3.6 | currency:'USD':'symbol':'1.2-4' }}</td>
        <td><div class="badge default w-fit">Borrower Weekly Reward</div></td>
        <td>
          <div class="text-sm text-slate-500">
            Jan 28, 2032, 11:00 PM
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row">1.73 SMARTCREDIT</th>
        <td>{{ 3.6 | currency:'USD':'symbol':'1.2-4' }}</td>
        <td><div class="badge default w-fit">Borrower Weekly Reward</div></td>
        <td>
          <div class="text-sm text-slate-500">
            Jan 28, 2032, 11:00 PM
          </div>
        </td>
      </tr> -->
    </tbody>
  </table>
  <div *ngIf="!unclaimedRewards || unclaimedRewards.length === 0"
    class="w-full mx-auto text-center text-slate-500 my-6">
    <div class="text-xl font-semibold">No unclaimed rewards</div>
    <div class="text-sm font-normal">You have no unclaimed rewards yet.</div>
  </div>
</div>
