import { Component, Input } from '@angular/core';
import { Currency } from 'src/app/shared/interfaces/currencies/currency';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'collaterals-table',
  templateUrl: './collaterals-table.component.html',
  styles: ['']
})
export class CollateralsTableComponent {
  @Input() collaterals: Currency[];
  @Input() cryptoIcons: {};
  public environment = environment;
  public showAll: boolean;
}
