<section class="pb-10">
  <div class="flex items-center mt-6 mb-3">
    <h2 class="mb-0 mr-2">Collaterals</h2>
    <button class="xs primary-inverse" (click)="showAll = !showAll">Show {{ !showAll ? 'More' :
      'Less' }}</button>
  </div>
  <div class="table-wrapper">
    <table>
      <thead>
        <tr>
          <th scope="col" class="sr-only" id="collaterals">Collaterals</th>
          <th scope="col" id="collateralLocked">Collateral Locked</th>
          <th scope="col" id="collateralRatio">Min. Collateral Ratio</th>
          <th scope="col" class="sr-only" id="actions">Actions</th>
        </tr>
      </thead>
      <tbody *ngIf="collaterals">
        <tr *ngFor="let c of collaterals">
          <ng-container
            *ngIf="(c.lockedCollateralValueInUSD + c.unlockedCollateralValueInUSD !== 0) || showAll">
            <td class="items-center" headers="collaterals">
              <div class="flex">
                <sc-icon [src]="cryptoIcons[c.symbol]" width="32px" height="32px"
                  class="self-center mb-2">
                </sc-icon>
                <div class="pl-3">
                  <div class="text-base font-medium">{{ c.name }}</div>
                  <div class="font-normal text-gray-500">{{ c.symbol }}</div>
                </div>
              </div>
            </td>
            <td headers="collateralLocked">
              <div class="text-base font-medium">{{ c.lockedCollateralValueInUSD +
                c.unlockedCollateralValueInUSD || 0 |
                currency:'USD':'symbol-narrow':'1.0-0' }} </div>
            </td>
            <td headers="collateralRatio">
              <span class="pill primary">{{ c.minCollateralRatio | number:'1.2-2'}}%</span>
            </td>
            <td class="text-right" headers="actions">
              <a role="button" class="primary-inverse sm mx-1" [routerLink]="['/purchase']"
                [queryParams]="{c: c.symbol}" [href]="environment.baseUrl + '/purchase'">Use as
                Collateral</a>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
</section>
