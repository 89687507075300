import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  filter,
  Subject,
  take,
  takeUntil,
  combineLatest,
  debounceTime,
  timer,
  switchMap,
  map
} from 'rxjs';
import { BorrowingService } from 'src/app/core/services/borrowing.service';
import { CurrenciesService } from 'src/app/core/services/currencies.service';
import { StatsService } from 'src/app/core/services/stats.service';
import { CreditLine } from 'src/app/shared/interfaces/borrowing/credit-line';
import { Loan } from 'src/app/shared/interfaces/borrowing/loan';
import { Currencies } from 'src/app/shared/interfaces/currencies/currencies';
import { DashboardStats } from 'src/app/shared/interfaces/statistics/dashboard-stats';
import { User } from 'src/app/shared/interfaces/user/user';
import { AppState } from 'src/app/store/app.reducer';
import { CRYPTO_ICONS, ASSET_ICONS, GENERAL_ICONS } from 'src/assets/images/svg-icons';

interface BorrowingDashboardStats {
  totalLoans: number;
  totalCreditLines: number;
  collateralDeposited: number;
  currentlyBorrowed: number;
  platformBorrowed: number;
}
@Component({
  selector: 'app-borrowing-dashboard',
  templateUrl: './borrowing-dashboard.component.html',
  styleUrls: ['./borrowing-dashboard.component.scss']
})
export class BorrowingDashboardComponent implements OnInit, OnDestroy {
  public remainingCollaterals: number;
  public remainingAssets: number;
  public maxCurrenciesToShow = 8;
  public creditLines: CreditLine[];
  public destroy$ = new Subject<void>();
  public currencies: Currencies;
  public loans: Loan[];
  public icons = { ...GENERAL_ICONS, ...ASSET_ICONS, ...CRYPTO_ICONS };
  public borrowingStats: BorrowingDashboardStats = {
    totalLoans: 0,
    totalCreditLines: 0,
    collateralDeposited: 0,
    currentlyBorrowed: 0,
    platformBorrowed: 0
  };
  public user: User;
  public stats: DashboardStats;
  public isFetching: boolean = false;
  constructor(
    private borrowingService: BorrowingService,
    private store: Store<AppState>,
    private currenciesService: CurrenciesService,
    private router: Router,
    private statsService: StatsService,
  ) {
    this.currenciesService.fetchCurrencies ? this.currenciesService.fetchCurrencies() : null;
    this.statsService.getDashboardStats ? this.statsService.getDashboardStats() : false;
  }

  ngOnInit(): void {
    this.isFetching = true;


    this.store.select(state => state.core.user)
      .pipe(filter(user => !!user), takeUntil(this.destroy$))
      .subscribe(async (user: User) => {
        this.user = user;
        this.borrowingService.getCreditLines({ ownerAddress: user.ethAddress })
        await this.borrowingService.getLoans(user.ethAddress, user.creditData.riskRating)
      })

    this.store.select(state => state.borrowing.creditLines)
      .pipe(takeUntil(this.destroy$))
      .subscribe((creditLines: CreditLine[]) => {
        if (creditLines.length === 0) {
          this.router.navigate(['/purchase']);
          this.isFetching = false;

        } else {
          timer(2000).pipe(take(1)).subscribe(() => {
            this.isFetching = false;
            this.store.select('dashboard')
              .pipe(takeUntil(this.destroy$))
              .subscribe((dashboardStore => {
                this.stats = dashboardStore.stats;
                if (!this.stats) {
                  this.statsService.getDashboardStats();
                }
              }))

            this.store.select(state => state.dashboard.stats)
              .pipe(takeUntil(this.destroy$))
              .subscribe((platformStats => {
                this.borrowingStats.platformBorrowed = platformStats?.getTVL?.borrowingTVL;
              }))

            combineLatest({
              currencies: this.store.select(state => state.dashboard.currencies)
                .pipe(filter(currencies => !!currencies), take(1)),
              creditLines: this.store.select(state => state.borrowing.creditLines)
                .pipe(filter(creditLines => !!creditLines && creditLines.length > 0), takeUntil(this.destroy$))
            }).pipe(takeUntil(this.destroy$)).subscribe(({ currencies, creditLines }) => {
              this.creditLines = creditLines;
              this.borrowingStats.totalCreditLines = 0;
              this.borrowingStats.totalCreditLines = creditLines.length;
              this.currencies = currencies;
              this.remainingCollaterals = this.currencies.collateralCurrencies.length - this.maxCurrenciesToShow;
              this.remainingAssets = this.currencies.underlyingCurrencies.length - this.maxCurrenciesToShow;

              this.creditLines.forEach((creditLine: CreditLine) => {
                if (!creditLine.assets || !creditLine.collaterals) {
                  this.borrowingService.getCreditLineCurrenciesAndBalances(creditLine, currencies.underlyingCurrencies, currencies.collateralCurrencies)
                    .subscribe(() => {
                      this.store.select(state => state.borrowing.creditLines)
                        .pipe(
                          filter(cLs => cLs && cLs.length > 0),
                          map((cLs: CreditLine[]): CreditLine | undefined => cLs.find(cL => cL.contractAddress === creditLine.contractAddress)),
                          take(1)).subscribe((updatedLine) => {
                            creditLine = updatedLine;
                          })
                    });
                }
              })

            })

            this.store.select(state => state.borrowing.loans)
              .pipe(filter(loans => !!loans && loans.length > 0), takeUntil(this.destroy$))
              .subscribe((loans: Loan[]) => {
                this.borrowingStats.totalLoans = 0;
                this.borrowingStats.collateralDeposited = 0;
                this.borrowingStats.currentlyBorrowed = 0;
                this.loans = loans;
                this.loans.forEach((loan: Loan) => {
                  if (loan.loanStatus === 'ACTIVE') {
                    this.borrowingStats.totalLoans++;
                    this.borrowingStats.currentlyBorrowed += this.currenciesService.weiToCurrency(loan.loanAmount.toString(), loan.underlying, 'USD');
                    this.borrowingStats.collateralDeposited += this.currenciesService.weiToCurrency(loan.currentCollateralAmount, loan.collateral, 'USD');
                  }
                })
              })
          })
        }
      })

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
