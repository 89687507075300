import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { LegendPosition, ScaleType } from "@swimlane/ngx-charts";
import { FifBucket } from "src/app/shared/interfaces/fifs/fif-bucket";
import { CRYPTO_ICONS, FIF, GENERAL_ICONS, MENU_ICONS } from "src/assets/images/svg-icons";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/store/app.reducer";
import { LendingService } from "src/app/core/services/lending.service";
import { Currency } from "src/app/shared/interfaces/currencies/currency";
import { Currencies } from "src/app/shared/interfaces/currencies/currencies";
import { CurrenciesService } from "src/app/core/services/currencies.service";
import { debounceTime, filter, map, Subject, take, takeUntil } from "rxjs";
import * as constants from "src/app/shared/constants";
import { FixedIncomeFund } from "src/app/shared/interfaces/fifs/fixedIncomeFund";
import { User } from "src/app/shared/interfaces/user/user";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { Dialog, DialogRef } from "@angular/cdk/dialog";
import { DistributionInfoDialog } from "../../components/distribution-info/distribution-info.dialog";
import seoConfig from '../../../../assets/static/seo.config.json';
import lendConfig from '../../../../assets/static/lend.config.json';
import { Meta, MetaDefinition, Title } from "@angular/platform-browser";
import { PixelService } from "src/app/core/services/pixel.service";

@Component({
  selector: "app-lend",
  templateUrl: "./lend.component.html",
  styleUrls: ["./lend.component.scss"],
})

export class LendComponent implements OnInit, OnDestroy {
  pixelService = inject(PixelService);
  fb = inject(FormBuilder);
  lendingService = inject(LendingService);
  store = inject(Store<AppState>);
  currenciesService = inject(CurrenciesService);
  route = inject(ActivatedRoute);
  dialog = inject(Dialog);
  title = inject(Title);
  meta = inject(Meta);
  cryptoIcons = CRYPTO_ICONS;
  icon = FIF;
  icons = { ...GENERAL_ICONS, ...MENU_ICONS };
  buttonClicked: boolean;
  lendForm: FormGroup;
  buckets: Array<FifBucket>;
  isFifCreating: boolean = false;
  chartOptions = {};
  currencies: Currency[];
  selectedAsset: any;
  overlayOutsideClicked: boolean = false;
  assetOpen: boolean = false;
  destroy$ = new Subject<void>();
  chartData: any = [];
  user: User;
  advancedMode: boolean;
  presetValues = {
    asset: undefined,
    distribution: []
  }
  environment = environment;
  pageDetails: any;
  // APY
  apy: number = 0;
  riskFreeRateLogBase: number;
  fifs: FixedIncomeFund[];
  legendPosition: LegendPosition = LegendPosition.Below;
  colorScheme = {
    name: 'cool',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#05D394', '#7ea5e9', '#4d7dd6', '#1e56c3'],
  };
  fundDialog: DialogRef<any>;
  public isDashboardBtnEnable: boolean = false;
  constructor() {
    this.lendForm = this.fb.group({
      currency: ["", Validators.required],
      distribution: [],
      first: [0],
      second: [0],
      third: [0],
      fourth: [0]
    });
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.presetValues.asset = params['a'];
        this.lendForm.controls['currency'].setValue(this.presetValues.asset);
      });
    this.currenciesService.fetchCurrencies();
    this.buckets = constants.BUCKETS;
    this.chartData = this.buckets.map((bucket: FifBucket) => ({ name: bucket.name, value: bucket.investmentRatio }))
    if (this.presetValues.asset) {
      this.pageDetails = lendConfig.tokens.find(r => r.token === this.presetValues.asset)
    } else {
      this.pageDetails = seoConfig.routes.find(r => r.path === '/sell');
    }
    this.addSeoConfig(this.pageDetails);
  }

  ngOnInit(): void {

    this.store.select(state => state.core.user)
      .pipe(filter(user => !!user), takeUntil(this.destroy$))
      .subscribe((user: User) => {
        this.user = user;
        this.lendingService.getFixedIncomeFunds(this.user.ethAddress, false);
      })

    this.store.select(store => store.lending.fixedIncomeFunds)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe((fixedIncomeFunds) => {
        if (fixedIncomeFunds) {
          this.isDashboardBtnEnable = true;
        }
      })

    this.pixelService.getPixelData().pipe(takeUntil(this.destroy$)).subscribe();
    this.lendingService.getRiskFreeRateLogBase();
    this.store.select(state => state.dashboard.currencies)
      .pipe(
        filter(currencies => !!currencies),
        take(1),
        map((currencies: Currencies) => currencies.underlyingCurrencies),
        takeUntil(this.destroy$)
      )
      .subscribe((currencies: Currency[]) => {
        this.currencies = currencies ? currencies.filter(c => c.symbol !== 'SMARTCREDIT') : undefined;
        if (currencies) {
          this.selectedAsset = this.presetValues.asset ? this.currencies.find(c => c.symbol === this.presetValues.asset) : this.currencies[0];
          this.lendForm.controls['currency'].setValue(this.currencies[0].symbol, { emitEvent: true });
        }
      });
    this.store.select(state => state.lending.creatingFif)
      .pipe(takeUntil(this.destroy$))
      .subscribe((creatingFif) => {
        this.isFifCreating = creatingFif;
      });
    this.store.select(state => state.core.user)
      .pipe(
        filter(user => !!user),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe((user: User) => {
        if (user) {
          this.user = user;
          // this.lendingService.getFixedIncomeFunds(user.ethAddress);
        }
      });
    // this.store.select(state => state.lending.fixedIncomeFunds)
    //   .pipe(
    //     filter(fifs => !!fifs),
    //     take(1),
    //     takeUntil(this.destroy$)
    //   )
    //   .subscribe((fifs: FixedIncomeFund[]) => {
    //     this.fifs = fifs;
    //   });
    this.store.select(state => state.lending.riskFreeRateLogBase)
      .pipe(
        filter(riskFreeRateLogBase => !!riskFreeRateLogBase),
        takeUntil(this.destroy$)
      )
      .subscribe((riskFreeRateLogBase: number) => {
        this.riskFreeRateLogBase = riskFreeRateLogBase;
      });
    this.onFormValueChangeEvents();
  }

  onFormValueChangeEvents() {
    this.lendForm.valueChanges
      .pipe(debounceTime(100), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.calculateApy();
      })
    this.lendForm.controls['distribution'].valueChanges
      .pipe(filter(value => !!value), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.chartData = [];
        if (value[0] === 'equal') {
          this.buckets = this.buckets.map((bucket: FifBucket) => ({
            ...bucket,
            investmentRatio: 25, max: 25
          }));
        }
        if (value[0] === 'highestAPY') {
          this.buckets = this.buckets.map((bucket: FifBucket, index) => ({
            ...bucket,
            investmentRatio: index === 3 ? 100 : 0,
            max: index === 3 ? 100 : 0
          }));
        }
        if (value[0] === 'fixed') {
          const fixedBuckets = [1, 2, 3]
          this.buckets = this.buckets.map((bucket: FifBucket, index) => ({
            ...bucket,
            investmentRatio: fixedBuckets.includes(index) ? (index === 3 ? 34 : 33) : 0,
            max: fixedBuckets.includes(index) ? (index === 3 ? 34 : 33) : 0
          }))
        }
        if (value[0] === 'variable') {
          this.buckets = this.buckets.map((bucket: FifBucket, index) => ({
            ...bucket,
            investmentRatio: index === 0 ? 100 : 0,
            max: index === 0 ? 100 : 0
          }));
        }
        this.chartData = this.buckets.map((bucket: FifBucket) => ({ name: bucket.name, value: bucket.investmentRatio }))
      })

    this.lendForm.controls['first'].valueChanges
      .pipe(debounceTime(100), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.updateBucketValues(this.buckets[0], value);
      })

    this.lendForm.controls['second'].valueChanges
      .pipe(debounceTime(100), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.updateBucketValues(this.buckets[1], value);
      })

    this.lendForm.controls['third'].valueChanges
      .pipe(debounceTime(100), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.updateBucketValues(this.buckets[2], value);
      })

    this.lendForm.controls['fourth'].valueChanges
      .pipe(debounceTime(100), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.updateBucketValues(this.buckets[3], value);
      })
  }

  updateBucketValues(bucket: any, newValue: any) {
    if (bucket.investmentRatio < newValue) {
      this.buckets = this.buckets.map((b) => {
        if (b.name !== bucket.name) {
          return {
            ...b,
            max: b.max - (Math.abs(bucket.investmentRatio - newValue)),
            investmentRatio: b.investmentRatio > b.max ? b.max : b.investmentRatio
          }
        } else {
          return {
            ...b,
            investmentRatio: newValue
          }
        }
      })
    } else if (bucket.investmentRatio > newValue) {
      this.buckets = this.buckets.map((b) => {
        if (b.name !== bucket.name) {
          return {
            ...b,
            max: b.max + (Math.abs(bucket.investmentRatio - newValue)),
          }
        } else {
          return {
            ...b,
            investmentRatio: newValue
          }
        }
      })
    }
    this.lendForm.controls['first'].setValue(this.buckets[0].investmentRatio, { emitEvent: false });
    this.lendForm.controls['second'].setValue(this.buckets[1].investmentRatio, { emitEvent: false });
    this.lendForm.controls['third'].setValue(this.buckets[2].investmentRatio, { emitEvent: false });
    this.lendForm.controls['fourth'].setValue(this.buckets[3].investmentRatio, { emitEvent: false });
    this.chartData = this.buckets.map((bucket: FifBucket) => ({ name: bucket.name, value: bucket.investmentRatio }))
    this.calculateBucketsTotalAndUpdateValidity();
  }

  calculateBucketsTotalAndUpdateValidity() {
    if ((this.buckets[0].investmentRatio + this.buckets[1].investmentRatio + this.buckets[2].investmentRatio + this.buckets[3].investmentRatio) !== 100) {
      this.lendForm.setErrors({ 'invalid': true });
    } else {
      this.lendForm.setErrors(null);
    }
  }

  onSubmit() {
    const fifParams = this.lendForm.value;
    this.lendingService.createFixedIncomeFund(fifParams.currency + "_FIF", this.buckets)
  }

  getRiskFreeRate(currency: Currency, currencies: Currency[]): number {
    const { riskFreeRate: baseRiskFreeRate } = currencies.find(symbol => symbol === currency);
    return baseRiskFreeRate;
  }

  selectAsset(asset: Currency) {
    this.selectedAsset = asset;
    this.lendForm.controls['currency'].setValue(asset.symbol, { emitEvent: true });
  }

  onOverlayOutsideClick() {
    this.overlayOutsideClicked = true;
    setTimeout(() => {
      this.overlayOutsideClicked = false;
    }, 100);
  }

  calculateApy() {
    if (this.riskFreeRateLogBase && typeof this.riskFreeRateLogBase === 'number') {
      this.lendingService.getApy(
        this.selectedAsset,
        this.buckets,
        this.riskFreeRateLogBase
      )
        .pipe(takeUntil(this.destroy$))
        .subscribe((apy: any) => {
          this.apy = apy;
        })
    } else {
      this.lendingService.getRiskFreeRateLogBase();
    }
  }

  openInfoDialog() {
    this.fundDialog = this.dialog.open(DistributionInfoDialog, {
      id: 'fund-info',
      ...constants.DEFAULT_DIALOG_CONFIG,
    });

    this.fundDialog.componentInstance['reject']
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe((reject) => {
        this.fundDialog.close();
      })
  }

  addSeoConfig(seoConfig: any): void {
    this.title.setTitle(seoConfig.seoTitle)
    if (seoConfig.metaTags) {
      seoConfig.metaTags.forEach((tag: MetaDefinition) => {
        this.meta.addTag(tag);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
