import { createAction, props } from "@ngrx/store"
import { EarnCalculationInput } from "src/app/shared/interfaces/earn/earn-calculation-input"
import { EarnCalculationResult } from "src/app/shared/interfaces/earn/earn-calculation-result"

export const saveCalculation = createAction(
  'saveCalculation',
  props<{
    earnCalculationInput: EarnCalculationInput;
  }>()
)
export const calculate = createAction(
  'calculate',
  props<{
    earnCalculationResult: EarnCalculationResult;
  }>()
)

export const resetState = createAction(
  'resetState'
)
