import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './layout/header/header.component';
import { MenuComponent } from './layout/menu/menu.component';
import { IconComponent } from './components/icon.component';
import { ThemeComponent } from './layout/theme/theme.component';
import { ArticleComponent } from './components/article.component';
import { PageTitleComponent } from './components/page-title.component';
import { HowToArticleComponent } from './components/how-to-article.component';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { PromoBannerComponent } from './components/promo-banner.component';
import { ShortAddressPipe } from './pipes/short-address.pipe';
import { AccountCenterComponent } from './layout/account-center/account-center.component';
import { SigningDialogComponent } from './components/signing-dialog.component';
import { DialogModule } from '@angular/cdk/dialog';
import { SpinnerComponent } from './components/spinner.component';
import { SkeletonRectComponent } from './components/skeleton-rect.component';
import { ErrorPageComponent } from './components/error-page.component';
import { WeiToCurrencyPipe } from './pipes/wei-to-currency.pipe';
import { CurrencyToWeiPipe } from './pipes/currency-to-wei.pipe';
import { CreditLineTypePipe } from './pipes/credit-line-type.pipe';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PromoCardsComponent } from './components/promo-cards.component';
import { Popup } from './components/popup.component';
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component';
import { FooterComponent } from './layout/footer/footer.component';
import { WeiToScPipe } from './pipes/wei-to-sc.pipe';
import { CookieNoticeComponent } from './components/cookie-notice.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { ParagraphsComponent } from './components/paragraphs.component';
import { ClaimingRewardsDialog } from './components/claiming-rewards.dialog';
import { DateFromPipe } from './pipes/date-from.pipe';
import { SentencecasePipe } from './pipes/sentencecase.pipe';
import { LetsExchangeDialog } from './components/lets-exchange/lets-exchange.dialog';
import { TooltipDirective } from './directives/tooltip.directive';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { SimpleAdvancedInfoComponent } from './components/simple-advanced-info.component';
import { CreditLineTypeTextPipe } from './pipes/credit-line-type-text.pipe';


@NgModule({
  declarations: [
    HeaderComponent,
    MenuComponent,
    IconComponent,
    ThemeComponent,
    ArticleComponent,
    PageTitleComponent,
    HowToArticleComponent,
    PromoBannerComponent,
    ShortAddressPipe,
    AccountCenterComponent,
    SigningDialogComponent,
    SpinnerComponent,
    SkeletonRectComponent,
    ErrorPageComponent,
    WeiToCurrencyPipe,
    CurrencyToWeiPipe,
    CreditLineTypePipe,
    CreditLineTypeTextPipe,
    DateAgoPipe,
    PromoCardsComponent,
    Popup,
    VerifyEmailComponent,
    FooterComponent,
    WeiToScPipe,
    CookieNoticeComponent,
    ParagraphsComponent,
    ClaimingRewardsDialog,
    DateFromPipe,
    SentencecasePipe,
    LetsExchangeDialog,
    TooltipDirective,
    TooltipComponent,
    SimpleAdvancedInfoComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    OverlayModule,
    DialogModule,
    ClipboardModule,
    DragScrollModule
  ],
  exports: [
    IconComponent,
    ThemeComponent,
    ArticleComponent,
    PageTitleComponent,
    HowToArticleComponent,
    PromoBannerComponent,
    ShortAddressPipe,
    WeiToCurrencyPipe,
    CurrencyToWeiPipe,
    WeiToScPipe,
    DateAgoPipe,
    DateFromPipe,
    CreditLineTypePipe,
    CreditLineTypeTextPipe,
    AccountCenterComponent,
    SpinnerComponent,
    PromoCardsComponent,
    VerifyEmailComponent,
    CookieNoticeComponent,
    ParagraphsComponent,
    SentencecasePipe,
    TooltipDirective,
    AccountCenterComponent,
    SimpleAdvancedInfoComponent,
    HeaderComponent,
    FooterComponent,
    MenuComponent
  ]
})
export class SharedModule { }
