import { Action, createReducer, on } from "@ngrx/store";
import { Article } from "src/app/shared/interfaces/articles/article";
import { Currencies } from "src/app/shared/interfaces/currencies/currencies";
import { DashboardStats } from "src/app/shared/interfaces/statistics/dashboard-stats";
import * as DashboardActions from "./dashboard.actions";

export interface AppState {
  dashboard: State
}

export interface State {
  currencies: Currencies | undefined;
  articles: Article[] | undefined;
  stats: DashboardStats | undefined;
}

const initialState: State = {
  currencies: undefined,
  articles: undefined,
  stats: undefined
}

const _dashboardReducer = createReducer(
  initialState,
  on(
    DashboardActions.getCurrencies,
    (state, action) => ({
      ...state,
      currencies: action.currencies
    })
  ),
  on(
    DashboardActions.getArticles,
    (state, action) => ({
      ...state,
      articles: action.articles
    })
  ),
  on(
    DashboardActions.getStats,
    (state, action) => ({
      ...state,
      stats: action.stats
    })
  ),
  on(DashboardActions.updateCollateralLiquidity,
    (state, actions) => ({
      ...state,
      currencies: {
        ...state.currencies,
        collateralCurrencies: state.currencies.collateralCurrencies.map(c => {
          if (c.symbol === actions.symbol) {
            c = {
              ...c,
              liquidityPool: actions.liquidity
            };
          }
          return c;
        })
      }
    })
  ),
  on(
    DashboardActions.resetState,
    () => initialState
  )
)

export function dashboardReducer(state: State, action: Action) {
  return _dashboardReducer(state, action);
}
