import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileGuard } from '../core/guards/profile.guard';
import { ProfileComponent } from './pages/profile/profile.component';

const routes: Routes = [{
  path: ':activeTab',
  component: ProfileComponent,
  canActivate: [ProfileGuard]
}, {
  path: '',
  redirectTo: 'details',
  pathMatch: 'full'
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
