import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-terminate-loan',
  templateUrl: './terminate-loan.dialog.html',
  styles: [``]
})
export class TerminateLoanDialog {
  @Output('terminationConfirmed') terminationConfirmed: EventEmitter<boolean> = new EventEmitter()
  @Output('reject') reject: EventEmitter<boolean> = new EventEmitter()
  constructor() { }

  onTerminationConfirmed() {
    this.terminationConfirmed.emit(true);
  }

  onCloseClick() {
    this.reject.emit(true);
  }
}
