export const REWARDS = {
  abi: [
    {
      inputs: [
        {
          internalType: 'bytes',
          name: 'signature',
          type: 'bytes'
        },
        {
          internalType: 'uint256',
          name: 'rewardId',
          type: 'uint256'
        },
        {
          internalType: 'address',
          name: 'receiver',
          type: 'address'
        },
        {
          internalType: 'uint256[]',
          name: 'tokenValues',
          type: 'uint256[]'
        },
        {
          internalType: 'address[]',
          name: 'tokenAddresses',
          type: 'address[]'
        },
        {
          internalType: 'uint256',
          name: 'ethValue',
          type: 'uint256'
        }
      ],
      name: 'redeem',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'rewardId',
          type: 'uint256'
        }
      ],
      name: 'getRedeemStatus',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool'
        }
      ],
      stateMutability: 'view',
      type: 'function',
      constant: true
    }
  ]
};
