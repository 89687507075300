import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import BigNumber from 'bignumber.js';
import { CurrenciesService } from 'src/app/core/services/currencies.service';
import { Web3Service } from 'src/app/core/web3/web3.service';
import { Currency } from 'src/app/shared/interfaces/currencies/currency';
import { regEx } from 'src/config/app.config';

@Component({
  selector: 'app-collateral-actions',
  templateUrl: './collateral-actions.dialog.html',
  styles: [``]
})
export class CollateralActionsDialog implements OnInit {
  public depositAmount: FormControl = new FormControl();
  public withdrawAmount: FormControl = new FormControl();
  public requiredAmount: number;
  public collateral: Currency;
  public withdrawTab: boolean = false;
  public availableBalance: number;

  @Output('deposit') deposit: EventEmitter<string> = new EventEmitter();
  @Output('withdraw') withdraw: EventEmitter<string> = new EventEmitter();

  constructor(
    @Inject(DIALOG_DATA) public data: { ethAddress: string, requiredCollateral: number, collateral: Currency },
    private currenciesService: CurrenciesService,
    private web3Service: Web3Service,
  ) {
    this.requiredAmount = data.requiredCollateral;
    this.collateral = data.collateral;

    this.web3Service.fetchBalance(
      data.collateral.ethAddress,
      data.ethAddress,
      data.collateral.symbol
    ).subscribe(res => this.availableBalance = this.currenciesService.weiToCurrency(res, data.collateral))
  }

  ngOnInit(): void {
    const maxWithdrawable = this.currenciesService.weiToCurrency(this.collateral.unlocked, this.collateral);
    this.depositAmount.setValidators([Validators.required, Validators.pattern(regEx.numbers), Validators.min(0.000001), this.minBalanceValidator()]);
    this.withdrawAmount.setValidators([Validators.required, Validators.pattern(regEx.numbers), Validators.min(0.000001), Validators.max(maxWithdrawable)]);
    this.depositAmount.setValue(this.currenciesService.weiToCurrency(this.requiredAmount, this.collateral));
  }

  onDeposit() {
    this.deposit.emit(this.currenciesService.currencyToWei(this.depositAmount.value, this.collateral));
  }

  onWithdraw() {
    this.withdraw.emit(this.currenciesService.currencyToWei(this.withdrawAmount.value, this.collateral));
  }

  minBalanceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const depositAmount = new BigNumber(control.value).toNumber();
      return depositAmount > this.availableBalance ? { minBalance: true } : null;
    };
  }
}
