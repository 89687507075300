import gql from 'graphql-tag';


export const fetchAllStakings = gql`
  query($where: JSON) {
    fetchAllStakings(where: $where) {
      id,
      contractAddress,
      transactionHash,
      signature,
      blockNumber,
      depositAmount,
      withdrawnAmount,
      createdAt,
      isGaslessRestaked
    },
    fetchAllWithdrawStakings(where: $where) {
      id,
      withdrawnAmount,
      contractAddress,
      transactionHash,
      signature,
      blockNumber,
      createdAt,
      isGaslessRestaked
    }
  }
`;

export const fetchAllStakingRewards = gql`
  query($where: JSON, $contractAddress: String!) {
    fetchAllStakingRewards(where: $where) {
      id,
      contractAddress,
      ratio,
      amount,
      isClaimed,
      createdAt,
      updatedAt,
      stakingId,
      status
    },
    claimableStakingRewards(contractAddress: $contractAddress) {
      id,
      contractAddress,
      ratio,
      status,
      amount,
      isClaimed,
      createdAt,
      updatedAt,
      stakingId
    }
  }
`;

export const claimStakingRewards = gql`
  mutation($contractAddress: String!) {
    claimStakingRewards(contractAddress: $contractAddress) {
      id,
      rewards {
        rewardType,
        rewardValue,
        rewardCode,
        status,
        createdAt,
        Currency {
          symbol,
          name,
          ethAddress,
          riskFreeRate,
          exchangeRate,
          isUnderlying,
          isCollateral,
          decimalPlaces
        }
      },
      signature,
      receiver
      createdAt,
      status
    }
  }
`;

export const gaslessRestake = gql`
  mutation($contractAddress: String!) {
    gaslessRestake(contractAddress: $contractAddress) {
      id,
      depositAmount,
      withdrawnAmount,
      contractAddress,
      transactionHash,
      signature,
      blockNumber,
      createdAt
    }
  }
`;

export const gaslessWithdraw = gql`
  mutation($contractAddress: String!, $amount: String!) {
    unstakeGaslessRestaked(contractAddress: $contractAddress, amount: $amount) {
      id,
      depositAmount,
      withdrawnAmount,
      contractAddress,
      transactionHash,
      signature,
      blockNumber,
      createdAt
    }
  }
`;

export const claimableStakingRewards = gql`
  query($contractAddress: String!) {
    claimableStakingRewards(contractAddress: $contractAddress) {
      id,
      contractAddress,
      ratio,
      status,
      amount,
      isClaimed,
      createdAt,
      updatedAt,
      stakingId
    }
  }
`;
